import React, { useState } from "react";
import AsyncSelect from "react-select/async";
import "./inventoryStore.css";
import Arrow from "../../../Assets/icons/down-arrow.png";
import axios from "axios";
import IdGenerate from "../../../utils/id_generate";
import usePermissions from "../../../components/permissions/permission";

export default function InventoryStore() {
  const { hasPermission } = usePermissions();
  const [poShow, setPoShow] = useState(false);

  const [branch, setBranch] = useState("Navinna");
  const [poId, setPoId] = useState("");
  const [poDate, setPoDate] = useState("");
  const [userId, setUsetId] = useState("USER-00001");

  const [Po, setPo] = useState([]);
  const PoSearchHandler = async (e) => {
    if (e !== "") {
      try {
        const res = await axios.get(
          `${process.env.REACT_APP_BACKEND_URL}/inventory/searchByPoIdAndStatus/${e}/WAITING`
        );
        console.log(res.data);
        const options = res.data.map((po) => ({
          value: po.inventory_batch_id,
          label: po.po_id,
        }));
        setPo(options);
        return options;
      } catch (error) {}
    }
  };

  const [items, setItems] = useState([]);
  const GetPoData = async (po) => {
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}/inventory/inventoryByPoIdAndStatus/${po.value}/WAITING`
      );
      console.log(res.data);
      setPoId(res.data[0].po_id);
      setPoDate(res.data[0].inventory_purchase_date);

      setItems(res.data);
    } catch (error) {}
    // GetPoInfo(po.value)
  };

  const currentDate = new Date(); // Get the current date and time
  const formattedDate = currentDate.toISOString(); // Format the date to ISO string
  const SubmitHandler = async () => {
    console.log(poId);
    console.log(items);

    try {
      const res = await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/inventory/store`,
        {
          po_items: items,
          po_id: poId,
          updated_date: formattedDate,
          updated_user_id: userId,
          status: "ACTIVE",
        }
      );
      console.log(res.data);
      window.alert("Inventory Store Successfully");
    } catch (error) {
      window.alert("Inventory Store Failed");
      console.log(error);
    }
  };

  const customStyles = {
    control: (baseStyles, state) => ({
      // class attribute : class=" css-i32vvf-control"
      ...baseStyles,
      background: "white",
      borderRadius: "100px",
      borderColor: state.isFocused ? "grey" : "red",
      border: state.isFocused ? "none" : "none",
    }),
    valueContainer: (provided) => ({
      // class attribute : class=" css-i32vvf-valueContainer"
      ...provided,
      background: "transparent",
    }),
    input: (provided) => ({
      // class attribute : class=" css-i32vvf-input"
      ...provided,
      background: "transparent",
      placeholder: "Search",
    }),
    singleValue: (provided) => ({
      // class attribute : class=" css-i32vvf-singleValue"
      ...provided,
      background: "transparent",
    }),
    indicatorSeparator: (provided) => ({
      // class attribute : class=" css-i32vvf-indicatorSeparator"
      ...provided,
      background: "transparent",

      borderRadius: "100px",
    }),
    dropdownIndicator: (provided) => ({
      // class attribute : class=" css-i32vvf-dropdownIndicator"
      ...provided,
      background: "transparent",
    }),

    menu: (provided) => ({
      // 'menu' is from the div class too.
      ...provided,
      background: "white",
    }),
    Option: (provided) => ({
      ...provided,
      background: "red",
    }),
  };
  return (
    <div className="p-10 bg-background">
      <p className="header-1">Purchased Inventory Items Store</p>

      <div className="flex items-center ">
        <p className="label-1">Purchase Order Id</p>
        <p className="label-1">:</p>
        <AsyncSelect
          className="w-80"
          styles={customStyles}
          cacheOptions
          loadOptions={PoSearchHandler}
          // defaultOptions
          onChange={GetPoData}
          value={Po}
        />
      </div>

      <div className="w-full flex items-center justify-center ">
        <div className="bg-[#F3F8FF] rounded-[46px] border border-[#00000036] px-[200px] py-11 my-10 flex flex-col items-center justify-center">
          <p className="header-1">Purchase Order Details</p>
          <div className="flex flex-col flex-wrap justify-between items-center">
            <div className="w-[600px] flex items-center justify-between my-10">
              <p className="label-1">Purchase Order Id :</p>
              <p className="font-[22px] w-[355px] h-[46px] rounded-[23px] bg-[#FFFFFF] border border-[#00000036] px-7 flex items-center ">
                {poId}
              </p>
            </div>
            <div className="w-[600px] flex items-center justify-between my-10">
              <p className="label-1">User Name :</p>
              <p className="font-[22px] w-[355px] h-[46px] rounded-[23px] bg-[#FFFFFF] border border-[#00000036] px-7 flex items-center ">
                {userId}
              </p>
            </div>
            <div className="w-[600px] flex items-center justify-between my-10">
              <p className="label-1">Purchase Date :</p>
              <p className="font-[22px] w-[355px] h-[46px] rounded-[23px] bg-[#FFFFFF] border border-[#00000036] px-7 flex items-center ">
                {poDate}
              </p>
            </div>
          </div>
        </div>
      </div>

      <p className="header-1">Purchasing Items</p>

      <div className="w-[100%] flex items-center justify-center my-10 ">
        <div className="bg-[#F3F8FF] w-full min-h-96  rounded-t-[40px] rounded-b-[50px]">
          <div className="w-full h-20 bg-PrimaryColor2 rounded-[100px] flex items-center justify-around">
            <p className="w-10 p1 text-[#656565] text-center">#</p>
            <div className="w-[350px] flex flex-col items-center">
              <p className="w-full p1 text-[#656565] text-center ">Item Name</p>
              <div className="border-[3px] border-[#656565] w-[67px] rounded-[100px] mt-1"></div>
            </div>
            <div className="w-[160px] flex flex-col items-center">
              <p className="w-full p1 text-[#656565] text-center ">
                Supplied Qty
              </p>
              <div className="border-[3px] border-[#656565] w-[67px] rounded-[100px] mt-1"></div>
            </div>
            <div className="w-[110px] flex flex-col items-center">
              <p className="w-full p1 text-[#656565] text-center ">Store</p>
              <div className="border-[3px] border-[#656565] w-[67px] rounded-[100px] mt-1"></div>
            </div>
            <div className="w-[150px] flex flex-col items-center">
              <p className="w-full p1 text-[#656565] text-center ">Location</p>
              <div className="border-[3px] border-[#656565] w-[67px] rounded-[100px] mt-1"></div>
            </div>
            <div className="w-[70px] flex flex-col items-center">
              <p className="w-full p1 text-[#656565] text-center ">Unit</p>
              <div className="border-[3px] border-[#656565] w-[67px] rounded-[100px] mt-1"></div>
            </div>
            <div className="w-[170px] flex flex-col items-center">
              <p className="w-full p1 text-[#656565] text-center ">Status</p>
              <div className="border-[3px] border-[#656565] w-[67px] rounded-[100px] mt-1"></div>
            </div>
          </div>
          <div className="w-full h-[200px] flex flex-col gap-y-10">
            {items.length > 0
              ? items.map((item, index) => (
                  <div className="w-full  flex items-center justify-around">
                    <p className="w-10 p1 text-[#656565] text-center">
                      {index + 1}
                    </p>
                    <p className="w-[350px] p1 text-[#656565]  text-center">
                      {item.item_name}
                    </p>
                    <p className="w-[160px] p1 text-[#656565]  text-center">
                      {item.added_qty}
                    </p>
                    <p className="w-[110px] p1 text-[#656565]  text-center">
                      {item.store_name}
                    </p>
                    <input
                      className="w-[150px] border border-[#656565]"
                      value={item.location}
                      onChange={(e) => {
                        const data = [...items];

                        data[index].location = e.target.value;
                        setItems(data);
                      }}
                    />
                    <p className="w-[70px] p1 text-[#656565] ">
                      {item.item_measure_unit}
                    </p>

                    <select
                      className="w-[170px] border border-[#656565]"
                      value={item.item_status}
                      onChange={(e) => {
                        const data = [...items];
                        data[index].item_status = e.target.value;

                        setItems(data);
                      }}
                    >
                      <option value={"WAITING"}>PENDING</option>
                      <option value={"ACTIVE"}>RELEASED</option>
                    </select>
                  </div>
                ))
              : null}
          </div>
        </div>
      </div>
      <div className="flex w-full items-center justify-end gap-x-10 my-10">
        <button className="w-[118px] h-[45px] flex items-center justify-center bg-[#AA4343] border rounded-[23px] px-4 text-white font-bold text-xl">
          Cancel
        </button>
        {hasPermission(1211) && (
          <button
            className="w-[118px] h-[45px] flex items-center justify-center bg-[#5B6D49] border rounded-[23px] px-4 text-white font-bold text-xl"
            onClick={SubmitHandler}
          >
            Submit
          </button>
        )}
      </div>
    </div>
  );
}
