/** @format */

import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
// import DialogBox from "../New Purchase Request/newPurchaseDialogBox";
// import DialogBoxChairman from "../Request Review by/chairemanDialogbox";
import usePermissions from "../../../components/permissions/permission";

const Committees_Home = () => {
  const { hasPermission } = usePermissions();
  const navigate = useNavigate();

  const handleNavigatecategory = () => {
    navigate("/committees/members");
  };
  const handleNavigatecategory1 = () => {
    navigate("/committees");
  };

  const handleNavigateTechical = () => {
    navigate("/committees/techical");
  };

  const handleTenderOpeningCommittee = () => {
    navigate("/committees/tender");
  };

  const handleSentForApproval = () => {
    navigate("/committess/sentforApprovals");
  };

  const handleSampleTesting = () => {
    navigate("/committess/sampleTesting");
  };
  const handleSampleTestingList = () => {
    navigate("/committess/sampleTestingQuoList");
  };

  const handleOpeningPriceForm1 = () => {
    navigate("/committess/openingPriceFormQuo");
  };

  const handleOpeningPriceFormView1 = () => {
    navigate("/committess/openingPriceFormQuoList");
  };

  return (
    <div className="bg-background overflow-y-auto h-screen">
      <p className="text-[40px] font-sans ml-6 mt-6 font-bold text-[#071C50] ">
        Committees
      </p>

      {/* Cards with "Click Here" buttons */}
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-3 gap-12 mt-[5%] mx-6 ">
        {/* Card 1 */}

        {hasPermission(1910) && (
          <div className="bg-white rounded-[32px] p-4 shadow-md flex flex-col justify-between card">
            <div className="min-h-[110px] min-w-[150px]">
              <h2 className="text-[35px] text-[#797C80] font-semibold">
                Appoint
              </h2>
              <br />
              <h2 className="text-[25px] text-[#797C80] font-semibold mb-1">
                Committee Members
              </h2>
            </div>
            <div className="flex items-center">
              {hasPermission(2710) && (
                <button
                  className="bg-primary w-[30%] mt-2 ml-2 text-white font-bold py-2 px-4 rounded-[22px]"
                  onClick={handleNavigatecategory}
                >
                  Create
                </button>
              )}

              {hasPermission(2720) && (
                <button
                  className="bg-primary w-[30%] mt-2 ml-auto text-white font-bold py-2 px-4 rounded-[22px]"
                  onClick={handleNavigatecategory1}
                >
                  View
                </button>
              )}
            </div>
          </div>
        )}

        {/* Card 2 */}
        {hasPermission(1920) && (
          <div className="bg-white rounded-[32px] p-4 shadow-md flex flex-col justify-between card">
            <div className="min-h-[110px] min-w-[150px]">
              <h2 className="text-[25px] text-[#797C80] font-semibold">
                Technical Evaluation
              </h2>
              <br />
              <h2 className="text-[35px] text-[#797C80] font-semibold">
                Committee
              </h2>
            </div>
            <div className="flex items-center">
              <div className="flex justify-end w-full">
                <button
                  onClick={handleNavigateTechical}
                  className="bg-primary w-[30%] text-white font-bold py-2 px-4 rounded-[22px]"
                >
                  View
                </button>
              </div>
            </div>
            {/* {showDialog && <DialogBox onClose={closeDialog} />} */}
          </div>
        )}

        {/* Card 3 */}
        {hasPermission(1930) && (
          <div className="bg-white rounded-[32px] p-4 shadow-md flex flex-col justify-between card">
            <div className="min-h-[110px] min-w-[150px]">
              <h2 className="text-[25px] text-[#797C80] font-semibold">
                Sample Testing
              </h2>
              <br />
              <h2 className="text-[35px] text-[#797C80] font-semibold">
                Committee
              </h2>
            </div>
            <div className="flex items-center">
              {hasPermission(2730) && (
                <button
                  className="bg-primary w-[30%] mt-2 ml-2 text-white font-bold py-2 px-4 rounded-[22px]"
                  onClick={handleSampleTesting}
                >
                  Create
                </button>
              )}
              {hasPermission(2740) && (
                <button
                  onClick={handleSampleTestingList}
                  className="bg-primary w-[30%] mt-2 ml-auto text-white font-bold py-2 px-4 rounded-[22px]"
                >
                  View
                </button>
              )}
            </div>
            {/* {showChairDialog && <DialogBoxChairman onClose={closeDialogChair} />} */}
          </div>
        )}

        {/* Card 4 */}
        {hasPermission(1940) && (
          <div className="bg-white rounded-[32px] p-4 shadow-md flex flex-col justify-between card">
            <div className="min-h-[110px] min-w-[150px]">
              <h2 className="text-[25px] text-[#797C80] font-semibold">
                Tender
              </h2>
              <br />
              <h2 className="text-[35px] text-[#797C80] font-semibold">
                Committee
              </h2>
            </div>
            <div className="flex items-center">
              <div className="flex justify-end w-full">
                <button
                  onClick={handleTenderOpeningCommittee}
                  className="bg-primary w-[30%] mt-2 text-white font-bold py-2 px-4 rounded-[22px]"
                >
                  View
                </button>
              </div>
            </div>
          </div>
        )}

        {hasPermission(1950) && (
          <div className="bg-white rounded-[32px] p-4 shadow-md flex flex-col justify-between card">
            <div className="min-h-[110px] min-w-[150px]">
              <h2 className="text-[35px] text-[#797C80] font-semibold">
                Opening Price
              </h2>
              <br />
              <h2 className="text-[25px] text-[#797C80] font-semibold mb-1">
                From
              </h2>
            </div>
            <div className="flex items-center">
              {hasPermission(2750) && (
                <button
                  className="bg-primary w-[30%] mt-2 ml-2 text-white font-bold py-2 px-4 rounded-[22px]"
                  onClick={handleOpeningPriceForm1}
                >
                  Create
                </button>
              )}
              {hasPermission(2760) && (
                <button
                  className="bg-primary w-[30%] mt-2 ml-auto text-white font-bold py-2 px-4 rounded-[22px]"
                  onClick={handleOpeningPriceFormView1}
                >
                  View
                </button>
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Committees_Home;
