/** @format */

import React, { useState, useEffect } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import axios from "axios";
import Cookies from "js-cookie";
import cloudIcon from "../../../../Assets/icons/cloud-add.png";

const Sample_Testing = () => {
  const [files, setFiles] = useState([]);
  const [file, setFile] = useState(null);
  const [items, setItems] = useState([]);
  const [suppliers, setSuppliers] = useState([]);
  const { REACT_APP_API_ENDPOINT } = process.env;
  const [selectedItem, setSelectedItem] = useState(null);
  const userId = Cookies.get("employee_no");
  const currentDate = new Date(); // Get the current date and time
  const formattedDate = currentDate.toISOString().split("T")[0];
  const [imagePreview, setImagePreview] = useState(null);
  const [image, setImage] = useState(null);
  const [itemCategoryData, setItemCategoryData] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [searchQuery1, setSearchQuery1] = useState("");
  const [prResults, setPrResults] = useState([]);
  const [supplierResults, setSupplierResults] = useState([]);
  const [selectSupplierID, setSelectSupplierID] = useState("");
  const [selectSupplierName, setSelectSupplierName] = useState("");
  const [selectPRTitle, setPRTitle] = useState("");
  const [selectPRID, setPRID] = useState("");
  const [selectQuoID, setQuoID] = useState("");
  const [totalDiscountRate, setTotalDiscountRate] = useState(0);
  const [subTotal, setSubTotal] = useState(0);
  const [netTotal, setNetTotal] = useState(0);
  const [category, setCategory] = useState("");
  const [dateofSub, setDateofSub] = useState("");
  const [selectComStatus, setSelectComStatus] = useState("APPROVED");
  const [selectComType, setSelectComType] = useState(
    "SAMPLE TESTING COMMITTEE"
  );
  const [tableData, setTableData] = useState([]);
  const [samTestCom, setSamTestCom] = useState("");
  const [supName, setSupName] = useState("");
  // Handle search query change
  const handleSearchChange = async (event) => {
    const query = event.target.value;
    setSearchQuery(query);

    // Fetch search results when the query is not empty
    if (query.length > 1) {
      try {
        const response = await axios.get(
          `${REACT_APP_API_ENDPOINT}/v1/supplyChain/supplier/search/${query}`
        );
        setSupplierResults(response.data); // Assuming the API returns a list of suppliers
      } catch (error) {
        console.error("Error fetching suppliers:", error);
      }
    } else {
      setSupplierResults([]); // Clear results if query is too short
    }
  };

  // Handle supplier selection
  const handleSelectSupplier = (supplierID) => {
    const selectedSupplier = suppliers.find(
      (supplier) => supplier.supplier_id === supplierID
    );

    if (selectedSupplier) {
      setSelectSupplierID(selectedSupplier.supplier_id);
      setSelectSupplierName(selectedSupplier.supplier_name);
      setQuoID(selectedSupplier.qoutation_id);
      console.log("quouid", selectedSupplier.qoutation_id);
    }
  };

  // Handle search query change
  const handleSearchChange1 = async (event) => {
    const query = event.target.value;
    setSearchQuery1(query);

    // Fetch search results when the query is not empty
    if (query.length > 1) {
      try {
        const response = await axios.get(
          `${REACT_APP_API_ENDPOINT}/v1/supplychain/quatation/get-sent-quatation/${encodeURIComponent(
            query
          )}`
        );
        setPrResults(response.data); // Assuming the API returns a list of suppliers
      } catch (error) {
        console.error("Error fetching Purchase Req:", error);
      }
    } else {
      setPrResults([]); // Clear results if query is too short
    }
  };

  useEffect(() => {
    if (selectComStatus !== null) {
      fetchItemData();
    }
  }, [selectComStatus, selectComType]);

  const fetchItemData = async () => {
    try {
      const response = await fetch(
        `${REACT_APP_API_ENDPOINT}/v1/supplyChain/committee/members/all/committees?status=${selectComStatus}&committee_title=${selectComType}`
      );
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const data = await response.json();
      setItems(data);
      console.log("Data", data);
    } catch (error) {
      console.error("Error fetching employee data:", error);
    }
  };

  // Handle purchase request selection
  const handleSelectPurchaseReq = (pr) => {
    // Update common properties
    setPRID(pr.pr_id); // Set the common PR ID
    setPRTitle(pr.title); // Set the common PR title
    setSearchQuery1(pr.pr_id); // Set the common search query
    setCategory(pr.category);
    setSuppliers(pr.suppliers);

    // Clone the existing quotations to update the specific quotation's table data

    // Prepare the table data based on the selected purchase request
    const formattedTableData = pr.suppliers[0].items.map((item) => ({
      item_id: item.item_id,
      item_name: item.item_name,
    }));
    console.log("formattedTableData", formattedTableData);
    setTableData(formattedTableData);
    // Clear search results to close the dropdown
    setPrResults([]);
  };
  console.log("tableData", tableData);

  const handleSubmit = async () => {
    try {
      const formData = new FormData();

      // Add text data to the formData
      formData.append("pr_no", selectPRID);
      formData.append("committee_id", samTestCom);
      formData.append("quotation_id", selectQuoID);
      formData.append("supplier_id", selectSupplierID);
      formData.append("create_at", formattedDate);
      formData.append("create_by", userId);
      formData.append("internal_auditor_employee_id", userId);
      formData.append("internal_auditor_signature", image);

      // Extract item_id and item_qty from tableData and add it to the formData
      const items1 = tableData.map((item) => ({
        item_id: item.item_id,
        sample_no: item.sample_no,
        status: item.status,
      }));
      formData.append("items1", JSON.stringify(items1));
      // Dynamically append files to the formData
      tableData.forEach((item, index) => {
        if (item.uploadedFile) {
          formData.append(`testing_document${index + 1}`, item.uploadedFile); // Append each file with a dynamic name
        }
      });

      // Inspect the FormData content
      for (let pair of formData.entries()) {
        console.log(`${pair[0]}: ${pair[1]}`);
      }

      // Make the POST request using Axios
      const response = await axios.post(
        `${REACT_APP_API_ENDPOINT}/v1/supplyChain/sampleTestingCommittee/sample-testing-committee`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      // Handle the response
      console.log("Response:", response.data);
      alert("Sample Testing Report for Purchase Request successfully Uploaded");
      handleReset();
    } catch (error) {
      // Handle errors
      console.error("Error:", error);
      alert("Failed to add Quotation for Purchase Request");
    }
  };

  // Reset function
  const handleReset = () => {
    setPRTitle("");
    setPRID("");
    setSearchQuery("");
    setSearchQuery1("");
    setCategory("");
    setSupplierResults([]);
    setTableData([]);
    setSelectSupplierID("");
    setSelectedItem("");
    setFile("");
  };

  // Remove file
  const removeFile = () => {
    setFile(null); // Reset the file to null
  };
  //First Signature Box
  const handleDragOver = (e) => {
    e.preventDefault();
  };

  const handleDrop = (e) => {
    e.preventDefault();
    const file = e.dataTransfer.files[0];
    setImage(file);
    const reader = new FileReader();

    reader.onload = () => {
      setImagePreview(reader.result);
    };

    reader.readAsDataURL(file);
  };

  const calculateSubTotal = () => {
    const total = tableData.reduce((accumulator, item) => {
      return accumulator + (item.item_line_amount || 0); // Ensure item_line_amount is a number
    }, 0);

    setSubTotal(total.toFixed(2)); // Set subTotal with 2 decimal points
  };
  // useEffect to update subTotal whenever tableData changes
  useEffect(() => {
    calculateSubTotal();
  }, [tableData]);

  const handleInputChange = (index, key, value) => {
    const temp = [...tableData]; // Create a copy of tableData

    // Update the specific field in the row at the given index
    temp[index][key] = value;

    // Update the state with the modified tableData
    setTableData(temp);
  };

  // Function to handle file upload
  const handleFileUpload = (index, file) => {
    const updatedTableData = [...tableData];
    updatedTableData[index].uploadedFile = file; // Assign the uploaded file to the correct item
    setTableData(updatedTableData);
  };

  // Function to remove the uploaded file
  const handleFileRemove = (index) => {
    const updatedTableData = [...tableData];
    updatedTableData[index].uploadedFile = null; // Remove the file for that item
    setTableData(updatedTableData);
  };

  // Function to handle status change
  const handleStatusChange = (index, status) => {
    const updatedTableData = [...tableData];
    updatedTableData[index].status = status; // Update status for the item
    setTableData(updatedTableData);
  };
  // Function to handle status change
  const handleSampleNOChange = (index, status) => {
    const updatedTableData = [...tableData];
    updatedTableData[index].sample_no = status; // Update status for the item
    setTableData(updatedTableData);
  };
  return (
    <div className="bg-background overflow-y-auto h-screen relative">
      <div>
        <p className="text-[40px] font-sans ml-6 mt-[1%] font-bold text-[#071C50] ">
          Sample Testing Quotation Form
        </p>
      </div>

      <div className="bg-[#F3F8FF] w-[95%] mt-6 ml-6 rounded-[46px] relative p-6">
        <div className="grid grid-cols-2 gap-6">
          {/* Purchase Request No */}
          <div className="flex items-center">
            <p className="text-[22px] text-[black] font-bold mt-2 mb-2">
              Purchase Request No:
            </p>
            <input
              className="rounded-lg border border-gray-300 w-[60%] ml-4 mt-2 mb-2"
              type="text"
              value={searchQuery1}
              onChange={handleSearchChange1}
              placeholder="Search by Purchase Request No"
            />
            {/* Dropdown for search results */}
            {prResults.length > 0 && (
              <div className="absolute top-full w-[30%] left-[30%] bg-white border border-gray-300 z-10 max-h-40 overflow-y-auto shadow-lg">
                {prResults.map((pr) => (
                  <div
                    key={pr.pr_id}
                    onClick={() => handleSelectPurchaseReq(pr)}
                    className="px-4 py-2 hover:bg-gray-200 cursor-pointer"
                  >
                    {pr.pr_id} - {pr.title}
                  </div>
                ))}
              </div>
            )}
          </div>

          {/* Purchase Request Title */}
          <div className="flex items-center">
            <p className="text-[22px] text-[black] font-bold mt-2 mb-2">
              Purchase Request Title:
            </p>
            <input
              className="rounded-lg border border-gray-300 w-[60%] ml-4 mt-2 mb-2"
              value={selectPRTitle}
              readOnly
            />
          </div>

          {/* Sample Testing Committee */}
          <div className="flex items-center col-span-2">
            <p className="text-[22px] text-[black] font-bold mt-2 mb-2">
              Sample Testing Committee:
            </p>
            <select
              value={samTestCom}
              onChange={(e) => setSamTestCom(e.target.value)}
              className="rounded-lg border border-[#00000036] ml-4 w-[30%]"
            >
              <option>Select the Committee</option>
              {items.map((item, index) => (
                <option key={index} value={item.committee_id}>
                  {item.committee_id} - {item.committee_title}
                </option>
              ))}
            </select>
          </div>
        </div>
      </div>

      <div className="w-full flex flex-col items-center justify-center">
        <div className="bg-[#F3F8FF] w-[90%] rounded-[46px] border border-[#00000036] px-[200px] py-11 my-10 flex flex-col items-center justify-center">
          <p className="header-1 text-center">Quotation</p>

          {/* Search input for supplier name */}
          <div className="w-[600px] flex items-center justify-between my-10 relative">
            <label className="label-1">Supplier Name</label>
            <label className="label-1">:</label>
            <select
              value={selectSupplierID}
              onChange={(e) => handleSelectSupplier(e.target.value)}
              className="input-1 border border-[#00000036]"
            >
              <option>Select the Supplier</option>
              {suppliers.map((item, index) => (
                <option key={index} value={item.supplier_id}>
                  {item.supplier_id} - {item.supplier_name}
                </option>
              ))}
            </select>
          </div>

          {/* Supplier ID input (auto-populated) */}
          {/* Supplier ID input (auto-populated) */}
          <div className="w-[600px] flex items-center justify-between my-10">
            <label className="label-1">Supplier ID</label>
            <label className="label-1">:</label>
            <input
              className="input-1 border border-[#00000036]"
              type="text"
              value={selectSupplierID}
              readOnly // Make the input read-only as it will be auto-populated
            />
          </div>

          <div className="w-[600px] flex items-center justify-between my-10">
            <label className="label-1">Date of Submission</label>
            <label className="label-1">:</label>
            <DatePicker
              className="input-1 border border-[#00000036]"
              placeholderText="Enter Date of Submission"
              selected={dateofSub}
              onChange={(date) => setDateofSub(date)}
            />
          </div>

          <p className="header-1 mb-10">Quotation Item Details</p>
          <div className="flex flex-col w-[120%] bg-[#F3F8FF] rounded-[46px] border border-[#00000036] mt-10">
            <div className="w-full h-[86px] flex items-center justify-around bg-[#BFD8AE] rounded-[46px] mr-5">
              <p className="w-10 p1 text-[#656565] text-center">#</p>
              <div className="w-[250px] flex flex-col items-center">
                <p className="w-full p1 text-[#656565] text-center ">
                  Item Name
                </p>
                <div className="border-[3px] border-[#656565] w-[67px] rounded-[100px] mt-1"></div>
              </div>
              <div className="w-[250px] flex flex-col items-center">
                <p className="w-full p1 text-[#656565] text-center ">
                  Sample Number
                </p>
                <div className="border-[3px] border-[#656565] w-[67px] rounded-[100px] mt-1"></div>
              </div>
              <div className="w-[200px] flex flex-col items-center">
                <p className="w-full p1 text-[#656565] text-center ">Status</p>
                <div className="border-[3px] border-[#656565] w-[67px] rounded-[100px] mt-1"></div>
              </div>
              <div className="w-[200px] flex flex-col items-center">
                <p className="w-full p1 text-[#656565] text-center ">
                  Testing Document
                </p>
                <div className="border-[3px] border-[#656565] w-[67px] rounded-[100px] mt-1"></div>
              </div>
            </div>
            <div className="flex flex-col  gap-y-5 my-3 h-[250px] overflow-y-scroll">
              {tableData.length > 0
                ? tableData.map((item, index) => (
                    <div key={index} className="w-full  flex justify-around ">
                      <div className="w-10 flex items-center">
                        <p className="w-full p1 text-[#656565] text-center ">
                          {index + 1}
                        </p>
                      </div>
                      <div className="w-[250px] flex  items-center">
                        <p className="w-full p1 text-[#656565] text-center ">
                          {item.item_name}
                        </p>
                      </div>
                      <div className="w-[250px] flex  items-center">
                        <p className="w-full p1 text-[#656565] text-center ">
                          <input
                            type="text"
                            className="w-[80%] border border-[#797979] rounded-[40px] px-[10px]"
                            value={item.sample_no || ""}
                            onChange={(e) =>
                              handleSampleNOChange(index, e.target.value)
                            }
                          />
                        </p>
                      </div>
                      <div className="w-[200px] flex  items-center">
                        <select
                          className="border border-gray-300 rounded-lg px-2 py-2 w-full text-xl"
                          value={item.status || ""}
                          onChange={(e) =>
                            handleStatusChange(index, e.target.value)
                          }
                        >
                          <option value="" className="text-gray-400">
                            Suitable/Not Suitable
                          </option>
                          <option value="Suitable">Suitable</option>
                          <option value="Not Suitable">Not Suitable</option>
                        </select>
                      </div>
                      <div className="w-[200px] flex  items-center">
                        <input
                          type="file"
                          onChange={(e) =>
                            handleFileUpload(index, e.target.files[0])
                          }
                          className="text-gray-600 bg-white border border-gray-300 rounded-md px-3 py-2 focus:outline-none"
                        />
                        {item.uploadedFile && (
                          <p className="text-sm mt-2">
                            File: {item.uploadedFile.name}{" "}
                            <button
                              className="text-red-500"
                              onClick={() => handleFileRemove(index)}
                            >
                              Remove
                            </button>
                          </p>
                        )}
                      </div>
                    </div>
                  ))
                : null}
            </div>
          </div>
        </div>
      </div>
      {/* Signarute Image Uploader */}

      <div className="  grid grid-cols-1 ml-6 ">
        {/* Drag and drop area */}
        <div
          className="flex flex-col items-center justify-center mt-6"
          onDragOver={handleDragOver}
          onDrop={handleDrop}
        >
          {imagePreview ? (
            <img
              src={imagePreview}
              alt="Preview"
              className="w-40 h-40 rounded-lg mb-4"
            />
          ) : (
            <div className="bg-white border border-gray-300 w-50 h-60 rounded-lg flex flex-col items-center justify-center mb-6">
              <p className="text-black font-bold ml-2 mt-2 text-[24px]">
                Internal Auditor
              </p>
              <img
                src={cloudIcon}
                alt="Cloud Icon"
                className="w-16 h-16 mb-2"
              />
              <p className="text-[#8691AB] font-bold ml-4">
                Drag and Drop Digital Signature
              </p>
            </div>
          )}
        </div>
      </div>

      <div className="w-full flex items-center justify-end gap-x-6 my-10 px-16">
        <button
          className="text-white font-bold text-2xl py-2 px-4 rounded-lg bg-[#AA4343]"
          onClick={handleReset}
        >
          Cancel
        </button>
        <button
          onClick={handleSubmit}
          className="bg-[#5B6D49] hover:bg-[#495e33] text-white font-bold text-2xl py-2 px-4 rounded-lg"
        >
          Submit a Quotation
        </button>
      </div>
    </div>
  );
};

export default Sample_Testing;
