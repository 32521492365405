/** @format */

import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { GrPrevious, GrNext } from "react-icons/gr";
import CSV from "../../Assets/icons/csv.png";
import approvalpending from "../../Assets/icons/image.png";
import usePermissions from "../../components/permissions/permission";

const ViewApproveItemReqFromBranch = () => {
  const { hasPermission } = usePermissions();
  const navigate = useNavigate();
  const [approvedBranchReq, setApprovedBranchReq] = useState([]);
  const [filterOption, setFilterOption] = useState("");
  const [searchQuery, setSearchQuery] = useState("");
  const [searchMonth, setSearchMonth] = useState("");
  const [searchYear, setSearchYear] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(50);
  const { REACT_APP_API_ENDPOINT } = process.env;

  useEffect(() => {
    fetchApprovedReqData();
  }, []);

  const fetchApprovedReqData = async () => {
    try {
      const response = await fetch(
        `${REACT_APP_API_ENDPOINT}/v1/inventory/inventoryRequest/getReleasedRequests`
      );
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const data = await response.json();
      const filteredData = data.filter((item) =>
        item.inventory_request_by.startsWith("BRANCH")
      );
      setApprovedBranchReq(filteredData);
      console.log("Approvedreq:", filteredData);
    } catch (error) {
      console.error("Error fetching employee data:", error);
    }
  };

  const handleFilterChange = (e) => {
    setFilterOption(e.target.value);
  };

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };

  const handleMonthChange = (e) => {
    setSearchMonth(e.target.value);
  };

  const handleYearChange = (e) => {
    setSearchYear(e.target.value);
  };

  const HandleOnClick = (itemPenReq) => {
    // Navigate to the new page and pass the object
    navigate("/approved-branch-req", {
      state: { selectedRecord: itemPenReq },
    });
  };

  // Pagination Logic
  const indexOfLastReq = currentPage * itemsPerPage;
  const indexOfFirstReq = indexOfLastReq - itemsPerPage;
  const filteredBranchReq = approvedBranchReq.filter((BranchReq) => {
    const searchMatch =
      !searchQuery ||
      BranchReq.inventory_request_id
        .toLowerCase()
        .includes(searchQuery.toLowerCase()) ||
      BranchReq.inventory_request_by
        .toLowerCase()
        .includes(searchQuery.toLowerCase()) ||
      BranchReq.item_id.toLowerCase().includes(searchQuery.toLowerCase());

    const monthMatch =
      !searchMonth ||
      new Date(BranchReq.inventory_request_date).getMonth() + 1 ===
        parseInt(searchMonth);

    const yearMatch =
      !searchYear ||
      new Date(BranchReq.inventory_request_date).getFullYear().toString() ===
        searchYear;

    return searchMatch && monthMatch && yearMatch;
  });

  const currentApprovedReq = filteredBranchReq.slice(
    indexOfFirstReq,
    indexOfLastReq
  );

  // console.log("currentApprovedReq", currentApprovedReq);
  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const handleExportCSV = () => {
    const csvContent =
      "data:text/csv;charset=utf-8," +
      approvedBranchReq.map((row) => Object.values(row).join(",")).join("\n");

    const encodedUri = encodeURI(csvContent);
    const link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    link.setAttribute("download", "employee_data.csv");
    document.body.appendChild(link);
    link.click();

    setTimeout(() => {
      window.alert("Successfully Downloaded");
    }, 100);
  };

  return (
    <div className="bg-background h-screen overflow-y-auto">
      <p className="text-[25px] font-sans ml-6 mt-6 font-bold text-[#797C80]">
        View Approved Branch Item Requests Details
      </p>
      <div className="flex justify-between overflow-hidden pb-6">
        <div>
          <div className="flex flex-col font-poppins font-bold ml-6 mt-4">
            <div className="relative font-sans group hidden sm:block">
              <input
                type="text"
                placeholder="Search by Requested ID, Branch"
                value={searchQuery}
                onChange={handleSearchChange}
                className="w-[200px] sm:w-[300px] h-10 duration-300 rounded-full border border-white px-2 py-1"
              />
            </div>
          </div>
          <div className="relative font-sans group hidden sm:block">
            <select
              id="monthFilter"
              name="monthFilter"
              value={searchMonth}
              onChange={handleMonthChange}
              className="w-[200px] font-bold ml-6 mt-4 sm:w-[300px] h-10 duration-300 rounded-full border border-white px-2 py-1"
            >
              <option
                className="placeholder-white sm:text-sm px-2 font-sans"
                value=""
              >
                Filter By Requested Month
              </option>
              <option value="01">January</option>
              <option value="02">February</option>
              <option value="03">March</option>
              <option value="04">April</option>
              <option value="05">May</option>
              <option value="06">June</option>
              <option value="07">July</option>
              <option value="08">August</option>
              <option value="09">September</option>
              <option value="10">October</option>
              <option value="11">November</option>
              <option value="12">December</option>
            </select>
          </div>
          <div className="relative font-sans group hidden sm:block">
            <select
              id="yearFilter"
              name="yearFilter"
              onChange={handleYearChange}
              className="w-[200px] font-bold ml-6 mt-4 sm:w-[300px] h-10 duration-300 rounded-full border border-white px-2 py-1"
            >
              <option value="">Filter By Requested Year</option>
              {Array.from(
                { length: 20 },
                (v, i) => new Date().getFullYear() - i
              ).map((year) => (
                <option key={year} value={year}>
                  {year}
                </option>
              ))}
            </select>
          </div>
        </div>
      </div>
      <div className="overflow-y-auto max-h-[calc(100vh-200px)] ml-5 mr-5 rounded-[22px] ">
        <table className="table-auto border-none overflow-x-auto w-full">
          <thead>
            <tr className="bg-white font-sans text-[16px]">
              <th className="text-primary px-6 py-4">Requested Order ID</th>
              <th className="text-primary px-6 py-4">Requested User ID</th>
              <th className="text-primary px-6 py-4">Requested Order Date</th>
              <th className="text-primary px-6 py-4">Branch Name</th>
              <th className="text-primary px-6 py-4">Status</th>
              <th className="text-primary px-6 py-4">Released ID</th>
              <th className="text-primary px-6 py-4">Released User ID</th>
              <th className="text-primary px-6 py-4">Released Date</th>
              <th className="text-primary px-6 py-4">Action</th>
            </tr>
          </thead>
          <tbody className="font-semibold font-sans">
            {currentApprovedReq.map((BranchItemReq) => (
              <tr key={BranchItemReq.id}>
                <td className="bg-white px-6 py-4 text-center">
                  {BranchItemReq.inventory_request_id}
                </td>
                <td className="bg-white px-6 py-4 text-center">
                  {BranchItemReq.inventory_request_user_id}
                </td>
                <td className="bg-white px-6 py-4 text-center">
                  {BranchItemReq.inventory_request_date}
                </td>
                <td className="bg-white px-6 py-4 text-center">
                  {BranchItemReq.inventory_request_by}
                </td>
                <td className="bg-white px-6 py-4 text-center">
                  {BranchItemReq.inventory_request_status}
                </td>
                <td className="bg-white px-6 py-4 text-center">
                  {BranchItemReq.inventory_release_id}
                </td>
                <td className="bg-white px-6 py-4 text-center">
                  {BranchItemReq.inventory_release_user_id}
                </td>
                <td className="bg-white px-6 py-4 text-center">
                  {BranchItemReq.inventory_release_date}
                </td>
                <td className="bg-white px-6 py-4 text-center">
                  {hasPermission(2750) && (
                    <button
                      className="bg-gray-300 p-2 items-center rounded-md w-[200px]"
                      onClick={() => HandleOnClick(BranchItemReq)}
                    >
                      View
                    </button>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <div className="flex justify-center mt-4">
        <button
          onClick={() => paginate(currentPage - 1)}
          disabled={currentPage === 1}
          className="bg-primary text-white px-4 py-2 mx-1 rounded-md"
        >
          <GrPrevious className="w-3 h-3" />
        </button>
        {[...Array(Math.ceil(filteredBranchReq.length / itemsPerPage))].map(
          (page, index) => (
            <button
              key={index}
              onClick={() => paginate(index + 1)}
              className={`${
                currentPage === index + 1 ? "bg-gray-300" : "bg-white"
              } text-primary px-4 py-2 mx-1 rounded-md`}
            >
              {index + 1}
            </button>
          )
        )}
        <button
          onClick={() => paginate(currentPage + 1)}
          disabled={
            currentPage === Math.ceil(filteredBranchReq.length / itemsPerPage)
          }
          className="bg-primary text-white px-4 py-2 mx-1 rounded-md"
        >
          <GrNext className="w-3 h-3" />
        </button>
      </div>
      <div className="flex justify-end mb-4 mr-4">
        <button className="bg-primary text-white font-sans p-3 rounded-[20px]">
          <div className="flex items-center" onClick={handleExportCSV}>
            <div onClick={handleExportCSV} className="font-semibold">
              Export CSV
            </div>
            <div>
              <img className="h-8 w-8" src={CSV} alt="logo-csv" />
            </div>
          </div>
        </button>
      </div>
    </div>
  );
};

export default ViewApproveItemReqFromBranch;
