/** @format */

import "./App.css";
import SideMenu from "./components/sidebar/sidebar.jsx";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  useLocation,
  BrowserRouter,
} from "react-router-dom";
import Login from "./components/login/login";

//pos
import Pos from "./modules/pos/pos/pos";
import Bill from "./modules/pos/bills/bill";
import PosDashboard from "./modules/pos/pos dashboard/posDashboard";
import PosItems from "./modules/pos/positems/positems.jsx";
import MobilePos from "./modules/pos/mobile-pos/mobile-pos.jsx";
import PosUsersCreate from "./modules/pos/user create/userCreate.jsx";
import Return_Home from "./modules/pos/return/return_home/return_home.jsx";
import Return_Sale from "./modules/pos/return/return_sale/return_sale.jsx";
import Return_Bill from "./modules/pos/return/return_bill/return_bills.jsx";
import Pos_Report from "./modules/customer/pos_report/pos_report.jsx";
//inventory
import InventoryDispose from "./modules/inventory/disposal/inventoryDispose";
import InventoryDashboard from "./modules/inventory/inventory dashboard/inventoryDashboard";
import InventoryDashboardNav from "./modules/inventory/inventory dashboard/inventoryDashboard_nav.jsx";
import InventoryExpireItem from "./modules/inventory/inventory dashboard/inventoryExpireItem.jsx";
import InventoryPurchase from "./modules/inventory/inventory purchase/inventoryPurchase";
import InventoryPurchaseOrders from "./modules/inventory/inventory purchase/inventoryPurchasesTable.jsx";
import InventoryPurchaseHistoryLog from "./modules/inventory/inventory purchase/inventoryPurchasesTableHistoryLog.jsx";
import InventoryPurchaseViewItems from "./modules/inventory/inventory purchase/inventoryPurchaseViewItems.jsx";

import InventoryStore from "./modules/inventory/inventory store/inventoryStore";
import StoreKeeperRelease from "./modules/inventory/release/store keeper/StoreKeeperRelease";
import InventoryRequest from "./modules/inventory/request/inventory request from store keeper/inventoryRequest";
import DisposedItems from "./modules/inventory/disposed items/disposedItems.jsx";
import DisposedItemsApproval1 from "./modules/inventory/Approval/disposalApproval1.jsx";
import DisposedItemsApproval2 from "./modules/inventory/Approval/disposalApproval2.jsx";
import DisposedItemsApproval3 from "./modules/inventory/Approval/disposalApproval3.jsx";
import InventoryReturns from "./modules/inventory/Inventory Returns/inventoryReturns.jsx";
import InventoryAdjustment from "./modules/inventory/Inventory Adjustment/inventoryAdjustment.jsx";
import AdjustedItems from "./modules/inventory/adjusted Items/adjustedItems.jsx";
import AdjustedItemsApproval1 from "./modules/inventory/adjusted Items/adjustmentApproval1.jsx";
import AdjustedItemsApproval2 from "./modules/inventory/adjusted Items/adjustmentApproval1.jsx";
import AdjustedItemsApproval3 from "./modules/inventory/adjusted Items/adjustmentApproval1.jsx";
import InventoryRequestNav from "./modules/inventory/inventoryRequest_nav.jsx";
import InventoryReturnRequestNav from "./modules/inventory/Inventory Returns/inventoryRequest_nav.jsx";
import InventoryPurchaseNav from "./modules/inventory/inventory purchase/inventoryPurchases_nav.jsx";
import InventoryBranchRequest from "./modules/inventory/request/Branch Requests/branchInventoryRequest.jsx";
import InventoryBranchItemReturnRequest from "./modules/inventory/Inventory Returns/branchItemReturnRequest.jsx";
import ReleasedReturnReqBranchView from "./modules/inventory/Inventory Returns/releasedItemReturnReqFromBranchView.jsx";
import InventoryBranchItemReturnReleasedRequest from "./modules/inventory/Inventory Returns/branchItemReturnReleasedRequest.jsx";
import InventoryBranchItemReturnReleasedRequestView from "./modules/inventory/Inventory Returns/releaseItemReturnReqFromBranch.jsx";
import ApproveInventoryBranchRequest from "./modules/inventory/request/Branch Requests/approveItemReqFromBranch.jsx";
import ViewItemApprovedInventoryBranchRequest from "./modules/inventory/request/Branch Requests/viewApprovedbranchInventoryRequest.jsx";
import ViewApprovedInventoryBranchRequest from "./modules/inventory/request/Branch Requests/approvedItemReqFromBranch.jsx";
import ViewHistoryLogInventoryBranchRequest from "./modules/inventory/request/Branch Requests/viewHistoryLogsbranchInventoryRequest.jsx";
import HistoryLogItemsInventoryBranchRequestInventory from "./modules/inventory/request/Branch Requests/historyLogItemReqFromBranch.jsx";
import InventoryProdctionRequest from "./modules/inventory/request/Production Requests/productionInventoryRequest.jsx";
import InventoryProdctionItemsRequest from "./modules/inventory/request/Production Requests/ItemsReqFromProduction.jsx";
import InventoryProdctionRequestReleased from "./modules/inventory/request/Production Requests/releasedItemReqFromProduction.jsx";
import InventoryProdctionRequestReleasedView from "./modules/inventory/request/Production Requests/viewReleasedProductionInventoryRequest.jsx";

import AddItemManually from "./modules/inventory/addItemManually.jsx";
import InventoryMobilePOSRequest from "./modules/inventory/request/Mobile POS Requests/mobilePOSInventoryRequest.jsx";
import ItemsMobilePOSRequest from "./modules/inventory/request/Mobile POS Requests/ItemsReqFromMobilePOS.jsx";
import MobilePOSReturnRequest from "./modules/inventory/Inventory Returns/mobilePOSItemReturnReq.jsx";
import MobilePOSReturnRequestList from "./modules/inventory/Inventory Returns/mobilePOSItemReturnReleasedRequestList.jsx";
import ViewItemsReleasedMobilePOSRequest from "./modules/inventory/request/Mobile POS Requests/releasedItemReqFromMobilePOS.jsx";
import ViewReleasedMobilePOSRequest from "./modules/inventory/request/Mobile POS Requests/viewReleasedMobilePOSInventoryRequest.jsx";
import ViewReleasedMobilePOSHistoryLogRequest from "./modules/inventory/request/Mobile POS Requests/viewHistoryLogsMobilePOSInventoryRequest.jsx";
import ViewReleasedMobilePOSHistoryLogItemsRequest from "./modules/inventory/request/Mobile POS Requests/historyLogItemReqFromMobilePOS.jsx";

//customer
import AddCustomer from "./modules/customer/add customer/addCustomer";
import UpdateCustomer from "./modules/customer/update customer/updateCustomer";
import CreditClearance from "./modules/customer/credit clearance/creditClearance";
import CustomerType from "./modules/customer/customer type/CustomerType.jsx";
import ViewUpdateCustomer from "./modules/customer/view and update customer/ViewUpdateCustomer.jsx";
//item category
import AddItemCategory from "../src/modules/item category/add item category/addItemCategory";
import UpdateItemCategory from "../src/modules/item category/update item category/updateItemCategory";
import ViewItemCategory from "../src/modules/item category/view item category/viewItemCategory.jsx";

//item
import CreateItem from "./modules/items/create item/createItem";
import UpdateItem from "./modules/items/update item/updateItem";
import ViewItem from "./modules/items/view item/viewItem";

//branch
import AddBranches from "./modules/branches/add branch/addBranches";
import UpdateBranches from "./modules/branches/update branch/updateBranches";
import ViewBranches from "./modules/branches/view Branch/viewBranch.jsx";

//stores
import AddStores from "./modules/stores/addStores/addStores";
import UpdateStores from "./modules/stores/updateStores/updateStores";
import ViewStores from "./modules/stores/viewstore/viewStore.jsx";

//supplier
import AddSupplier from "./modules/supplier/add supplier/addSupplier";
import UpdateSupplier from "./modules/supplier/update supplier/updateSupplier";

//user
import AddUser from "./modules/users/add user/addUser";
import UpdateUser from "./modules/users/update user/updateUser";
import UserRole from "./modules/users/user role/userRole";

//*production planing-------------------------------------------------------------------------->

//recipe
import CreateRecipe from "./modules/production_planing/recipe/CreateRecipe";
import CreatedRecipe from "./modules/production_planing/recipe/CreatedRecipe";
import PendingRecipes from "./modules/production_planing/recipe/PendingRecipes";
import RejectedRecipe from "./modules/production_planing/recipe/RejectedRecipes";

//*job
import CreateJob from "./modules/production_planing/job/CreateJob";
import CreatedJob from "./modules/production_planing/job/CreatedJob";
import CreatedJobList from "./modules/production_planing/job/CreatedJobLists.jsx";

import AddItemstoCreatedJob from "./modules/production_planing/job/AddItemCreatedJobs.jsx";

import RejectedJobs from "./modules/production_planing/job/RejectedJob";
import PendingJobs from "./modules/production_planing/job/PendingJob";

//*unit
import Unit from "./modules/production_planing/unit/Unit";
import ChargePerHour from "./modules/production_planing/unit/ChargePerHour";

//*manage production
import Manage_Production from "./modules/production_planing/manage_production/Manage_Production";

//*production
import StartJob from "./modules/production_planing/production/StartJob";
import CreateProduction from "./modules/production_planing/production/CreateProduction";

//*warehouse
import WarehouseMangement from "./modules/production_planing/warehouse/WarehouseMangement";
import WarehouseCompleteTable from "./modules/production_planing/warehouse/WarehouseCompleteTable";
import WarehousePending from "./modules/production_planing/warehouse/WarehousePending";
import WarehousePendingForPricing from "./modules/production_planing/warehouse/WarehousePendingForPricing.jsx";
import ManageReject from "./modules/production_planing/warehouse/WarehouseReject";
import ManageAdjustments from "./modules/production_planing/warehouse/WarehouseAdjustment";
import Job_Nav from "./modules/production_planing/job_nav.jsx";
import Recipe_Nav from "./modules/production_planing/recipe_nav.jsx";
import Warehouse_Nav from "./modules/production_planing/warehouse_nav.jsx";
//auth
import UserAuth from "./Auth/posUserAuth";

//Designation
import AddDesignation from "./modules/designation/add designation/adddesignation";
import UpdateDesignation from "./modules/designation/update designation/updatedesignation";

//Training Type
import AddTraningType from "./modules/training_type/add_training_type";
import UpdateTrainingType from "./modules/training_type/update_training_type";

//Hris
import Dashboard from "./modules/hris/dashboard/card.jsx";
import Onboard from "./modules/hris/employee/emp_onBoard.jsx";
import Emp_Nav from "./modules/hris/employee/emp_nav.jsx";
import View_Export_Table from "./modules/hris/employee/view_export/view_export_table.jsx";
import History_Log from "./modules/hris/employee/history_log.jsx";
import User_Profile from "./modules/hris/employee/user_profile.jsx";
import Attendance from "./modules/hris/attendance/Attendance.jsx";
import Inandout from "./modules/hris/attendance/in_out/in_and_out_time.jsx";
import Inandout_Shift from "./modules/hris/attendance/in_out/in_and_out_time_shift.jsx";
import Leave from "./modules/hris/attendance/leave/leave.jsx";
import Lateness_Permission from "./modules/hris/attendance/late_permission/summary-report.jsx";
import Leave_Doc from "./modules/hris/attendance/late_permission/document_log.jsx";
import OT from "./modules/hris/attendance/OT/OT.jsx";
import Overtime from "./modules/hris/payrole/incentive/overtimr.jsx";
import leaveapproval1 from "./modules/hris/approval_process/approval1/Leave_approve.jsx";
import Attendance_Edit from "./modules/hris/attendance/leave/attendance_edit/attendance_edit.jsx";
import Overtime_Assigning_Nav from "./modules/hris/attendance/overtime_assigning/overtime_assigning_nav.jsx";
import Overtime_Assigning from "./modules/hris/attendance/overtime_assigning/overtime_assigning.jsx";
import Overtime_Approval from "./modules/hris/approval_process/approval1/overtime_approval.jsx";
import Attendance_Edit_Approval1 from "./modules/hris/approval_process/approval1/attendance_edit_approval.jsx";

// newly added section in HRIS
import Emp_Leave_Management from "./modules/hris/attendance/leave/leave_managment/leave_management.jsx";
import Leave_Approve from "./modules/hris/attendance/leave/leave_approve/leave_approve.jsx";
import Date_Restrictions from "./modules/hris/attendance/leave/restricted_date.jsx/restricted_date.jsx";
import Leave_Request from "./modules/hris/attendance/leave/leave_request/leave_request.jsx";
import Timetable from "./modules/hris/attendance/leave/timetable_management/timetable.jsx";
import Return_Pos_Bill from "./modules/pos/return/return_sale/return_pos_bill.jsx";
import AllowancesandDeductions from "./modules/hris/payrole/month-end/allowance_and_deduction_table.jsx";
import Training from "./modules/hris/performance/trainings/training_cards.jsx";
import Completed_Training from "./modules/hris/performance/trainings/completed_training.jsx";
import Upcoming_Trainings from "./modules/hris/performance/trainings/upcoming_trainings.jsx";
import Examinations_Card from "./modules/hris/performance/examinations/examinations_card.jsx";
import Reports_Analytics from "./modules/hris/reports_analytics/reports_analytics.jsx";
import Performance_Nav from "./modules/hris/performance/performance_nav.jsx";
import Completed_Examinations from "./modules/hris/performance/examinations/completed/completed_examinations.jsx";
import Upcoming_Examinations from "./modules/hris/performance/examinations/upcoming/upcoming_examinations.jsx";
import Salary_Increments from "./modules/hris/performance/salary/salary_increments.jsx";
import Leave_Management from "./modules/hris/leave_management/leave-nav.jsx";
import Leave_Info from "./modules/hris/leave_management/leave-info.jsx";
import Loan_Nav from "./modules/hris/loan_information/loan_nav.jsx";
import View_Loan_Info from "./modules/hris/loan_information/view_loan_info.jsx";
import Review_Guarantors from "./modules/hris/loan_information/review_guarantors.jsx";
import Loan_Elibility from "./modules/hris/loan_information/loan_eligibility.jsx";
import Leave_Reports from "./modules/hris/reports_analytics/leave_reports_info.jsx";
import Approval1 from "../src/modules/hris/approval_process/approval1/approval1.jsx";
import Approval2 from "../src/modules/hris/approval_process/approval2/approval2.jsx";
import Approval1_Info from "./modules/hris/approval_process/approval1/Leave_approve.jsx";
import Approval2_Info from "../src/modules/hris/approval_process/approval2/approval2_table.jsx";
import Approval1user from "../src/modules/hris/approval_process/approval1/approve1_tableuser.jsx";
import Approval2user from "../src/modules/hris/approval_process/approval2/appoval_user_details.jsx";
import Allpayments from "./modules/hris/payrole/month-end/all_allowance.jsx";
import Absense_Report from "../src/modules/hris/attendance/timetable_attendance/absense_report.jsx";
import Payments_Menu from "../src/modules/hris/payrole/payroll_nav.jsx";
import Allowances from "./modules/hris/payrole/month-end/allowances.jsx";
import Deductions from "./modules/hris/payrole/month-end/deduction.jsx";
import AllPayroll from "./modules/hris/payrole/month-end/all_payroll.jsx";
import AllDeductions from "./modules/hris/payrole/month-end/all_deduction.jsx";
import All_allowance from "./modules/hris/payrole/month-end/all_allowance.jsx";
import MonthendPayroll from "./modules/hris/payrole/month-end/monthendpayroll.jsx";
import AttendanceReport from "./modules/hris/attendance/attendance_report/attendance_report.jsx";
//suppychain
import Per_Request from "./modules/supply/Purchasing/Purchasing Request/request.jsx";
import Per_Request_Form from "./modules/supply/Purchasing/Purchase Request Form/requestform.jsx";
import PurchaseReqNav from "./modules/supply/Purchasing/Purchase Request Form/purchaseRequestNav.jsx";
import PurchaseReqSubjectNav from "./modules/supply/Purchasing/Subjects/subjectsNav..jsx";
import PurchaseRequestView from "./modules/supply/Purchasing/View Purchase Request/view.jsx";
import PurchaseReqAskQuatation from "./modules/supply/Purchasing/Send Quatation/sendQuatation.jsx";
import PurchaseReqAskQuo from "./modules/supply/Purchasing/Send Quatation/askQuo.jsx";
import PurchaseRequestPendingView from "./modules/supply/Purchasing/Pending Purchase Request/viewPR.jsx";
import PurchaseRequestViewSubject from "./modules/supply/Purchasing/Subjects/viewPR.jsx";
import PurchaseRequestViewChairman from "./modules/supply/Purchasing/Request Review by/view.jsx";
import Decisions_home from "./modules/supply/Decisions/Decisions Home/decisionsNav.jsx";
import PendingApproveQuo from "./modules/supply/Decisions/Decisions Home/pendingTenderQuoView.jsx";
import Decisions_Chairman_Approval from "./modules/supply/Decisions/Decisions Home/decisionChairmanApproval.jsx";
import Decisions_GM_Approval from "./modules/supply/Decisions/Decisions Home/decisionGMApproval.jsx";
import Decisions_Supplier_Pending from "./modules/supply/Decisions/Decisions Home/decisionSupplierPending.jsx";
import Decisions_Create_po from "./modules/supply/Decisions/Decisions Home/decisionCreatePO.jsx";
import ActivityLog from "./modules/supply/Decisions/Activity Log/activitylog.jsx";
import SupplySuppliers from "./modules/supply/Suppliers/supply.jsx";
import Supply_Add from "./modules/supply/Suppliers/EditSupplier/addForm.jsx";
import Committees_Home from "./modules/supply/Committees/home.jsx";
import Committees from "./modules/supply/Committees/Appoint Members/membersTabel.jsx";
import Appoint_Members from "./modules/supply/Committees/Appoint Members/members.jsx";
import Approve_Member_com from "./modules/supply/Committees/Appoint Members/editmembers.jsx";
import Technical_Members from "./modules/supply/Committees/Technical Evaluation/evaluation.jsx";
import Tender_Opening_Committees_Home from "./modules/supply/Committees/Technical Evaluation/Tender Opening Committee/mailComponent.jsx";
import Tender_Committees_Home from "./modules/supply/Decisions/Decisions Home/pendingTenderApprovalsList.jsx";
import Dashboard_Home from "./modules/supply/Dashboard/home.jsx";
import Send_A_Mail from "./modules/supply/Dashboard/Send A Mail/mailComponent.jsx";
import Create_A_Tender from "./modules/supply/Dashboard/Create Tender/createForm.jsx";
import Acceptance_Letter from "./modules/supply/Dashboard/Acceptance Letter/createLeter.jsx";
import Update_Acceptance_Letter from "./modules/supply/Dashboard/Update Tender/updateForm.jsx";
import Sent_For_Approvals from "./modules/supply/Committees/Sent For Approvals/home.jsx";
import Bid_Evaluation_Summary_Report from "./modules/supply/Committees/Technical Evaluation/Bid Evaluation Summary Report/createReport.jsx";
import Bid_Evaluation_Summary_Report_view from "./modules/supply/Committees/Technical Evaluation/Bid Evaluation Summary Report View/viewReport.jsx";
import Create_Commitee_Meeting_Report from "./modules/supply/Committees/Technical Evaluation/Commitee Meeting Report/createReport.jsx";
import View_Commitee_Meeting_Report from "./modules/supply/Committees/Technical Evaluation/Commitee Meeting Report/viewReport.jsx";
import Opening_Price_Report_Form from "./modules/supply/Committees/Technical Evaluation/Tender Opening Committee/openingPriceForm.jsx";
import Opening_Price_Quo_Form from "./modules/supply/Committees/Technical Evaluation/Tender Opening Committee/openingPriceFormQuo.jsx";
import Opening_Price_Quo_Form_List from "./modules/supply/Committees/Technical Evaluation/Tender Opening Committee/openingPriceFormQuoList.jsx";
import Opening_Price_Quo_Form_Lists from "./modules/supply/Committees/Technical Evaluation/Tender Opening Committee/openingPriceFormQuoLists.jsx";
import Opening_Price_Quo_Form_View from "./modules/supply/Committees/Technical Evaluation/Tender Opening Committee/openingPriceFormQuoView.jsx";
import Opening_Price_Report_Form_View from "./modules/supply/Committees/Technical Evaluation/Tender Opening Committee/viewOpeningPriceForm.jsx";
import Sample_Testing from "./modules/supply/Committees/Sample Testing/sampleTesting.jsx";
import Sample_Testing_Quo_List from "./modules/supply/Committees/Sample Testing/sampleTestingQuoList.jsx";
import Sample_Testing_Quo_Lists from "./modules/supply/Committees/Sample Testing/sampleTestingQuoLists.jsx";
import Sample_Testing_View from "./modules/supply/Committees/Sample Testing/sampletestingView.jsx";
import Sample_Testing_Quo_View from "./modules/supply/Committees/Sample Testing/sampletestingView.jsx";
import Sample_Testing_Document_View from "./modules/supply/Committees/Sample Testing/sampleDocumentView.jsx";
import Sample_Commitee_Report from "./modules/supply/Committees/Sample Testing/sample_commitee_report.jsx";
import Purchase_Order from "./modules/supply/Purchase Order/home.jsx";
import Purchase_Order_Create from "./modules/supply/Purchase Order/Purchase Order/create.jsx";
import Purchase_Order_View from "./modules/supply/Purchase Order/Purchase Order/view.jsx";
import Purchase_Order_PDF from "./modules/supply/Purchase Order/Purchase Order/purchaseOrderPDF.jsx";
import Purchase_Voucher_Create from "./modules/supply/Purchase Order/Voucher/create.jsx";
import Purchase_Voucher_View from "./modules/supply/Purchase Order/Voucher/view.jsx";
import Purchase_Voucher_Edit from "./modules/supply/Purchase Order/Voucher/edit.jsx";
import TecBidEvaluationReport from "./modules/supply/Committees/Technical Evaluation/Bid Evaluation Summary Report View/tec_bid_evaluation_report.jsx";
import Tec_Meeting_Report from "./modules/supply/Committees/Technical Evaluation/Commitee Meeting Report/tec_meeting_report.jsx";
import OpeningPricing from "./modules/supply/Committees/Technical Evaluation/Tender Opening Committee/opening_price.jsx";
import Error from "../src/components/404error.jsx";
import User_Management from "./modules/settings/user_management/user_management.jsx";
import PurchaseOrders from "./modules/supply/Purchase Order/purchaseOrders.jsx";
import Vouchers from "./modules/supply/Purchase Order/vouchers.jsx";
import PurchaseOrdersPartial from "./modules/supply/Purchase Order/Purchase Order/create partial.jsx";

//settings
import Settings_User from "../src/modules/settings/user.jsx";
import Setting_Permission from "../src/modules/settings/userpermission.jsx";
import Add_User from "./modules/settings/user_management/add_user.jsx";
import Approval_1 from "./modules/pos/approve/approve_1.jsx";
import Approval_1_inventory from "./modules/inventory/Approval/approval.jsx";
import Approval_1_supply from "./modules/supply/Approval/approve_1.jsx";
import Desigantion from "./modules/settings/designation/designation.jsx";
//newly added approvals module
import Approval_1_Modules from "./modules/approvals/approve_1/approve_1.jsx";
import Approve_2_Modules from "./modules/approvals/approve_2/approve_2.jsx";
import Approve_3_Modules from "./modules/approvals/approve_3/approve_3.jsx";
import Approval2_Overtime from "./modules/hris/approval_process/approval2/approval2_overtime.jsx";

// Branch Request
import BranchRequest from "./modules/BranchReq/ItemReqFromBranch.jsx";
import ReturnRequest from "./modules/BranchReq/BranchItemReturnReq.jsx";
import ViewBranchRequest from "./modules/BranchReq/viewItemReqFromBranch.jsx";
import BranchRequestNav from "./modules/BranchReq/ItemReqFromBranch_nav.jsx";
import CheckBranchRequest from "./modules/BranchReq/checkedItemReqFromBranch.jsx";
import VewApprovedBranchRequest from "./modules/BranchReq/viewApprovedItemReqFromBranch.jsx";
import ApprovedBranchRequest from "./modules/BranchReq/approvedItemReqFromBranch.jsx";
import ViewHistoryLogInventoryBranchSideRequest from "./modules/BranchReq/viewHistoryLogsbranchSideInventoryRequest.jsx";
import HistoryLogItemsInventoryBranchRequest from "./modules/BranchReq/historyLogItemReqFromBranchSide.jsx";
import InventoryBranchItemReturnReleasedRequestViewBranchSide from "./modules/BranchReq/releasedItemReturnReqFromBranchView.jsx";
import InventoryBranchItemReturnReleasedRequestListViewBranchSide from "./modules/BranchReq/branchItemReturnReleasedRequestList.jsx";

//HRIS Payroll
import ProductionValue from "./modules/hris/payrole/incentive/production_value.jsx";
import SectionIncentive from "./modules/hris/payrole/incentive/section_incentive.jsx";
import SLAB from "./modules/hris/payrole/incentive/SLAB.jsx";
import SpecialIncentive from "./modules/hris/payrole/incentive/special_incentive.jsx";
import Leave_amount from "./modules/hris/payrole/incentive/leave_amount.jsx";
import Incentive from "./modules/hris/payrole/incentive/incentive_payroll.jsx";
import GeneratedPayroll from "./modules/hris/payrole/Generated_payroll/generated_payroll.jsx";
import Overtime_Assignment_Table from "./modules/hris/attendance/overtime_assigning/overtime_assignment_table.jsx";

// Quality Management
import SampleAllocation from "./modules/qualityManagement/sample_allocation/sample_allocation.jsx";
import SampleTesting from "./modules/qualityManagement/sample_testing/sample_testing.jsx";
import SampleTestReports from "./modules/qualityManagement/sample_test_reports/sample_test_reports.jsx";
import DisposalProcess from "./modules/qualityManagement/disposal_process/disposal_process.jsx";
import TrainingManagement from "./modules/qualityManagement/training_management/training_management.jsx";
import TenderQualityTestReport from "./modules/qualityManagement/sample_test_reports/tender_quality_test_reports.jsx";
import BulkQualityTestReport from "./modules/qualityManagement/sample_test_reports/bulk_quality_test_report.jsx";
import CompletedTraining from "./modules/qualityManagement/training_management/completed_Training.jsx";
import UpcommingTraining from "./modules/qualityManagement/training_management/upcoming_training.jsx";
import TrainingCertifications from "./modules/qualityManagement/training_management/training_certification.jsx";
import ApprovalStage01Home from "./modules/qualityManagement/Approval_Stage_01/approval_stage_one.jsx";
import ApprovalStage02Home from "./modules/qualityManagement/Approval_Stage_02/approval_stage_two.jsx";
import SampleTestingReportApprovalOne from "./modules/qualityManagement/Approval_Stage_01/sample_test_report.jsx";
import SampleTestingReportApprovalTwo from "./modules/qualityManagement/Approval_Stage_02/sample_test_report.jsx";
import ManualDisposalApprovalOne from "./modules/qualityManagement/Approval_Stage_01/manual_report.jsx";
import ManualDisposalApprovalTwo from "./modules/qualityManagement/Approval_Stage_02/manual_report.jsx";

// // Finance Management

import PurchaseInvoice from "./modules/finance/purchase_invoice/purchase_invoice.jsx";
import Summary_Report from "./modules/hris/attendance/late_permission/summary-report.jsx";
import Designation from "./modules/settings/designation/designation.jsx";
import ChartOfAccounts from "./modules/finance/chart_accounts/chart_account.jsx";
import PaymentEntry from "./modules/finance/payment_entry/payment_entry.jsx";
import SalesInvoice from "./modules/finance/sales_invoice/sales_invoice.jsx";
import Dashbord from "./modules/finance/dashbord/dashboard.jsx";
import AccountLedger from "./modules/finance/chart_accounts/account_ledger.jsx";
import AccountsPayables from "./modules/finance/account_payables/accounts_payables.jsx";
import AccountReceivable from "./modules/finance/account_receivables/account_receivables.jsx";
import JournalEntry from "./modules/finance/journal_entry/journal_entry.jsx";
import AssigenAccount from "./modules/finance/assigen_account/assigen_account.jsx";
import TrialBalance from "./modules/finance/trial_balance/trial_balance.jsx";
import AccountAuthorization from "./modules/finance/account_authorization/account_authorization.jsx";
import BankReconciliationTool from "./modules/finance/bank_reconciliation/bank_reconciliation.jsx";
import Debtors from "./modules/finance/account_receivables/debtors.jsx";

// ======================Vehicle Fleet============================

import VehicleInfomationMamagement from "./modules/vehicleFleet/Vehicle_information_management/vehicle_Impomation_home.jsx";
import VehicleDatatils from "./modules/vehicleFleet/Vehicle_information_management/vehicle_details/vehicle_details.jsx";
import Vehicle_Parts_Details from "./modules/vehicleFleet/Vehicle_information_management/vehicle_part_details/vehicle_part_details.jsx";
import Driver_Details from "./modules/vehicleFleet/Vehicle_information_management/driver_details/driver_details.jsx";

import Current_Vehicle_Dispatches from "./modules/vehicleFleet/Current_vehicle_dispatches_management/dispatch_Impomation_home.jsx";
import Manager_Transportation_Dispaches from "./modules/vehicleFleet/Current_vehicle_dispatches_management/current_manager_dispatches.jsx";
import Gatepass_Transportation_Dispaches from "./modules/vehicleFleet/Current_vehicle_dispatches_management/current_vehicle_dispatches.jsx";

import Current_Vehicle_Schedule from "./modules/vehicleFleet/Route_planning_management/route_Impomation_home.jsx";
import Manager_Transportation_Schedules from "./modules/vehicleFleet/Route_planning_management/manager_route_schedules/manager_transportation_schedule.jsx";
import Gatepass_Transportation_Schedules from "./modules/vehicleFleet/Route_planning_management/gate_pass_schedules/gate_pass_schedule.jsx";

import Fuel_Manegemnt_Home from "./modules/vehicleFleet/Fuel_management/fuel_Impomation_home.jsx";
import Fuel_Consumption_Data from "./modules/vehicleFleet/Fuel_management/fuel_consumption_data.jsx";
import Fuel_cost_update from "./modules/vehicleFleet/Fuel_management/fuel_cost_update_data.jsx";
import Generate_monthly_fuel_reports from "./modules/vehicleFleet/Fuel_management/Generate_Monthly_Fuel_Reports.jsx";

import Maintenance_Manegement from "./modules/vehicleFleet/Maintenance_and_repairs/Maintenance_Impomation_home.jsx";
import Upload_Request_Letter_Details from "./modules/vehicleFleet/Maintenance_and_repairs/Upload_request_letter/upload_request_letter_details.jsx";
import Maintenance_records from "./modules/vehicleFleet/Maintenance_and_repairs/Maintenance/maintenance_records.jsx";
import Breakdown_records from "./modules/vehicleFleet/Maintenance_and_repairs/Breakdown/breakdown_records.jsx";

import Inventory_Management from "./modules/vehicleFleet/Inventory_management/Inventory_management_home.jsx";
import Vehicle_Count_Dashboard from "./modules/vehicleFleet/Inventory_management/vehicle_Count_Dashboard.jsx";
import Fuel_Consumption_Dashboard from "./modules/vehicleFleet/Inventory_management/fuel_consumption_dashboard.jsx";
import Location_data_dashboard from "./modules/vehicleFleet/Inventory_management/location_data_dashboard.jsx";

import Performance from "./modules/vehicleFleet/Performance/performance_home.jsx";
import Driver_performance_monitoring from "./modules/vehicleFleet/Performance/monthly_driver_performance.jsx";
import Compliance_reporting from "./modules/vehicleFleet/Compliance_reporting/compliance_reporting_home.jsx";
import Vehicles_Exceeding_5000km from "./modules/vehicleFleet/Compliance_reporting/vehicles_exceeding_5000km.jsx";

import Approval_process_01_Home from "./modules/vehicleFleet/Approval_process_01/approvla1_home.jsx";
import Approval_process_01_Document_Uploading from "./modules/vehicleFleet/Approval_process_01/documents_Uploading.jsx";
import Approval_process_01_Document_Vehicle from "./modules/vehicleFleet/Approval_process_01/vehicle_Documents_Uploading.jsx";
import Approval_process_01_Document_Driver from "./modules/vehicleFleet/Approval_process_01/driver_Documents_Uploading.jsx";
import Approval_process_01_System_Editing from "./modules/vehicleFleet/Approval_process_01/system_Editing.jsx";
import Approval_process_01_Gate_Pass_Request from "./modules/vehicleFleet/Approval_process_01/gate_pass.jsx";

import Approval_process_02_Home from "./modules/vehicleFleet/Approval_process_02/approvla2_home.jsx";
import Approval_process_02_Document_Uploading from "./modules/vehicleFleet/Approval_process_02/documents_Uploading.jsx";
import Approval_process_02_Document_Vehicle from "./modules/vehicleFleet/Approval_process_02/vehicle_Documents_Uploading.jsx";
import Approval_process_02_Document_Driver from "./modules/vehicleFleet/Approval_process_02/driver_Documents_Uploading.jsx";
import Approval_process_02_System_Editing from "./modules/vehicleFleet/Approval_process_02/system_Editing.jsx";
import Approval_process_02_Gate_Pass_Request from "./modules/vehicleFleet/Approval_process_02/gate_pass.jsx";

import Approval_process_03_Home from "./modules/vehicleFleet/Approval_process_03/approvla3_home.jsx";
import Approval_process_03_Document_Uploading from "./modules/vehicleFleet/Approval_process_03/documents_Uploading.jsx";
import Approval_process_03_Document_Vehicle from "./modules/vehicleFleet/Approval_process_03/vehicle_Documents_Uploading.jsx";
import Approval_process_03_Document_Driver from "./modules/vehicleFleet/Approval_process_03/driver_Documents_Uploading.jsx";
import Approval_process_03_System_Editing from "./modules/vehicleFleet/Approval_process_03/system_Editing.jsx";
import Approval_process_03_Gate_Pass_Request from "./modules/vehicleFleet/Approval_process_03/gate_pass.jsx";


import Vehicle_Location from "./modules/vehicleFleet/Vehicle_location/vehicle_location_home.jsx"

const SidebarChecker = ({ children }) => {
  const location = useLocation();
  const validRoutes = [
    "/pos/pos-approval",
    "/pos/pos",
    "/pos/bill",
    "/pos/pos-dashboard",
    "/pos/create-user",
    "/inventory/dispose",
    "/inventory/disposed",
    "/approval1-dispose",
    "/approval2-dispose",
    "/approval3-dispose",
    "/inventory/dashboard",
    "/inventory/purchase",
    "/inventory/store",
    "/inventory/adjust",
    "/inventory/adjusted",
    "/approval1-adjusted",
    "/approval2-adjusted",
    "/approval3-adjusted",
    "/inventory/return",
    "/inventory/store-keeper-release",
    "/inventory/request-nav", // card page for inventory requests
    "/inventory/return-request-nav",
    "/inventory/view-pro-item-req", //inventory Production Item Request
    "/inventory/view-pro-item-req-release",
    "/inventory/view-pro-item-req-release-view",
    "/inventory/view-pro-items-req", //inventory Production Item Request
    "/inventory/branch-item-req", //inventory Branch Item Request
    "/inventory/branch-item-return-req",
    "/inventory/branch-item-return-req-released-view",
    "/inventory/branch-item-return-released-req",
    "/inventory/branch-item-return-released-req-view",
    "/inventory/branch-item-return-released-req-view-branch-side",
    "/inventory/branch-item-return-released-list-req-view-branch-side",
    "/inventory/view-approve-branch-req", // inventory Branch Item request to Approve
    "/inventory/view-approved-branch-req", // inventory Branch Requests Approved
    "/inventory/view-item-approved-branch-req", //inventory Branch Items Requests Approved
    "/inventory/view-item-approved-history-log-branch-req",
    "/inventory/add-item-manualy", // Inventory add Item Manually
    "/inventory/mobilepos-item-req", // Mobile POS item Reuests
    "/inventory/view-mobilepos-req", // Items of Mobile POS Req
    "/inventory/mobilepos-return-req",
    "/inventory/mobilepos-return-req-list",
    "/inventory/view-item-released-mobilepos-req", //  released item in mobile pos
    "/inventory/view-Released-mobilepos-req", // view Released item requets in mobile pos
    "/inventory/view-item-Released-history-log-mobilepos-req", // History Log of Mobile POS req
    "/inventory/view-items-history-log-mobilepos-req", // History Log req items in mobile pos
    "/inventory/approval",
    "/branches/add",
    "/branches/update",
    "/branches/view",
    "/customer/add",
    "/customer/update",
    "/customer/credit",
    "/itemcategory/add",
    "/itemcategory/update",
    "/itemcategory/view",
    "/items/create",
    "/items/update",
    "/items/view",
    "/stores/add",
    "/stores/update",
    "/stores/view",
    "/supplier/add",
    "/supplier/update",
    "/users/add",
    "/users/update",
    "/users/role",
    "/designation/add",
    "/designation/update",
    "/trainingtype/add",
    "/trainingtype/update",
    "/recipe-nav",
    "/create-recipe",
    "/pending-recipe",
    "/created-recipe",
    "/rejected-recipe",
    "/created-job",
    "/created-job-list",
    "/created-job-add-item",
    "/create-job",
    "/pending-job",
    "/rejected-job",
    "/make-units",
    "/chargeperhour",
    "/start-production",
    "/create-production",
    "/warehouse-nav",
    "/warehouse",
    "/warehouse-complete",
    "/warehouse-pending",
    "/warehouse-pending-for-pricing",
    "/warehouse-reject",
    "/warehouse-adjustments",
    "/job-nav",
    "/recipe_nav",
    "/warehouse_nav",
    "/manage-production",
    "/dashboard",
    "/emp-onboarding",
    "/emp-nav",
    "/view-export-table",
    "/history-log",
    "/user-profile/:employee_no",
    "/attendance",
    "/in-and-out",
    "/in-and-out-shift",
    "/leaves",
    "/summary-report",
    "/leave-doc",
    "/over-time",
    "/payments-menu",
    "/payment_details/:value/:name",
    "/training",
    "/completed-training",
    "/upcoming-training",
    "/examinations",
    "/reports-analytics",
    "/performance-nav",
    "/completed_examinations",
    "/upcoming-examinations",
    "/salary-increments",
    "/leave-management",
    "/leave-info",
    "/loan-nav",
    "/view-loan-info",
    "/review-guarantors",
    "/loan-eligible",
    "/supplychain",
    "/purchase/request",
    "/purchase/requestForm",
    "/purchase/viewPurchaseRequest",
    "/purchase/viewPurchaseRequest/subject",
    "/purchase/viewPurchaseRequestChairman",
    "/supply/decisions",
    "/supply/approve_tender",
    "/supply/decisions/chariman_approval",
    "/supply/decisions/gm_approval",
    "/supply/decisions/pending_approval",
    "/supply/decisions/create_po",
    "/supply/ActivityLog",
    "/suppliers/supply",
    "/suppliers/category",
    "/suppliers/add",
    "/committees/home",
    "/committees/members",
    "/committees/opening",
    "/committees/techical",
    "/supply/dashboard",
    "/supply/sendAmail",
    "/supply/createTender",
    "/supply/acceptanceLetter",
    "/supply/updateTender",
    "/supply/approval",
    "/committess/sentforApprovals",
    "/committess/bidEvaluationSummaryReportCreate",
    "/committess/bidEvaluationSummaryReportView",
    "/committess/committeeMeetingReportCreate",
    "/committess/committeeMeetingReportView",
    "/committess/openingPriceForm",
    "/committess/openingPriceFormView",
    "/committess/sampleTesting",
    "/committess/sampleTestingView",
    "/committess/sampleTestingDocument",
    "/committess/sampleTestingDocument/PDF",
    "/approval1",
    "/approval1-info",
    "/approval2",
    "/approval2-info",
    "/approval1-user",
    "/purchaseOrder/home",
    "/voucher/create",
    "/purchaseOrder/view",
    "/purchaseOrder/pdf",
    "/purchaseOrder/create",
    "/voucher/view",
    "/voucher/edit",
    "/voucher/view/pdf",
    "/committees/committeeMeetingReportView/pdf",
    "/committees/openingPriceFormView/pdf",
    "/payments",
    "/settings_user",
    "/settings_permission",
    "/user_management",
    "/add-user",
    "/pos/mobile-pos",
    "/pos/pos-items",
    "/absense-report",
    "/approve-1-modules",
    "/approve-2-modules",
    "/approve-3-modules",
    "/branch-req",
    "/branch-return-req",
    "/branch-req-nav",
    "/view-branch-req",
    "/view-check-branch-req",
    "/view-approved-branch-req",
    "/approved-branch-req",
    "/view-item-approved-history-log-branch-req",
    "/inventory/view-items-history-log-branch-req",
    "/inventory/view-items-history-log-branch-req-inventory-side",
    "/Timetable-management",
    "/allowances",
    "/dedutions",
    "/payment_details/PAY_TAX/Pay%20Tax/Deduction",
    "/payment_details/stamp_duty/Stamp%20Duty/Deduction",
    "/payment_details/salary_advance/Salary%20Advance/Deduction",
    "/payment_details/welfare_members/Welfare%20Members/Deduction",
    "/payment_details/festival_advance/Festival%20Advance/Deduction",
    "/payment_details/bank_loan_01/Bank%20Loan%2001/Deduction",
    "/payment_details/other_deductions_1/Other%20Deductions%201/Deduction",
    "/payment_details/welfare_advance/Welfare%20Advance/Deduction",
    "/payment_details/Union_1/Union%201/Deduction",
    "/payment_details/Union_2/Union%202/Deduction",
    "/payment_details/Union_3/Union%203/Deduction",
    "/payment_details/Union_4/Union%204/Deduction",
    "/payment_details/Union_5/Union%205/Deduction",
    "/payment_details/Buddha_Sangamaya_members/Buddha%20Sangamaya%20Members/Deduction",
    "/payment_details/Sanasa_loan/Sanasa%20Loan/Deduction",
    "/payment_details/Other_deductions_2/Other%20Deductions%202/Deduction",
    "/payment_details/Welfare_Loan/Welfare%20Loan/Deduction",
    "/payment_details/Saving_To_Bank/Saving%20to%20Bank/Deduction",
    "/payment_details/Sanasa_member/Sanasa%20Member/Deduction",
    "/payment_details/Sanasa_Advance/Sanasa%20Advance/Deduction",
    "/payment_details/Donations/Donations/Deduction",
    "/payment_details/other_deductions_3/Other%20Deductions%203/Deduction",
    "/payment_details/Bank_Loan_2/Bank%20Loan%202/Deduction",
    "/payment_details/Distress_Loan/Distress%20Loan/Deduction",
    "/payment_details/Distress_Loan_Interest/Distress%20Loan%20Interest/Deduction",
    "/payment_details/no_pay/No%20Pay/Deduction",
    "/payment_details/Allowance_TP/Allowance%20TP/Allowance",
    "/payment_details/AJT_Allowance/AJT%20Allowance/Allowance",
    "/payment_details/Cost_Of_Allowance/Cost%20Of%20Allowance/Allowance",
    "/payment_details/SLADC_Allowance/SLADC%20Allowance/Allowance",
    "/payment_details/Salary_Arrears/Salary%20Arrears/Allowance",
    "/payment_details/Traveling/Traveling/Allowance",
    "/payment_details/Spe_Allowance/Special%20Allowance/Allowance",
    "/payment_details/Difficult_Allowance/Difficult%20Allowance/Allowance",
    "/payment_details/Act_Allowance/Acting%20Allowance/Allowance",
    "/payment_details/Acct_Allo_Arre/Accounting%20Allowance%20Arrears/Allowance",
    "/payment_details/SPE_ALLOWANCE_02/Special%20Allowance%2002/Allowance",
    "/payment_details/cost_of_allowance_language/Cost%20of%20Allowance%20Language/Allowance",
    "/payment_details/Over_Time/Over%20Time/Allowance",
    "/payment_details/language_allowance/Language%20Allowance/Allowance",
    "/payment_details/Traveling_Fuel/Traveling%20and%20Fuel/Allowance",
    "/monthend-payroll",
    "/incentive-payroll",
    "/production_value",
    "/section-incentive",
    "/slab",
    "/all-deduction",
    "/special-incentive",
    "/all-allowance",
    "/overtime",
    "/leave-amount-management",
    "/incentive",
    "/generated-payroll",
    "/attendance-report",
    "/purchase-orders",
    "/vouchers",
    "/purchase-orders/create-partial-order",
    "/inventory/purchase/orders",
    "/inventory/purchase/historyLog",
    "/inventory/purchase-nav",
    "/customer-type",
    "/view-update-customer",
    "/inventory/purchase/view-items",
    "/purchase-req-nav",
    "/purchase/viewPurchaseRequest/pending",
    "/purchase/sendQuatation",
    "/quality-management",
    "/quality-management/disposal-process",
    "/quality-management/sample-allocation",
    "/quality-management/sample-test-reports",
    "/quality-management/sample_testing",
    "/quality-management/training-management",
    "/quality-management/sample-testing",
    "/quality-management/tender-quality-test-reports",
    "/quality-management/bulk-quality-test-reports",
    "/quality-management/approva1-approvaSampleTesting",
    "/quality-management/approva1-approvaManualDisposal",
    "/quality-management/approva2-approvaSampleTesting",
    "/quality-management/approva2-approvaManualDisposal",
    "/quality-management/training-management/completed-training",
    "/quality-management/training-management/upcomming-training",
    "/quality-management/training-certifications",
    "/quality-management/approval-Stage-01-Home",
    "/quality-management/approval-Stage-02-Home",
    "/quality-management/approval-one/sample-testing-report",
    "/quality-management/approval-one/manual-disposal",
    "/quality-management/approval-two/sample-testing-report",
    "/quality-management/approval-two/manual-disposal",
    "/purchase/askQuo",
    "/finance-management/chart-of-accounts",
    "/finance-management/payment-entry",
    "/finance-management/journal-entry",
    "/finance-management/purchase-invoice",
    "/finance-management/sales-invoice",
    "/finance-management/dashbord",
    "/finance-management/chart-of-accounts/account-ledger",
    "/finance-management/accounts-payables",
    "/finance-management/account-receivable",
    "/finance-management/assigen-account",
    "/finance-management/trial-balance",
    "/finance-management/account-authorization",
    "/finance-management/bank-reconciliation-tool",
    "/finance-management/account-receivable/debtors",
    "/purchase-req-sub-nav",
    "/committees",
    "/committess/openingPriceFormQuo",
    "/committess/openingPriceFormQuoList",
    "/committess/openingPriceFormQuoLists",
    "/committess/openingPriceFormQuoView",
    "/committess/sampleTestingQuoList",
    "/committess/sampleTestingQuoLists",
    "/committess/sampleTestingQuoView",
    "/return_home",
    "/return_bill",
    "/return_sale",
    "/committees/members/edit",
    "/inventory/dashboardNav",
    "/emp_leave_management",
    "/leave_approve",
    "/date_restrictions",
    "/leave_request",
    "/timetable_management",
    "/inventory/expire_items",
    "/designation",
    "/committees/tender",
    "/return_pos_bill",
    "/pos_report",
    "/vehicle-information-management",
    "/vehicle-fleet-management/vehicle-details",
    "/vehicle-fleet-management/driver-details",
    "/vehicle-fleet-management/vehicle-part-details",
    "/current-vehicle-dispatches",
    "/current-vehicle-dispatches/manager-transportation-dispatches",
    "/current-vehicle-dispatches/gatepass-transportation-dispatches",
    "/current-vehicle-schedule",
    "/route-planning-schedules/manager-transportation-schedules",
    "/route-planning-Schedules/gatepass-transportation-schedules",
    "/fuel-manegemnt",
    "/fuel-manegemnt/fuel-consumption-data",
    "/fuel-manegemnt/fuel-cost-update",
    "/fuel-manegemnt/generate-monthly-fuel-reports",
    "/maintenance-manegement",
    "/maintenance-manegement/upload-request-letter-form",
    "/maintenance-manegement/maintenance-records",
    "/maintenance-manegement/breakdown-records",
    "/inventory-management",
    "/inventory-management/vehicle-count-dashboard",
    "/inventory-management/fuel-consumption-dashboard",
    "/inventory-management/location-data-dashboard",
    "/performance",
    "/performance/driver-performance-monitoring",
    "/compliance-reporting",
    "/compliance-reporting/vehicles_exceeding_5000km",
    "/Approval-process-01",
    "/Approval-process-01/document-uploading",
    "/Approval-process-01/document-uploading-vehicle",
    "/Approval-process-01/document-uploading-driver",
    "/Approval-process-01/system-editing",
    "/Approval-process-01/gate-pass-request",
    "/Approval-process-02",
    "/Approval-process-02/document-uploading",
    "/Approval-process-02/document-uploading-vehicle",
    "/Approval-process-02/document-uploading-driver",
    "/Approval-process-02/system-editing",
    "/Approval-process-02/gate-pass-request",
    "/Approval-process-03",
    "/Approval-process-03/document-uploading",
    "/Approval-process-03/document-uploading-vehicle",
    "/Approval-process-03/document-uploading-driver",
    "/Approval-process-03/system-editing",
    "/Approval-process-03/gate-pass-request",
    "/vehicle-location",
    "/attendance-edit",
    "/overtime-assigning-nav",
    "/overtime_assigning",
    "/overtime_assignment_table",
    "/overtime-approval",
    "/approval2_overtime",
    "/attendance_edit_approval1",



  ];

  const isSidebarRequired = validRoutes.some((route) =>
    new RegExp(`^${route.replace(/:[^/]+/, "[^/]+")}$`).test(location.pathname)
  );
  return isSidebarRequired ? (
    <>
      <div className="SideBar">
        <SideMenu />
      </div>
      <div className="App-content">{children}</div>
    </>
  ) : (
    <div>{children}</div>
  );
};

function App() {
  return (
    <div>
      <Router>
        <SidebarChecker>
          <Routes>
            <Route element={<UserAuth ids={["ROLE-1"]} />}></Route>

            <Route path="/pos/pos" element={<Pos />} />

            <Route path="/pos/pos-approval" element={<Approval_1 />} />

            <Route path="/pos/bill" element={<Bill />} />
            <Route path="/pos/pos-dashboard" element={<PosDashboard />} />
            <Route path="/pos/mobile-pos" element={<MobilePos />} />
            <Route path="/pos/pos-items" element={<PosItems />} />
            <Route path="/pos/create-user" element={<PosUsersCreate />} />
            <Route path="/inventory/dispose" element={<InventoryDispose />} />
            <Route path="/inventory/disposed" element={<DisposedItems />} />
            <Route path="/return_home" element={<Return_Home />} />
            <Route path="/return_sale" element={<Return_Sale />} />
            <Route path="/return_bill" element={<Return_Bill />} />

            <Route
              path="/approval1-dispose"
              element={<DisposedItemsApproval1 />}
            />
            <Route
              path="/approval2-dispose"
              element={<DisposedItemsApproval2 />}
            />
            <Route
              path="/approval3-dispose"
              element={<DisposedItemsApproval3 />}
            />
            <Route
              path="/inventory/dashboard"
              element={<InventoryDashboard />}
            />
            <Route
              path="/inventory/dashboardNav"
              element={<InventoryDashboardNav />}
            />
            <Route
              path="/inventory/expire_items"
              element={<InventoryExpireItem />}
            />
            <Route path="/inventory/purchase" element={<InventoryPurchase />} />
            <Route
              path="/inventory/purchase/historyLog"
              element={<InventoryPurchaseHistoryLog />}
            />
            <Route
              path="/inventory/purchase/view-items"
              element={<InventoryPurchaseViewItems />}
            />
            <Route
              path="/inventory/purchase/orders"
              element={<InventoryPurchaseOrders />}
            />
            <Route path="/inventory/store" element={<InventoryStore />} />
            <Route
              path="/inventory/store-keeper-release"
              element={<StoreKeeperRelease />}
            />
            <Route
              path="/inventory/request-nav"
              element={<InventoryRequestNav />}
            />
            <Route
              path="/inventory/return-request-nav"
              element={<InventoryReturnRequestNav />}
            />
            <Route
              path="/inventory/purchase-nav"
              element={<InventoryPurchaseNav />}
            />
            <Route
              path="/inventory/branch-item-req"
              element={<InventoryBranchRequest />}
            />
            <Route
              path="/inventory/branch-item-return-req"
              element={<InventoryBranchItemReturnRequest />}
            />
            <Route
              path="/inventory/branch-item-return-req-released-view"
              element={<ReleasedReturnReqBranchView />}
            />
            <Route
              path="/inventory/branch-item-return-released-req"
              element={<InventoryBranchItemReturnReleasedRequest />}
            />
            <Route
              path="/inventory/branch-item-return-released-req-view"
              element={<InventoryBranchItemReturnReleasedRequestView />}
            />
            <Route
              path="/inventory/branch-item-return-released-req-view-branch-side"
              element={
                <InventoryBranchItemReturnReleasedRequestViewBranchSide />
              }
            />
            <Route
              path="/inventory/branch-item-return-released-list-req-view-branch-side"
              element={
                <InventoryBranchItemReturnReleasedRequestListViewBranchSide />
              }
            />
            <Route
              path="/inventory/view-approve-branch-req"
              element={<ApproveInventoryBranchRequest />}
            />
            <Route
              path="/inventory/view-approved-branch-req"
              element={<ViewItemApprovedInventoryBranchRequest />}
            />
            <Route
              path="/inventory/view-item-approved-branch-req"
              element={<ViewApprovedInventoryBranchRequest />}
            />
            <Route
              path="/inventory/view-item-approved-history-log-branch-req"
              element={<ViewHistoryLogInventoryBranchRequest />}
            />
            <Route
              path="/inventory/view-items-history-log-branch-req"
              element={<HistoryLogItemsInventoryBranchRequest />}
            />
            <Route
              path="/inventory/view-items-history-log-branch-req-inventory-side"
              element={<HistoryLogItemsInventoryBranchRequestInventory />}
            />
            <Route
              path="/inventory/view-pro-item-req"
              element={<InventoryProdctionRequest />}
            />
            <Route
              path="/inventory/view-pro-item-req-release"
              element={<InventoryProdctionRequestReleased />}
            />
            <Route
              path="/inventory/view-pro-item-req-release-view"
              element={<InventoryProdctionRequestReleasedView />}
            />
            <Route
              path="/inventory/view-pro-items-req"
              element={<InventoryProdctionItemsRequest />}
            />
            <Route
              path="/inventory/add-item-manualy"
              element={<AddItemManually />}
            />
            {/* mobile pos */}
            <Route
              path="/inventory/mobilepos-item-req"
              element={<InventoryMobilePOSRequest />}
            />
            <Route
              path="/inventory/view-mobilepos-req"
              element={<ItemsMobilePOSRequest />}
            />
            <Route
              path="/inventory/mobilepos-return-req"
              element={<MobilePOSReturnRequest />}
            />
            <Route
              path="/inventory/mobilepos-return-req-list"
              element={<MobilePOSReturnRequestList />}
            />
            <Route
              path="/inventory/view-Released-mobilepos-req"
              element={<ViewReleasedMobilePOSRequest />}
            />
            <Route
              path="/inventory/view-item-released-mobilepos-req"
              element={<ViewItemsReleasedMobilePOSRequest />}
            />
            <Route
              path="/inventory/view-item-Released-history-log-mobilepos-req"
              element={<ViewReleasedMobilePOSHistoryLogRequest />}
            />
            <Route
              path="/inventory/view-items-history-log-mobilepos-req"
              element={<ViewReleasedMobilePOSHistoryLogItemsRequest />}
            />

            <Route path="/inventory/request" element={<InventoryRequest />} />
            <Route path="/inventory/adjusted" element={<AdjustedItems />} />
            <Route path="/inventory/adjust" element={<InventoryAdjustment />} />
            <Route
              path="/approval1-adjusted"
              element={<AdjustedItemsApproval1 />}
            />
            <Route
              path="/approval2-adjusted"
              element={<AdjustedItemsApproval2 />}
            />
            <Route
              path="/approval3-adjusted"
              element={<AdjustedItemsApproval3 />}
            />
            <Route path="/inventory/return" element={<InventoryReturns />} />
            <Route
              path="/inventory/approval"
              element={<Approval_1_inventory />}
            />

            <Route path="/pos_report" element={<Pos_Report />} />

            <Route path="/branches/add" element={<AddBranches />} />
            <Route path="/branches/update" element={<UpdateBranches />} />
            <Route path="/branches/view" element={<ViewBranches />} />
            <Route path="/customer/add" element={<AddCustomer />} />
            <Route path="/customer/update" element={<UpdateCustomer />} />
            <Route path="/customer/credit" element={<CreditClearance />} />
            <Route path="/itemcategory/add" element={<AddItemCategory />} />
            <Route
              path="/itemcategory/update"
              element={<UpdateItemCategory />}
            />

            <Route path="/customer-type" element={<CustomerType />} />
            <Route path="/itemcategory/view" element={<ViewItemCategory />} />
            <Route path="/items/create" element={<CreateItem />} />
            <Route path="/items/update" element={<UpdateItem />} />
            <Route path="/items/view" element={<ViewItem />} />
            <Route path="/stores/add" element={<AddStores />} />
            <Route path="/stores/update" element={<UpdateStores />} />
            <Route path="/stores/view" element={<ViewStores />} />
            <Route path="/supplier/add" element={<AddSupplier />} />
            <Route path="/supplier/update" element={<UpdateSupplier />} />
            <Route path="/users/add" element={<AddUser />} />
            <Route path="/users/update" element={<UpdateUser />} />
            <Route path="/users/role" element={<UserRole />} />
            {/*designation*/}
            <Route path="/designation/add" element={<AddDesignation />} />
            <Route path="/designation/update" element={<UpdateDesignation />} />
            {/*Training Type*/}
            <Route path="/trainingtype/add" element={<AddTraningType />} />
            <Route
              path="/trainingtype/update"
              element={<UpdateTrainingType />}
            />
            {/* Recipe */}
            <Route path="/create-recipe" element={<CreateRecipe />} />
            <Route path="/pending-recipe" element={<PendingRecipes />} />
            <Route path="/created-recipe" element={<CreatedRecipe />} />
            <Route path="/rejected-recipe" element={<RejectedRecipe />} />
            <Route path="/recipe-nav" element={<Recipe_Nav />} />
            {/* Jobs */}
            <Route path="/job-nav" element={<Job_Nav />} />
            <Route path="/created-job" element={<CreatedJob />} />
            <Route path="/created-job-list" element={<CreatedJobList />} />
            <Route
              path="/created-job-add-item"
              element={<AddItemstoCreatedJob />}
            />
            <Route path="/create-job" element={<CreateJob />} />
            <Route path="/pending-job" element={<PendingJobs />} />
            <Route path="/rejected-job" element={<RejectedJobs />} />
            {/* Unit */}
            <Route path="/make-units" element={<Unit />} />
            <Route path="/chargeperhour" element={<ChargePerHour />} />
            <Route path="/start-production" element={<StartJob />} />
            <Route path="/create-production" element={<CreateProduction />} />
            {/* Warehouse*/}
            <Route path="/warehouse-nav" element={<Warehouse_Nav />} />
            <Route path="/warehouse" element={<WarehouseMangement />} />
            <Route
              path="/warehouse-complete"
              element={<WarehouseCompleteTable />}
            />
            <Route path="/warehouse-pending" element={<WarehousePending />} />
            <Route
              path="/warehouse-pending-for-pricing"
              element={<WarehousePendingForPricing />}
            />
            <Route path="/warehouse-reject" element={<ManageReject />} />
            <Route
              path="/warehouse-adjustments"
              element={<ManageAdjustments />}
            />
            <Route path="/manage-production" element={<Manage_Production />} />

            {/*HRIS*/}
            <Route path="/dashboard" element={<Dashboard />} />
            <Route path="/emp-onboarding" element={<Onboard />} />
            <Route path="/emp-nav" element={<Emp_Nav />} />
            <Route path="/view-export-table" element={<View_Export_Table />} />
            <Route path="/history-log" element={<History_Log />} />
            <Route
              path="/user-profile/:employee_no"
              element={<User_Profile />}
            />
            <Route path="/attendance" element={<Attendance />} />
            <Route path="/in-and-out" element={<Inandout />} />
            <Route path="/in-and-out-shift" element={<Inandout_Shift />} />
            <Route path="/leaves" element={<Leave />} />
            <Route path="/absense-report" element={<Absense_Report />} />

            <Route path="/summary-report" element={<Summary_Report />} />
            <Route path="/leave-doc" element={<Leave_Doc />} />
            <Route path="/over-time" element={<OT />} />
            <Route path="/payments-menu" element={<Payments_Menu />} />
            <Route path="/allowances" element={<Allowances />} />
            <Route path="/dedutions" element={<Deductions />} />
            <Route path="/allpayroll" element={<AllPayroll />} />
            <Route path="/monthend-payroll" element={<MonthendPayroll />} />
            <Route path="/all-deduction" element={<AllDeductions />} />
            <Route path="/all-allowance" element={<All_allowance />} />
            <Route
              path="/payment_details/:value/:name/:type"
              element={<AllowancesandDeductions />}
            />

            <Route path="/return_pos_bill" element={<Return_Pos_Bill />} />
            <Route path="/training" element={<Training />} />
            <Route path="/payments" element={<Allpayments />} />
            <Route path="/overtime" element={<Overtime />} />
            <Route
              path="/completed-training"
              element={<Completed_Training />}
            />
            <Route path="/upcoming-training" element={<Upcoming_Trainings />} />
            <Route path="/examinations" element={<Examinations_Card />} />
            <Route path="/reports-analytics" element={<Reports_Analytics />} />
            <Route path="/performance-nav" element={<Performance_Nav />} />
            <Route
              path="/completed_examinations"
              element={<Completed_Examinations />}
            />
            <Route
              path="/upcoming-examinations"
              element={<Upcoming_Examinations />}
            />
            <Route path="/production_value" element={<ProductionValue />} />
            <Route path="/salary-increments" element={<Salary_Increments />} />
            <Route path="/leave-management" element={<Leave_Management />} />
            <Route path="/leave-info" element={<Leave_Info />} />
            <Route path="/loan-nav" element={<Loan_Nav />} />
            <Route path="/view-loan-info" element={<View_Loan_Info />} />
            <Route path="/review-guarantors" element={<Review_Guarantors />} />
            <Route path="/loan-eligible" element={<Loan_Elibility />} />
            <Route path="/leave-reports" element={<Leave_Reports />} />
            <Route path="/approval1" element={<Approval1 />} />
            <Route path="/approval1-user" element={<Approval1user />} />
            <Route path="/approval2-user" element={<Approval2user />} />
            <Route path="/approval2" element={<Approval2 />} />
            <Route path="/approval1-info" element={<Approval1_Info />} />
            <Route path="/approval2-info" element={<Approval2_Info />} />
            <Route path="/section-incentive" element={<SectionIncentive />} />
            <Route path="/slab" element={<SLAB />} />
            <Route path="/special-incentive" element={<SpecialIncentive />} />
            <Route path="/incentive" element={<Incentive />} />
            <Route path="/generated-payroll" element={<GeneratedPayroll />} />
            <Route path="/attendance-report" element={<AttendanceReport />} />
            <Route
              path="/leave-amount-management"
              // eslint-disable-next-line react/jsx-pascal-case
              element={<Leave_amount />}
            />
             <Route path="/approval2_overtime" element={<Approval2_Overtime/>}/>
            <Route path="/attendance-edit" element={<Attendance_Edit />} />

            <Route
              path="/emp_leave_management"
              element={<Emp_Leave_Management />}
            />
            <Route path="/timetable_management" element={<Timetable />} />
            <Route path="/leave_approve" element={<Leave_Approve />} />
            <Route path="/date_restrictions" element={<Date_Restrictions />} />
            <Route path="/overtime-assigning-nav" element={<Overtime_Assigning_Nav/>}/>
            <Route path="/overtime_assigning" element={<Overtime_Assigning/>}/>
            <Route path="/leave_approve" element={<Leave_Approve />} />
            <Route path="/date_restrictions" element={<Date_Restrictions />} />
            <Route path="/leave_request" element={<Leave_Request />} />
            <Route path="/overtime_assignment_table" element={<Overtime_Assignment_Table/>}/>
            <Route path="/overtime-approval" element = {<Overtime_Approval/>} />
            <Route path="/attendance_edit_approval1" element={<Attendance_Edit_Approval1/>} />
                       {/*Supply Chain*/}
            <Route path="/purchase/request" element={<Per_Request />} />
            <Route
              path="/purchase/requestForm"
              element={<Per_Request_Form />}
            />
            <Route path="/purchase-req-nav" element={<PurchaseReqNav />} />
            <Route
              path="/purchase/viewPurchaseRequest/subject"
              element={<PurchaseRequestViewSubject />}
            />
            <Route
              path="/purchase/viewPurchaseRequest"
              element={<PurchaseRequestView />}
            />
            <Route
              path="/purchase/viewPurchaseRequest/pending"
              element={<PurchaseRequestPendingView />}
            />
            <Route
              path="/purchase/viewPurchaseRequestChairman"
              element={<PurchaseRequestViewChairman />}
            />
            <Route path="/purchase-orders" element={<PurchaseOrders />} />
            <Route path="/vouchers" element={<Vouchers />} />
            <Route
              path="/purchase/sendQuatation"
              element={<PurchaseReqAskQuatation />}
            />

            <Route path="/purchase/askQuo" element={<PurchaseReqAskQuo />} />
            <Route
              path="/purchase-orders/create-partial-order"
              element={<PurchaseOrdersPartial />}
            />

            <Route
              path="/purchase-req-sub-nav"
              element={<PurchaseReqSubjectNav />}
            />
            <Route path="/supply/decisions" element={<Decisions_home />} />
            <Route
              path="/supply/approve_tender"
              element={<PendingApproveQuo />}
            />
            <Route
              path="/supply/decisions/chariman_approval"
              element={<Decisions_Chairman_Approval />}
            />
            <Route
              path="/supply/decisions/gm_approval"
              element={<Decisions_GM_Approval />}
            />
            <Route
              path="/supply/decisions/pending_approval"
              element={<Decisions_Supplier_Pending />}
            />
            <Route
              path="/supply/decisions/create_po"
              element={<Decisions_Create_po />}
            />
            <Route path="/supply/ActivityLog" element={<ActivityLog />} />
            <Route path="/suppliers/supply" element={<SupplySuppliers />} />
            <Route path="/suppliers/add" element={<Supply_Add />} />
            <Route path="/committees/home" element={<Committees_Home />} />
            <Route path="/committees" element={<Committees />} />
            <Route path="/committees/members" element={<Appoint_Members />} />
            <Route
              path="/committees/members/edit"
              element={<Approve_Member_com />}
            />
            <Route
              path="/committees/opening"
              element={<Tender_Opening_Committees_Home />}
            />
            <Route
              path="/committees/tender"
              element={<Tender_Committees_Home />}
            />
            <Route
              path="/committees/techical"
              element={<Technical_Members />}
            />
            <Route
              path="/committess/sampleTestingQuoList"
              element={<Sample_Testing_Quo_List />}
            />
            <Route
              path="/committess/sampleTestingQuoLists"
              element={<Sample_Testing_Quo_Lists />}
            />
            <Route
              path="/committess/sampleTestingQuoView"
              element={<Sample_Testing_Quo_View />}
            />
            <Route path="/supply/dashboard" element={<Dashboard_Home />} />
            <Route path="/supply/sendAmail" element={<Send_A_Mail />} />
            <Route path="/supply/createTender" element={<Create_A_Tender />} />

            <Route
              path="/supply/acceptanceLetter"
              element={<Acceptance_Letter />}
            />
            <Route
              path="/supply/updateTender"
              element={<Update_Acceptance_Letter />}
            />
            <Route path="/supply/approval" element={<Approval_1_supply />} />

            <Route
              path="/committess/sentforApprovals"
              element={<Sent_For_Approvals />}
            />
            <Route
              path="/committess/bidEvaluationSummaryReportCreate"
              element={<Bid_Evaluation_Summary_Report />}
            />
            <Route
              path="/committess/bidEvaluationSummaryReportView"
              element={<Bid_Evaluation_Summary_Report_view />}
            />
            <Route
              path="/committess/committeeMeetingReportCreate"
              element={<Create_Commitee_Meeting_Report />}
            />
            <Route
              path="/committess/committeeMeetingReportView"
              element={<View_Commitee_Meeting_Report />}
            />
            <Route
              path="/committess/openingPriceForm"
              element={<Opening_Price_Report_Form />}
            />
            <Route
              path="/committess/openingPriceFormQuo"
              element={<Opening_Price_Quo_Form />}
            />
            <Route
              path="/committess/openingPriceFormQuoList"
              element={<Opening_Price_Quo_Form_List />}
            />
            <Route
              path="/committess/openingPriceFormQuoLists"
              element={<Opening_Price_Quo_Form_Lists />}
            />
            <Route
              path="/committess/openingPriceFormQuoView"
              element={<Opening_Price_Quo_Form_View />}
            />
            <Route
              path="/committess/openingPriceFormView"
              element={<Opening_Price_Report_Form_View />}
            />
            <Route
              path="/committess/sampleTesting"
              element={<Sample_Testing />}
            />
            <Route
              path="/committess/sampleTestingView"
              element={<Sample_Testing_View />}
            />
            <Route
              path="/committess/sampleTestingDocument"
              element={<Sample_Testing_Document_View />}
            />
            <Route
              path="/committess/sampleTestingDocument/PDF"
              element={<Sample_Commitee_Report />}
            />
            <Route path="/purchaseOrder/home" element={<Purchase_Order />} />
            <Route
              path="/purchaseOrder/create"
              element={<Purchase_Order_Create />}
            />
            <Route
              path="/purchaseOrder/view"
              element={<Purchase_Order_View />}
            />
            <Route path="/purchaseOrder/pdf" element={<Purchase_Order_PDF />} />
            <Route
              path="/voucher/create"
              element={<Purchase_Voucher_Create />}
            />
            <Route path="/voucher/edit" element={<Purchase_Voucher_Edit />} />
            <Route path="/voucher/view" element={<Purchase_Voucher_View />} />
            <Route
              path="/voucher/view/pdf"
              element={<TecBidEvaluationReport />}
            />
            <Route
              path="/committees/committeeMeetingReportView/pdf"
              element={<Tec_Meeting_Report />}
            />
            <Route
              path="/committees/openingPriceFormView/pdf"
              element={<OpeningPricing />}
            />
            <Route path="/" element={<Login />} />
            <Route path="*" element={<Error />} />

            {/* settings */}
            <Route path="/settings_user" element={<Settings_User />} />
            <Route
              path="/settings_permission"
              element={<Setting_Permission />}
            />
            <Route path="/user_management" element={<User_Management />} />
            <Route path="/add-user" element={<Add_User />} />

            {/* approvals */}
            <Route path="/designation" element={<Designation />} />
            <Route path="/approve-1-modules" element={<Approval_1_Modules />} />
            <Route path="/approve-2-modules" element={<Approve_2_Modules />} />
            <Route path="/approve-3-modules" element={<Approve_3_Modules />} />

            {/* Branch Req */}
            <Route path="/branch-req" element={<BranchRequest />} />
            <Route path="/branch-return-req" element={<ReturnRequest />} />
            <Route path="/view-branch-req" element={<ViewBranchRequest />} />
            <Route path="/branch-req-nav" element={<BranchRequestNav />} />
            <Route
              path="/view-check-branch-req"
              element={<CheckBranchRequest />}
            />
            <Route
              path="/view-approved-branch-req"
              element={<VewApprovedBranchRequest />}
            />
            <Route
              path="/approved-branch-req"
              element={<ApprovedBranchRequest />}
            />
            <Route
              path="/view-item-approved-history-log-branch-req"
              element={<ViewHistoryLogInventoryBranchSideRequest />}
            />
            <Route
              path="/view-update-customer"
              element={<ViewUpdateCustomer />}
            />

            {/* Quality Management */}
            <Route
              path="/quality-management/sample-allocation"
              element={<SampleAllocation />}
            />
            <Route
              path="/quality-management/sample-testing"
              element={<SampleTesting />}
            />
            <Route
              path="/quality-management/sample-test-reports"
              element={<SampleTestReports />}
            />
            <Route
              path="/quality-management/disposal-process"
              element={<DisposalProcess />}
            />
            <Route
              path="/quality-management/training-management"
              element={<TrainingManagement />}
            />
            <Route
              path="/quality-management/tender-quality-test-reports"
              element={<TenderQualityTestReport />}
            />
            <Route
              path="/quality-management/bulk-quality-test-reports"
              element={<BulkQualityTestReport />}
            />

            <Route
              path="/quality-management/training-management/completed-training"
              element={<CompletedTraining />}
            />
            <Route
              path="/quality-management/training-management/upcomming-training"
              element={<UpcommingTraining />}
            />

            <Route
              path="/quality-management/training-certifications"
              element={<TrainingCertifications />}
            />

            <Route
              path="/quality-management/approval-Stage-01-Home"
              element={<ApprovalStage01Home />}
            />

            <Route
              path="/quality-management/approval-Stage-02-Home"
              element={<ApprovalStage02Home />}
            />

            <Route
              path="/quality-management/approval-one/sample-testing-report"
              element={<SampleTestingReportApprovalOne />}
            />

            <Route
              path="/quality-management/approval-two/sample-testing-report"
              element={<SampleTestingReportApprovalTwo />}
            />

            <Route
              path="/quality-management/approval-one/manual-disposal"
              element={<ManualDisposalApprovalOne />}
            />

            <Route
              path="/quality-management/approval-two/manual-disposal"
              element={<ManualDisposalApprovalTwo />}
            />

            <Route
              path="/finance-management/chart-of-accounts"
              element={<ChartOfAccounts />}
            />

            <Route
              path="/finance-management/payment-entry"
              element={<PaymentEntry />}
            />
            <Route
              path="/finance-management/journal-entry"
              element={<JournalEntry />}
            />
            <Route
              path="/finance-management/purchase-invoice"
              element={<PurchaseInvoice />}
            />
            <Route
              path="/finance-management/sales-invoice"
              element={<SalesInvoice />}
            />

            <Route path="/finance-management/dashbord" element={<Dashbord />} />

            <Route
              path="/finance-management/chart-of-accounts/account-ledger/:account_code"
              element={<AccountLedger />}
            />

            <Route
              path="/finance-management/accounts-payables"
              element={<AccountsPayables />}
            />

            <Route
              path="/finance-management/account-receivable"
              element={<AccountReceivable />}
            />

            <Route
              path="/finance-management/account-receivable/debtors"
              element={<Debtors />}
            />

            <Route
              path="/finance-management/assigen-account"
              element={<AssigenAccount />}
            />

            <Route
              path="/finance-management/trial-balance"
              element={<TrialBalance />}
            />

            <Route
              path="/finance-management/account-authorization"
              element={<AccountAuthorization />}
            />

            <Route
              path="/vehicle-information-management"
              element={<VehicleInfomationMamagement />}
            />
            <Route
              path="/vehicle-fleet-management/vehicle-details"
              element={<VehicleDatatils />}
            />
            <Route
              path="/vehicle-fleet-management/driver-details"
              element={<Driver_Details />}
            />
            <Route
              path="/vehicle-fleet-management/vehicle-part-details"
              element={<Vehicle_Parts_Details />}
            />
            <Route
              path="/current-vehicle-dispatches"
              element={<Current_Vehicle_Dispatches />}
            />
            <Route
              path="/current-vehicle-dispatches/manager-transportation-dispatches"
              element={<Manager_Transportation_Dispaches />}
            />
            <Route
              path="/current-vehicle-dispatches/gatepass-transportation-dispatches"
              element={<Gatepass_Transportation_Dispaches />}
            />
            <Route
              path="/current-vehicle-schedule"
              element={<Current_Vehicle_Schedule />}
            />
            <Route
              path="/route-planning-schedules/manager-transportation-schedules"
              element={<Manager_Transportation_Schedules />}
            />
            <Route
              path="/route-planning-Schedules/gatepass-transportation-schedules"
              element={<Gatepass_Transportation_Schedules />}
            />

            <Route path="/fuel-manegemnt" element={<Fuel_Manegemnt_Home />} />
            <Route
              path="/fuel-manegemnt/fuel-consumption-data"
              element={<Fuel_Consumption_Data />}
            />

            <Route
              path="/fuel-manegemnt/fuel-cost-update"
              element={<Fuel_cost_update />}
            />
            <Route
              path="/fuel-manegemnt/generate-monthly-fuel-reports"
              element={<Generate_monthly_fuel_reports />}
            />

            <Route
              path="/maintenance-manegement"
              element={<Maintenance_Manegement />}
            />

            <Route
              path="/maintenance-manegement/upload-request-letter-form"
              element={<Upload_Request_Letter_Details />}
            />
            <Route
              path="/maintenance-manegement/maintenance-records"
              element={<Maintenance_records />}
            />
            <Route
              path="/maintenance-manegement/breakdown-records"
              element={<Breakdown_records />}
            />

            <Route
              path="/inventory-management"
              element={<Inventory_Management />}
            />
            <Route
              path="/inventory-management/vehicle-count-dashboard"
              element={<Vehicle_Count_Dashboard />}
            />
            <Route
              path="/inventory-management/fuel-consumption-dashboard"
              element={<Fuel_Consumption_Dashboard />}
            />

            <Route
              path="/Inventory-management/location-data-dashboard"
              element={<Location_data_dashboard />}
            />

            <Route path="/performance" element={<Performance />} />

            <Route
              path="/performance/driver-performance-monitoring"
              element={<Driver_performance_monitoring />}
            />

            <Route
              path="/compliance-reporting"
              element={<Compliance_reporting />}
            />

            <Route
              path="/compliance-reporting/vehicles_exceeding_5000km"
              element={<Vehicles_Exceeding_5000km />}
            />

            <Route
              path="/Approval-process-01"
              element={<Approval_process_01_Home />}
            />

            <Route
              path="/Approval-process-01/document-uploading"
              element={<Approval_process_01_Document_Uploading />}
            />

            <Route
              path="/Approval-process-01/document-uploading-vehicle"
              element={<Approval_process_01_Document_Vehicle />}
            />

            <Route
              path="/Approval-process-01/document-uploading-driver"
              element={<Approval_process_01_Document_Driver />}
            />

            <Route
              path="/Approval-process-01/system-editing"
              element={<Approval_process_01_System_Editing />}
            />

            <Route
              path="/Approval-process-01/gate-pass-request"
              element={<Approval_process_01_Gate_Pass_Request />}
            />

            <Route
              path="/Approval-process-02"
              element={<Approval_process_02_Home />}
            />

            <Route
              path="/Approval-process-02/document-uploading"
              element={<Approval_process_02_Document_Uploading />}
            />

            <Route
              path="/Approval-process-02/document-uploading-vehicle"
              element={<Approval_process_02_Document_Vehicle />}
            />

            <Route
              path="/Approval-process-02/document-uploading-driver"
              element={<Approval_process_02_Document_Driver />}
            />

            <Route
              path="/Approval-process-02/system-editing"
              element={<Approval_process_02_System_Editing />}
            />

            <Route
              path="/Approval-process-02/gate-pass-request"
              element={<Approval_process_02_Gate_Pass_Request />}
            />

            <Route
              path="/Approval-process-03"
              element={<Approval_process_03_Home />}
            />

            <Route
              path="/Approval-process-03/document-uploading"
              element={<Approval_process_03_Document_Uploading />}
            />

            <Route
              path="/Approval-process-03/document-uploading-vehicle"
              element={<Approval_process_03_Document_Vehicle />}
            />

            <Route
              path="/Approval-process-03/document-uploading-driver"
              element={<Approval_process_03_Document_Driver />}
            />

            <Route
              path="/Approval-process-03/system-editing"
              element={<Approval_process_03_System_Editing />}
            />

            <Route
              path="/Approval-process-03/gate-pass-request"
              element={<Approval_process_03_Gate_Pass_Request />}
            />

            
          <Route
              path="/vehicle-location"
              element={<Vehicle_Location />}
            />

            <Route
              path="/finance-management/trial-balance"
              element={<TrialBalance />}
            />

            <Route
              path="/finance-management/bank-reconciliation-tool"
              element={<BankReconciliationTool />}
            />
          </Routes>
        </SidebarChecker>
      </Router>
    </div>
  );
}

export default App;
