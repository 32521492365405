import React, { useEffect, useState } from "react";
import axios from "axios";

const Debtors = () => {
    const backendUrl = process.env.REACT_APP_BACKEND_URL_FINANCE;
    const [tableData, setTableData] = useState([]);
    const [filters, setFilters] = useState({
        customer_name: "",
        customer_id: "",
        start_date: "",
        end_date: ""
    });
    const [pagination, setPagination] = useState({
        page: 1,
        page_size: 5,
        total: 0,
        total_pages: 0
    });

    // Fetch table data with filters and pagination
    const getTableData = async () => {
        try {
            const response = await axios.get(`${backendUrl}/receivable/salesInvoice/getDebtors`, {
                params: {
                    ...filters,
                    page: pagination.page,
                    page_size: pagination.page_size
                }
            });
            setTableData(response.data.data);
            setPagination({
                ...pagination,
                total: response.data.total,
                total_pages: response.data.total_pages
            });
        } catch (error) {
            console.error("Error fetching debtors:", error);
        }
    };

    // Handle changes in filters
    const handleFilterChange = (e) => {
        const { name, value } = e.target;
        setFilters((prev) => ({
            ...prev,
            [name]: value
        }));
    };

    // Handle pagination change
    const handlePageChange = (newPage) => {
        setPagination((prev) => ({
            ...prev,
            page: newPage
        }));
    };

    const handlePageSizeChange = (e) => {
        setPagination((prev) => ({
            ...prev,
            page_size: parseInt(e.target.value, 10),
            page: 1
        }));
    };

    useEffect(() => {
        getTableData();
    }, [filters, pagination.page, pagination.page_size]);

    return (
        <div className="mx-10 mt-5 text-xl">
            <h2 className="text-3xl font-semibold mb-4">Debtors</h2>

            {/* Filters */}
            <div className="flex flex-wrap gap-4 mb-5">
                <input
                    type="text"
                    name="customer_name"
                    placeholder="Customer Name"
                    value={filters.customer_name}
                    onChange={handleFilterChange}
                    className="border p-2 rounded"
                />
                <input
                    type="text"
                    name="customer_id"
                    placeholder="Customer ID"
                    value={filters.customer_id}
                    onChange={handleFilterChange}
                    className="border p-2 rounded"
                />
                <input
                    type="date"
                    name="start_date"
                    value={filters.start_date}
                    onChange={handleFilterChange}
                    className="border p-2 rounded"
                />
                <input
                    type="date"
                    name="end_date"
                    value={filters.end_date}
                    onChange={handleFilterChange}
                    className="border p-2 rounded"
                />
            </div>

            {/* Table */}
            <table className="min-w-full bg-white border border-gray-300">
                <thead>
                <tr>
                    <th className="px-4 py-2 border">Customer ID</th>
                    <th className="px-4 py-2 border">Customer Name</th>
                    <th className="px-4 py-2 border">Opening Balance</th>
                    <th className="px-4 py-2 border">Closing Balance</th>
                </tr>
                </thead>
                <tbody>
                {tableData.map((debtor) => (
                    <tr key={debtor.customer_id}>
                        <td className="px-4 py-2 border">{debtor.customer_id}</td>
                        <td className="px-4 py-2 border">{debtor.customer_name}</td>
                        <td className="px-4 py-2 border">{debtor.opening_balance}</td>
                        <td className="px-4 py-2 border">{debtor.closing_balance}</td>
                    </tr>
                ))}
                </tbody>
            </table>

            {/* Pagination Controls */}
            <div className="flex justify-between items-center mt-4">
                <div>
                    Page {pagination.page} of {pagination.total_pages}
                </div>
                <div className="flex items-center gap-2">
                    <label htmlFor="pageSize">Rows per page:</label>
                    <select
                        id="pageSize"
                        value={pagination.page_size}
                        onChange={handlePageSizeChange}
                        className="border p-1 rounded"
                    >
                        <option value={5}>5</option>
                        <option value={10}>10</option>
                        <option value={20}>20</option>
                        <option value={50}>50</option>
                    </select>
                </div>
                <div className="flex gap-2">
                    <button
                        onClick={() => handlePageChange(Math.max(1, pagination.page - 1))}
                        className="border px-3 py-1 rounded"
                        disabled={pagination.page === 1}
                    >
                        Previous
                    </button>
                    <button
                        onClick={() => handlePageChange(Math.min(pagination.total_pages, pagination.page + 1))}
                        className="border px-3 py-1 rounded"
                        disabled={pagination.page === pagination.total_pages}
                    >
                        Next
                    </button>
                </div>
            </div>
        </div>
    );
};

export default Debtors;
