/** @format */

import React, { useEffect, useState, useRef } from "react";
import { useReactToPrint } from "react-to-print";
import { Link, useNavigate, useLocation } from "react-router-dom";
import AsyncSelect from "react-select/async";
import Printer from "../../../../Assets/icons/print 1.png";
import IdGenerate from "../../../../utils/id_generate";
import axios from "axios";
import Cookies from "js-cookie";

export default function ReleasedItemReqFromProductionView() {
  const location = useLocation();
  const navigate = useNavigate();
  const selectedRecord = location.state?.selectedRecord || {};
  const [tableData, setTableData] = useState(selectedRecord.raw_items || []);
  const userId = Cookies.get("employee_no");
  const [netTotal, setNetTotal] = useState(0);
  const { REACT_APP_API_ENDPOINT } = process.env;
  console.log(selectedRecord);
  const [approvedQuantity, setApprovedQuantity] = useState(
    selectedRecord.approved_quantity || ""
  );
  const [alert, setAlert] = useState({
    status: "",
    alert: "",
    show: false,
  });

  const currentDate = new Date(); // Get the current date and time
  const formattedDate = currentDate.toISOString().split("T")[0]; // Format the date to ISO string

  const SubmitHandler = async (itemId) => {
    const jobID = selectedRecord.job_id;

    try {
      const response = await fetch(
        `${REACT_APP_API_ENDPOINT}/v1/inventory/inventoryRequest/release-inventoryRequest-item`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            job_id: jobID,
            item_id: itemId,
            release_by: userId, // Add release_by parameter
          }),
        }
      );
      if (response.ok) {
        const data = await response.json(); // Parse the JSON response
        console.log("Data released:", data);

        // Display the backend message in the alert
        window.alert(
          data.message || "Successfully Released Production Item Request"
        );

        navigate("/inventory/view-pro-item-req");
      } else {
        const errorMsg = await response.text();
        console.error("Error:", errorMsg);
        setAlert({
          status: "error",
          alert: "Something Went Wrong",
          show: true,
        });
      }
    } catch (error) {
      console.error("Fetch error:", error);
      setAlert({ status: "error", alert: "Something Went Wrong", show: true });
    }
  };

  const Data = [...tableData]; // Create a copy of tableData
  Data.push({
    item_name: tableData.item_name,
    item_id: tableData.raw_item_id,
    item_quantity: tableData.item_quantity,
    item_unit: tableData.item_unit,
  });

  // Line amount calculation function
  const calculateLineAmount = (batchPrice, itemQuantity) => {
    return (batchPrice * itemQuantity).toFixed(2); // Keep 2 decimal places
  };

  // Calculate the net total of all line amounts
  useEffect(() => {
    const total = tableData.reduce(
      (sum, item) => sum + item.batch_price * item.item_quantity,
      0
    );
    setNetTotal(total.toFixed(2)); // Update net total with 2 decimal places
  }, [tableData]);
  const CancelHandler = () => {
    navigate(-1);
  };

  return (
    <div className="p-10 bg-background">
      {/* <TopNaw moduleName ='Inventory Items Purchase' userName={userName}/> */}
      <p className="header-1">Item Released Request From Production</p>
      <div>
        <div className="flex items-center ">
          <p className="label-1">Job Id</p>
          <p className="label-1">:</p>
          <p className="font-[22px] w-[310px] h-[46px] rounded-[23px] bg-[#F3F8FF] ml-10 border border-[#00000036] px-7 flex items-center ">
            {selectedRecord.job_id}
          </p>
        </div>
      </div>

      <div className="w-full flex items-center justify-center ">
        <div className="bg-[#F3F8FF] rounded-[46px] border border-[#00000036] px-[200px] py-11 my-10 flex flex-col items-center justify-center">
          <p className="header-1">Released Request Order Details</p>
          <div className="flex flex-col flex-wrap justify-between items-center">
            <div className="w-[600px] flex items-center justify-between my-10">
              <p className="label-1">Production Estimate Start Date :</p>
              <p className="font-[22px] w-[355px] h-[46px] rounded-[23px] bg-[#FFFFFF] border border-[#00000036] px-7 flex items-center ">
                {new Date(
                  selectedRecord.production_estimate_start_date
                ).toLocaleString()}
              </p>
            </div>
            <div className="w-[600px] flex items-center justify-between my-10">
              <p className="label-1">Production Estimate End Date :</p>
              <p className="font-[22px] w-[355px] h-[46px] rounded-[23px] bg-[#FFFFFF] border border-[#00000036] px-7 flex items-center ">
                {new Date(
                  selectedRecord.production_estimate_end_date
                ).toLocaleString()}
              </p>
            </div>
          </div>
        </div>
      </div>

      <p className="header-1">Request Order Items</p>

      {/* table */}
      <div className="w-full flex items-center justify-center my-10">
        <div className="bg-[#F3F8FF] w-full min-h-96 mx-16 rounded-t-[40px] rounded-b-[50px]">
          <div className="w-full h-20 bg-PrimaryColor2 rounded-[100px] flex items-center justify-around">
            <p className="w-10 p1 text-[#656565] text-center">#</p>
            <div className="w-[350px] flex flex-col items-center">
              <p className="w-full p1 text-[#656565] text-center ">Item ID</p>
              <div className="border-[3px] border-[#656565] w-[67px] rounded-[100px] mt-1"></div>
            </div>
            <div className="w-[200px] flex flex-col items-center">
              <p className="w-full p1 text-[#656565] text-center ">Item Name</p>
              <div className="border-[3px] border-[#656565] w-[67px] rounded-[100px] mt-1"></div>
            </div>
            <div className="w-[170px] flex flex-col items-center">
              <p className="w-full p1 text-[#656565] text-center ">Item Qty</p>
              <div className="border-[3px] border-[#656565] w-[67px] rounded-[100px] mt-1"></div>
            </div>
            <div className="w-[160px] flex flex-col items-center">
              <p className="w-full p1 text-[#656565] text-center ">Item Unit</p>
              <div className="border-[3px] border-[#656565] w-[67px] rounded-[100px] mt-1"></div>
            </div>
            <div className="w-[200px] flex flex-col items-center">
              <p className="w-full p1 text-[#656565] text-center ">
                Item Batch Price
              </p>
              <div className="border-[3px] border-[#656565] w-[67px] rounded-[100px] mt-1"></div>
            </div>
            <div className="w-[200px] flex flex-col items-center">
              <p className="w-full p1 text-[#656565] text-center ">Store</p>
              <div className="border-[3px] border-[#656565] w-[67px] rounded-[100px] mt-1"></div>
            </div>
            <div className="w-[200px] flex flex-col items-center">
              <p className="w-full p1 text-[#656565] text-center ">Location</p>
              <div className="border-[3px] border-[#656565] w-[67px] rounded-[100px] mt-1"></div>
            </div>
            <div className="w-[200px] flex flex-col items-center">
              <p className="w-full p1 text-[#656565] text-center ">
                Released Date
              </p>
              <div className="border-[3px] border-[#656565] w-[67px] rounded-[100px] mt-1"></div>
            </div>
            <div className="w-[200px] flex flex-col items-center">
              <p className="w-full p1 text-[#656565] text-center ">
                Released By
              </p>
              <div className="border-[3px] border-[#656565] w-[67px] rounded-[100px] mt-1"></div>
            </div>
            <div className="w-[200px] flex flex-col items-center">
              <p className="w-full p1 text-[#656565] text-center ">
                Line Amount
              </p>
              <div className="border-[3px] border-[#656565] w-[67px] rounded-[100px] mt-1"></div>
            </div>
          </div>
          <div className="">
            {tableData.length > 0
              ? tableData.map((item, index) => (
                  <div
                    key={index}
                    className="w-full flex items-start justify-around my-3  "
                  >
                    <p className="w-10 p1 text-[#656565] text-center">
                      {index + 1}
                    </p>
                    <p className="w-[350px] p1 text-[#656565] text-center">
                      {item.raw_item_id}
                    </p>
                    <p className="w-[160px] p1 text-[#656565] text-center">
                      {item.item_name}
                    </p>
                    <p className="w-[160px] p1 text-[#656565] text-center">
                      {item.item_quantity}
                    </p>
                    <p className="w-[200px] p1 text-[#656565] text-center">
                      {item.item_unit}
                    </p>
                    <p className="w-[200px] p1 text-[#656565] text-center">
                      {item.batch_price}
                    </p>
                    <p className="w-[200px] p1 text-[#656565] text-center">
                      {item.store_id}
                    </p>
                    <p className="w-[200px] p1 text-[#656565] text-center">
                      {item.rack_location}
                    </p>
                    <p className="w-[200px] p1 text-[#656565] text-center">
                      {new Date(item.release_date).toLocaleString()}
                    </p>
                    <p className="w-[200px] p1 text-[#656565] text-center">
                      {item.release_by}
                    </p>
                    <p className="w-[200px] p1 text-[#656565] text-center">
                      {calculateLineAmount(
                        item.batch_price,
                        item.item_quantity
                      )}
                    </p>
                  </div>
                ))
              : null}
          </div>
        </div>
      </div>

      <div className="w-full flex justify-between items-start px-16 pt-3 ">
        <div className="w-[620px]  rounded-[23px] bg-[#F3F8FF] px-10 py-7 flex flex-col justify-between gap-y-7">
          <div className="w-full flex items-center gap-2">
            <p className="p1 text-[#656565] w-[500px]">
              Production Created By:
            </p>
            <div className="relative w-[300px]">
              <p className="font-[22px] w-[310px] h-[46px] rounded-[23px] bg-[#FFFFFF] border border-[#00000036] px-7 flex items-center ">
                {selectedRecord.production_manager}
              </p>
            </div>
          </div>
        </div>
        <div className="w-[350px] h-full  flex flex-col gap-y-2">
          <div className="w-[350px] h-full rounded-[23px] bg-[#F3F8FF] flex flex-col px-4 py-3">
            <div className="flex items-center justify-between">
              <p className="p1 text-[#656565]"> Net Total</p>
              <p className="p1 text-[#656565]">{netTotal}</p>
            </div>
          </div>
        </div>
      </div>

      <div className="flex w-full items-center justify-end gap-x-10 my-10">
        {/* <button className="w-[138px] h-[41px] flex items-center justify-between bg-white border rounded-[23px] px-5">
          <img src={Printer} alt="" />
          <p
            className="p2 text-[#656565]"
            onClick={() => {
              handlePrint(null, () => contentToPrint.current);
            }}
          >
            Print
          </p>
        </button> */}
        <button
          className="w-[118px] h-[45px] flex items-center justify-center bg-[#AA4343] border rounded-[23px] px-4 text-white font-bold text-xl"
          onClick={CancelHandler}
        >
          Back
        </button>
      </div>

      {/* Print layout */}

      {/* <div className="p-10 hidden">
        <div className="p-10" ref={contentToPrint}>
          <p className="header-1">Inventory Purchase (GRN)</p>

          <div id="printable" className="formal-document">
            <h1 className="document-title">Goods Received Note</h1>
            <div className="document-section">
              <h2 className="section-title">Purchase Order Details</h2>
              <div className="section-content">
                <p>
                  <strong>Purchase Order Id:</strong> {PoData[0].po_id}
                </p>
                <p>
                  <strong>Supplier:</strong> {PoData[0].supplier_name}
                </p>
                <p>
                  <strong>PO Date:</strong> {PoData[0].po_issue_date}
                </p>
              </div>
            </div>

            <div className="document-section">
              <h2 className="section-title">Purchasing Items</h2>
              <table className="items-table">
                <thead>
                  <tr>
                    <th>#</th>
                    <th>Item Name</th>
                    <th>Requested Qty</th>
                    <th>Supplied Qty</th>
                    <th>Unit</th>
                    <th>Remark</th>
                  </tr>
                </thead>
                <tbody>
                  {poItemsData.length > 0 &&
                    poItemsData.map((item, index) => (
                      <tr key={index}>
                        <td>{index + 1}</td>
                        <td>{item.item_name}</td>
                        <td>{item.requested_qty}</td>
                        <td>{item.supplied_qty}</td>
                        <td>{item.unit}</td>
                        <td>{item.remark}</td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div> */}
    </div>
  );
}
