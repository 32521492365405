import React from 'react'
import { FaBell } from "react-icons/fa";
import { MdKeyboardArrowDown } from "react-icons/md";

const Navbar = () => {
  return (
    <div>
        <div className="flex justify-between items-center">
        <div>
          <p className='text-[20px] font-bold'>Welcome back, User</p>
        </div>
        <div>
          <div className="flex gap-4 items-center">
            <FaBell />

            <div>
              <p className='text-[20px] font-semibold'>User</p>
            </div>
            <div>
              <MdKeyboardArrowDown />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Navbar