import axios from "axios";
import React, { useEffect, useState } from "react";
import { IoIosCloseCircleOutline, IoIosCloudUpload } from "react-icons/io";
import Notification from "../../../components/notification/notification";
import FileUpload from "./upload_files";
import ReactSelect from 'react-select';

const Add_payment_entry_popup = ({ togglePopup, resetTable }) => {
  const backendUrl = process.env.REACT_APP_BACKEND_URL_FINANCE;
  const [allAccount, setAllAccount] = useState([]);
  const [addPaymentEntry, setAddPaymentEntry] = useState({
    po_id: null,
    pos_id: null,
    party_type: "",
    payment_type: "",
    party_name: "",
    entity_id: "",
    transaction_type: "",
    payment_date: "",
    amount: "",
    payment_method: "",
    description: "",
    prepared_by: "",
    paid_from_account_id: "",
    paid_to_account_id: "",
    payment_file: "",
    cheque_number: "",
    cheque_date: "",
    payee_name: "",
  });
  const [isResetConfirmationOpen, setIsResetConfirmationOpen] = useState(false);
  const [isSaveConfirmationOpen, setIsSaveConfirmationOpen] = useState(false);
  const [notification, setNotification] = useState({ message: "", type: "" });
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [allReceiveIds, setAllReceive] = useState([]);
  const [allpoIds, setPoID] = useState([]);

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  // const handleInputChange = (selectedOption, { name }) => {
  //
  //   setAddPaymentEntry((prevDetails) => {
  //     const updatedDetails = {
  //       ...prevDetails,
  //       [name]: selectedOption ? selectedOption.value : ""
  //     };
  //
  //     if(name === "payment_type"){
  //       updatedDetails.po_id = "";
  //       updatedDetails.pos_id = "";
  //       updatedDetails.party_type = "";
  //       updatedDetails.party_name = "";
  //       updatedDetails.entity_id = "";
  //     }
  //     return updatedDetails;
  //   });
  // };

  const handleInputChange = (eventOrOption, actionMeta) => {
    let name, value;

    if (actionMeta) {
      // This block handles ReactSelect input changes
      name = actionMeta.name;
      value = eventOrOption ? eventOrOption.value : "";
    } else {
      // This block handles normal HTML input/select elements
      name = eventOrOption.target.name;
      value = eventOrOption.target.value;
    }

    setAddPaymentEntry((prevDetails) => {
      const updatedDetails = {
        ...prevDetails,
        [name]: value
      };

      // Reset dependent fields if payment type changes
      if (name === "payment_type") {
        updatedDetails.po_id = "";
        updatedDetails.pos_id = "";
        updatedDetails.party_type = "";
        updatedDetails.party_name = "";
        updatedDetails.entity_id = "";
      }

      return updatedDetails;
    });
  };

  const handleOpenResetConfirmation = () => {
    setIsResetConfirmationOpen(true);
  };

  const handleOpenSaveConfirmation = () => {
    const user = getCookieValue("employee_no");

    setAddPaymentEntry((prevDetails) => ({
      ...prevDetails,
      payment_file: uploadedFiles[0],
      prepared_by: user,
    }));
    setIsSaveConfirmationOpen(true);
  };

  const handleCloseSaveConfirmation = () => {
    setIsSaveConfirmationOpen(false);
  };

  const handleCloseResetConfirmation = () => {
    setIsResetConfirmationOpen(false);
  };

  const handleResetForm = () => {
    setAddPaymentEntry({
      po_id: null,
      pos_id: null,
      party_type: "",
      payment_type: "",
      entity_id: "",
      transaction_type: "",
      payment_date: "",
      amount: "",
      payment_method: "",
      description: "",
      prepared_by: "",
      paid_from_account_id: "",
      paid_to_account_id: "",
      payment_file: "",
      cheque_number: "",
      cheque_date: "",
      payee_name: "",
    });

    setUploadedFiles([]);
    setIsResetConfirmationOpen(false);
  };

  const handleCloseNotification = () => {
    setNotification({ message: "", type: "" });
  };

  const getAccountData = async () => {
    try {
      const response = await axios.get(
        `${backendUrl}/account/getAllAccountsByFilters`
      );

      const formattedData = response.data.data.map((item) => ({
        id: item.account_id,
        code: item.account_code,
        name: item.account_name
      }));

      setAllAccount(formattedData);
    } catch (error) {
      console.error("Error fetching sample details:", error);
      // setTableData([]);
    }
  };

  const handleAccountChange = (selectedOption, {name}) => {
    setAddPaymentEntry((prevState) => ({
      ...prevState,
      [name]: selectedOption ? selectedOption.value : null,
    }));
  };

  const handleRemoveFile = (index) => {
    setUploadedFiles((prevFiles) => prevFiles.filter((_, i) => i !== index));
  };

  const handlePOIDFromChange = (selectedOption) => {
    if (selectedOption) {
      setAddPaymentEntry((prevState) => ({
        ...prevState,
        po_id: selectedOption.value,
      }));
    } else {
      setAddPaymentEntry((prevState) => ({
        ...prevState,
        po_id: "",
      }));
    }
  };

  const handlePOSIDFromChange = (selectedOption) => {
    if (selectedOption) {
      setAddPaymentEntry((prevState) => ({
        ...prevState,
        pos_id: selectedOption.value,
      }));
    } else {
      setAddPaymentEntry((prevState) => ({
        ...prevState,
        pos_id: "",
      }));
    }
  };

  const handleSaveForm = async () => {
    console.log(addPaymentEntry);

    try {
      addPaymentEntry.transaction_type = 'Payment'
      const response = await axios.post(
        `${backendUrl}/paymentEntry/add`,
        addPaymentEntry,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      setNotification({
        message: response.data.message,
        type: "success",
      });

      handleResetForm();
    } catch (error) {
      console.error("Error", error);
      setNotification({
        message: error?.response?.data?.error,
        type: "error",
      });
    }

    setIsSaveConfirmationOpen(false);
  };

  const getPoIds = async () => {
    try {
      const response = await axios.get(
        `${backendUrl}/paymentEntry/getPOsWhichHasUnpaidGRNs`
      );

      setPoID(response.data);
    } catch (error) {
      console.error("Error fetching sample details:", error);
    }
  };

  const getReceive = async () => {
    try {
      const response = await axios.get(
        `${backendUrl}/paymentEntry/getUnpaidReceivables`
      );

      console.log(response.data)
      setAllReceive(response.data);
    } catch (error) {
      console.error("Error fetching sample details:", error);
      // setTableData([]);
    }
  };

  // get party details
  const getPartyDetails = async () => {
    console.log(addPaymentEntry.po_id);

    try {
      if(addPaymentEntry.po_id){
        const response = await axios.get(
            `${backendUrl}/paymentEntry/getDetailsByPOId/${addPaymentEntry.po_id}`
        );

        console.log(response.data)

        setAddPaymentEntry((prevState) => ({
          ...prevState,
          party_type: response.data.partyType,
          entity_id: response.data.supplierID,
          party_name: response.data.supplierParty,
        }));
      } else {
        setAddPaymentEntry((prevState) => ({
          ...prevState,
          party_type: "",
          entity_id: "",
          party_name: "",
        }));
      }
    } catch (error) {
      console.error("Error fetching sample details:", error);
      // setTableData([]);
    }
  };

  const getPOSPatyDetails = async () => {
    console.log(addPaymentEntry.pos_id);

    try {
      if(addPaymentEntry.pos_id){
        const response = await axios.get(
            `${backendUrl}/paymentEntry/getDetailsByPOSId/${addPaymentEntry.pos_id}`
        );

        console.log(response.data[0])

        setAddPaymentEntry((prevState) => ({
          ...prevState,
          party_type: response.data[0].partyType,
          entity_id: response.data[0].partyID,
          party_name: response.data[0].partyName,
        }));
      } else {
        setAddPaymentEntry((prevState) => ({
          ...prevState,
          party_type: "",
          entity_id: "",
          party_name: "",
        }));
      }
    } catch (error) {
      console.error("Error fetching sample details:", error);
      // setTableData([]);
    }
  };

  useEffect(() => {
    getAccountData();
    getPoIds();
    getReceive();
  }, []);

  useEffect(() => {
    setAddPaymentEntry((prevState) => ({
      ...prevState,
      party_type: "",
      entity_id: "",
      party_name: "",
    }));

    getPartyDetails();
  }, [addPaymentEntry.po_id]);

  useEffect(() => {
    getPOSPatyDetails();
  }, [addPaymentEntry.pos_id]);



  return (
    <div className="fixed top-0 left-0 z-50 flex items-center justify-center w-full h-full bg-gray-200 bg-opacity-80">
      <div className="w-[80%] h-[90%] overflow-y-auto p-8 bg-white rounded-lg">
        <div className="flex items-center justify-between mb-6">
          <h2 className="text-2xl font-semibold">Add Payment Entry</h2>
          <button
            type="button"
            onClick={togglePopup}
            className="p-4 text-4xl text-gray-500 transition duration-300 hover:text-gray-700"
          >
            <IoIosCloseCircleOutline />
          </button>
        </div>

        {/* Notification Alert */}
        <Notification
          message={notification.message}
          type={notification.type}
          onClose={handleCloseNotification}
        />
        <form>
          <div className="items-center gap-8 mt-5">
            {/* Define a two-column grid */}
            <div className="grid grid-cols-2 gap-y-8 gap-x-16 text-lg">

              {/* Payment Type */}
              <div>
                <label className="block text-gray-700 font-semibold mb-1">
                  Payment Type
                </label>
                <ReactSelect
                    options={[
                      { value: "Receive", label: "Receive" },
                      { value: "Pay", label: "Pay" },
                      { value: "Internal Transfer", label: "Internal Transfer" }
                    ]}
                    name="payment_type"
                    onChange={handleInputChange}
                    value={addPaymentEntry.payment_type ? { value: addPaymentEntry.payment_type, label: addPaymentEntry.payment_type } : null}
                    isClearable
                    placeholder="Select Payment Type"
                    className="w-full mt-2"
                    styles={{
                      control: (provided) => ({
                        ...provided,
                        padding: "0.2rem 0.5rem",
                        border: "1px solid #d1d5db",
                        borderRadius: "0.375rem"
                      })
                    }}
                />
              </div>

              {addPaymentEntry.payment_type === "Pay" && (
                  <div>
                    <label className="block text-gray-700 font-semibold mb-1">
                      PO ID
                    </label>
                    <ReactSelect
                        options={allpoIds.map((type) => ({
                          value: type.po_id,
                          label: type.po_id
                        }))}
                        name="po_id"
                        onChange={handlePOIDFromChange}
                        value={addPaymentEntry.po_id ? { value: addPaymentEntry.po_id, label: addPaymentEntry.po_id } : null}
                        isClearable
                        placeholder="Select PO ID"
                        className="w-full mt-2"
                        styles={{
                          control: (provided) => ({
                            ...provided,
                            padding: "0.2rem 0.5rem",
                            border: "1px solid #d1d5db",
                            borderRadius: "0.375rem"
                          })
                        }}
                    />
                  </div>
              )}

              {addPaymentEntry.payment_type === "Receive" && (
                <div>
                  <label className="block text-gray-700 font-semibold mb-1">
                    POS ID
                  </label>
                  <ReactSelect
                      options={allReceiveIds.map((type) => ({
                        value: type.reference_id,
                        label: type.reference_id
                      }))}
                      name="pos_id"
                      onChange={handlePOSIDFromChange}
                      value={addPaymentEntry.pos_id ? { value: addPaymentEntry.pos_id, label: addPaymentEntry.pos_id } : null}
                      isClearable
                      placeholder="Select POS ID"
                      className="w-full mt-2"
                      styles={{
                        control: (provided) => ({
                          ...provided,
                          padding: "0.2rem 0.5rem",
                          border: "1px solid #d1d5db",
                          borderRadius: "0.375rem"
                        })
                      }}
                  />
                </div>
              )}

              {/* Hide Party Type and Party when payment_type is Internal Transfer */}
              {addPaymentEntry.payment_type !== "Internal Transfer" && (
                <>
                  {/* Party Type */}
                  <div>
                    <label className="block text-gray-700 font-semibold mb-1">
                      Party Type
                    </label>
                    <input
                      type="text"
                      name="party_type"
                      className="w-full p-3 mt-2 border border-gray-300 rounded-md focus:ring-2"
                      value={addPaymentEntry.party_type}
                      onChange={handleInputChange}
                      readOnly
                      disabled
                    />
                  </div>

                  {/*  Party */}

                  <div>
                    <label className="block text-gray-700 font-semibold mb-1">
                       Party
                    </label>
                    <input
                      type="text"
                      name="party_name"
                      className="w-full p-3 mt-2 border border-gray-300 rounded-md focus:ring-2"
                      value={addPaymentEntry.party_name}
                      onChange={handleInputChange}
                      readOnly
                      disabled
                    />
                  </div>
                </>
              )}

              {/* Payment Method */}
              <div>
                <label className="block text-gray-700 font-semibold mb-1">
                  Payment Method
                </label>
                <ReactSelect
                    options={[
                      { value: "Cash", label: "Cash" },
                      { value: "Card", label: "Card" },
                      { value: "Cheque", label: "Cheque" }
                    ]}
                    name="payment_method"
                    onChange={handleInputChange}
                    value={addPaymentEntry.payment_method ? { value: addPaymentEntry.payment_method, label: addPaymentEntry.payment_method } : null}
                    isClearable
                    placeholder="Select Payment Method"
                    className="w-full mt-2"
                    styles={{
                      control: (provided) => ({
                        ...provided,
                        padding: "0.2rem 0.5rem",
                        border: "1px solid #d1d5db",
                        borderRadius: "0.375rem"
                      })
                    }}
                />
              </div>

              {/* From Account */}
              <div>
                <label className="block text-gray-700 font-semibold mb-1">
                  Account From
                </label>
                <ReactSelect
                    options={allAccount.map((type) => ({
                      value: type.id,
                      label: `${type.code} - ${type.name}`
                    }))}
                    name="paid_from_account_id"
                    onChange={handleAccountChange}
                    value= {
                      addPaymentEntry.paid_from_account_id
                          ? allAccount
                              .filter((account) => account.id === addPaymentEntry.paid_from_account_id)
                              .map((account) => ({
                                value: account.id,
                                label: `${account.code} - ${account.name}`
                              }))[0] // selects the first match
                          : {}
                    }
                    isClearable
                    placeholder="Select POS ID"
                    className="w-full mt-2"
                    styles={{
                      control: (provided) => ({
                        ...provided,
                        padding: "0.2rem 0.5rem",
                        border: "1px solid #d1d5db",
                        borderRadius: "0.375rem"
                      })
                    }}
                />
              </div>

              {/* To Account */}
              <div>
                <label className="block text-gray-700 font-semibold mb-1">
                  Account To
                </label>
                <ReactSelect
                    options={allAccount.map((type) => ({
                      value: type.id,
                      label: `${type.code} - ${type.name}`
                    }))}
                    name="paid_to_account_id"
                    onChange={handleAccountChange}
                    value= {
                      addPaymentEntry.paid_to_account_id
                          ? allAccount
                              .filter((account) => account.id === addPaymentEntry.paid_to_account_id)
                              .map((account) => ({
                                value: account.id,
                                label: `${account.code} - ${account.name}`
                              }))[0] // selects the first match
                          : {}
                    }
                    isClearable
                    placeholder="Select POS ID"
                    className="w-full mt-2"
                    styles={{
                      control: (provided) => ({
                        ...provided,
                        padding: "0.2rem 0.5rem",
                        border: "1px solid #d1d5db",
                        borderRadius: "0.375rem"
                      })
                    }}
                />
              </div>

              {/* Amount */}
              <div>
                <label className="block text-gray-700 font-semibold mb-1">
                  Amount
                </label>
                <input
                  type="text"
                  name="amount"
                  className="w-full p-3 mt-2 border border-gray-300 rounded-md focus:ring-2"
                  onChange={handleInputChange}
                  value={addPaymentEntry.amount}
                />
              </div>

              {/* Payment Date */}
              <div className="w-full md:w-[200px] relative">
                <label
                  htmlFor="start_date"
                  className="block text-gray-700 font-semibold mb-1"
                >
                  Payment Date
                </label>
                <input
                  id="payment_date"
                  name="payment_date"
                  type="date"
                  className="w-full p-2 border border-black rounded focus:ring-2 focus:ring-black"
                  onChange={handleInputChange}
                  value={addPaymentEntry.payment_date}
                />
              </div>

              {/* Description */}
              <div>
                <label className="block text-gray-700 font-semibold mb-1">
                  Description
                </label>
                <textarea
                  name="description"
                  className="w-full p-3 mt-2 border border-gray-300 rounded-md focus:ring-2"
                  onChange={handleInputChange}
                  value={addPaymentEntry.description}
                />
              </div>

              {addPaymentEntry.payment_method === "Cheque" && (
                <>
                  <div>
                    <label className="block text-gray-700 font-semibold mb-1">
                      Cheque Number
                    </label>
                    <input
                      type="text"
                      name="cheque_number"
                      className="w-full p-3 mt-2 border border-gray-300 rounded-md focus:ring-2"
                      onChange={handleInputChange}
                      value={addPaymentEntry.cheque_number}
                    />
                  </div>
                  <div>
                    <label className="block text-gray-700 font-semibold mb-1">
                      Cheque Date
                    </label>
                    <input
                      type="date"
                      name="cheque_date"
                      className="w-full p-3 mt-2 border border-gray-300 rounded-md focus:ring-2"
                      onChange={handleInputChange}
                      value={addPaymentEntry.cheque_date}
                    />
                  </div>

                  <div>
                    <label className="block text-gray-700 font-semibold mb-1">
                      Payee Name
                    </label>
                    <input
                      type="text"
                      name="payee_name"
                      className="w-full p-3 mt-2 border border-gray-300 rounded-md focus:ring-2"
                      onChange={handleInputChange}
                      value={addPaymentEntry.payee_name}
                    />
                  </div>
                </>
              )}
            </div>

            {/* Document Upload Section */}
            {uploadedFiles.length === 0 && (
              <>
                <h1 className="text-2xl font-bold mt-10 mb-4">
                  Payment Document
                </h1>
                <button
                  type="button"
                  onClick={handleOpenModal}
                  className="p-3 border border-green-600 rounded-full text-green-600 hover:bg-green-600 hover:text-white transition-all duration-300"
                >
                  <div className="flex items-center gap-2">
                    <span className="text-sm font-bold">
                      Click Here to Upload
                    </span>
                    <IoIosCloudUpload className="text-xl" />
                  </div>
                </button>
              </>
            )}
            {/* Display Uploaded Files */}
            {uploadedFiles.length > 0 && (
              <div className="mt-5">
                <h3 className="mb-2 text-xl font-bold">Selected Files</h3>
                {uploadedFiles.map((file, index) => (
                  <div
                    key={index}
                    className="flex items-center justify-between p-2 mb-2 bg-yellow-100 border rounded-lg"
                  >
                    <span>{file.name}</span>
                    <button
                      onClick={() => handleRemoveFile(index)}
                      className="px-3 py-1 text-white bg-red-500 rounded hover:bg-red-600 transition-colors duration-200"
                    >
                      Remove
                    </button>
                  </div>
                ))}
              </div>
            )}
          </div>
        </form>

        <div className="mt-5">
          <div className="flex items-center justify-between px-4 py-2">
            <div></div>
            <div className="flex gap-4">
              <button
                type="button"
                className="flex items-center gap-2 px-4 py-2 text-white bg-black rounded hover:bg-gray-800 focus:outline-none focus:ring-2 focus:ring-black"
                onClick={handleOpenResetConfirmation}
              >
                Reset
              </button>
              <button
                type="submit"
                className="flex items-center gap-2 px-4 py-2 text-white bg-black rounded hover:bg-gray-800 focus:outline-none focus:ring-2 focus:ring-black"
                onClick={handleOpenSaveConfirmation}
              >
                Save
              </button>
            </div>
          </div>
        </div>

        {isModalOpen && (
          <div className="fixed top-0 left-0 z-50 flex items-center justify-center w-full h-full bg-gray-200 bg-opacity-80">
            <div className="p-8 bg-white rounded-lg">
              <FileUpload
                setUploadedFiles={setUploadedFiles}
                uploadedFiles={uploadedFiles}
                onClose={handleCloseModal}
              />
              <button
                className="p-3 py-2 mt-4 text-white bg-red-500 rounded-lg"
                onClick={handleCloseModal}
              >
                Close
              </button>
            </div>
          </div>
        )}

        {isSaveConfirmationOpen && (
          <div className="fixed top-0 left-0 z-50 flex items-center justify-center w-full h-full bg-gray-200 bg-opacity-80">
            <div className="p-8 bg-white rounded-lg">
              <h2 className="mb-4 text-xl font-bold">Confirm Save</h2>
              <p>Are you sure you want to save the form data?</p>
              <div className="flex justify-end gap-4 mt-6">
                <button
                  className="px-4 py-2 text-white bg-green-500 rounded-xl"
                  onClick={handleSaveForm}
                >
                  Yes, Save
                </button>
                <button
                  className="px-4 py-2 text-gray-600 bg-white border border-gray-300 rounded-xl"
                  onClick={handleCloseSaveConfirmation}
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        )}

        {isResetConfirmationOpen && (
          <div className="fixed top-0 left-0 z-50 flex items-center justify-center w-full h-full bg-gray-200 bg-opacity-80">
            <div className="p-8 bg-white rounded-lg">
              <h2 className="mb-4 text-xl font-bold">Confirm Reset</h2>
              <p>
                Are you sure you want to reset the form? This action cannot be
                undone.
              </p>
              <div className="flex justify-end gap-4 mt-6">
                <button
                  className="px-4 py-2 text-white bg-red-500 rounded-xl"
                  onClick={handleResetForm}
                >
                  Yes, Reset
                </button>
                <button
                  className="px-4 py-2 text-gray-600 bg-white border border-gray-300 rounded-xl"
                  onClick={handleCloseResetConfirmation}
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Add_payment_entry_popup;

const getCookieValue = (cookieName) => {
  const name = cookieName + "=";
  const decodedCookie = decodeURIComponent(document.cookie);
  const cookieArray = decodedCookie.split(";");
  for (let i = 0; i < cookieArray.length; i++) {
    let cookie = cookieArray[i].trim();
    if (cookie.indexOf(name) === 0) {
      return cookie.substring(name.length, cookie.length);
    }
  }
  return "";
};
