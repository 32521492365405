import React, { useState } from "react";

const Autocomplete = ({ suggestions, onSelect }) => {
  const [filteredSuggestions, setFilteredSuggestions] = useState([]);
  const [userInput, setUserInput] = useState("");
  const [isSuggestionsOpen, setIsSuggestionsOpen] = useState(false);

  const handleInputChange = (e) => {
    const value = e.target.value;
    setUserInput(value);

    if (value) {
      const filtered = suggestions.filter((suggestion) =>
        suggestion.label.toLowerCase().includes(value.toLowerCase())
      );
      setFilteredSuggestions(filtered);
      setIsSuggestionsOpen(true);
    } else {
      setFilteredSuggestions([]);
      setIsSuggestionsOpen(false);
    }
  };

  const handleSelectSuggestion = (suggestion) => {
    setUserInput(suggestion.label);
    onSelect(suggestion);
    setIsSuggestionsOpen(false);
  };

  return (
    <div className="relative">
      <input
        type="text"
        className="w-full p-2 border border-gray-300 rounded"
        value={userInput}
        onChange={handleInputChange}
        onFocus={() => setIsSuggestionsOpen(true)}
        onBlur={() => setTimeout(() => setIsSuggestionsOpen(false), 200)} // Delay closing to allow clicks
        placeholder="Select Sample ID"
      />
      {isSuggestionsOpen && filteredSuggestions.length > 0 && (
        <ul className="absolute z-10 w-full p-2 mt-1 bg-white border border-gray-300 rounded shadow-md">
          {filteredSuggestions.map((suggestion, index) => (
            <li
              key={index}
              className="p-2 cursor-pointer hover:bg-gray-100"
              onClick={() => handleSelectSuggestion(suggestion)}
            >
              {suggestion.label}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default Autocomplete;
