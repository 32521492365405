import React, { useState, useEffect, useRef } from "react";
import { FaCalendarAlt } from "react-icons/fa";
import { useLocation, useNavigate } from "react-router-dom";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import cloudIcon from "../../../../../Assets/icons/cloud-add.png";
import axios from "axios";
import ReactToPrint from "react-to-print";

const View_Commitee_Meeting_Report = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { REACT_APP_API_ENDPOINT } = process.env;
  const tenderId = location.state;
  console.log("Tender ID:", tenderId);

  const [imagePreview, setImagePreview] = useState(null);
  const [formData, setFormData] = useState({});
  const [instituteNames, setInstituteNames] = useState([]);

  const pdfView = () => {
    navigate("/committees/committeeMeetingReportView/pdf", {
      state: { formData, instituteNames },
    });
  };

  useEffect(() => {
    const fetchData = async () => {
      if (tenderId.pr_id) {
        try {
          const response = await axios.get(
            `${REACT_APP_API_ENDPOINT}/v1/supplyChain/tec_report/get/${tenderId.pr_id}`
          );

          if (response.data.length > 0) {
            const data = response.data[0];
            setFormData(data);
            setImagePreview(data.signature); // Assuming you have a URL or base64 image
            console.log("Data:", data);
            console.log("Form Data:", formData);
            fetchInstitutes(tenderId.pr_id);
          } else {
            setFormData({});
          }
        } catch (error) {
          console.error("Error fetching data:", error);
          setFormData({});
        }
      }
    };
    fetchData();
  }, [tenderId]);

  const fetchInstitutes = async (id) => {
    try {
      const response = await axios.get(
        `${REACT_APP_API_ENDPOINT}/v1/supplyChain/tec_report/attendance/${id}`
      );
      // Assuming the response data is in the format we expect
      setInstituteNames(response.data || []); // Ensure it is an array
      console.log("Institute ", response.data);
    } catch (error) {
      console.error("Error fetching institutes:", error);
      setInstituteNames([]); // Ensure it is an array even on error
    }
  };

  const handleDragOver = (e) => {
    e.preventDefault();
  };

  const handleDrop = (e) => {
    e.preventDefault();
    const file = e.dataTransfer.files[0];
    const reader = new FileReader();

    reader.onload = () => {
      setImagePreview(reader.result);
    };

    reader.readAsDataURL(file);
  };

  const [selectedCurrentDate, setSelecteCurrentdDate] = useState(new Date());
  const componentRef = useRef();
  return (
    <div className="bg-[#E5EDF9] overflow-y-auto h-screen " ref={componentRef}>
      <p className="text-[40px] font-sans ml-6 mt-6 font-bold text-[#071C50] ">
        View: Technical Evaluation Committee Meeting Report
      </p>
      <div className=" ml-[80%]">
        <label className="text-[22px] font-bold">Tender ID:</label>
        <input
          className="w-40 rounded-lg ml-4 h-8"
          value={tenderId.pr_id}
        ></input>
      </div>

      <div className="bg-[#F3F8FF] w-[95%]  mt-6 ml-6 rounded-[46px]">
        <div className="  ml-6 mt-6">
          <div className="flex justify-around">
            <div>
              <p className=" text-[22px] text-[#8691AB] font-bold">
                The Nature of the <br /> Committee:
              </p>
            </div>
            <div class=" text-[22px] text-[#8691AB] font-bold ml-16 flex">
              {/* Cabinet Memo */}
              <div>
                <label className="inline-flex items-center">
                  <div className="bg-[#8691AB] w-3 h-3 rounded-full mr-2"></div>
                  <span className="mr-2">
                    Cabinet <br />
                    Memo
                  </span>
                  <input
                    type="radio"
                    className="form-radio h-10 w-10 text-blue-500"
                    name="committee_nature"
                    value="Cabinet Memo"
                    checked={formData.committee_nature === "Cabinet Memo"}
                    disabled
                  />
                </label>
              </div>
              {/* Department pro */}
              <div>
                <label className="inline-flex items-center ml-14">
                  <div className="bg-[#8691AB] w-3 h-3 rounded-full mr-2"></div>
                  <span className="mr-2">
                    Department <br />
                    Pro.
                  </span>
                  <input
                    type="radio"
                    className="form-radio text-blue-500 h-10 w-10"
                    name="committee_nature"
                    value="Department Pro."
                    checked={formData.committee_nature === "Department Pro."}
                    disabled
                  />
                </label>
              </div>
              {/* Business pro */}
              <div>
                <label className="inline-flex items-center ml-14">
                  <div className="bg-[#8691AB] w-3 h-3 rounded-full mr-2"></div>
                  <span className="mr-2">
                    Business <br />
                    Pro.
                  </span>
                  <input
                    type="radio"
                    className="form-radio text-blue-500 h-10 w-10"
                    name="committee_nature"
                    value="Business Pro."
                    checked={formData.committee_nature === "Business Pro."}
                    disabled
                  />
                </label>
              </div>
              {/* Office pro */}
              <div>
                <label className="inline-flex items-center ml-14">
                  <div className="bg-[#8691AB] w-3 h-3 rounded-full mr-2"></div>
                  <span className="mr-2">
                    Office <br />
                    Pro.
                  </span>
                  <input
                    type="radio"
                    className="form-radio text-blue-500 h-10 w-10"
                    name="committee_nature"
                    value="Office Pro."
                    checked={formData.committee_nature === "Office Pro."}
                    disabled
                  />
                </label>
              </div>
            </div>
          </div>
        </div>
        <div className="mt-4 ml-6 flex">
          <p className=" text-[22px] text-[#8691AB] font-bold">
            Name of the procurment item:
          </p>
          <input
            className=" rounded-lg border border-gray-300 w-[50%] ml-10"
            value={formData.procurement_item}
            disabled
          ></input>
        </div>
        <div className="mt-4 ml-6 flex">
          <p className=" text-[22px] text-[#8691AB] font-bold">
            Name of the procurment:
          </p>
          <input
            className=" rounded-lg border border-gray-300 w-[50%] ml-20"
            disabled
            value={formData.procurement_name}
          ></input>
        </div>
        <div className="mt-4 ml-6 flex">
          <p className=" text-[22px] text-[#8691AB] font-bold">
            No of meeting:
          </p>
          <input
            className=" rounded-lg border border-gray-300 w-[20%] ml-10"
            value={formData.meeting_number}
            disabled
          ></input>

          <p className=" text-[22px] text-[#8691AB] font-bold ml-[15%]">
            Date:
          </p>

          <input
            className=" rounded-lg border border-gray-300 w-[20%] ml-10"
            value={formData.date}
            disabled
          ></input>
        </div>
        <div className="mt-4 ml-6 ">
          <p className=" text-[22px] text-[#8691AB] font-bold">
            Purpose/Purposes
          </p>
          <input
            className=" rounded-lg border border-gray-300 w-[70%] ml-20 mb-6 h-8"
            disabled
            value={formData.purpose}
          ></input>
        </div>
      </div>

      {/* Second form part */}
      <div className="justify-center flex">
        {" "}
        <p className="text-[27px] font-sans ml-6 mt-6 font-bold text-[#071C50] items-center">
          Name & post of the Technical evaluation committee meeting member in
          attendance
        </p>
      </div>

      <div className="bg-[#F3F8FF] w-[95%] mt-6 ml-6 rounded-[46px] mb-6">
        {instituteNames.map((institute, index) => (
          <div key={index} className="mt-4 ml-6 flex items-center">
            <label>Name</label>
            <input
              className="rounded-lg border border-gray-300 w-[50%] ml-10"
              value={institute.name} // Adjust the property name based on actual API response
              disabled
            ></input>
            <label className="ml-6">Role</label>
            <input
              className="rounded-lg border border-gray-300 w-[50%] ml-10"
              value={institute.role} // Adjust the property name based on actual API response
              disabled
            ></input>
          </div>
        ))}
      </div>

      {/* Third form part */}
      <div className="justify-center flex">
        {" "}
        <p className="text-[27px] font-sans ml-6 mt-6 font-bold text-[#071C50] items-center">
          Key Observations
        </p>
      </div>
      <div className="bg-[#F3F8FF] w-[95%]  mt-6 ml-6 rounded-[46px]">
        <div>
          <p className=" text-[22px] text-[#8691AB] font-bold ml-6">
            Details of the meeting proceedings and comments on matters which
            arising from the previous meeting. (if any) :
          </p>
          <input
            className=" rounded-lg border border-gray-300 w-[80%] ml-10 h-8"
            disabled
            value={formData.previous_meeting_comments}
          ></input>
        </div>
        <div>
          <p className=" text-[22px] text-[#8691AB] font-bold ml-6 mt-6">
            If any ideas on the attendance/ absence  of committee members
          </p>
          <input
            className=" rounded-lg border border-gray-300 w-[80%] ml-10 h-8"
            disabled
            value={formData.attendance_comments}
          ></input>
        </div>
        <div>
          <p className=" text-[22px] text-[#8691AB] font-bold ml-6 mt-6">
            Comments on the entire procurement process
          </p>
          <input
            className=" rounded-lg border border-gray-300 w-[80%] ml-10 h-8"
            value={formData.procurement_comments}
            disabled
          ></input>
        </div>
        <div>
          <p className=" text-[22px] text-[#8691AB] font-bold ml-6 mt-6">
            Documents presented and subjected to discussion
          </p>
          <input
            className=" rounded-lg border border-gray-300 w-[80%] ml-10 h-8"
            value={formData.documents_discussed}
            disabled
          ></input>
        </div>
        <div>
          <p className=" text-[22px] text-[#8691AB] font-bold ml-6 mt-6">
            Special strategies/methods used (if any)
          </p>
          <input
            className=" rounded-lg border border-gray-300 w-[80%] ml-10 h-8"
            disabled
            value={formData.special_strategies}
          ></input>
        </div>
        <div>
          <p className=" text-[22px] text-[#8691AB] font-bold ml-6 mt-6">
            Summary on key decisions taken
          </p>
          <input
            className=" rounded-lg border border-gray-300 w-[80%] ml-10 h-8 mb-6"
            disabled
            value={formData.key_decisions}
          ></input>
        </div>
      </div>

      {/* Fourth form part */}
      <div className="justify-center flex">
        {" "}
        <p className="text-[27px] font-sans ml-6 mt-6 font-bold text-[#071C50] items-center">
          Essential Information
        </p>
      </div>
      <div className="bg-[#F3F8FF] w-[95%]  mt-6 ml-6 rounded-[46px]">
        <div>
          <p className=" text-[22px] text-[#8691AB] font-bold ml-6">
            For document verification: usage of standard bid documents and
            accuracy of the bid documents.
          </p>
          <input
            className=" rounded-lg border border-gray-300 w-[80%] ml-10 h-8"
            disabled
            value={formData.bid_document_verification}
          ></input>
        </div>
        <div>
          <p className=" text-[22px] text-[#8691AB] font-bold ml-6 mt-6">
            Total number of applications received for pre-qualified and short
            listing names and addresses of bidders. 
          </p>
          <input
            className=" rounded-lg border border-gray-300 w-[80%] ml-10 h-8"
            disabled
            value={formData.applications_received}
          ></input>
        </div>
        <div>
          <p className=" text-[22px] text-[#8691AB] font-bold ml-6 mt-6">
            Recommendations for awarding of contracts. Name and address of
            successful bidder, value of contract ( if relevant/ in different
            currencies)  
          </p>
          <input
            className=" rounded-lg border border-gray-300 w-[80%] ml-10 h-8"
            disabled
            value={formData.award_recommendations}
          ></input>
        </div>
        <div>
          <p className=" text-[22px] text-[#8691AB] font-bold ml-6 mt-6">
            Clearly mention the reasons given to the procurement entity for
            interim decisions
          </p>
          <input
            className=" rounded-lg border border-gray-300 w-[80%] ml-10 h-8"
            disabled
            value={formData.interim_decision_reasons}
          ></input>
        </div>
        <div>
          <p className=" text-[22px] text-[#8691AB] font-bold ml-6 mt-6">
            Justification for their decision/ decisions if the committee members
            desired to be included. 
          </p>
          <input
            className=" rounded-lg border border-gray-300 w-[80%] ml-10 h-8 mb-6"
            disabled
            value={formData.decision_justifications}
          ></input>
        </div>
      </div>

      {/* Fifth form part */}

      <div className="bg-[#F3F8FF] w-[95%]  mt-6 ml-6 rounded-[46px]">
        <div className="flex">
          <p className=" text-[22px] text-[#8691AB] font-bold ml-6 mt-6">
            Name
          </p>
          <input
            className=" rounded-lg border border-gray-300 w-[30%] ml-10 h-8 mt-6"
            disabled
            value={formData.name}
          ></input>
          <p className=" text-[22px] text-[#8691AB] font-bold ml-6 mt-6">
            Committee Role
          </p>
          <input
            className=" rounded-lg border border-gray-300 w-[30%] ml-10 h-8 mt-6"
            disabled
            value={formData.committee_role}
          ></input>
        </div>
        {/* Yes NO  */}
        <div className="flex">
          <div>
            {" "}
            <p className=" text-[22px] text-[#8691AB] font-bold ml-6 mt-6">
              Whether agreed with the above decision.
            </p>
            <div className="flex mt-6">
              <div>
                <label class="inline-flex items-center ml-14">
                  <div className="bg-[#8691AB] w-3 h-3 rounded-full mr-2"></div>
                  <span class="mr-2">Yes</span>
                  <input
                    type="radio"
                    class="form-radio text-blue-500 h-10 w-10"
                    name="recommendation"
                    value="no"
                    disabled
                  />
                </label>
              </div>
              <div>
                <label class="inline-flex items-center ml-20">
                  <div className="bg-[#8691AB] w-3 h-3 rounded-full mr-2"></div>
                  <span class="mr-2">No</span>
                  <input
                    type="radio"
                    class="form-radio text-blue-500 h-10 w-10"
                    name="recommendation"
                    value="no"
                    disabled
                  />
                </label>
              </div>
            </div>
          </div>
          <div className="ml-10">
            {/* Drag and drop area */}
            <div
              className="flex flex-col items-center justify-center mt-6"
              onDragOver={handleDragOver}
              onDrop={handleDrop}
            >
              {imagePreview ? (
                <img
                  src={`${REACT_APP_API_ENDPOINT}/v1/supplyChain/tec_report/signature/${imagePreview}`}
                  alt="Preview"
                  className="w-40 h-40 rounded-lg mb-4"
                />
              ) : (
                <div className="bg-white border border-gray-300 w-40 h-40 rounded-lg flex flex-col items-center justify-center mb-6">
                  <p className="text-black font-bold ml-2 text-[24px]">
                    Certified By
                  </p>
                  <img
                    src={cloudIcon}
                    alt="Cloud Icon"
                    className="w-16 h-16 mb-2"
                  />
                  <p className="text-[#8691AB] font-bold ml-4">
                    Drag and Drop Digital Signature
                  </p>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>

      <div className="mb-6 mt-6 ml-6">
        <button
          className="bg-[#797C80] text-[20px] font-bold text-white rounded-[33px] w-40 h-8 print:hidden"
          onClick={pdfView}
        >
          Export as PDF
        </button>
      </div>
    </div>
  );
};

export default View_Commitee_Meeting_Report;
