import React, {useEffect, useRef, useState} from "react";
import {IoAdd} from "react-icons/io5";
import {FaEdit, FaTrash} from "react-icons/fa";
import axios from "axios";
import Sales_invoice_form from "./sales_invoice_form";
import usePermissions from "../../../components/permissions/permission";
import Cookies from "js-cookie";
import {IoIosCloseCircleOutline, IoMdAddCircleOutline} from "react-icons/io";
import {TiTick} from "react-icons/ti";
import SalesInvoiceDetailModal from "./SalesInvoiceDetailModal";
import {BiDetail} from "react-icons/bi";

const Sales_invoice = () => {
    const backendUrl = process.env.REACT_APP_BACKEND_URL_FINANCE;
    const [selectedValue, setSelectedValue] = useState();
    const [isOpen, setIsOpen] = useState(false);
    const [rowsPerPage, setRowsPerPage] = useState(20); // Default rows per page
    const [tableData, setTableData] = useState([]);
    const [selectedRow, setSelectedRow] = useState(null);
    const [selectedSalesInvoice, setSelectedSalesInvoice] = useState(null);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [dropdownVisible, setDropdownVisible] = useState(false);
    const [pagination, setPagination] = useState({
        current_page: 1,
        page_size: 20,
        total: 0,
        total_pages: 0,
    });

    const { hasPermission } = usePermissions();

    const [formMode, setFormMode] = useState("add"); // 'add' or 'edit'
    const [initialFormData, setInitialFormData] = useState({});

    //const {hasPermission} = usePermissions();
    const actionsRef = useRef(null);

    const handleRowSelect = (id) => {
        if (selectedRow === id) {
            setSelectedRow(null);
            setDropdownVisible(false);
        } else {
            setSelectedRow(id);
            setDropdownVisible(true);
        }
    };

    const togglePopup = (mode = "add") => {
        setFormMode(mode);
        if (mode === "add") {
            setInitialFormData({});
        }
        setIsOpen(!isOpen);
    };

    const handleAdd = () => {
        togglePopup("add");
    };

    const handleEdit = () => {
        if (selectedRow) {
            togglePopup("edit");
            setInitialFormData(selectedRow); // Patch the selected row data for editing
        }
    };

    const handleDelete = async () => {
        if (selectedRow) {
            console.log("Delete row: ", selectedRow);
            try {
                const response = await axios.delete(`${backendUrl}/receivable/salesInvoice/deleteSalesInvoice/${selectedRow}`, {
                    data: { deleted_by: Cookies.get("employee_no") }
                });
                setDropdownVisible(false);
                setSelectedRow(null);
                console.log("Sales invoice deleted:", response);
                await getTableData();
            } catch (error) {
                console.error("Error deleting journal entry:", error);
            }
        }
    };

    const handleDetail = async () => {
        try {
            if (selectedRow) {
                const response = await axios.get(`${backendUrl}/receivable/salesInvoice/getSalesInvoice/${selectedRow}`);
                if (response.data.invoice) {
                    setSelectedSalesInvoice(response.data.invoice);
                    setIsModalOpen(true);
                }
            }
        } catch (error) {
            console.error("Error fetching entry details:", error);
        }
    };

    const handleApprovalActions = async (status) => {
        try {
            const response = await axios.put(
                `${backendUrl}/receivable/salesInvoice/checkOrApproveSalesInvoice/${selectedRow}`, {
                    approval_status: status,
                    action_by: Cookies.get("employee_no")
                }
            );
            await getTableData();
            setDropdownVisible(!dropdownVisible);
        } catch (error) {
            console.log(error);
        }
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setSelectedValue((prevState) => ({
            ...prevState,
            [name]: value,
        }));
    };

    const getTableData = async () => {
        try {
            const params = {
                page_size: rowsPerPage,
                page: pagination.current_page,
                ...selectedValue, // Include your filter parameters
            };

            const response = await axios.get(
                `${backendUrl}/receivable/salesInvoice/getSalesInvoice`,
                {params}
            );
            console.log("getTableDataRes", response.data.data);

            // Ensure you set both data and pagination state
            if (response.data) {
                console.log(response.data);
                setTableData(response.data.data); // Set the data array
                setPagination(response.data.pagination); // Set the pagination info
            } else {
                console.error("Unexpected response format:", response.data);
                setTableData([]);
            }
        } catch (error) {
            console.error("Error fetching sample details:", error);
            setTableData([]);
        }
    };
    const handleRowsPerPageChange = (newRowsPerPage) => {
        setRowsPerPage(newRowsPerPage);
        setPagination((prev) => ({
            ...prev,
            current_page: 1, // Reset to first page
        }));
    };

    // Function to handle PDF download
    const downloadPDF = async (pdfUploadPath, fileName) => {
        try {
            // Fetch the PDF from the backend using the correct URL with the query param
            const response = await axios.get(
                `${backendUrl}/receivable/salesInvoice/downloadSalesInvoice`,
                {
                    params: {
                        pdf_upload_path: pdfUploadPath // Pass the pdf_upload_path as query param
                    },
                    responseType: "blob", // Ensure the response is in Blob format (binary)
                }
            );

            // Create a Blob from the PDF response
            const pdfBlob = new Blob([response.data], { type: "application/pdf" });

            // Create a link element to trigger the download
            const link = document.createElement("a");
            link.href = URL.createObjectURL(pdfBlob);
            link.download = fileName; // Set the file name for the download
            document.body.appendChild(link); // Append the link to the document
            link.click(); // Trigger the download
            document.body.removeChild(link); // Remove the link after the download
        } catch (error) {
            console.log("Error downloading the PDF:", error);
        }
    };


    // Close dropdown when clicking outside
    const handleClickOutside = (event) => {
        if (actionsRef.current && !actionsRef.current.contains(event.target)) {
            setSelectedRow(null);
            setDropdownVisible(false);
        }
    };

    useEffect(() => {
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    // Call the API whenever rowsPerPage or currentPage changes
    useEffect(() => {
        getTableData();
        // console.log(selectedValue)
    }, [rowsPerPage, pagination.current_page, selectedValue]);

    return (
        <div className="mx-10 mt-5 text-xl">
            <div className="overflow-visible">
                <div className="flex items-center justify-between mt-6">
                    <div>
                        <p className="text-[30px] font-semibold text-black">
                            Sales Invoice
                        </p>
                    </div>
                </div>

                <div className="mt-5">
                    <div className="flex flex-col gap-4 mt-5 md:flex-row md:items-center md:justify-between">
                        <div className="flex flex-col md:flex-row md:items-center md:gap-4">
                            {/*  Customer Name  */}
                            <div className="w-full md:w-[250px] relative">
                                <label
                                    htmlFor="customer_name"
                                    className="block mb-2 text-sm font-medium text-black"
                                >
                                    Customer Name
                                </label>
                                <input
                                    id="customer_name"
                                    name="customer_name"
                                    type="text"
                                    className="w-full p-2 border border-black rounded focus:ring-2 focus:ring-black"
                                    onChange={handleChange}
                                />
                            </div>

                            {/*  Prepared By	 */}
                            <div className="w-full md:w-[250px] relative">
                                <label
                                    htmlFor="prepared_by"
                                    className="block mb-2 text-sm font-medium text-black"
                                >
                                    Prepared By
                                </label>
                                <input
                                    id="prepared_by"
                                    name="prepared_by"
                                    type="text"
                                    className="w-full p-2 border border-black rounded focus:ring-2 focus:ring-black"
                                    onChange={handleChange}
                                />
                            </div>

                            {/*  Invoice ID	 */}
                            <div className="w-full md:w-[250px] relative">
                                <label
                                    htmlFor="invoice_id"
                                    className="block mb-2 text-sm font-medium text-black"
                                >
                                    Invoice ID
                                </label>
                                <input
                                    id="invoice_id"
                                    name="invoice_id"
                                    type="text"
                                    className="w-full p-2 border border-black rounded focus:ring-2 focus:ring-black"
                                    onChange={handleChange}
                                />
                            </div>

                            {/* Status */}
                            <div className="w-full md:w-[200px] relative">
                                <label
                                    htmlFor="status"
                                    className="block mb-2 text-sm font-medium text-black"
                                >
                                    Status
                                </label>
                                <select
                                    id="status"
                                    name="status"
                                    className="w-full p-2 border border-black rounded focus:ring-2 focus:ring-black"
                                    onChange={handleChange}
                                >
                                    <option value=""></option>
                                    <option value="Draft">Draft</option>
                                    <option value="Submitted">Submitted</option>
                                    <option value="Approved">Approved</option>
                                    <option value="Rejected">Rejected</option>
                                    <option value="Cancelled">Cancelled</option>
                                </select>
                            </div>
                        </div>

                        {/* Right-Side Button or Dropdown */}
                        <div className="relative flex items-center gap-4 md:mt-0" ref={actionsRef}>
                            {selectedRow ? (
                                // Action Dropdown
                                <div className="relative">
                                    <button
                                        onClick={() => setDropdownVisible(!dropdownVisible)}
                                        className="px-4 py-2 text-white bg-black rounded hover:bg-gray-800 focus:outline-none focus:ring-2 focus:ring-black"
                                    >
                                        Actions
                                    </button>
                                    {dropdownVisible && (
                                        <div className="absolute right-0 z-20 mt-2 bg-white border border-black rounded shadow-lg">
                                            {hasPermission(3112) && (
                                                <button
                                                    onClick={handleEdit}
                                                    className="flex items-center w-full px-4 py-2 text-xl text-black hover:bg-gray-100"
                                                >
                                                    <FaEdit className="mr-2"/> Edit
                                                </button>
                                            )}
                                            {hasPermission(3113) && (
                                                <button
                                                    onClick={handleDelete}
                                                    className="flex items-center w-full px-4 py-2 text-xl text-black hover:bg-gray-100"
                                                >
                                                    <FaTrash className="mr-2"/> Delete
                                                </button>
                                            )}
                                            {hasPermission(3110) && (
                                                <button
                                                    onClick={handleDetail}
                                                    className="flex items-center w-full px-4 py-2 text-xl text-black hover:bg-gray-100"
                                                >
                                                    <BiDetail className="mr-2"/> Details
                                                </button>
                                            )}
                                            {hasPermission(3114) && (
                                                <button
                                                    onClick={() => handleApprovalActions('Submitted')}
                                                    className="flex items-center w-full px-4 py-2 text-xl text-black hover:bg-gray-100"
                                                >
                                                    <IoMdAddCircleOutline className="mr-2"/> Submit
                                                </button>
                                            )}
                                            {hasPermission(3115) && (
                                                <button
                                                    onClick={() => handleApprovalActions('Approved')}
                                                    className="flex items-center w-full px-4 py-2 text-xl text-black hover:bg-gray-100"
                                                >
                                                    <TiTick className="mr-2"/> Approve
                                                </button>
                                            )}
                                            {hasPermission(3117) && (
                                                <button
                                                    onClick={() => handleApprovalActions('Rejected')}
                                                    className="flex items-center w-full px-4 py-2 text-xl text-black hover:bg-gray-100"
                                                >
                                                    <IoIosCloseCircleOutline className="mr-2"/> Reject
                                                </button>
                                            )}
                                        </div>
                                    )}
                                </div>
                            ) : (

                                <button
                                    className="flex items-center gap-2 px-4 py-2 text-white bg-black rounded hover:bg-gray-800 focus:outline-none focus:ring-2 focus:ring-black"
                                    onClick={togglePopup}
                                >
                                    <IoAdd className="text-xl"/>
                                    Add
                                </button>
                            )}
                        </div>
                    </div>
                </div>
            </div>

            <div className="my-4 overflow-x-auto mt-20">
                {/* Scrollable Table Container with Increased Height */}
                <div className="max-h-[600px] overflow-y-auto">
                    {/* TailwindCSS Table */}
                    <table className="min-w-full bg-white border border-black mt-12">
                        <thead className="bg-gray-200">
                        {/* Background color to distinguish header */}
                        <tr>
                            <th className="px-6 py-3 font-bold text-left text-black uppercase border-b border-black sticky top-0 bg-gray-200">
                                Invoice ID
                            </th>
                            <th className="px-6 py-3 font-bold text-left text-black uppercase border-b border-black sticky top-0 bg-gray-200">
                                Customer Name
                            </th>
                            <th className="px-6 py-3 font-bold text-left text-black uppercase border-b border-black sticky top-0 bg-gray-200">
                                Invoice No
                            </th>
                            <th className="px-6 py-3 font-bold text-left text-black uppercase border-b border-black sticky top-0 bg-gray-200">
                                Status
                            </th>
                            <th className="px-6 py-3 font-bold text-left text-black uppercase border-b border-black sticky top-0 bg-gray-200">
                                Total
                            </th>
                            <th className="px-6 py-3 font-bold text-left text-black uppercase border-b border-black sticky top-0 bg-gray-200">
                                Submitted Date
                            </th>
                            <th className="px-6 py-3 font-bold text-left text-black uppercase border-b border-black sticky top-0 bg-gray-200">
                                Document
                            </th>
                        </tr>
                        </thead>
                        <tbody>
                        {tableData.map((row, index) => (
                            <tr
                                key={row.sales_invoice_id}
                                className={`${
                                    selectedRow === row.sales_invoice_id ? "bg-blue-200" : ""
                                } hover:bg-gray-100`}
                                onClick={() => handleRowSelect(row.sales_invoice_id)}
                            >
                                <td className="px-6 py-4 border-b border-black">{row.sales_invoice_id}</td>
                                <td className="px-6 py-4 border-b border-black">{row.customer_name}</td>
                                <td className="px-6 py-4 border-b border-black">{row.invoice_no}</td>
                                <td className="px-6 py-4 border-b border-black">{row.approval_status}</td>
                                <td className="px-6 py-4 border-b border-black">{row.grand_total}</td>
                                <td className="px-6 py-4 border-b border-black">{row.checked_date? new Date(row.checked_date).toLocaleDateString(): ""}</td>
                                <td className="px-6 py-4 text-sm text-gray-900 whitespace-nowrap">
                                    <button
                                        className="px-4 py-2 bg-green-500 text-white font-semibold rounded-lg hover:bg-green-600 focus:outline-none focus:ring-2 focus:ring-green-400 focus:ring-opacity-75 transition duration-300"
                                        onClick={() =>
                                            downloadPDF(row.sales_invoice_file, `invoice_${row.invoice_no}.pdf`)
                                        }
                                        disabled={!row.sales_invoice_file || row.sales_invoice_file === ""}
                                    >
                                        Download PDF
                                    </button>
                                </td>
                            </tr>
                        ))}
                        </tbody>
                    </table>
                </div>
            </div>

            {/* Pagination Controls */}
            <div className="flex justify-center mt-4 gap-2">
                {[20, 40, 60].map((size) => (
                    <button
                        key={size}
                        onClick={() => handleRowsPerPageChange(size)}
                        className={`px-4 py-2 ${
                            rowsPerPage === size ? "bg-gray-800 text-white" : "bg-gray-300"
                        } rounded`}
                    >
                        {size}
                    </button>
                ))}
                <button
                    onClick={() =>
                        setPagination((prevPagination) => ({
                            ...prevPagination,
                            current_page: Math.min(
                                prevPagination.current_page + 1,
                                prevPagination.total_pages
                            ),
                        }))
                    }
                    disabled={pagination.current_page >= pagination.total_pages}
                    className="px-4 py-2 bg-black text-white rounded hover:bg-gray-800 focus:outline-none focus:ring-2 focus:ring-black"
                >
                    Load More
                </button>
            </div>

            {/* Modal Form */}
            {isOpen && (
                <Sales_invoice_form
                    formMode={formMode}
                    initialFormData={initialFormData ? tableData.find((item) => item.sales_invoice_id === selectedRow) : null}
                    togglePopup={togglePopup}
                    getTableData={getTableData}
                />
            )}
            <SalesInvoiceDetailModal
                isOpen={isModalOpen}
                onClose={() => setIsModalOpen(false)}
                invoice={selectedSalesInvoice}
            />
        </div>
    );
};

export default Sales_invoice;
