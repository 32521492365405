/** @format */

import React, { useState } from "react";
import { Link } from "react-router-dom";
import DialogBox from "../Pending Purchase Request/newPurchaseDialogBox";
import usePermissions from "../../../../components/permissions/permission";

const PurchaseReqNav = () => {
  const { hasPermission } = usePermissions();
  const [showDialog, setShowDialog] = useState(false);
  const [dialogStatus, setDialogStatus] = useState("");

  // Open dialog and set status
  const openDialog = (status) => {
    setDialogStatus(status);
    setShowDialog(true);
  };

  // Close dialog
  const closeDialog = () => {
    setShowDialog(false);
    setDialogStatus("");
  };

  return (
    <div className="bg-background overflow-y-auto h-screen">
      <p className="text-[40px] font-sans ml-6 mt-6 font-bold text-[#071C50] ">
        Purchase Requests
      </p>

      {/* Cards with "Click Here" buttons */}
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-3 gap-12 mt-[10%] mx-6">
        {/* Card 1 */}
        {hasPermission(2600) && (
          <Link to="/purchase/requestForm" className="card-link">
            <div className="bg-white rounded-[32px] p-4 shadow-md flex flex-col justify-between card">
              <div className="min-h-[110px] min-w-[150px]">
                <h2 className="text-[35px] text-[#797C80] font-semibold mb-1">
                  Create Purchase Requests
                </h2>
              </div>
              <div className="flex items-center">
                <button className="bg-primary w-[30%] mt-2 ml-2 text-white font-bold py-2 px-4 rounded-[22px]">
                  Create
                </button>
              </div>
            </div>
          </Link>
        )}

        {/* Card 2: Pending Purchase Requests for Approval */}
        {hasPermission(2610) && (
          <div className="bg-white rounded-[32px] p-4 shadow-md flex flex-col justify-between card">
            <div className="min-h-[110px] min-w-[150px]">
              <h2 className="text-[35px] text-[#797C80] font-semibold mb-1">
                Pending Purchase Requests for Approval
              </h2>
            </div>
            <div className="flex items-center">
              <div className="flex justify-end w-full">
                <button
                  onClick={() => openDialog("Approved")}
                  className="bg-primary w-[30%] mt-2 text-white font-bold py-2 px-4 rounded-[22px]"
                >
                  View
                </button>
              </div>
            </div>
          </div>
        )}

        {/* Card 3: Pending Purchase Requests for Prepared */}
        {hasPermission(2620) && (
          <div className="bg-white rounded-[32px] p-4 shadow-md flex flex-col justify-between card">
            <div className="min-h-[110px] min-w-[150px]">
              <h2 className="text-[35px] text-[#797C80] font-semibold mb-1">
                Pending Purchase Requests for Prepared
              </h2>
            </div>
            <div className="flex items-center">
              <div className="flex justify-end w-full">
                <button
                  onClick={() => openDialog("Prepared")}
                  className="bg-primary w-[30%] mt-2 text-white font-bold py-2 px-4 rounded-[22px]"
                >
                  View
                </button>
              </div>
            </div>
          </div>
        )}

        {/* DialogBox Component */}
        {showDialog && (
          <DialogBox onClose={closeDialog} status={dialogStatus} />
        )}
      </div>
    </div>
  );
};

export default PurchaseReqNav;
