import axios from "axios";
import React, { useEffect, useState } from "react";
import { IoIosCloseCircleOutline } from "react-icons/io";
import Notification from "../../../components/notification/notification";
import Cookies from "js-cookie";

const Add_chat_account_popup = ({ togglePopup, resetTable }) => {
  const backendUrl = process.env.REACT_APP_BACKEND_URL_FINANCE;
  const [isEnabled, setIsEnabled] = useState(false);

  const [addChatOfAccount, setAddChatOfAccount] = useState({
    account_code: "",
    account_name: "",
    root_type: "",
    currency: "",
    report_type: "",
    is_enabled: 1,
    description: "",
    parent_account_id: null,
  });

  const [isResetConfirmationOpen, setIsResetConfirmationOpen] = useState(false);
  const [isSaveConfirmationOpen, setIsSaveConfirmationOpen] = useState(false);
  const [notification, setNotification] = useState({ message: "", type: "" });
  const [enable, setIsEnable] = useState(false);
  const [allsetAllParent, setAllparent] = useState([]);

  const AccountTypes = [
    "Valuation",
    "Expenses Included In Valuation",
    "Fixed Asset",
    "Income Account",
    "Indirect Expense",
    "Indirect Income",
    "Liability",
    "Payable",
    "Receivable",
    "Round Off",
    "Stock",
    "Stock Adjustment",
    "Stock Received But Not Billed",
    "Service Received But Not Billed",
    "Tax",
    "Temporary",
  ];

  // Handle Input Change
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setAddChatOfAccount((prevDetails) => ({
      ...prevDetails,
      [name]: value,
    }));
  };

  const handleOpenResetConfirmation = () => {
    setIsResetConfirmationOpen(true);
  };

  const handleOpenSaveConfirmation = () => {
    setIsSaveConfirmationOpen(true);
  };

  const handleCloseSaveConfirmation = () => {
    setIsSaveConfirmationOpen(false);
  };

  const handleCloseResetConfirmation = () => {
    setIsResetConfirmationOpen(false);
  };

  // Reset Form
  const handleResetForm = () => {
    setAddChatOfAccount({
      account_code: "",
      account_name: "",
      root_type: "",
      currency: "",
      report_type: "",
      is_enabled: 1,
      description: "",
      parent_account_id: null,
    });
    setIsResetConfirmationOpen(false);
  };

  // Save Form
  const handleSaveForm = async () => {
    console.log(addChatOfAccount);
    try {
      const response = await axios.post(
        `${backendUrl}/account/add`,
          {...addChatOfAccount, created_by: Cookies.get("employee_no") },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      console.log(response.data);

      setNotification({
        message: response.data.message,
        type: "success",
      });
      handleResetForm();
    } catch (error) {
      if (error.status === 400) {
        setNotification({
          message: error.response.data.error,
          type: "error",
        });
      }
      console.log(error);
    }

    setIsSaveConfirmationOpen(false);
  };

  // Handle Notification Close
  const handleCloseNotification = () => {
    setNotification({ message: "", type: "" });
  };

  // Function to handle changes and set report type based on root type
  const handleEnableRootInputChange = (e) => {
    const { name, value } = e.target;
    let reportType = "";

    // Set report type based on root type
    if (value === "Asset" || value === "Liability" || value === "Equity") {
      reportType = "Balance Sheet";
    } else if (value === "Revenue" || value === "Expense") {
      reportType = "Profit & Loss";
    }

    setAddChatOfAccount((prevDetails) => ({
      ...prevDetails,
      [name]: value,
      report_type: reportType,
    }));
  };

  const handleHighLevelChange = (e) => {
    handleResetForm();
    const isChecked = e.target.checked;
    setIsEnabled(isChecked);
  };

  const getParentaccount = async () => {
    try {
      // change this url only used testing
      const response = await axios.get(
        `${backendUrl}/account/getAllAccountsByFilters`
      );

      console.log(response.data);

      setAllparent(response.data.data);
    } catch (error) {
      console.log(error);
    }
  };

  const handleFlaseParentsChange = async (e) => {
    const selected = parseInt(e.target.value, 10); // Convert the selected value to an integer

    const selectedAccount = allsetAllParent.find(
      (type) => type.account_id === selected
    );

    if (selectedAccount) {
      // Convert the value to an integer before setting it in state
      setAddChatOfAccount((prevState) => ({
        ...prevState,
        parent_account_id: selectedAccount.account_id,
        root_type: selectedAccount.root_type,
        report_type: selectedAccount.report_type,
      }));
    }
  };

  const handleParentsChange = async (e) => {
    const selected = parseInt(e.target.value, 10); // Convert the selected value to an integer

    const selectedAccount = allsetAllParent.find(
      (type) => type.account_id === selected
    );

    if (selectedAccount) {
      // Convert the value to an integer before setting it in state
      setAddChatOfAccount((prevState) => ({
        ...prevState,
        parent_account_id: selectedAccount.account_id,
      }));
    }
  };

  useEffect(() => {
    getParentaccount();
  }, []);

  return (
    <div className="flex items-center justify-center h-full bg-gray-100">
      <div className="relative w-full p-8 bg-white rounded-lg">
        <div className="flex items-center justify-between mb-6">
          <h2 className="text-2xl font-semibold">Add Accounts</h2>
          <button
            type="button"
            onClick={togglePopup}
            className="p-4 text-4xl text-gray-500 transition duration-300 hover:text-gray-700"
          >
            <IoIosCloseCircleOutline />
          </button>
        </div>

        {/* Notification Alert */}
        <Notification
          message={notification.message}
          type={notification.type}
          onClose={handleCloseNotification}
        />
        <form>
          {/* Checkbox for "Is Enabled" */}
          <div className="mb-4">
            <label className="inline-flex items-center">
              <input
                type="checkbox"
                name="isEnabled"
                className="form-checkbox h-5 w-5 text-blue-600"
                onChange={handleHighLevelChange}
              />
              <span className="ml-2 text-gray-700">First Level Account</span>
            </label>
          </div>

          <div className="items-center gap-8 mt-5">
            {/* Define a two-column grid */}
            <div className="grid grid-cols-2 gap-y-[30px] gap-x-[60px] text-[20px]">
              {/* Account Name */}
              <div>
                <label className="block text-gray-700">Account Name *</label>
                <input
                  type="text"
                  name="account_name"
                  value={addChatOfAccount.account_name}
                  className="w-full p-2 mt-2 border border-gray-300 rounded"
                  onChange={handleInputChange}
                />
              </div>

              {/* Account Code */}
              <div>
                <label className="block text-gray-700">Account Code *</label>
                <input
                  type="text"
                  name="account_code"
                  value={addChatOfAccount.account_code}
                  className="w-full p-2 mt-2 border border-gray-300 rounded"
                  onChange={handleInputChange}
                />
              </div>

              {/*  Report Type */}
              {isEnabled === false && addChatOfAccount.parent_account_id && (
                <div>
                  <label className="block text-gray-700">Report Type *</label>
                  <input
                    type="text"
                    name="report_type"
                    value={addChatOfAccount.report_type}
                    className="w-full p-2 mt-2 border border-gray-300 rounded"
                    readOnly
                  />
                </div>
              )}
              {/* Root Type */}
              {isEnabled === false && addChatOfAccount.parent_account_id && (
                <div>
                  <label className="block text-gray-700">Root Type *</label>
                  <input
                    type="text"
                    name="root_type"
                    value={addChatOfAccount.root_type}
                    className="w-full p-2 mt-2 border border-gray-300 rounded"
                    readOnly
                  />
                </div>
              )}

              {/* Currency Dropdown */}
              <div>
                <label className="block text-gray-700">Currency *</label>
                <select
                  name="currency"
                  className="w-full p-2 mt-2 border border-gray-300 rounded"
                  onChange={handleInputChange}
                  value={addChatOfAccount.currency}
                >
                  <option value="">Select Currency</option>
                  <option value="LKR">LKR - Sri Lanka LKR</option>
                </select>
              </div>

              {/* Account Type Dropdown */}
              {isEnabled === false && (
                <div className="col-start-2 row-start-1">
                  <label className="block text-gray-700">Account Type *</label>
                  <select
                    name="account_type"
                    className="w-full p-2 mt-2 border border-gray-300 rounded"
                    onChange={handleInputChange}
                    value={addChatOfAccount.account_type}
                  >
                    <option value=""></option>
                    {AccountTypes.map((type) => (
                      <option key={type} value={type}>
                        {type}
                      </option>
                    ))}
                  </select>
                </div>
              )}

              {/* Parent Account */}
              {isEnabled === false && (
                <div className="col-start-2 row-start-2">
                  <label className="block text-gray-700">
                    Parent Account *
                  </label>
                  <select
                    name="account_type"
                    className="w-full p-2 mt-2 border border-gray-300 rounded"
                    onChange={handleFlaseParentsChange}
                  >
                    <option value=""></option>
                    {allsetAllParent && allsetAllParent.length > 0 ? (
                      allsetAllParent.map((type) => (
                        <option key={type.account_id} value={type.account_id}>
                          {type.account_name}
                        </option>
                      ))
                    ) : (
                      <option disabled>No Parents available</option>
                    )}
                  </select>
                </div>
              )}

              {/* Description */}
              <div className="col-start-2 row-start-3">
                <label className="block text-gray-700">Description *</label>
                <input
                  type="text"
                  name="description"
                  value={addChatOfAccount.description}
                  className="w-full p-2 mt-2 border border-gray-300 rounded"
                  onChange={handleInputChange}
                />
              </div>

              {/* Enable  Part */}

              {/* Enable Root Type */}

              {isEnabled && (
                <div>
                  <label className="block text-gray-700">Root Type *</label>
                  <select
                    name="root_type"
                    className="w-full p-2 mt-2 border border-gray-300 rounded"
                    value={addChatOfAccount.root_type}
                    onChange={handleEnableRootInputChange}
                  >
                    <option value=""></option>
                    <option value="Asset">Asset</option>
                    <option value="Liability">Liability</option>
                    <option value="Equity">Equity</option>
                    <option value="Revenue">Revenue</option>
                    <option value="Expense">Expense</option>
                  </select>
                </div>
              )}

              {/* Report Type Field */}
              {isEnabled && addChatOfAccount.root_type && (
                <div>
                  <label className="block text-gray-700">Report Type *</label>
                  <input
                    type="text"
                    name="report_type"
                    value={addChatOfAccount.report_type}
                    className="w-full p-2 mt-2 border border-gray-300 rounded"
                    readOnly
                  />
                </div>
              )}
            </div>
          </div>
        </form>

        <div className="mt-5">
          <div className="flex items-center justify-between px-4 py-2">
            <div></div>
            <div className="flex gap-4">
              <button
                type="button"
                className="flex items-center gap-2 px-4 py-2 text-white bg-black rounded hover:bg-gray-800 focus:outline-none focus:ring-2 focus:ring-black"
                onClick={handleOpenResetConfirmation}
              >
                Reset
              </button>
              <button
                type="submit"
                className="flex items-center gap-2 px-4 py-2 text-white bg-black rounded hover:bg-gray-800 focus:outline-none focus:ring-2 focus:ring-black"
                onClick={handleOpenSaveConfirmation}
              >
                Save
              </button>
            </div>
          </div>
        </div>

        {isSaveConfirmationOpen && (
          <div className="fixed top-0 left-0 z-50 flex items-center justify-center w-full h-full bg-gray-200 bg-opacity-80">
            <div className="p-8 bg-white rounded-lg">
              <h2 className="mb-4 text-xl font-bold">Confirm Save</h2>
              <p>Are you sure you want to save the form data?</p>
              <div className="flex justify-end gap-4 mt-6">
                <button
                  className="px-4 py-2 text-white bg-green-500 rounded-xl"
                  onClick={handleSaveForm}
                >
                  Yes, Save
                </button>
                <button
                  className="px-4 py-2 text-gray-600 bg-white border border-gray-300 rounded-xl"
                  onClick={handleCloseSaveConfirmation}
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        )}

        {isResetConfirmationOpen && (
          <div className="fixed top-0 left-0 z-50 flex items-center justify-center w-full h-full bg-gray-200 bg-opacity-80">
            <div className="p-8 bg-white rounded-lg">
              <h2 className="mb-4 text-xl font-bold">Confirm Reset</h2>
              <p>
                Are you sure you want to reset the form? This action cannot be
                undone.
              </p>
              <div className="flex justify-end gap-4 mt-6">
                <button
                  className="px-4 py-2 text-white bg-red-500 rounded-xl"
                  onClick={handleResetForm}
                >
                  Yes, Reset
                </button>
                <button
                  className="px-4 py-2 text-gray-600 bg-white border border-gray-300 rounded-xl"
                  onClick={handleCloseResetConfirmation}
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Add_chat_account_popup;
