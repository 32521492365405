





import React from "react";
import { Link } from "react-router-dom";
const Leave = () => {


  return (
    <div className="bg-background overflow-y-auto h-screen">
      <div className="mx-6">
        <p className="text-[40px] font-sans ml-6 mt-6 font-bold text-[#797C80]">
          Leave Management
        </p>
        <div className="grid grid-cols-4 grid-flow-col gap-4 mt-5">
          <div className="bg-white rounded-lg p-3 shadow-lg font-semibold text-[35px]">
            <p>Employee Leave</p>
            <p>Management</p>

            <Link to="/emp_leave_management">
              <div>
                <button className="bg-primary text-white p-2 text-[15px] font-bold rounded-xl">
                  Click Here
                </button>
              </div>
            </Link>
          </div>
          <div className="bg-white rounded-lg p-3 shadow-lg font-semibold text-[35px]">
            <p>Leave Approval</p>
            <p>Process</p>
            <Link to="/leave_approve">
              <div>
                <button className="bg-primary text-white p-2 text-[15px] font-bold rounded-xl">
                  Click Here
                </button>
              </div>
            </Link>
          </div>
          <div className="bg-white rounded-lg p-3 shadow-lg font-semibold text-[35px]">
            <p>Date </p>
            <p>Restrictions</p>
            <Link to="/date_restrictions">
              <div>
                <button className="bg-primary text-white p-2 text-[15px] font-bold rounded-xl">
                  Click Here
                </button>
              </div>
            </Link>
          </div>
          <div className="bg-white rounded-lg p-3 shadow-lg font-semibold text-[35px]">
            <p>Leave </p>
            <p>Request</p>
            <Link to="/leave_request">
              <div>
                <button className="bg-primary text-white p-2 text-[15px] font-bold rounded-xl">
                  Click Here
                </button>
              </div>
            </Link>
          </div>

          <div className="bg-white rounded-lg p-3 shadow-lg font-semibold text-[35px]">
            <p>Attendance </p>
            <p>Edit</p>
            <Link to="/attendance-edit">
              <div>
                <button className="bg-primary text-white p-2 text-[15px] font-bold rounded-xl">
                  Click Here
                </button>
              </div>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Leave;
