import React, { useState, useEffect } from 'react';
import axios from 'axios';

const OvertimeAssigning = ({ onClose, selectedOvertime, onSuccess }) => {
    const [formData, setFormData] = useState({
        date: selectedOvertime?.date || '',
        reason: selectedOvertime?.reason || '',
        start_time: selectedOvertime?.start_time || '',
        end_time: selectedOvertime?.end_time || '',
    });
    const [searchQuery, setSearchQuery] = useState('');
    const [filteredEmployeeData, setFilteredEmployeeData] = useState([]);
    const [employeeData, setEmployeeData] = useState([]); // All employee data
    const [selectedEmployees, setSelectedEmployees] = useState([]);
    const [showSuccessPopup, setShowSuccessPopup] = useState(false);
    const [isEditMode, setIsEditMode] = useState(false); // Controls if edit mode is enabled
    const isExistingRecord = Boolean(selectedOvertime); // Check if editing an existing record

    useEffect(() => {
        if (isEditMode) {
            // Fetch all employee data when edit mode is enabled
            axios.get('https://back-ayurveda.erp-quortech.com/v1/hris/employees/getemployeebasicdetails')
                .then((response) => {
                    setEmployeeData(response.data);
                    setFilteredEmployeeData(response.data); // Set initially for filtered data
                })
                .catch((error) => {
                    console.error("Error fetching employee data:", error);
                });
        }
    }, [isEditMode]);

    useEffect(() => {
        if (selectedOvertime) {
            // Populate form data with selectedOvertime values
            setFormData({
                date: selectedOvertime.date,
                reason: selectedOvertime.reason,
                start_time: selectedOvertime.start_time,
                end_time: selectedOvertime.end_time,
            });

            // Fetch selected employees for this overtime_id
            axios.get(`https://back-ayurveda.erp-quortech.com/v1/hris/Overtime/overtime/${selectedOvertime.overtime_id}`)
                .then((response) => {
                    setSelectedEmployees(response.data.map(emp => ({
                        employee_no: emp.employee_no,
                        employee_name_initial: emp.employee_name_initial,
                    })));
                })
                .catch((error) => {
                    console.error("Error fetching selected employees:", error);
                });
        }
    }, [selectedOvertime]);

    const handleSelectEmployee = (employee) => {
        setSelectedEmployees((prevSelected) => {
            const isSelected = prevSelected.some((e) => e.employee_no === employee.employee_no);
            if (isSelected) {
                return prevSelected.filter((e) => e.employee_no !== employee.employee_no);
            }
            return [...prevSelected, employee];
        });
    };

    const handleSearch = (query) => {
        setSearchQuery(query);
        if (query) {
            setFilteredEmployeeData(
                employeeData.filter((emp) =>
                    emp.employee_name_initial.toLowerCase().includes(query.toLowerCase())
                )
            );
        } else {
            setFilteredEmployeeData(employeeData);
        }
    };

    const handleFormChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({ ...prevData, [name]: value }));
    };

    const handleSubmit = () => {
        // Prepare the payload
        const payload = {
            ...formData,
            employees: selectedEmployees.map(emp => emp.employee_no),
        };

        if (isExistingRecord) {
            // If editing an existing overtime entry, send an update request
            axios.put(`https://back-ayurveda.erp-quortech.com/v1/hris/Overtime/edit/${selectedOvertime.overtime_id}`, payload)
                .then(() => {
                    setShowSuccessPopup(true);
                    onSuccess();
                })
                .catch((error) => {
                    console.error("Error updating overtime:", error);
                });
        } else {
            // Otherwise, create a new overtime entry
            axios.post('https://back-ayurveda.erp-quortech.com/v1/hris/Overtime/add', payload)
                .then(() => {
                    setShowSuccessPopup(true);
                    onSuccess();
                })
                .catch((error) => {
                    console.error("Error adding overtime:", error);
                });
        }
    };

    return (
        <div className="bg-background h-screen overflow-y-auto relative">
            <div className="mx-8 my-5">
                <p className="text-[30px] font-semibold">{isExistingRecord ? 'Overtime Details' : 'Overtime Assigning'}</p>
                <div className="bg-white p-5 rounded-lg shadow-lg mt-8">
                    <p className="text-[20px] font-semibold">Assignment Form</p>

                    {/* Enable Edit Mode Button */}
                    {isExistingRecord && !isEditMode && (
                        <button
                            className="bg-blue-500 text-white px-4 py-2 rounded mt-4 mb-4"
                            onClick={() => setIsEditMode(true)}
                        >
                            Enable Edit
                        </button>
                    )}

                    <div className="mt-8 text-[20px] grid grid-cols-3 grid-flow-row gap-4">
                        <div className="flex items-center gap-4">
                            <label>Date :</label>
                            <input
                                type="date"
                                name="date"
                                className="border border-black rounded-lg p-1"
                                value={formData.date}
                                onChange={handleFormChange}
                                readOnly={!isEditMode} // Read-only unless in edit mode
                            />
                        </div>
                        <div className="flex items-center gap-4">
                            <label>Reason :</label>
                            <input
                                name="reason"
                                className="border border-black rounded-lg p-1"
                                value={formData.reason}
                                onChange={handleFormChange}
                                readOnly={!isEditMode} // Read-only unless in edit mode
                            />
                        </div>
                        <div className="flex items-center gap-4">
                            <label>Start Time :</label>
                            <input
                                type="time"
                                name="start_time"
                                className="border border-black rounded-lg p-1"
                                value={formData.start_time}
                                onChange={handleFormChange}
                                readOnly={!isEditMode} // Read-only unless in edit mode
                            />
                        </div>
                        <div className="flex items-center gap-4">
                            <label>End Time :</label>
                            <input
                                type="time"
                                name="end_time"
                                className="border border-black rounded-lg p-1"
                                value={formData.end_time}
                                onChange={handleFormChange}
                                readOnly={!isEditMode} // Read-only unless in edit mode
                            />
                        </div>
                    </div>

                    {/* Employee Selection */}
                    {isEditMode && (
                        <div className="mt-6">
                            <h2 className="text-2xl font-bold font-[#071C50]">Assign Employees to Timetable</h2>
                            <div className="mt-4">
                                <input
                                    type="text"
                                    placeholder="Search employee by name"
                                    className="input rounded-xl border border-gray-300 h-10 px-4 py-2 mb-4 placeholder-gray-400"
                                    value={searchQuery}
                                    onChange={(e) => handleSearch(e.target.value)}
                                />
                                <div className="overflow-y-auto max-h-64">
                                    <table className="table-auto w-full">
                                        <thead>
                                            <tr>
                                                <th className="px-4 py-2 bg-[#F5F5F5] rounded-l-xl">Employee ID</th>
                                                <th className="px-4 py-2 bg-[#F5F5F5]">Employee Name</th>
                                                <th className="px-4 py-2 bg-[#F5F5F5] rounded-r-xl">Select</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {filteredEmployeeData.map((employee) => (
                                                <tr key={employee.employee_no} className="border-b border-gray-300">
                                                    <td className="px-4 py-2">{employee.employee_no}</td>
                                                    <td className="px-4 py-2">{employee.employee_name_initial}</td>
                                                    <td className="px-4 py-2 text-center">
                                                        <input
                                                            type="checkbox"
                                                            onChange={() => handleSelectEmployee(employee)}
                                                            checked={selectedEmployees.some(
                                                                (e) => e.employee_no === employee.employee_no
                                                            )}
                                                        />
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    )}

                    {/* Selected Employees Table */}
                    <div className="mt-6">
                        <h3 className="text-xl font-bold text-[#344054] mb-4">Selected Employees</h3>
                        <div className="overflow-y-auto max-h-64">
                            <table className="table-auto w-full">
                                <thead>
                                    <tr>
                                        <th className="px-4 py-2 bg-[#F5F5F5] rounded-l-xl">Employee ID</th>
                                        <th className="px-4 py-2 bg-[#F5F5F5]">Employee Name</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {selectedEmployees.map((employee) => (
                                        <tr key={employee.employee_no} className="border-b border-gray-300">
                                            <td className="px-4 py-2">{employee.employee_no}</td>
                                            <td className="px-4 py-2">{employee.employee_name_initial}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </div>

                    {/* Buttons for Submission and Cancel */}
                    <div className="flex justify-between mt-4">
                        {isEditMode && (
                            <button
                                onClick={handleSubmit}
                                className="bg-gray-500 text-white px-4 py-2 rounded-[22px]"
                            >
                                {isExistingRecord ? 'Update Overtime Assignment' : 'Assign Employees to Timetable'}
                            </button>
                        )}
                        <button className="bg-[#797C80] text-white px-4 py-2 rounded-[22px]" onClick={onClose}>
                            Cancel
                        </button>
                    </div>
                </div>
            </div>

            {showSuccessPopup && (
                <div className="fixed top-0 left-0 right-0 bottom-0 flex items-center justify-center bg-black bg-opacity-50">
                    <div className="bg-white p-5 rounded-lg shadow-lg">
                        <p className="text-lg font-semibold text-green-600">Overtime {isExistingRecord ? 'updated' : 'assigned'} successfully!</p>
                        <button
                            onClick={() => setShowSuccessPopup(false)}
                            className="mt-4 bg-green-500 text-white px-4 py-2 rounded"
                        >
                            Close
                        </button>
                    </div>
                </div>
            )}
        </div>
    );
};

const OvertimeAssignmentTable = () => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [overtimeData, setOvertimeData] = useState([]);
    const [selectedOvertime, setSelectedOvertime] = useState(null);

    useEffect(() => {
        axios.get('https://back-ayurveda.erp-quortech.com/v1/hris/Overtime/overtime')
            .then((response) => {
                setOvertimeData(Array.isArray(response.data) ? response.data : []);
            })
            .catch(() => setOvertimeData([]));
    }, []);

    const handleEditClick = (overtime) => {
        setSelectedOvertime(overtime);
        setIsModalOpen(true);
    };

    const handleCloseModal = () => {
        setIsModalOpen(false);
        setSelectedOvertime(null);
    };

    const refreshData = () => {
        axios.get('https://back-ayurveda.erp-quortech.com/v1/hris/Overtime/overtime')
            .then((response) => {
                setOvertimeData(Array.isArray(response.data) ? response.data : []);
                setIsModalOpen(false);
            })
            .catch(() => setOvertimeData([]));
    };

    return (
        <div className="bg-background overflow-y-auto h-screen">
            <div className="mx-5 my-5">
                <p className="text-[30px] font-bold">Overtime Assignment Table</p>
            </div>
            <div className="mx-5 my-5">
                <table className="min-w-full bg-white border border-gray-200">
                    <thead>
                        <tr className="bg-gray-100 border-b">
                            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Name of work</th>
                            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Date</th>
                            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Reason</th>
                            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Start Time</th>
                            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">End Time</th>
                            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Approval Status</th>
                            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Certified Status</th>
                            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        {overtimeData.length ? (
                            overtimeData.map((overtime) => (
                                <tr key={overtime.overtime_id} className="border-b hover:bg-gray-50">
                                    <td className="px-6 py-4 text-sm">{overtime.name_of_work}</td>
                                    <td className="px-6 py-4 text-sm">{new Date(overtime.date).toLocaleDateString()}</td>
                                    <td className="px-6 py-4 text-sm">{overtime.reason}</td>
                                    <td className="px-6 py-4 text-sm">{overtime.start_time}</td>
                                    <td className="px-6 py-4 text-sm">{overtime.end_time}</td>
                                    <td className="px-6 py-4 text-sm">{overtime.first_approval_status}</td>
                                    <td className="px-6 py-4 text-sm">{overtime.second_approval_status}</td>
                                    <td className="px-6 py-4 text-sm">
                                        <button className="text-blue-600 hover:underline" onClick={() => handleEditClick(overtime)}>Edit</button>
                                    </td>
                                </tr>
                            ))
                        ) : (
                            <tr>
                                <td colSpan="8" className="px-6 py-4 text-center text-gray-500">No overtime data available</td>
                            </tr>
                        )}
                    </tbody>
                </table>
            </div>

            {isModalOpen && selectedOvertime && (
                <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
                    <div className="bg-white w-[80%] h-[90%] p-5 rounded-lg shadow-lg overflow-y-auto">
                        <OvertimeAssigning
                            onClose={handleCloseModal}
                            selectedOvertime={selectedOvertime}
                            onSuccess={refreshData}
                        />
                        <button className="absolute top-4 right-4 text-gray-600 hover:text-gray-900" onClick={handleCloseModal}>
                            &times;
                        </button>
                    </div>
                </div>
            )}
        </div>
    );
};

export default OvertimeAssignmentTable;
