/** @format */

import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import New from "../../../../../Assets/icons/New.png";
import Send from "../../../../../Assets/icons/Side Nav icons.png";
import Trash from "../../../../../Assets/icons/Trash.png";

import TableMail from "./mailTabel";
import DialogNewMail from "./newMessageDialog";
import DialogBoxUploadDocuments from "./uploadBidderDocmentsDialog";
import DialogBoxViewDocuments from "./viewBidderDocumentsDialog";
import usePermissions from "../../../../../components/permissions/permission";
const Tender_Opening_Committees_Home = () => {
  const { hasPermission } = usePermissions();
  const navigate = useNavigate();
  const handleOpeningPriceForm1 = () => {
    navigate("/committess/openingPriceFormQuo");
  };

  const handleOpeningPriceFormView1 = () => {
    navigate("/committess/openingPriceFormQuoList");
  };

  const [showDialog, setShowDialog] = useState(false);
  const [documentShowDialog, setDocumentShowDialog] = useState(false);
  const [viewbidDocumentDialog, setviwbidDocumentDialog] = useState(false);
  // /committess/openingPriceForm
  const openDocDialog = () => {
    setDocumentShowDialog(true);
  };

  const closeDocDialog = () => {
    setDocumentShowDialog(false);
  };

  const openDialog = () => {
    setShowDialog(true);
  };

  const closeDialog = () => {
    setShowDialog(false);
  };

  const openViewBidDialog = () => {
    setviwbidDocumentDialog(true);
  };

  const closeViewBidDialog = () => {
    setviwbidDocumentDialog(false);
  };

  return (
    <div className="bg-background overflow-y-auto h-screen">
      <p className="text-[40px] font-sans ml-10 mt-6 font-bold text-[#071C50] ">
        Tender Opening Committee
      </p>

      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-3 gap-12 mt-[2%] ml-10">
        {/* Card 3 */}
        {hasPermission(2672) && (
          <div className="bg-white rounded-[32px] p-4 shadow-md flex flex-col justify-between card mb-8">
            <div className="min-h-[110px] min-w-[150px]">
              <h2 className="text-[35px] text-[#797C80] font-semibold">
                Opening Price
              </h2>

              <h2 className="text-[25px] text-[#797C80] font-semibold mb-1">
                From
              </h2>
            </div>
            <div className="flex items-center relative">
              {hasPermission(10071) && (
                <button
                  className="bg-primary w-[35%] mt-2 mr-auto text-white font-bold py-1 px-4 rounded-[22px]"
                  onClick={handleOpeningPriceForm1}
                >
                  Create
                </button>
              )}

              {hasPermission(10070) && (
                <button
                  className="bg-[#656565] w-[35%] mt-2 mr-auto text-white font-bold py-1 px-4 rounded-[22px]"
                  onClick={handleOpeningPriceFormView1}
                >
                  View
                </button>
              )}
              <div className="bg-primary rounded-[64px] w-4 h-10 absolute transform-translate-y-1/2 right-0 top-0"></div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Tender_Opening_Committees_Home;
