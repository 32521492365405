import React from "react";
import { Link } from "react-router-dom";

const return_home = () => {
  return (
    <div>
      <div className="bg-[#E5EDF9] overflow-y-auto h-screen">

        <p className="text-[38px]  font-bold ml-6 mt-6">Return Menu</p>
        <div className="flex items-center gap-10">
            <div className="bg-white p-5 w-[300px] h-[200px] ml-[8%] mt-6 rounded-xl shadow-md">
              <div>
                <p className="font-bold text-[35px]">Return</p>
                <p className="text-[25px] font-bold">a sale</p>
              </div>
              <div className="mt-5 flex items-start justify-between">
                <Link to="/return_sale">
                    <button className="bg-primary text-white p-3 font-bold rounded-[20px]">
                      Click Here
                    </button>
                </Link>
                <div className="bg-primary rounded-full h-8 p-1"></div>
              </div>
            </div>


            <div className="bg-white p-5 w-[300px] h-[200px] ml-[8%] mt-6 rounded-xl shadow-md">
              <div>
                <p className="font-bold text-[35px]">Returned</p>
                <p className="text-[25px] font-bold">Bills</p>
              </div>
              <div className="mt-5 flex items-start justify-between">
                <Link to="/return_bill">
                    <button className="bg-primary text-white p-3 font-bold rounded-[20px]">
                      Click Here
                    </button>
                </Link>
                <div className="bg-primary rounded-full h-8 p-1"></div>
              </div>
            </div>
        </div>
      </div>
    </div>
  );
};

export default return_home;
