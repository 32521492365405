/** @format */

import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import boxLink from "../../../Assets/icons/Dashboard box.png";
import boxes from "../../../Assets/icons/boxes.png";
import emailvector1 from "../../../Assets/icons/emailvector1.png";
import documenticon from "../../../Assets/icons/documenticon.png";
import TableViewTender from "./homeTable";
import Tender_Recommndations_View_Dialog from "./Tender Recommendations/viewRecommendationsDialog";
import axios from "axios";
import usePermissions from "../../../components/permissions/permission";

const Dashboard_Home = () => {
  const { hasPermission } = usePermissions();
  const navigate = useNavigate();

  const handleSendAMail = () => {
    navigate("/supply/sendAmail");
  };

  const handleCreaATender = () => {
    navigate("/supply/createTender");
  };

  const handleAcceptanceLetter = () => {
    navigate("/supply/acceptanceLetter");
  };

  const [showDialogIndex, setShowDialogIndex] = useState(null);
  const [searchResults, setSearchResults] = useState([]);
  const [searchTerm, setSearchTerm] = useState("TENDER");
  const { REACT_APP_API_ENDPOINT } = process.env;
  const openDialog = (index) => {
    setShowDialogIndex(index);
  };

  const closeDialog = () => {
    setShowDialogIndex(null);
  };

  useEffect(() => {
    // Define a function to make the GET request
    const fetchData = async () => {
      try {
        // Make the GET request with axios
        const response = await axios.get(
          `${REACT_APP_API_ENDPOINT}/v1/supplyChain/tender/search/tender/${searchTerm}`
        );
        // Set the search results based on the response data
        setSearchResults(response.data);
      } catch (error) {
        console.error("Error fetching search results:", error);
      }
    };

    // Call fetchData function only if searchTerm is not empty
    if (searchTerm) {
      fetchData();
    }
  }, [searchTerm]); // Trigger the effect whenever searchTerm changes

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value); // Update the searchTerm state when input changes
  };

  return (
    <div className="bg-background overflow-y-auto h-screen">
      <p className="text-[40px] font-sans ml-6 mt-6 font-bold text-[#071C50] ">
        Dashboard
      </p>

      {/* Cards with "Click Here" buttons */}
      <div className="grid grid-rows-2 grid-flow-col gap-4 mt-6 mx-6 ">
        {/* Card 1 */}

        {hasPermission(1790) && (
          <div className="bg-white rounded-[32px] p-4 shadow-md justify-between card row-span-2">
            <div className=" min-w-[150px]">
              <h2 className="text-[35px] text-[#797C80] font-semibold">
                Suppliers
              </h2>
            </div>
            <div className="flex items-center justify-between">
              <div className="bg-primary  text-white font-bold py-2 px-4 rounded-[22px] items-center text-[26px]">
                <p>26</p>
              </div>
              <div>
                <img src={boxLink} alt="BoxLink" className=" " />
              </div>
            </div>
          </div>
        )}

        {/* Card 2 */}

        {hasPermission(1800) && (
          <div className="bg-white rounded-[32px] p-4 shadow-md justify-between card row-span-2">
            <div className="min-w-[150px]">
              <h2 className="text-[35px] text-[#797C80] font-semibold">
                Purchase Request
              </h2>
            </div>
            <div className="flex items-center justify-between">
              <div className="bg-primary text-white font-bold py-2 px-4 rounded-[22px] items-center text-[26px]">
                <p>8</p>
              </div>
              <div>
                <img src={boxes} alt="BoxLink" className=" " />
              </div>
            </div>
          </div>
        )}

        {/* Card 4 */}
      </div>
    </div>
  );
};

export default Dashboard_Home;
