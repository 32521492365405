import React, { useEffect, useState } from "react";
import moment from "moment-timezone";
import "moment-timezone";
import { FaCar, FaCalendarAlt } from "react-icons/fa";
import { MdKeyboardArrowDown } from "react-icons/md";
import DatePicker from "react-datepicker";
import Navbar from "../../../components/navbar/navbar";
import { Line } from "react-chartjs-2";
import axios from "axios";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";

// Register Chart.js components
ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const Fuel_consumption_dashboard = () => {
  const backendUrl = process.env.REACT_APP_VEHICLE_FLEET_BACKEND_URL;
  const [currentTime, setCurrentTime] = useState(moment().tz("Asia/Colombo").format("h:mm:ss a"));
  const currentDate = moment().tz("Asia/Colombo").format("MMMM Do YYYY");
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [selectedVehicle, setselectedVehicle] = useState("All vehicles");
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [cardData,setCardData] = useState({});

  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentTime(moment().tz("Asia/Colombo").format("h:mm:ss a"));
    }, 1000);
    return () => clearInterval(timer);
  }, []);

  const handleDateChange = (date) => {
    setStartDate(date);
    moment(date).tz("Asia/Colombo").format('YYYY-MM-DD');
  };

  const getVehicleData = async () => {
    try {
      let params = {
        vehicle_type: selectedVehicle === "All vehicle" ? null : selectedVehicle,
        start_date: startDate ? moment(startDate).tz("Asia/Colombo").format("YYYY-MM-DD") : undefined,
        end_date: endDate ? moment(endDate).tz("Asia/Colombo").format("YYYY-MM-DD") : undefined,
      };

      const response = await axios.get(`${backendUrl}/fuelsManagements/fuelConsumptionChart`, { params });

      setCardData(response.data.combinedResults)
    } catch (error) {
      console.error("Error fetching Total Fuel Consumed details", error);
    }
  };

  useEffect(() => {
    getVehicleData();
  }, [selectedVehicle, startDate, endDate]);

  const lineChartData = {
    labels: ["January", "February", "March", "April", "May", "June"],
    datasets: [
      {
        label: "Fuel Consumption (Liters)",
        data: [22500, 21000, 36200, 19507, 1436, 564],
        backgroundColor: "rgba(75, 192, 192, 0.2)",
        borderColor: "rgba(75, 192, 192, 1)",
        borderWidth: 2,
        pointBackgroundColor: "rgba(75, 192, 192, 1)",
        fill: true,
      },
    ],
  }

  const lineChartOptions = {
    responsive: true,
    plugins: {
      legend: {
        position: "top",
      },
      title: {
        display: true,
        text: "Monthly Fuel Consumption",
      },
    },
    scales: {
      y: {
        beginAtZero: true,
      },
    },
  };



  return (
    <div className="mx-10 mt-2">
      <Navbar />
      <div className="overflow-hidden">
        <div className="flex items-center justify-between mt-4 gap-6">
          <div>
            <p className="text-[24px] font-semibold">Inventory Management</p>
            <div className="flex align-left items-center gap-6">
              <p className="text-[14px] font-semibold text-black">Vehicle Fleet Management System</p>
              <p className="text-[14px] font-semibold text-black">Inventory Management</p>
              <p className="text-[14px] font-semibold text-[#ABDCB0]">Fuel Consumption Dashboard</p>
            </div>
          </div>
          <div className="flex items-center gap-6">
            <div className="text-[#069855] text-[18px] font-bold">{currentDate}</div>
            <div className="text-[18px] font-bold">{currentTime}</div>
          </div>
        </div>
      </div>
      <div className="mt-3">
        <div className="flex items-center justify-between">
          <div className="relative">
            <button
              className="p-2 border border-black rounded-[12px]"
              onClick={() => setIsDropdownOpen(!isDropdownOpen)}
            >
              <div className="flex gap-3 items-center">
                <div>{selectedVehicle}</div>
                <MdKeyboardArrowDown />
              </div>
            </button>
            {isDropdownOpen && (
              <div className="absolute mt-2 w-48 bg-white border border-gray-300 rounded-lg shadow-lg">
                <ul>
                  {["Car", "Van", "All vehicle"].map((fuelCons, index) => (
                    <li
                      key={index}
                      className="p-2 hover:bg-gray-200 cursor-pointer"
                      onClick={() => {
                        setselectedVehicle(fuelCons);
                        setIsDropdownOpen(false);
                      }}
                    >
                      {fuelCons}
                    </li>
                  ))}
                </ul>
              </div>
            )}
          </div>
          <div className="flex items-center text-black gap-2">
            <div className="flex items-center space-x-2 bg-white rounded-[20px] px-3 py-2 shadow-sm border border-black">
              <FaCalendarAlt className="h-5 w-5 text-gray-400" />
              <DatePicker
                selected={startDate}
                onChange={handleDateChange}
                selectsStart
                startDate={startDate}
                endDate={endDate}
                dateFormat="d MMM, yyyy"
                className="text-sm text-gray-600 focus:outline-none"
                placeholderText="Start Date"
              />
              <span className="text-gray-400">-</span>
              <DatePicker
                selected={endDate}
                onChange={(date) => setEndDate(date)}
                selectsEnd
                startDate={startDate}
                endDate={endDate}
                minDate={startDate}
                dateFormat="d MMM, yyyy"
                className="text-sm text-gray-600 focus:outline-none"
                placeholderText="End Date"
              />
            </div>
          </div>
        </div>
      </div>
     {/* Cards */}
     <div className="mt-8">
        <div className="grid grid-cols-3 grid-flow-rows gap-4">
         
          {/* card 1 */}
          <div className="border border-black p-2 rounded-xl">
          
            <div className="flex justify-between items-center mt-3">
           
              <div>
                <FaCar className="w-6 h-6" />
              </div>
              <div>
                <p className="font-semibold text-green-400">{currentDate}</p>
              </div>
            </div>
            <p className="mt-4 font-semibold">Total Fuel Consumed</p>{" "}
            <p className="text-[24px] font-semibold">
              {cardData.total_fuel_consumption || 0}
            </p>
     
          </div>
          {/* card 2 */}
          <div className="border border-black p-2 rounded-xl">
       
            <div className="flex justify-between items-center mt-3">
              
              <div>
                <FaCar className="w-6 h-6" />
              </div>
              <div>
                <p className="font-semibold text-green-400">{currentDate}</p>
              </div>
            </div>
            <p className="mt-4 font-semibold">Average Fuel Efficiency</p>
            <p className="text-[24px] font-semibold">
              {cardData.average_fuel_consumption || 0}
            </p>
          </div>
        </div>
      </div>
      {/* Line Chart */}
      <div className="flex gap-3 items-center w-full mt-4">
   
        {/* Reduced top margin */}
        <div className="flex-auto">
          <Line data={lineChartData} options={lineChartOptions} />
        </div>
      </div>
    </div>
  );
};

export default Fuel_consumption_dashboard;
