import React, { useEffect, useState } from "react";
import { IoIosCloseCircleOutline } from "react-icons/io";

const Details_testing_stages = ({ togglePopup, row }) => {
  console.log(row);
  const [sampleDetails, setSampleDetails] = useState({
    sample_type: "",
    sample_id: "",
    sample_name: "",
    material_type: "",
    amount: "",
    received_date: "",
    duration: "",
  });
  const [stage, setStage] = useState([{ testing_stage: "" }]);
  const [filteredSampleType, setFilteredSampleType] = useState([]);
  const [isResetConfirmationOpen, setIsResetConfirmationOpen] = useState(false);
  const [isSaveConfirmationOpen, setIsSaveConfirmationOpen] = useState(false);

  // Set sample details
  const handleInputChange = (e, index = null) => {
    const { name, value } = e.target;

    if (index !== null) {
      // Updating a specific testing stage
      const newStages = [...stage];
      newStages[index][name] = value;
      setStage(newStages);
    } else {
      // Updating sample details
      setSampleDetails((prevDetails) => ({
        ...prevDetails,
        [name]: value,
      }));
    }
  };

  // Auto Generating Last Sample ID
  const fetchLasSampleID = async () => {
    // Logic to fetch and set the last sample ID
  };

  const handleOpenResetConfirmation = () => {
    setIsResetConfirmationOpen(true);
  };

  const handleOpenSaveConfirmation = () => {
    setIsSaveConfirmationOpen(true);
  };

  const handleCloseSaveConfirmation = () => {
    setIsSaveConfirmationOpen(false);
  };

  const handleCloseResetConfirmation = () => {
    setIsResetConfirmationOpen(false);
  };

  // Save Form
  const handleSaveForm = async () => {
    console.log(sampleDetails);
    console.log(stage);
    try {
      // Your API call logic to save form data
      console.log("Form saved successfully!");
    } catch (error) {
      console.error("Error saving form:", error);
    }
    setIsSaveConfirmationOpen(false);
  };

  // Reset Form
  const handleResetForm = () => {
    setSampleDetails({
      sample_type: "",
      sample_id: "",
      sample_name: "",
      material_type: "",
      amount: "",
      received_date: "",
      duration: "",
      result: "",
      remark: "",
    });
    setStage([{ testing_stage: "" }]);
    setIsResetConfirmationOpen(false);
  };

  // Add a new testing stage
  const addStage = () => {
    setStage([...stage, { testing_stage: "" }]);
  };

  // Remove a testing stage
  const removeStop = (index) => {
    const newStages = stage.filter((_, i) => i !== index);
    setStage(newStages);
  };

  const setDetailsTestingStages = () => {
    const temp = row.quality_test_stages;
    setSampleDetails(temp);
  };

  useEffect(() => {
    // setDetailsTestingStages();
  }, []);
  return (
    <div className="fixed top-0 left-0 z-50 flex items-center justify-center w-full h-full bg-gray-200 bg-opacity-80">
      <div className="w-[80%] h-[80%] overflow-y-auto p-8 bg-white rounded-lg">
        <div className="flex items-center justify-between mb-6">
          <h2 className="text-2xl font-semibold">Details of Testing Stages</h2>
          <button
            type="button"
            onClick={togglePopup}
            className="p-4 text-4xl text-gray-500 transition duration-300 hover:text-gray-700"
          >
            <IoIosCloseCircleOutline />
          </button>
        </div>
        <form>
          <div className="items-center gap-8 mt-5">
            <div className="grid grid-cols-2 gap-y-[30px] gap-x-[60px] text-[20px]">
              {row.map((obj) => (
                <>
                  {/* Testing Stage */}
                  <div>
                    <label className="block text-gray-700">Testing Stage</label>
                    <input
                      type="text"
                      name="test_stage"
                      className="w-full p-2 mt-2 border border-gray-300 rounded"
                      value={obj.test_stage}
                      onChange={handleInputChange}
                      readOnly
                    />
                  </div>

                  {/* Testing Stage Percentage */}
                  <div>
                    <label className="block text-gray-700">
                      Testing Stage Percentage
                    </label>
                    <input
                      type="text"
                      name="percentage"
                      className="w-full p-2 mt-2 border border-gray-300 rounded"
                      value={obj.percentage}
                      onChange={handleInputChange}
                      readOnly
                    />
                  </div>
                </>
              ))}
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Details_testing_stages;
