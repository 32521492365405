import React, { useState, useEffect } from "react";
import { GrPrevious, GrNext } from "react-icons/gr";
import { useNavigate } from "react-router-dom";
import usePermissions from "../../../../components/permissions/permission";
import { RiArrowLeftDoubleLine } from "react-icons/ri";

function Attendancereport() {
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(10);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [search, setSearch] = useState(""); // State to hold the search input
  const navigate = useNavigate();
  const [filteredData, setFilteredData] = useState([]);
  const [month, setMonth] = useState("");
  const [year, setYear] = useState("");
  const { REACT_APP_API_ENDPOINT } = process.env;

  const { hasPermission } = usePermissions();

  const [isGenerateOpen, setIsGenerateOpen] = useState(false);
  const [isConfirmationOpen, setIsConfirmationOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState({ month: "", year: "" });

  const fetchData = async () => {
    if (!month || !year) {
      alert("Please select both month and year.");
      return;
    }
    setLoading(true);
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_ENDPOINT}/v1/hris/attendence/get-attendance-report?month=${month}&year=${year}`
      );
      const result = await response.json();
      setData(result);
      setFilteredData(result); // Also initialize filteredData with fetched data
      setLoading(false);
    } catch (error) {
      console.error("Error fetching data:", error);
      setLoading(false);
    }
  };

  const handleFilterChange = (e) => {
    const keyword = e.target.value.toLowerCase();
    setSearch(keyword);
    filterData(keyword);
  };

  const handleResetFilter = () => {
    setSearch("");
    setFilteredData(data);
  };

  const filterData = (keyword) => {
    const filtered = data.filter((item) => {
      return (
        (item.employee_no &&
          item.employee_no.toLowerCase().includes(keyword)) ||
        (item.employee_fullname &&
          item.employee_fullname.toLowerCase().includes(keyword)) ||
        (item.leave_type && item.leave_type.toLowerCase().includes(keyword)) ||
        (item.reason && item.reason.toLowerCase().includes(keyword)) ||
        (item.requested_date &&
          item.requested_date.toLowerCase().includes(keyword)) ||
        (item.approved_status_1 &&
          item.approved_status_1.toLowerCase().includes(keyword)) ||
        (item.approved_status_2 &&
          item.approved_status_2.toLowerCase().includes(keyword)) ||
        (item.leave_file_path &&
          item.leave_file_path.toLowerCase().includes(keyword)) ||
        (item.leave_file_name &&
          item.leave_file_name.toLowerCase().includes(keyword)) ||
        (item.checkIN_time &&
          item.checkIN_time.toLowerCase().includes(keyword)) ||
        (item.checkOUT_time &&
          item.checkOUT_time.toLowerCase().includes(keyword)) ||
        (item.OT && item.OT.toLowerCase().includes(keyword)) ||
        (item.status && item.status.toLowerCase().includes(keyword))
      );
    });
    setFilteredData(filtered);
  };

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = filteredData.slice(indexOfFirstItem, indexOfLastItem);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const handleTrackNow = () => {
    setIsGenerateOpen(true); // Open the popup
  };

  const handleBack = () => {
    navigate("/attendance");
  };

  const handleGenerateSubmit = () => {
    let hasError = false;
    const errors = { month: "", year: "" };

    if (!month || month < 1 || month > 12) {
      errors.month = "Please enter a valid month (1-12)";
      hasError = true;
    }
    if (!year || year.length !== 4) {
      errors.year = "Please enter a valid year (e.g., 2023)";
      hasError = true;
    }

    setErrorMessage(errors);

    if (!hasError) {
      // Show confirmation modal
      setIsConfirmationOpen(true);
    }
  };

  const handleConfirmationSubmit = async () => {
    setLoading(true);
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_ENDPOINT}/v1/hris/attendence/clone-attendance?month=${month}&year=${year}`
      );
      if (response.ok) {
        // Handle successful response
        console.log("Report generated successfully");
      } else {
        // Handle errors
        console.error("Failed to generate report");
      }
    } catch (error) {
      console.error("Error generating report:", error);
    }
    setLoading(false);
    setIsConfirmationOpen(false);
    setIsGenerateOpen(false);
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <div className="bg-background overflow-y-auto h-screen">
      <div className="flex justify-between items-center mr-[5%]">
        <p className="text-[25px] font-sans ml-6 font-bold text-[#797C80]">
          Attendance Report
        </p>
        <button
          className="bg-white p-3 rounded-lg font-bold mb-5 mt-5"
          onClick={handleBack}
        >
          <div className="flex gap-2 items-center">
            <RiArrowLeftDoubleLine />
            Navigate to home
          </div>
        </button>
      </div>

      {/* Search */}
      <div className="flex justify-between items-center mt-[20px]">
        {/* input fields */}
        <div>
          <div className="ml-6 flex gap-[40px]">
            <div>
              <p className="text-headerColor font-bold mb-2">Month</p>
              <input
                type="number"
                min="1"
                max="12"
                value={month}
                onChange={(e) => setMonth(e.target.value)}
                className="w-[217px] h-[55px] rounded-[22px] pl-5 pr-5"
              />
            </div>
            <div>
              <p className="text-headerColor font-bold mb-2">Year</p>
              <input
                type="number"
                value={year}
                onChange={(e) => setYear(e.target.value)}
                className="w-[217px] h-[55px] rounded-[22px] pl-5 pr-5"
              />
            </div>
          </div>
          <div className="ml-6 mt-3">
            <button
              onClick={fetchData}
              className="bg-primary text-white px-4 py-2 rounded-md"
            >
              Get Attendance Data
            </button>
          </div>
          <div className="flex items-center gap-5">
            <div className="ml-6 mt-3">
              <input
                value={search}
                onChange={handleFilterChange}
                className="w-[480px] h-[55px] rounded-[22px] pl-5"
                placeholder="Search By Employee Code"
              />
            </div>
            <div>
              <p
                className="text-headerColor font-bold"
                onClick={handleResetFilter}
              >
                Reset
              </p>
            </div>
          </div>
        </div>
        {hasPermission(2010) && (
          <div className="ml-[35%] mr-[5%]">
            <div className="mt-[10%]">
              <div
                key=""
                className="bg-white rounded-[32px] w-[350px] h-[200px] p-4 shadow-md flex flex-col justify-between"
              >
                <div>
                  <h2 className="text-[25px] font-semibold mb-1 font-sans">
                    Generate Attendance Report
                  </h2>
                </div>
                <div className="flex justify-between items-center">
                  <button
                    className="bg-primary w-[40%] mt-2 text-white font-bold py-2 px-4 rounded-[22px]"
                    onClick={handleTrackNow}
                  >
                    +Generate
                  </button>
                  <div className="bg-primary w-[13px] h-[37px] rounded-xl"></div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
      <label className="font-semibold ml-[5%]">Attendance Report</label>
      <div className="ml-[6%]">
        <hr className="line border-t border-gray-300 w-[5%]"></hr>
      </div>
      <div className="flex flex-col font-sans font-bold ml-6 mt-6 rounded-xl">
        <table className="table-auto border-none w-[95%]">
          <thead>
            <tr className="bg-white text-[18px]">
              <th className="text-primary px-4 py-2 w-[10%]">Employee ID</th>
              <th className="text-primary px-4 py-2 w-[10%]">Full Name</th>
              <th className="text-primary px-4 py-2 w-[10%]">Leave Type</th>
              <th className="text-primary px-4 py-2 w-[20%]">Reason</th>
              <th className="text-primary px-4 py-2 w-[10%]">Requested Date</th>
              <th className="text-primary px-4 py-2 w-[10%]">
                Approved Status 1
              </th>
              <th className="text-primary px-4 py-2 w-[10%]">
                Approved Status 2
              </th>
              <th className="text-primary px-4 py-2 w-[20%]">
                Leave File Path
              </th>
              <th className="text-primary px-4 py-2 w-[20%]">
                Leave File Name
              </th>
              <th className="text-primary px-4 py-2 w-[15%]">Check-in Time</th>
              <th className="text-primary px-4 py-2 w-[15%]">Check-out Time</th>
              <th className="text-primary px-4 py-2 w-[10%]">OT</th>
              <th className="text-primary px-4 py-2 w-[10%]">Status</th>
            </tr>
          </thead>
          <tbody>
            {currentItems.map((item) => (
              <tr key={item.id} className="bg-white text-[18px]">
                <td className="px-4 py-2">{item.employee_no}</td>
                <td className="px-4 py-2">{item.employee_fullname}</td>
                <td className="px-4 py-2">{item.leave_type}</td>
                <td className="px-4 py-2">{item.reason}</td>
                <td className="px-4 py-2">{item.requested_date}</td>
                <td className="px-4 py-2">{item.approved_status_1}</td>
                <td className="px-4 py-2">{item.approved_status_2}</td>
                <td className="px-4 py-2">{item.leave_file_path}</td>
                <td className="px-4 py-2">{item.leave_file_name}</td>
                <td className="px-4 py-2">{item.checkIN_time}</td>
                <td className="px-4 py-2">{item.checkOUT_time}</td>
                <td className="px-4 py-2">{item.OT}</td>
                <td className="px-4 py-2">{item.status}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      <div className="flex justify-center mt-4">
        <button
          onClick={() => paginate(currentPage - 1)}
          disabled={currentPage === 1}
          className="bg-primary text-white px-4 py-2 mx-1 rounded-md"
        >
          <GrPrevious className="w-3 h-3" />
        </button>
        {Array.from({
          length: Math.ceil(filteredData.length / itemsPerPage),
        }).map((_, index) => (
          <button
            key={index}
            onClick={() => paginate(index + 1)}
            className={`${
              currentPage === index + 1 ? "bg-primary text-white" : "bg-white"
            } px-4 py-2 mx-1 rounded-md`}
          >
            {index + 1}
          </button>
        ))}
        <button
          onClick={() => paginate(currentPage + 1)}
          disabled={
            currentPage === Math.ceil(filteredData.length / itemsPerPage)
          }
          className="bg-primary text-white px-4 py-2 mx-1 rounded-md"
        >
          <GrNext className="w-3 h-3" />
        </button>
      </div>

      {isGenerateOpen && (
        <div className="fixed top-0 left-0 h-full flex items-center justify-center bg-black bg-opacity-50 z-50 w-full">
          <div className="bg-[#F3F8FF] p-6 rounded-[40px]">
            <div className="mb-6 items-center justify-center">
              <p className="font-sans text-[32px] font-bold text-center">
                Generate Production Incentive
              </p>

              <div className="mt-5 flex flex-col">
                <div className="flex flex-row items-center">
                  <div>
                    <p className="font-sans font-semibold">Month :</p>
                  </div>

                  <div className="ml-6">
                    <input
                      type="number"
                      min="1"
                      max="12"
                      value={month}
                      onChange={(e) => setMonth(e.target.value)}
                      className="w-[300px] h-10 rounded-[73px] py-1.5 bg-white placeholder-white sm:text-sm px-2 font-sans"
                    />
                    {errorMessage.month && (
                      <p className="text-red-500 text-sm mt-1">
                        {errorMessage.month}
                      </p>
                    )}
                  </div>
                </div>
                <div className="flex flex-row items-center mt-4">
                  <div>
                    <p className="font-sans font-semibold">Year :</p>
                  </div>

                  <div className="ml-6">
                    <input
                      type="number"
                      value={year}
                      onChange={(e) => setYear(e.target.value)}
                      className="w-[300px] h-10 rounded-[73px] py-1.5 bg-white placeholder-white sm:text-sm px-2 font-sans"
                    />
                    {errorMessage.year && (
                      <p className="text-red-500 text-sm mt-1">
                        {errorMessage.year}
                      </p>
                    )}
                  </div>
                </div>
              </div>

              <div className="flex justify-center mt-5 space-x-3">
                <button
                  className="bg-primary p-2 rounded-[22px] text-white w-[100px]"
                  onClick={handleGenerateSubmit}
                >
                  Submit
                </button>
                <button
                  className="bg-gray-500 p-2 rounded-[22px] text-white w-[100px]"
                  onClick={() => setIsGenerateOpen(false)}
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </div>
      )}

      {isConfirmationOpen && (
        <div className="fixed top-0 left-0 h-full flex items-center justify-center bg-black bg-opacity-50 z-50 w-full">
          <div className="bg-[#F3F8FF] p-6 rounded-[40px]">
            <div className="mb-6 items-center justify-center">
              <p className="font-sans text-[32px] font-bold text-center">
                Are you sure you want to generate the report?
              </p>
              <div className="flex justify-center mt-5 space-x-3">
                <button
                  className="bg-primary p-2 rounded-[22px] text-white"
                  onClick={handleConfirmationSubmit}
                >
                  Yes
                </button>
                <button
                  className="bg-gray-500 p-2 rounded-[22px] text-white"
                  onClick={() => setIsConfirmationOpen(false)}
                >
                  No
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default Attendancereport;
