/** @format */

import React, { useState, useEffect, useCallback, useMemo } from "react";

const FinanceManagementPermission = ({
  onPermissionsChange,
  userPermissions,
}) => {
  const [isVisible, setIsVisible] = useState(false);
  const [checkedValues, setCheckedValues] = useState({});

  const toggleVisibility = () => {
    setIsVisible(!isVisible);
  };

  // Initialize checked values from userPermissions
  useEffect(() => {
    if (userPermissions && userPermissions.length > 0) {
      const initialCheckedValues = userPermissions.reduce((acc, curr) => {
        acc[String(curr)] = true;
        return acc;
      }, {});
      setCheckedValues(initialCheckedValues);
      console.log("Initialized checked values:", initialCheckedValues);
    }
  }, [userPermissions]);

  const permissionHierarchy = useMemo(
    () => ({
      21: { children: [306, 307, 308, 309, 310, 311, 312, 313, 314] },
      306: { children: [3061] },
      307: { children: [3071, 3072, 3073, 3074] },
      308: { children: [3081, 3082, 3083, 3084, 3085, 3086, 3087] },
      309: { children: [3090, 3091, 3092, 3093, 3094, 3095, 3096] },
      310: { children: [3100, 3101, 3102, 3103, 3104, 3105, 3106] },
      311: { children: [3110, 3111, 3112, 3113, 3114, 3115, 3116] },
      312: { children: [3120] },
      313: { children: [3130] },
    }),
    []
  );

  const handleCheckboxChange = (value) => {
    setCheckedValues((prev) => {
      const newValues = { ...prev, [value]: !prev[value] };

      // Automatically check parent checkboxes if a child checkbox is checked
      if (newValues[value]) {
        Object.keys(permissionHierarchy).forEach((key) => {
          if (permissionHierarchy[key]?.children.includes(value)) {
            newValues[key] = true;
          }
        });
      }

      // Automatically check child checkboxes if a parent checkbox is checked
      if (permissionHierarchy[value] && newValues[value]) {
        permissionHierarchy[value].children.forEach((child) => {
          newValues[child] = true;
        });
      }

      return newValues;
    });
  };

  const updatePermissions = useCallback(() => {
    const selectedPermissions = Object.keys(checkedValues)
      .filter((key) => checkedValues[key])
      .map(Number);

    if (
      selectedPermissions.some((perm) =>
        permissionHierarchy[21].children.includes(perm)
      ) &&
      !selectedPermissions.includes(21)
    ) {
      selectedPermissions.push(21);
    }

    onPermissionsChange(selectedPermissions);
  }, [checkedValues, onPermissionsChange, permissionHierarchy]);

  useEffect(() => {
    updatePermissions();
  }, [checkedValues, updatePermissions]);

  return (
    <div>
      <div>
        <div className="">
          <p className="font-bold text-[20px] ml-[20%] mt-4 underline">
            Finance Management
          </p>
          <div className="ml-[20%]">
            <button
              className="bg-blue-500 p-3 mt-5 text-white font-semibold rounded-[30px]"
              onClick={toggleVisibility}
            >
              {isVisible ? "Hide" : "Show"} Details
            </button>
          </div>
        </div>
        <div>
          {isVisible && (
            <div className="ml-[20%] mt-5 grid grid-cols-3 gap-10">
              {/* Permission Sections */}
              <div>
                <p className="bg-gray-200 rounded-[20px] p-5 font-bold text-[15px] text-black">
                  Dashboard
                </p>
                <div className="ml-5 mt-2">
                  <label className="block mb-2 text-blue-500">
                    <input
                      type="checkbox"
                      className="mr-2"
                      value="306"
                      checked={!!checkedValues[306]}
                      onChange={() => handleCheckboxChange(306)}
                    />
                    Dashboard
                  </label>
                </div>
              </div>

              <div>
                <p className="bg-gray-200 p-5 rounded-[20px] font-bold text-[15px] text-black">
                  Chart of Accounts
                </p>
                <div className="ml-5 mt-2">
                  <label className="block mb-2 text-blue-500">
                    <input
                      type="checkbox"
                      className="mr-2"
                      value="301"
                      checked={!!checkedValues[307]}
                      onChange={() => handleCheckboxChange(307)}
                    />
                    Chart of Accounts
                  </label>
                  <div className="ml-[9%] grid grid-cols-2 grid-flow-row gap-1">
                    <div className="text-black">
                      <label className="block mb-2">
                        <input
                          type="checkbox"
                          className="mr-2"
                          value="3071"
                          checked={!!checkedValues[3071]}
                          onChange={() => handleCheckboxChange(3071)}
                        />
                        Account view
                      </label>
                    </div>
                    <div className="text-black">
                      <label className="block mb-2">
                        <input
                          type="checkbox"
                          className="mr-2"
                          value="3072"
                          checked={!!checkedValues[3072]}
                          onChange={() => handleCheckboxChange(3072)}
                        />
                        Account add
                      </label>
                    </div>
                    <div className="text-black">
                      <label className="block mb-2">
                        <input
                          type="checkbox"
                          className="mr-2"
                          value="3073"
                          checked={!!checkedValues[3073]}
                          onChange={() => handleCheckboxChange(3073)}
                        />
                        Account edit
                      </label>
                    </div>
                    <div className="text-black">
                      <label className="block mb-2">
                        <input
                          type="checkbox"
                          className="mr-2"
                          value="3074"
                          checked={!!checkedValues[3074]}
                          onChange={() => handleCheckboxChange(3074)}
                        />
                        Account delete
                      </label>
                    </div>
                  </div>
                </div>
              </div>

              <div>
                <p className="bg-gray-200 p-5 rounded-[20px] font-bold text-[15px] text-black">
                  Payment Entry
                </p>
                <div className="ml-5 mt-2">
                  <label className="block mb-2 text-blue-500">
                    <input
                      type="checkbox"
                      className="mr-2"
                      value="308"
                      checked={!!checkedValues[308]}
                      onChange={() => handleCheckboxChange(308)}
                    />
                    Payment Entry
                  </label>
                  <div className="ml-[9%] grid grid-cols-2 grid-flow-row gap-1">
                    <div className="text-black">
                      <label className="block mb-2">
                        <input
                          type="checkbox"
                          className="mr-2"
                          value="3081"
                          checked={!!checkedValues[3081]}
                          onChange={() => handleCheckboxChange(3081)}
                        />
                        Payment entry add
                      </label>
                    </div>

                    <div className="text-black">
                      <label className="block mb-2">
                        <input
                          type="checkbox"
                          className="mr-2"
                          value="3082"
                          checked={!!checkedValues[3082]}
                          onChange={() => handleCheckboxChange(3082)}
                        />
                        Payment entry edit
                      </label>
                    </div>

                    <div className="text-black">
                      <label className="block mb-2">
                        <input
                          type="checkbox"
                          className="mr-2"
                          value="3083"
                          checked={!!checkedValues[3083]}
                          onChange={() => handleCheckboxChange(3083)}
                        />
                        Payment entry delete
                      </label>
                    </div>

                    <div className="text-black">
                      <label className="block mb-2">
                        <input
                          type="checkbox"
                          className="mr-2"
                          value="3084"
                          checked={!!checkedValues[3084]}
                          onChange={() => handleCheckboxChange(3084)}
                        />
                        Payment entry check
                      </label>
                    </div>

                    <div className="text-black">
                      <label className="block mb-2">
                        <input
                          type="checkbox"
                          className="mr-2"
                          value="3085"
                          checked={!!checkedValues[3085]}
                          onChange={() => handleCheckboxChange(3085)}
                        />
                        Payment entry approve
                      </label>
                    </div>

                    <div className="text-black">
                      <label className="block mb-2">
                        <input
                          type="checkbox"
                          className="mr-2"
                          value="3086"
                          checked={!!checkedValues[3086]}
                          onChange={() => handleCheckboxChange(3086)}
                        />
                        Payment entry disable
                      </label>
                    </div>
                    <div className="text-black">
                      <label className="block mb-2">
                        <input
                          type="checkbox"
                          className="mr-2"
                          value="3087"
                          checked={!!checkedValues[3087]}
                          onChange={() => handleCheckboxChange(3087)}
                        />
                        Payment entry view
                      </label>
                    </div>
                  </div>
                </div>
              </div>

              <div>
                <p className="bg-gray-200 p-5 rounded-[20px] font-bold text-[15px] text-black">
                  Journal Entry
                </p>

                <div className="ml-5 mt-2">
                  <label className="block mb-2 text-blue-500">
                    <input
                      type="checkbox"
                      className="mr-2"
                      value="309"
                      checked={!!checkedValues[309]}
                      onChange={() => handleCheckboxChange(309)}
                    />
                    Journal Entry
                  </label>

                  <div className="ml-5 mt-2">
                    <div className="ml-[9%] grid grid-cols-2 grid-flow-row gap-1">
                      <div className="text-black">
                        <label className="block mb-2">
                          <input
                            type="checkbox"
                            className="mr-2"
                            value="3090"
                            checked={!!checkedValues[3090]}
                            onChange={() => handleCheckboxChange(3090)}
                          />
                          Journal Entry view
                        </label>
                      </div>
                    </div>

                    <div className="ml-[9%] grid grid-cols-2 grid-flow-row gap-1">
                      <div className="text-black">
                        <label className="block mb-2">
                          <input
                            type="checkbox"
                            className="mr-2"
                            value="3091"
                            checked={!!checkedValues[3091]}
                            onChange={() => handleCheckboxChange(3091)}
                          />
                          Journal Entry Add
                        </label>
                      </div>
                    </div>

                    <div className="ml-[9%] grid grid-cols-2 grid-flow-row gap-1">
                      <div className="text-black">
                        <label className="block mb-2">
                          <input
                            type="checkbox"
                            className="mr-2"
                            value="3092"
                            checked={!!checkedValues[3092]}
                            onChange={() => handleCheckboxChange(3092)}
                          />
                          Journal Entry Edit
                        </label>
                      </div>
                    </div>

                    <div className="ml-[9%] grid grid-cols-2 grid-flow-row gap-1">
                      <div className="text-black">
                        <label className="block mb-2">
                          <input
                            type="checkbox"
                            className="mr-2"
                            value="3093"
                            checked={!!checkedValues[3093]}
                            onChange={() => handleCheckboxChange(3093)}
                          />
                          Journal Entry Delete
                        </label>
                      </div>
                    </div>

                    <div className="ml-[9%] grid grid-cols-2 grid-flow-row gap-1">
                      <div className="text-black">
                        <label className="block mb-2">
                          <input
                            type="checkbox"
                            className="mr-2"
                            value="3094"
                            checked={!!checkedValues[3094]}
                            onChange={() => handleCheckboxChange(3094)}
                          />
                          Journal Entry Disable
                        </label>
                      </div>
                    </div>

                    <div className="ml-[9%] grid grid-cols-2 grid-flow-row gap-1">
                      <div className="text-black">
                        <label className="block mb-2">
                          <input
                            type="checkbox"
                            className="mr-2"
                            value="3095"
                            checked={!!checkedValues[3095]}
                            onChange={() => handleCheckboxChange(3095)}
                          />
                          Journal Entry Submit
                        </label>
                      </div>
                    </div>

                    <div className="ml-[9%] grid grid-cols-2 grid-flow-row gap-1">
                      <div className="text-black">
                        <label className="block mb-2">
                          <input
                            type="checkbox"
                            className="mr-2"
                            value="3096"
                            checked={!!checkedValues[3096]}
                            onChange={() => handleCheckboxChange(3096)}
                          />
                          Journal Entry Approve
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div>
                <p className="bg-gray-200 p-5 rounded-[20px] font-bold text-[15px] text-black">
                  Purchase Invoice
                </p>

                <div className="ml-5 mt-2">
                  <label className="block mb-2 text-blue-500">
                    <input
                      type="checkbox"
                      className="mr-2"
                      value="310"
                      checked={!!checkedValues[310]}
                      onChange={() => handleCheckboxChange(310)}
                    />
                    Purchase Invoice
                  </label>
                  <div className="ml-[9%] grid grid-cols-2 grid-flow-row gap-1">
                    <div className="text-black">
                      <label className="block mb-2">
                        <input
                          type="checkbox"
                          className="mr-2"
                          value="3100"
                          checked={!!checkedValues[3100]}
                          onChange={() => handleCheckboxChange(3100)}
                        />
                        Purchase Invoice View
                      </label>
                    </div>

                    <div className="text-black">
                      <label className="block mb-2">
                        <input
                          type="checkbox"
                          className="mr-2"
                          value="3101"
                          checked={!!checkedValues[3101]}
                          onChange={() => handleCheckboxChange(3101)}
                        />
                        Purchase Invoice Add
                      </label>
                    </div>

                    <div className="text-black">
                      <label className="block mb-2">
                        <input
                          type="checkbox"
                          className="mr-2"
                          value="3102"
                          checked={!!checkedValues[3102]}
                          onChange={() => handleCheckboxChange(3102)}
                        />
                        Purchase Invoice Edit
                      </label>
                    </div>
                    <div className="text-black">
                      <label className="block mb-2">
                        <input
                          type="checkbox"
                          className="mr-2"
                          value="3103"
                          checked={!!checkedValues[3103]}
                          onChange={() => handleCheckboxChange(3103)}
                        />
                        Purchase Invoice Delete
                      </label>
                    </div>

                    <div className="text-black">
                      <label className="block mb-2">
                        <input
                          type="checkbox"
                          className="mr-2"
                          value="3104"
                          checked={!!checkedValues[3104]}
                          onChange={() => handleCheckboxChange(3104)}
                        />
                        Purchase Invoice Submit
                      </label>
                    </div>

                    <div className="text-black">
                      <label className="block mb-2">
                        <input
                          type="checkbox"
                          className="mr-2"
                          value="3105"
                          checked={!!checkedValues[3105]}
                          onChange={() => handleCheckboxChange(3105)}
                        />
                        Purchase Invoice Approve
                      </label>
                    </div>

                    <div className="text-black">
                      <label className="block mb-2">
                        <input
                          type="checkbox"
                          className="mr-2"
                          value="3106"
                          checked={!!checkedValues[3106]}
                          onChange={() => handleCheckboxChange(3106)}
                        />
                        Purchase Invoice Disable
                      </label>
                    </div>
                  </div>
                </div>
              </div>

              <div>
                <p className="bg-gray-200 p-5 rounded-[20px] font-bold text-[15px] text-black">
                Sales Invoice
                </p>

                <div className="ml-5 mt-2">
                  <label className="block mb-2 text-blue-500">
                    <input
                      type="checkbox"
                      className="mr-2"
                      value="311"
                      checked={!!checkedValues[311]}
                      onChange={() => handleCheckboxChange(311)}
                    />
                     Sales Invoice
                  </label>
                  <div className="ml-[9%] grid grid-cols-2 grid-flow-row gap-1">
                    <div className="text-black">
                      <label className="block mb-2">
                        <input
                          type="checkbox"
                          className="mr-2"
                          value="3110"
                          checked={!!checkedValues[3110]}
                          onChange={() => handleCheckboxChange(3110)}
                        />
                          Sales Invoice View
                      </label>
                    </div>

                    <div className="text-black">
                      <label className="block mb-2">
                        <input
                          type="checkbox"
                          className="mr-2"
                          value="3111"
                          checked={!!checkedValues[3111]}
                          onChange={() => handleCheckboxChange(3111)}
                        />
                        Sales Invoice Add
                      </label>
                    </div>

                    <div className="text-black">
                      <label className="block mb-2">
                        <input
                          type="checkbox"
                          className="mr-2"
                          value="3112"
                          checked={!!checkedValues[3112]}
                          onChange={() => handleCheckboxChange(3112)}
                        />
                        Sales Invoice Edit
                      </label>
                    </div>
                    <div className="text-black">
                      <label className="block mb-2">
                        <input
                          type="checkbox"
                          className="mr-2"
                          value="3113"
                          checked={!!checkedValues[3113]}
                          onChange={() => handleCheckboxChange(3113)}
                        />
                        Sales Invoice Delete
                      </label>
                    </div>

                    <div className="text-black">
                      <label className="block mb-2">
                        <input
                          type="checkbox"
                          className="mr-2"
                          value="3114"
                          checked={!!checkedValues[3114]}
                          onChange={() => handleCheckboxChange(3114)}
                        />
                        Sales Invoice Submit
                      </label>
                    </div>

                    <div className="text-black">
                      <label className="block mb-2">
                        <input
                          type="checkbox"
                          className="mr-2"
                          value="3115"
                          checked={!!checkedValues[3115]}
                          onChange={() => handleCheckboxChange(3115)}
                        />
                        Sales Invoice Approve
                      </label>
                    </div>

                    <div className="text-black">
                      <label className="block mb-2">
                        <input
                          type="checkbox"
                          className="mr-2"
                          value="3116"
                          checked={!!checkedValues[3116]}
                          onChange={() => handleCheckboxChange(3116)}
                        />
                        Sales Invoice Disable
                      </label>
                    </div>
                  </div>
                </div>
              </div>
           

              <div>
                <p className="bg-gray-200 p-5 rounded-[20px] font-bold text-[15px] text-black">
                  Accounts Payables
                </p>

                <div className="ml-5 mt-2">
                  <label className="block mb-2 text-blue-500">
                    <input
                      type="checkbox"
                      className="mr-2"
                      value="312"
                      checked={!!checkedValues[312]}
                      onChange={() => handleCheckboxChange(312)}
                    />
                    Accounts Payables
                  </label>
                  <div className="ml-[9%] grid grid-cols-2 grid-flow-row gap-1">
                    <div className="text-black">
                      <label className="block mb-2">
                        <input
                          type="checkbox"
                          className="mr-2"
                          value="3120"
                          checked={!!checkedValues[3120]}
                          onChange={() => handleCheckboxChange(3120)}
                        />
                        Accounts Payables View
                      </label>
                    </div>
                  </div>
                </div>
              </div>

              <div>
                <p className="bg-gray-200 p-5 rounded-[20px] font-bold text-[15px] text-black">
                  Account Authorization
                </p>

                <div className="ml-5 mt-2">
                  <label className="block mb-2 text-blue-500">
                    <input
                      type="checkbox"
                      className="mr-2"
                      value="314"
                      checked={!!checkedValues[314]}
                      onChange={() => handleCheckboxChange(314)}
                    />
                    Account Authorization
                  </label>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default FinanceManagementPermission;
