import React, { useEffect, useState } from "react";
import "./bill.css";
import Cookies from "js-cookie";
import BillPreview1 from "../../../components/bill preview 1/billPreview1";
import "./bill.css";

export default function Bill() {
  const [bills, setBills] = useState([]);
  const [filteredBills, setFilteredBills] = useState([]);
  const [billId, setBillId] = useState("");
  const [dateFrom, setDateFrom] = useState("");
  const [dateTo, setDateTo] = useState("");
  const [billPreviewShow, setBillPreviewShow] = useState(false);
  const [selectedBillId, setSelectedBillId] = useState("");

  useEffect(() => {
    const branch = Cookies.get("branch");

    if (branch) {
      const fetchItems = async () => {
        try {
          const endpoint = `https://back-ayurveda.erp-quortech.com/v1/inventory/pos/getAllSalesByDatePosTypeAndBranchId?branch_id=${branch}`;
          console.log("Fetching from URL:", endpoint);

          const response = await fetch(endpoint);
          if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
          }
          const data = await response.json();
          console.log("Fetched Items: ", data);
          setBills(data);
          setFilteredBills(data); // Initialize filteredBills with all bills
        } catch (error) {
          console.error("Error fetching item data:", error);
        }
      };

      fetchItems();
    }
  }, []);

  useEffect(() => {
    filterBills();
  }, [billId]);

  const filterBills = () => {
    let filtered = bills;

    if (billId) {
      filtered = filtered.filter((bill) => bill.pos_id.includes(billId));
    }

    if (dateFrom) {
      filtered = filtered.filter(
        (bill) => new Date(bill.pos_date) >= new Date(dateFrom)
      );
    }

    if (dateTo) {
      filtered = filtered.filter(
        (bill) => new Date(bill.pos_date) <= new Date(dateTo)
      );
    }

    setFilteredBills(filtered);
  };

  const handleBillClick = (id) => {
    setSelectedBillId(id);
    setBillPreviewShow(true);
  };

  return (
    <div className="p-4 bg-background h-screen overflow-y-auto relative">
      <p className="text-[25px] font-bold ml-10 ">Pos Bill View</p>
      <div className="w-full flex items-center justify-between my-10">
        <div className="w-[350px] ml-5 flex items-center justify-between">
          <p className="text-[20px] font-bold">Bill Id :</p>
          <input
            type="text"
            className="w-[228px] h-[35px] rounded-[46px] bg-white border border-[#797979] px-5"
            value={billId}
            onChange={(e) => setBillId(e.target.value)}
          />
        </div>
        <div className="w-[800px] flex items-center justify-between">
          <div className="w-[350px] flex items-center justify-between">
            <p className="text-[20px] font-bold">From :</p>
            <input
              className="w-[228px] h-[35px] rounded-[46px] bg-white border border-[#797979] px-5"
              type="date"
              id="dateFrom"
              value={dateFrom}
              onChange={(e) => setDateFrom(e.target.value)}
            />
          </div>
          <div className="w-[350px] flex items-center justify-between">
            <p className="text-[20px] font-bold">To :</p>
            <input
              className="w-[228px] h-[35px] rounded-[46px] bg-white border border-[#797979] px-5"
              type="date"
              id="dateTo"
              value={dateTo}
              onChange={(e) => setDateTo(e.target.value)}
            />
          </div>
        </div>
      </div>
      <div className="bg-[#F3F8FF] w-full min-h-96 rounded-t-[40px] rounded-b-[50px] ">
        <div className="w-full bg-PrimaryColor2 rounded-[100px] flex items-center h-[40px] justify-around mr-5">
          

          
          <div className="w-[350px] flex flex-col items-center">
            <p className="w-full text-[20px] font-bold text-[#656565] text-center ">Bill ID</p>
            <div className="border-[2px] border-[#656565] w-[67px] rounded-[100px] "></div>
          </div>
          <div className="w-[350px] flex flex-col items-center">
            <p className="w-full text-[20px] font-bold text-[#656565] text-center mr-[260px]">Issued Date</p>
            <div className="border-[2px] border-[#656565] w-[67px] rounded-[100px] mr-[260px]"></div>
          </div>
          <div className="w-[350px] flex flex-col items-center">
            <p className="w-full text-[20px] font-bold text-[#656565] text-center mr-[380px]">Customer</p>
            <div className="border-[2px] border-[#656565] w-[67px] rounded-[100px] mr-[380px]"></div>
          </div>
          <div className="w-10 flex flex-col items-center">
            <p className="w-full text-[20px] font-bold text-[#656565] text-center mr-[250px]">Status</p>
          </div>
        </div>
        <div className="max-h-[800px] overflow-y-scroll mr-5">
          {filteredBills.map((bill, index) => (
            <div
              key={index}
              className="flex justify-around items-center mt-4 cursor-pointer"
              onClick={() => handleBillClick(bill.pos_id)}
            >
              
              
              <p className="w-[350px] text-[20px] font-bold text-[#656565] text-center">
                {bill.pos_id}
              </p>
              <p className="w-[350px] text-[20px] font-bold text-[#656565] text-center">
                {new Date(bill.pos_date).toLocaleDateString()}
              </p>
              <p className="w-[350px] text-[20px] font-bold text-[#656565] text-center">
                {bill.customer_id}
              </p>

              <p className="w-[350px] text-[20px] font-bold text-[#656565] text-center">
                {bill.status}
              </p>
            </div>
          ))}
        </div>

        {billPreviewShow && (
          <>
            {/* Background overlay */}
            <div className="fixed inset-0 bg-gray-500 bg-opacity-50 z-40"></div>

            {/* Popup Container */}
            <div className="fixed inset-0 z-50 flex items-center justify-center">
              <div className="bg-white w-1/2 h-[90%] p-6 rounded-lg shadow-lg relative">
                <button
                  className="absolute top-2 right-2 text-white bg-red-500  p-2 rounded-full font-bold hover:text-gray-900 mb-4"
                  onClick={() => setBillPreviewShow(false)}
                >
                  &#10005; {/* Close button */}
                </button>
                <BillPreview1 id={selectedBillId} />
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
}
