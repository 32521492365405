/** @format */

import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import usePermissions from "../../../components/permissions/permission";
import { GrPrevious, GrNext } from "react-icons/gr";

const PurchaseOrdersTableHistoryLog = () => {
  const { hasPermission } = usePermissions();
  const [decisionId, setdecisionId] = useState("");
  const [data, setdata] = useState([]);
  const [purchaseOrders, setPurchaseOrders] = useState([]);
  const [showDialog, setShowDialog] = useState(false);
  const { REACT_APP_API_ENDPOINT } = process.env;
  const [approvePR, setApprovePR] = useState([]);
  const navigate = useNavigate();
  const [searchQuery, setSearchQuery] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(50);

  const getButtonStyle = (status) => {
    switch (status) {
      case "APPROVED":
        return "bg-approveStat text-approveStattxt border-none rounded-[9px] w-[139px] h-[33px] flex-shrink-0";
      case "REJECTED":
        return "bg-rejectedStat text-rejectedStattxt border-none rounded-[9px] w-[139px] h-[33px] flex-shrink-0";
      case "PENDING":
        return "bg-pendingStat text-pendingStattxt border-none rounded-[9px] w-[139px] h-[33px] flex-shrink-0";
      default:
        return ""; // Default style
    }
  };

  const getButtonStyleStatus = (decision) => {
    switch (decision) {
      case "CREATED TENDER":
        return "bg-createdStat text-createdStattxt border-none rounded-[9px] w-[150px] h-[33px] flex-shrink-0";
      case "CREATED PO":
        return "bg-createdStat text-createdStattxt border-none rounded-[9px] w-[150px] h-[33px] flex-shrink-0";
      case "CREATE A TENDER":
        return "bg-createdStat text-createdStattxt border-none rounded-[9px] w-[150px] h-[33px] flex-shrink-0";
      case "CREATE A PO":
        return "bg-createdStat text-createdStattxt border-none rounded-[9px] w-[150px] h-[33px] flex-shrink-0";
      case "PENDING":
        return "bg-pendingStat text-pendingStattxt border-none rounded-[9px] w-[150px] h-[33px] flex-shrink-0";
    }
  };

  useEffect(() => {
    const fetchPurchaseOrders = async () => {
      try {
        const response = await fetch(
          `${REACT_APP_API_ENDPOINT}/v1/supplyChain/grn/get`
        );
        const data = await response.json();
        setPurchaseOrders(data);
        console.log("Purchase Orders data", data);
      } catch (error) {
        console.error("Error fetching PurchaseOrders:", error);
      }
    };

    fetchPurchaseOrders();
  }, []);

  const handleViewClick = (item) => {
    navigate("/inventory/purchase/view-items", {
      state: { selectedRecord: item },
    });
  };

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };

  // Pagination Logic
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const filteredEmployees = purchaseOrders.filter((item) => {
    const searchMatch =
      !searchQuery ||
      item.po_id.toLowerCase().includes(searchQuery.toLowerCase());

    return searchMatch;
  });

  const currentItems = filteredEmployees.slice(
    indexOfFirstItem,
    indexOfLastItem
  );

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  return (
    <div className="bg-background overflow-y-auto h-screen">
      <p className="text-[40px] font-sans ml-6 mt-6 font-bold text-[#071C50] ">
        Inventory Purchase (GRN) History Log
      </p>
      <div className="flex flex-col font-poppins font-bold mt-10 ml-16">
        <div className="flex justify-between overflow-hidden pb-6">
          <div>
            <div className="flex flex-col font-poppins font-bold ml-6 mt-4">
              <div className="relative font-sans group hidden sm:block">
                <input
                  type="text"
                  placeholder="Search by PO ID"
                  value={searchQuery}
                  onChange={handleSearchChange}
                  className="w-[200px] sm:w-[300px] h-10 duration-300 rounded-full border border-white px-2 py-1"
                />
              </div>
            </div>
          </div>
        </div>
        <div className="bg-[#FFF] rounded-[20px] p-4 w-[97%] max-h-[500px] overflow-y-auto mt-5">
          {currentItems.length > 0 ? (
            <table className="w-full border-collapse border-none">
              <thead>
                <tr className="text-left bg-[#FFF]">
                  <th className="px-4 py-2 font-normal">PO No</th>
                  <th className="px-4 py-2 font-normal">GRN No</th>
                  <th className="px-4 py-2 font-normal">Created Date</th>
                  <th className="px-4 py-2 font-normal">Created by</th>
                  <th className="px-4 py-2 font-normal">Action</th>
                </tr>
              </thead>
              <tbody className="font-bold">
                {currentItems.map((item, index) => (
                  <tr key={index}>
                    <td className="px-4 py-2">{item.po_id}</td>
                    <td className="px-4 py-2">{item.grn_number}</td>
                    <td className="px-4 py-2">{item.create_at}</td>
                    <td className="px-4 py-2">{item.create_by}</td>
                    <td className="px-4 py-2">
                      {hasPermission(2610) && (
                        <button
                          className="text-approveStattxt hover:underline"
                          onClick={() => handleViewClick(item)}
                        >
                          View
                        </button>
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          ) : (
            <div className="text-center text-gray-500 font-semibold">
              No data available
            </div>
          )}
        </div>

        <div className="flex justify-center mt-4">
          <button
            onClick={() => paginate(currentPage - 1)}
            disabled={currentPage === 1}
            className="bg-primary text-white px-4 py-2 mx-1 rounded-md"
          >
            <GrPrevious className="w-3 h-3" />
          </button>
          {[...Array(Math.ceil(filteredEmployees.length / itemsPerPage))].map(
            (page, index) => (
              <button
                key={index}
                onClick={() => paginate(index + 1)}
                className={`${
                  currentPage === index + 1 ? "bg-gray-300" : "bg-white"
                } text-primary px-4 py-2 mx-1 rounded-md`}
              >
                {index + 1}
              </button>
            )
          )}
          <button
            onClick={() => paginate(currentPage + 1)}
            disabled={
              currentPage === Math.ceil(filteredEmployees.length / itemsPerPage)
            }
            className="bg-primary text-white px-4 py-2 mx-1 rounded-md"
          >
            <GrNext className="w-3 h-3" />
          </button>
        </div>
      </div>
    </div>
  );
};

export default PurchaseOrdersTableHistoryLog;
