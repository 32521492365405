/** @format */

import React from "react";

const ConfirmationModal = ({ message, onCancel, onConfirm }) => {
  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center overflow-x-hidden overflow-y-auto outline-none focus:outline-none">
      <div className="fixed inset-0 bg-gray-500 opacity-75"></div>
      <div className="relative z-50 w-auto max-w-sm mx-auto my-6">
        <div className="relative bg-white border-0 rounded-[30px] shadow-lg outline-none focus:outline-none">
          <div className="relative p-6">
            <div>
              <button
                className="absolute top-2 right-2 bg-red-600 text-white px-2 py-1 mt-3 mr-3 rounded-[20px]"
                onClick={onCancel}
              >
                X
              </button>
            </div>
            <div className="flex justify-center">
              <div className="bg-primary w-[48px] h-[11px] rounded-[22px]"></div>
            </div>
            <p className="my-4 text-blueGray-500 text-[30px] text-[#575859] leading-relaxed flex justify-center font-bold">
              {message}
            </p>
          </div>
          <div className="flex items-center justify-center gap-[40px] p-6">
            <div>
              <button
                className="text-black bg-[#F1C1C1] rounded-[22px] font-bold  px-6 py-2 text-[22px] outline-none focus:outline-none mr-1 mb-1"
                type="button"
                onClick={onCancel}
              >
                No
              </button>
            </div>
            <div>
              <button
                className="bg-[#B5DFB4] text-black active:bg-green-600 font-bold text-[22px] px-6 py-2 rounded-[22px] shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1"
                type="button"
                onClick={onConfirm}
              >
                Yes
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ConfirmationModal;
