/** @format */

import React, { useState, useEffect } from "react";
import ViewMore from "./viewmore";

function Approve1TableUser() {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [searchQuery, setSearchQuery] = useState("");
  const [userTypeFilter, setUserTypeFilter] = useState("");
  const [viewMoreId, setViewMoreId] = useState(null);
  const [viewMoreOpen, setViewMoreOpen] = useState(false);
  const { REACT_APP_API_ENDPOINT } = process.env;
  const [confirmationDialog, setConfirmationDialog] = useState({
    isOpen: false,
    itemId: null,
    action: "", // "APPROVE" or "REJECT"
    description: "",
  });

  const fetchData = async () => {
    try {
      const response = await fetch(
        `${REACT_APP_API_ENDPOINT}/v1/hris/employees/getdataapprove1`
      );
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const result = await response.json();
      setData(result);
      setLoading(false);
    } catch (err) {
      setError(err);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };

  const handleUserTypeChange = (e) => {
    setUserTypeFilter(e.target.value);
  };

  const handleStatusChange = (item, action) => {
    if (!item.employee_no) {
      alert("Employee number is missing for this record."); // Provide user feedback if employee number is missing
      return;
    }
    setConfirmationDialog({
      isOpen: true,
      itemId: item.id,
      employeeNo: item.employee_no, // Ensuring employee_no is included in the dialog state
      action: action,
      description: "",
    });
  };

  const confirmStatusChange = async () => {
    const { itemId, action, description, employeeNo } = confirmationDialog;
    const approvedStatus = action === "APPROVE" ? "APPROVED" : "REJECTED";

    try {
      const url = `${REACT_APP_API_ENDPOINT}/v1/hris/employees/editemployeedataapprove1?id=${itemId}&employee_no=${employeeNo}`;
      const response = await fetch(url, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          approved_status_1: approvedStatus,
          approved_status_1_description: description,
        }),
      });

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const updatedItem = await response.json();
      console.log("Status update response:", updatedItem);

      setData((prevData) =>
        prevData.map((item) =>
          item.id === itemId ? { ...item, ...updatedItem } : item
        )
      );
      fetchData(); // Refresh data to reflect the changes
      setConfirmationDialog({
        isOpen: false,
        itemId: null,
        employeeNo: null,
        action: "",
        description: "",
      });
    } catch (err) {
      console.error("Failed to update status:", err);
      alert("Failed to update the status due to an error.");
    }
  };

  const filteredData = data.filter((item) => {
    const employeeNo = item.employee_no || "";
    const leaveType = item.leave_type || "";
    const matchesSearchQuery = employeeNo
      .toLowerCase()
      .includes(searchQuery.toLowerCase());
    const matchesUserTypeFilter = userTypeFilter
      ? leaveType === userTypeFilter
      : true;
    return matchesSearchQuery && matchesUserTypeFilter;
  });

  const handleViewMore = (id) => {
    setViewMoreId(id);
    setViewMoreOpen(true);
  };
  return (
    <div className="bg-background overflow-y-auto h-screen">
      <p className="text-[25px] font-sans ml-6 mt-6 font-bold text-[#797C80]">
        Approve One User Details Table
      </p>

      <div className="mt-5 ml-6 mb-[50px]"></div>

      <div className="flex justify-between items-center mt-[-50px]">
        <div>
          <div className="flex items-center gap-5">
            <div className="ml-6 mt-3">
              <input
                className="w-[480px] h-[55px] rounded-[22px]"
                placeholder="Search By Employee Code"
                value={searchQuery}
                onChange={handleSearchChange}
              />
            </div>
            <div>
              <button
                className="text-headerColor font-bold"
                onClick={() => {
                  setSearchQuery("");
                  setUserTypeFilter("");
                }}
              >
                Reset
              </button>
            </div>
          </div>

          <div className="flex items-center gap-5">
            <div className="ml-6 mt-3">
              <select
                className="w-[480px] h-[55px] rounded-[22px] bg-white"
                value={userTypeFilter}
                onChange={handleUserTypeChange}
              >
                <option value="" disabled hidden>
                  Sort by Employee Department Type
                </option>
                <option value="">All</option>
                {Array.from(new Set(data.map((item) => item.leave_type))).map(
                  (leaveType) => (
                    <option key={leaveType} value={leaveType}>
                      {leaveType}
                    </option>
                  )
                )}
              </select>
            </div>
          </div>
        </div>
      </div>

      <div className="flex flex-col font-sans font-bold ml-6 mt-6 rounded-xl item">
        <table className="table-auto border-none w-[95%] ">
          <thead>
            <tr className="bg-white">
              <th className="text-primary px-4 py-2">Employee NO</th>
              <th className="text-primary px-4 py-2">Edit Description</th>{" "}
              <th className="text-primary px-4 py-2">View More</th>
              <th className="text-primary px-4 py-2">Approval</th>
            </tr>
          </thead>
          <tbody>
            {loading && (
              <tr>
                <td colSpan="4" className="bg-white px-4 py-2 text-center">
                  Loading...
                </td>
              </tr>
            )}
            {/* Error state */}

            {/* No data state */}
            {filteredData.length === 0 && (
              <tr>
                <td colSpan="4" className="bg-white px-4 py-2 text-center">
                  No data available
                </td>
              </tr>
            )}
            {/* Render data */}
            {filteredData.map((item) => (
              <tr key={item.id} className="">
                <td className="bg-white px-4 py-2">{item.employee_no}</td>
                <td className="bg-white px-4 py-2">{item.edit_description}</td>
                <td className="bg-white px-4 py-2">
                  <button
                    className="text-primary hover:underline"
                    onClick={() => handleViewMore(item.id)}
                  >
                    View More
                  </button>
                </td>

                <td className="bg-white px-4 py-2">
                  <button
                    className="bg-[#5B6D49] text-white px-4 py-2 rounded-[22px] mr-2"
                    onClick={() => handleStatusChange(item, "APPROVE")}
                  >
                    Approve
                  </button>
                  <button
                    className="bg-[#C8021F] text-white px-4 py-2 rounded-[22px]"
                    onClick={() => handleStatusChange(item, "REJECT")}
                  >
                    Reject
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      {viewMoreOpen && (
        <ViewMore id={viewMoreId} onClose={() => setViewMoreOpen(false)} />
      )}

      {confirmationDialog.isOpen && (
        <div className="fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-50">
          <div className="bg-white p-6 rounded-lg shadow-lg">
            <p className="text-xl mb-4">
              Are you sure you want to {confirmationDialog.action.toLowerCase()}{" "}
              this item?
            </p>
            <input
              type="text"
              placeholder="Add a description"
              value={confirmationDialog.description}
              onChange={(e) =>
                setConfirmationDialog({
                  ...confirmationDialog,
                  description: e.target.value,
                })
              }
              className="w-full p-2 border border-gray-300 rounded mb-4"
            />
            <div className="flex justify-end">
              <button
                className="bg-gray-500 text-white px-4 py-2 rounded mr-2"
                onClick={() =>
                  setConfirmationDialog({
                    isOpen: false,
                    itemId: null,
                    action: "",
                    description: "",
                  })
                }
              >
                Cancel
              </button>
              <button
                style={{
                  backgroundColor:
                    confirmationDialog.action === "APPROVE"
                      ? "#5B6D49"
                      : "#C8021F",
                  color: "white",
                  padding: "8px 16px",
                  borderRadius: "0.375rem", // equivalent to 'rounded' in Tailwind CSS
                }}
                onClick={confirmStatusChange}
              >
                {confirmationDialog.action === "APPROVE"
                  ? "Yes, Approve"
                  : "Yes, Reject"}
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default Approve1TableUser;
