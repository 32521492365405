/** @format */

import moment from "moment";
import React, { useEffect, useState } from "react";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { CiSearch } from "react-icons/ci";
import { RiMenu5Fill } from "react-icons/ri";
import { FaCalendarAlt } from "react-icons/fa";
import { RiDownloadCloud2Line } from "react-icons/ri";
// import Approve_01_documents_uploading_table from "../../../components/table/approve_01_driver_documents_uploading_table";
import { FiAlertOctagon } from "react-icons/fi";
import Navbar from "../../../components/navbar/navbar";
import { Link } from "react-router-dom";

const Documents_Uploading = () => {
  const [currentTime, setCurrentTime] = useState(moment().format("h:mm:ss a"));
  const [isOpen, setIsOpen] = useState(false);

  const [data, setData] = useState([]); // State to hold fetched data

  const currentDate = moment().format("MMMM Do YYYY");

  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  const [searchQuery, setSearchQuery] = useState("");

  const togglePopup = () => {
    setIsOpen(!isOpen); // Toggle the isOpen state
  };

  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentTime(moment().format("h:mm:ss a"));
    }, 1000);
    return () => clearInterval(timer);
  }, []);

  const exportToCSV = () => {
    // const csvData = employeeData.map((employee) => ({
    //   ID: employee.employee_no,
    //   FullName: employee.employee_fullname,
    //   Department: employee.department_name,
    //   remaining_leave: employee.remaining_leave,
    // }));
    // const csv = Papa.unparse(csvData);
    // const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
    // saveAs(blob, "employee_data.csv");
  };

  return (
    <div className="mx-10 mt-5">
      <Navbar />
      <div className="overflow-hidden">
        <div className="flex items-center justify-between mt-6">
          <div>
            <p className="text-[30px] font-semibold">
              Documents Uploading : Approve 02
            </p>

            
            <div className="flex align-left items-center gap-6">
              <p className="text-[15px] font-semibold text-black">
                Vehicle fleet management system
              </p>
              <p className="text-[15px] font-semibold text-black">
                Approval Process
              </p>
              <p className="text-[15px] font-semibold text-black">Approve 02</p>
              <p className="text-[15px] font-semibold text-[#ABDCB0]">
                Documents Uploading
              </p>
            </div>
          </div>
          <div className="flex items-center gap-6">
            <div>
              <div className="text-[#069855] text-[20px] font-bold">
                {currentDate}
              </div>
            </div>
            <div className="text-[20px] font-bold">{currentTime}</div>
          </div>
        </div>
      </div>
      <div className="mt-14">
        <div className="flex items-center justify-between">
          {/* <div className="flex items-center gap-4 mt-5">
            <div className="relative">
              <input
                className="border border-black rounded-xl p-2 pl-10 w-[325px]"
                placeholder="Search by ID or Name"
                 value={searchInput}
                onChange={(e) => setSearchInput(e.target.value)}
              />
              <CiSearch className="absolute text-gray-500 transform -translate-y-1/2 top-1/2 left-3" />
            </div>
            <div>
              <button className="p-2 border border-black rounded-[12px]">
                <div className="flex items-center gap-3">
                  Filter <RiMenu5Fill />
                </div>
              </button>
            </div>
          </div> */}
        </div>
      </div>

      {/* card layer */}
      <div className="mt-10">
        <div className="grid grid-cols-3 grid-flow-rows gap-5">
          {/* card 1 */}
          <div className=" border border-black p-3 rounded-xl">
            <div className="flex justify-between items-center">
              <div className="">
                {/* <p className="font-semibold text-black">Document Uploading</p> */}
                <p className="mt-5 font-semibold">Vehicle Document Uploading</p>
              </div>
              <div>
                <FiAlertOctagon className="w-8 h-8 text-green-400" />
              </div>
            </div>
            <Link to="/Approval-process-02/document-uploading-vehicle">
              <button className=" text-white p-6 border border-[#069855] rounded-[25px] bg-[#069855] mt-5">
                view
              </button>
            </Link>
            <p className="text-[30px] font-semibold">{data.totalWorkforce}</p>
          </div>
          {/* card 2 */}
          <div className=" border border-black p-3 rounded-xl">
            <div className="flex justify-between items-center">
              <div>
                <p className="mt-5 font-semibold">Driver Document Uploading</p>
              </div>
              <div>
                <FiAlertOctagon className="w-8 h-8 text-green-400" />
              </div>
            </div>
            <Link to="/Approval-process-02/document-uploading-driver">
              <button className=" text-white p-6 border border-[#069855] rounded-[25px] bg-[#069855] mt-5">
                view
              </button>
            </Link>
            <p className="text-[30px] font-semibold">{data.totalWorkforce}</p>
          </div>
        </div>
      </div>

      {/* <div className="overflow-x-auto">
        <Approve_01_documents_uploading_table />
      </div> */}

      {/* Add documents_uploading Popup */}
      {isOpen && (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-gray-800 bg-opacity-50">
          <div className="w-3/4 p-6 bg-white rounded-lg shadow-lg">
            {/* <--Popup togglePopup={togglePopup} /> */}
          </div>
        </div>
      )}
    </div>
  );
};

export default Documents_Uploading;
