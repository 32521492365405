/** @format */


import React, { useState, useEffect, useCallback } from "react";

const Quality_management_permission = ({ onPermissionsChange, userPermissions }) => {

  const [isVisible, setIsVisible] = useState(false);
  const [checkedValues, setCheckedValues] = useState({});

  const toggleVisibility = () => {
    setIsVisible(!isVisible);
  };
  //that shoulde change
  useEffect(() => {
    if (userPermissions && userPermissions.length > 0) {
      const initialCheckedValues = userPermissions.reduce((acc, curr) => {
        acc[String(curr)] = true;
        return acc;
      }, {});
      setCheckedValues(initialCheckedValues);
      console.log("Initialized checked values:", initialCheckedValues);
    }
  }, [userPermissions]);

  const permissionHierarchy = {
    19: { children: [300, 301, 302, 303, 304, 305, 306, 307] },
    300: { children: [8000, 8001, 8002, 8003, 8004] },
    301: { children: [8005, 8006, 8007, 8008, 8009] },
    302: { children: [6000, 6001] },
    303: { children: [8016, 8017,8018,8025,8026] },
    304: { children: [6002, 6003, 6004] },
    305: { children: [8023, 8024]},
    306: {chailden: [6005,6006]},
    307: {chailden: [6007,6008]},
    6000: { children: [8010, 8012, 8014] },
    6001: { children: [8011, 8013,8015] },
    6002: { children: [8021, 8019] },
    6003: { children: [8020, 8022] },
    6005: {children:[8027,8028,8029]},
    6006: {children:[8030,8031,8032]},
    6007: {children:[8033,8034,8035]},
    6008: {children:[8036,8037,8038]},
  };


  const handleCheckboxChange = (value, dependentValue) => {
    setCheckedValues((prev) => {
      const newValues = { ...prev, [value]: !prev[value] };

      if (dependentValue && !prev[dependentValue]) {
        newValues[dependentValue] = true;
      }

      // Automatically check parent checkboxes if child checkbox is checked
      if (newValues[value]) {
        Object.keys(permissionHierarchy).forEach((key) => {
          if (permissionHierarchy[key]?.children.includes(value)) {
            newValues[key] = true;
          }
        });
      }

      // Automatically check child checkboxes if parent checkbox is checked
      if (permissionHierarchy[value] && newValues[value]) {
        permissionHierarchy[value].children.forEach((child) => {
          newValues[child] = true;
        });
      }

      return newValues;
    });
  };

  const updatePermissions = useCallback(() => {
    const selectedPermissions = Object.keys(checkedValues)
      .filter((key) => checkedValues[key])
      .map(Number);

    if (
      selectedPermissions.some((perm) =>
        permissionHierarchy[19].children.includes(perm)
      ) &&
      !selectedPermissions.includes(19)
    ) {
      selectedPermissions.push(19);
    }

    onPermissionsChange(selectedPermissions);
  }, [checkedValues, onPermissionsChange]);

  useEffect(() => {
    updatePermissions();
  }, [checkedValues, updatePermissions]);

  

  return (
    <div>
      <div>
        <div className="">
          <p className="font-bold text-[20px] ml-[20%] mt-4 underline">
            Quality Management
          </p>
          <div className="ml-[20%]">
            <button
              className="bg-blue-500 p-3 mt-5 text-white font-semibold rounded-[30px]"
              onClick={toggleVisibility}
            >
              {isVisible ? "Hide" : "Show"} Details
            </button>
          </div>
        </div>
        <div>
          {isVisible && (
            <div className="ml-[20%] mt-5 grid grid-cols-3 gap-10">
              <div>
                <p className="bg-gray-200 rounded-[20px] p-5 font-bold text-[15px] text-black">
                  Sample Allocation
                </p>
                <div className="ml-5 mt-2">
                  <label className="block mb-2 text-blue-500">
                    <input
                      type="checkbox"
                      className="mr-2"
                      value="300"
                      checked={!!checkedValues[300]}
                      onChange={() => handleCheckboxChange(300)}
                    />
                    Sample Allocation
                  </label>
                  <div className="ml-[9%] grid grid-cols-2 grid-flow-row gap-1">
                    <div className="text-black">
                      <label className="block mb-2">
                        <input
                          type="checkbox"
                          className="mr-2"
                          value="8003"
                          checked={!!checkedValues[8003]}
                          onChange={() => handleCheckboxChange( 8003)}
                        />
                        pdf export
                      </label>
                    </div>
                    <div className="text-black">
                      <label className="block mb-2">
                        <input
                          type="checkbox"
                          className="mr-2"
                          value="8004"
                          checked={!!checkedValues[8004]}
                          onChange={() => handleCheckboxChange( 8004)}
                        />
                        Excel export
                      </label>
                    </div>
                    <div className="text-black">
                      <label className="block mb-2">
                        <input
                          type="checkbox"
                          className="mr-2"
                          value="8000"
                          checked={!!checkedValues[8000]}
                          onChange={() => handleCheckboxChange( 8000)}
                        />
                        Add sample
                      </label>
                    </div>
                    <div className="text-black">
                      <label className="block mb-2">
                        <input
                          type="checkbox"
                          className="mr-2"
                          value="8001"
                          checked={!!checkedValues[8001]}
                          onChange={() => handleCheckboxChange( 8001)}
                        />
                        Edit
                      </label>
                    </div>
                    <div className="text-black">
                      <label className="block mb-2">
                        <input
                          type="checkbox"
                          className="mr-2"
                          value="8002"
                          checked={!!checkedValues[8002]}
                          onChange={() => handleCheckboxChange( 8002)}
                        />
                        Delete
                      </label>
                    </div>
                  </div>
                </div>
              </div>

              <div>
                <p className="bg-gray-200 p-5 rounded-[20px] font-bold text-[15px] text-black">
                  Sample Testing
                </p>

                <div className="ml-5 mt-2">
                  <label className="block mb-2 text-blue-500">
                    <input
                      type="checkbox"
                      className="mr-2"
                      value="301"
                      checked={!!checkedValues[301]}
                      onChange={() => handleCheckboxChange(301)}
                    />
                    Sample Testing
                  </label>
                  <div className="ml-[9%] grid grid-cols-2 grid-flow-row gap-1">
                    <div className="text-black">
                      <label className="block mb-2">
                        <input
                          type="checkbox"
                          className="mr-2"
                          value="8008"
                          checked={!!checkedValues[8008]}
                          onChange={() => handleCheckboxChange(8008)}
                        />
                        pdf export
                      </label>
                    </div>
                    <div className="text-black">
                      <label className="block mb-2">
                        <input
                          type="checkbox"
                          className="mr-2"
                          value="8009"
                          checked={!!checkedValues[8009]}
                          onChange={() => handleCheckboxChange(8009)}
                        />
                        Excel export
                      </label>
                    </div>
                    <div className="text-black">
                      <label className="block mb-2">
                        <input
                          type="checkbox"
                          className="mr-2"
                          value="8005"
                          checked={!!checkedValues[8005]}
                          onChange={() => handleCheckboxChange(8005)}
                        />
                        Add sample
                      </label>
                    </div>
                    <div className="text-black">
                      <label className="block mb-2">
                        <input
                          type="checkbox"
                          className="mr-2"
                          value="8006"
                          checked={!!checkedValues[8006]}
                          onChange={() => handleCheckboxChange(8006)}
                        />
                        Edit
                      </label>
                    </div>
                    <div className="text-black">
                      <label className="block mb-2">
                        <input
                          type="checkbox"
                          className="mr-2"
                          value="8007"
                          checked={!!checkedValues[8007]}
                          onChange={() => handleCheckboxChange(8007)}
                        />
                        Delete
                      </label>
                    </div>
                  </div>
                </div>
              </div>

              <div>
                <p className="bg-gray-200 p-5 rounded-[20px] font-bold text-[15px] text-black">
                  Sample Test Reports
                </p>

                <div className="ml-5 mt-2">
                  <label className="block mb-2 text-blue-500">
                    <input
                      type="checkbox"
                      className="mr-2"
                      value="6000"
                      checked={!!checkedValues[6000]}
                      onChange={() => handleCheckboxChange(6000)}
                    />
                    Tender Quality Test Reports
                  </label>
                  <div className="ml-[9%] grid grid-cols-2 grid-flow-row gap-1">
                    <div className="text-black">
                      <label className="block mb-2">
                        <input
                          type="checkbox"
                          className="mr-2"
                          value="8010"
                          checked={!!checkedValues[8010]}
                          onChange={() => handleCheckboxChange(8010)}
                        />
                        pdf export
                      </label>
                    </div>

                    <div className="text-black">
                      <label className="block mb-2">
                        <input
                          type="checkbox"
                          className="mr-2"
                          value="8012"
                          checked={!!checkedValues[8012]}
                          onChange={() => handleCheckboxChange(8012)}
                        />
                        Excel export
                      </label>
                    </div>

                    <div className="text-black">
                      <label className="block mb-2">
                        <input
                          type="checkbox"
                          className="mr-2"
                          value="8014"
                          checked={!!checkedValues[8014]}
                          onChange={() => handleCheckboxChange(8014)}
                        />
                        pdf download
                      </label>
                    </div>
                  </div>
                </div>

                <div className="ml-5 mt-2">
                  <label className="block mb-2 text-blue-500">
                    <input
                      type="checkbox"
                      className="mr-2"
                      value="6001"
                      checked={!!checkedValues[6001]}
                      onChange={() => handleCheckboxChange(6001)}
                    />
                    Bulk Quality Test Reports
                  </label>
                  <div className="ml-[9%] grid grid-cols-2 grid-flow-row gap-1">
                    <div className="text-black">
                      <label className="block mb-2">
                        <input
                          type="checkbox"
                          className="mr-2"
                          value="8011"
                          checked={!!checkedValues[8011]}
                          onChange={() => handleCheckboxChange(8011)}
                        />
                        pdf export
                      </label>
                    </div>

                    <div className="text-black">
                      <label className="block mb-2">
                        <input
                          type="checkbox"
                          className="mr-2"
                          value="8013"
                          checked={!!checkedValues[8013]}
                          onChange={() => handleCheckboxChange(8013)}
                        />
                        Excel export
                      </label>
                    </div>

                    
                    <div className="text-black">
                      <label className="block mb-2">
                        <input
                          type="checkbox"
                          className="mr-2"
                          value="8015"
                          checked={!!checkedValues[8015]}
                          onChange={() => handleCheckboxChange(8015)}
                        />
                         pdf download
                      </label>
                    </div>
                  </div>
                </div>
              </div>

              <div>
                <p className="bg-gray-200 p-5 rounded-[20px] font-bold text-[15px] text-black">
                  Disposal Process
                </p>

                <div className="ml-5 mt-2">
                  <label className="block mb-2 text-blue-500">
                    <input
                      type="checkbox"
                      className="mr-2"
                      value="303"
                      checked={!!checkedValues[303]}
                      onChange={() => handleCheckboxChange(303)}
                    />
                    Disposal Process
                  </label>

                  <div className="ml-5 mt-2">
                    <div className="ml-[9%] grid grid-cols-2 grid-flow-row gap-1">
                      <div className="text-black">
                        <label className="block mb-2">
                          <input
                            type="checkbox"
                            className="mr-2"
                            value="8016"
                            checked={!!checkedValues[8016]}
                            onChange={() => handleCheckboxChange(8016)}
                          />
                          Add Disposal
                        </label>
                      </div>
                    </div>

                    <div className="ml-[9%] grid grid-cols-2 grid-flow-row gap-1">
                      <div className="text-black">
                        <label className="block mb-2">
                          <input
                            type="checkbox"
                            className="mr-2"
                            value="8025"
                            checked={!!checkedValues[8025]}
                            onChange={() => handleCheckboxChange(8025)}
                          />
                        Edit Disposal
                        </label>
                      </div>
                    </div>

                    <div className="ml-[9%] grid grid-cols-2 grid-flow-row gap-1">
                      <div className="text-black">
                        <label className="block mb-2">
                          <input
                            type="checkbox"
                            className="mr-2"
                            value="8026"
                            checked={!!checkedValues[8026]}
                            onChange={() => handleCheckboxChange(8026)}
                          />
                          Delete Disposal
                        </label>
                      </div>
                    </div>

                    <div className="ml-[9%] grid grid-cols-2 grid-flow-row gap-1">
                      <div className="text-black">
                        <label className="block mb-2">
                          <input
                            type="checkbox"
                            className="mr-2"
                            value="8018"
                            checked={!!checkedValues[8018]}
                            onChange={() => handleCheckboxChange(8018)}
                          />
                          Excel Export
                        </label>
                      </div>

                      <div className="text-black">
                        <label className="block mb-2">
                          <input
                            type="checkbox"
                            className="mr-2"
                            value="8017"
                            checked={!!checkedValues[8017]}
                            onChange={() => handleCheckboxChange(8017)}
                          />
                          PDF Export
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div>
                <p className="bg-gray-200 p-5 rounded-[20px] font-bold text-[15px] text-black">
                  Training Management
                </p>

                <div className="ml-5 mt-2">
                  <label className="block mb-2 text-blue-500">
                    <input
                      type="checkbox"
                      className="mr-2"
                      value="6002"
                      checked={!!checkedValues[6002]}
                      onChange={() => handleCheckboxChange(6002)}
                    />
                    Completed Training
                  </label>
                  <div className="ml-[9%] grid grid-cols-2 grid-flow-row gap-1">
                    <div className="text-black">
                      <label className="block mb-2">
                        <input
                          type="checkbox"
                          className="mr-2"
                          value="8019"
                          checked={!!checkedValues[8019]}
                          onChange={() => handleCheckboxChange(8019)}
                        />
                        pdf export
                      </label>
                    </div>

                    <div className="text-black">
                      <label className="block mb-2">
                        <input
                          type="checkbox"
                          className="mr-2"
                          value="8021"
                          checked={!!checkedValues[8021]}
                          onChange={() => handleCheckboxChange(8021)}
                        />
                        Excel export
                      </label>
                    </div>
                  </div>
                </div>

                <div className="ml-5 mt-2">
                  <label className="block mb-2 text-blue-500">
                    <input
                      type="checkbox"
                      className="mr-2"
                      value="6003"
                      checked={!!checkedValues[6003]}
                      onChange={() => handleCheckboxChange(6003)}
                    />
                    Upcoming Training
                  </label>
                  <div className="ml-[9%] grid grid-cols-2 grid-flow-row gap-1">
                    <div className="text-black">
                      <label className="block mb-2">
                        <input
                          type="checkbox"
                          className="mr-2"
                          value="8020"
                          checked={!!checkedValues[8020]}
                          onChange={() => handleCheckboxChange(8020)}
                        />
                        pdf export
                      </label>
                    </div>

                    <div className="text-black">
                      <label className="block mb-2">
                        <input
                          type="checkbox"
                          className="mr-2"
                          value="8022"
                          checked={!!checkedValues[8022]}
                          onChange={() => handleCheckboxChange(8022)}
                        />
                        Excel export
                      </label>
                    </div>
                  </div>
                </div>

                <div className="ml-5 mt-2">
                  <label className="block mb-2 text-blue-500">
                    <input
                      type="checkbox"
                      className="mr-2"
                      value="6004"
                      checked={!!checkedValues[6004]}
                      onChange={() => handleCheckboxChange(6004)}
                    />
                    Count Card
                  </label>
                </div>
              </div>

              <div>
                <p className="bg-gray-200 p-5 rounded-[20px] font-bold text-[15px] text-black">
                  Training & Certifications
                </p>

                <div className="ml-5 mt-2">
                  <label className="block mb-2 text-blue-500">
                    <input
                      type="checkbox"
                      className="mr-2"
                      value="305"
                      checked={!!checkedValues[305]}
                      onChange={() => handleCheckboxChange(305)}
                    />
                    Training & Certifications
                  </label>
                  <div className="ml-[9%] grid grid-cols-2 grid-flow-row gap-1">
                    <div className="text-black">
                      <label className="block mb-2">
                        <input
                          type="checkbox"
                          className="mr-2"
                          value="8023"
                          checked={!!checkedValues[8023]}
                          onChange={() => handleCheckboxChange(8023)}
                        />
                        pdf export
                      </label>
                    </div>

                    <div className="text-black">
                      <label className="block mb-2">
                        <input
                          type="checkbox"
                          className="mr-2"
                          value="8024"
                          checked={!!checkedValues[8024]}
                          onChange={() => handleCheckboxChange(8024)}
                        />
                        Excel export
                      </label>
                    </div>
                  </div>
                </div>
              </div>

              <div>
                <p className="bg-gray-200 p-5 rounded-[20px] font-bold text-[15px] text-black">
                Approval Satge One
                </p>

                <div className="ml-5 mt-2">
                  <label className="block mb-2 text-blue-500">
                    <input
                      type="checkbox"
                      className="mr-2"
                      value="6005"
                      checked={!!checkedValues[6005]}
                      onChange={() => handleCheckboxChange(6005)}
                    />
                    Sample Testing Report
                  </label>
                  <div className="ml-[9%] grid grid-cols-2 grid-flow-row gap-1">
                    <div className="text-black">
                      <label className="block mb-2">
                        <input
                          type="checkbox"
                          className="mr-2"
                          value="8027"
                          checked={!!checkedValues[8027]}
                          onChange={() => handleCheckboxChange(8027)}
                        />
                        Approve
                      </label>
                    </div>

                    <div className="text-black">
                      <label className="block mb-2">
                        <input
                          type="checkbox"
                          className="mr-2"
                          value="8028"
                          checked={!!checkedValues[8028]}
                          onChange={() => handleCheckboxChange(8028)}
                        />
                        Reject
                      </label>
                    </div>

                    <div className="text-black">
                      <label className="block mb-2">
                        <input
                          type="checkbox"
                          className="mr-2"
                          value="8029"
                          checked={!!checkedValues[8029]}
                          onChange={() => handleCheckboxChange(8029)}
                        />
                        View Move
                      </label>
                    </div>
                  </div>
                </div>

                <div className="ml-5 mt-2">
                  <label className="block mb-2 text-blue-500">
                    <input
                      type="checkbox"
                      className="mr-2"
                      value="6006"
                      checked={!!checkedValues[6006]}
                      onChange={() => handleCheckboxChange(6006)}
                    />
                     Manual  Disposal
                  </label>
                  <div className="ml-[9%] grid grid-cols-2 grid-flow-row gap-1">
                    <div className="text-black">
                      <label className="block mb-2">
                        <input
                          type="checkbox"
                          className="mr-2"
                          value="8030"
                          checked={!!checkedValues[8030]}
                          onChange={() => handleCheckboxChange(8030)}
                        />
                        Approve
                      </label>
                    </div>

                    <div className="text-black">
                      <label className="block mb-2">
                        <input
                          type="checkbox"
                          className="mr-2"
                          value="8013"
                          checked={!!checkedValues[8031]}
                          onChange={() => handleCheckboxChange(8031)}
                        />
                        reject
                      </label>
                    </div>

                    
                    <div className="text-black">
                      <label className="block mb-2">
                        <input
                          type="checkbox"
                          className="mr-2"
                          value="8038"
                          checked={!!checkedValues[8038]}
                          onChange={() => handleCheckboxChange(8038)}
                        />
                         view move
                      </label>
                    </div>
                  </div>
                </div>
              </div>


              <div>
                <p className="bg-gray-200 p-5 rounded-[20px] font-bold text-[15px] text-black">
                Approval Satge two
                </p>

                <div className="ml-5 mt-2">
                  <label className="block mb-2 text-blue-500">
                    <input
                      type="checkbox"
                      className="mr-2"
                      value="6007"
                      checked={!!checkedValues[6007]}
                      onChange={() => handleCheckboxChange(6007)}
                    />
                    Sample Testing Report
                  </label>
                  <div className="ml-[9%] grid grid-cols-2 grid-flow-row gap-1">
                    <div className="text-black">
                      <label className="block mb-2">
                        <input
                          type="checkbox"
                          className="mr-2"
                          value="8033"
                          checked={!!checkedValues[8033]}
                          onChange={() => handleCheckboxChange(8033)}
                        />
                        Approve
                      </label>
                    </div>

                    <div className="text-black">
                      <label className="block mb-2">
                        <input
                          type="checkbox"
                          className="mr-2"
                          value="8034"
                          checked={!!checkedValues[8034]}
                          onChange={() => handleCheckboxChange(8034)}
                        />
                        Reject
                      </label>
                    </div>

                    <div className="text-black">
                      <label className="block mb-2">
                        <input
                          type="checkbox"
                          className="mr-2"
                          value="8035"
                          checked={!!checkedValues[8035]}
                          onChange={() => handleCheckboxChange(8035)}
                        />
                        View Move
                      </label>
                    </div>
                  </div>
                </div>

                <div className="ml-5 mt-2">
                  <label className="block mb-2 text-blue-500">
                    <input
                      type="checkbox"
                      className="mr-2"
                      value="6008"
                      checked={!!checkedValues[6008]}
                      onChange={() => handleCheckboxChange(6008)}
                    />
                     Manual  Disposal
                  </label>
                  <div className="ml-[9%] grid grid-cols-2 grid-flow-row gap-1">
                    <div className="text-black">
                      <label className="block mb-2">
                        <input
                          type="checkbox"
                          className="mr-2"
                          value="8036"
                          checked={!!checkedValues[8036]}
                          onChange={() => handleCheckboxChange(8036)}
                        />
                        Approve
                      </label>
                    </div>

                    <div className="text-black">
                      <label className="block mb-2">
                        <input
                          type="checkbox"
                          className="mr-2"
                          value="8037"
                          checked={!!checkedValues[8037]}
                          onChange={() => handleCheckboxChange(8037)}
                        />
                        reject
                      </label>
                    </div>

                    
                    <div className="text-black">
                      <label className="block mb-2">
                        <input
                          type="checkbox"
                          className="mr-2"
                          value="8038"
                          checked={!!checkedValues[8038]}
                          onChange={() => handleCheckboxChange(8038)}
                        />
                         view move
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Quality_management_permission;
