/** @format */

import React, { useEffect, useState } from "react";
import "./createItem.css";

export default function CreateItem() {
  const currentDate = new Date(); // Get the current date and time
  const formattedDate = currentDate.toISOString(); // Format the date to ISO string
  const [userId, setUserId] = useState("USER-000000");
  const [itemCategoryData, setItemCategoryData] = useState([]);
  const { REACT_APP_API_ENDPOINT } = process.env;
  const [fetchKey, setFetchKey] = useState(0);
  const [selectedItemCategory, setSelectedItemCategory] =
    useState("Item Category Type");

  const [alert, setAlert] = useState({
    status: "",
    alert: "",
    show: false,
  });

  const [data, setData] = useState({
    item_name: "",
    item_category_id: "",
    item_measure_unit: "",
    item_description: "",
    item_update_date: formattedDate,
    item_update_user_id: userId,
    item_status: "ACTIVE",
  });

  const fetchData = async () => {
    try {
      const response = await fetch(
        `${REACT_APP_API_ENDPOINT}/v1/inventory/itemCategory/get-couple-category`
      );
      const data = await response.json();
      setItemCategoryData(data);
      console.log("Items:", itemCategoryData);
    } catch (error) {
      console.error("Error fetching Item Category data:", error);
    }
  };

  useEffect(() => {
    fetchData();
  }, [fetchKey]);

  const SubmitHandler = async () => {
    console.log("crete data:", data);
    try {
      const res = await fetch(`${process.env.REACT_APP_BACKEND_URL}/item/add`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          item_name: data.item_name,
          item_category_id: data.item_category_id, // Include item_category_id
          item_measure_unit: data.item_measure_unit,
          item_description: data.item_description,
          item_update_date: data.item_update_date, // Include update date
          item_update_user_id: data.item_update_user_id, // Include update user ID
          item_status: data.item_status, // Include item status
        }),
      });
      const result = await res.json();
      console.log(result);
      if (res.status === 200 || res.status === 201) {
        window.alert("Item Created Successfully");
        ResetHandler();
      } else {
        console.log("Error:", result);
      }
    } catch (error) {
      setAlert({
        status: "error",
        alert: "Something Went Wrong",
        show: true,
      });
    }
  };

  const ResetHandler = () => {
    setData({
      item_name: "",
      item_category_id: "",
      item_measure_unit: "",
      item_description: "",
      item_update_date: formattedDate,
      item_update_user_id: userId,
      item_status: "ACTIVE",
    });
  };
  return (
    <div className="p-10 bg-background h-screen overflow-y-auto">
      <div className="mb-20">
        <p className="header-1">Create Item</p>
      </div>
      <div className="bg-[#F3F8FF] rounded-[46px] border border-[#00000036] py-11">
        <div className="text-center my-5 mb-20">
          <p className="header-1">Enter Item Details</p>
        </div>
        <div className="flex justify-between">
          <div className="CreateItem-info-div">
            <div className="CreateItem-form ml-5">
              <label className="label-1">Item Name</label>
              <label className="label-1">:</label>
              <input
                type="text"
                className="input-1 border border-[#00000036]"
                value={data.item_name}
                onChange={(e) => {
                  const Data = { ...data };
                  Data.item_name = e.target.value;
                  setData(Data);
                }}
              />
            </div>
            <div className="CreateItem-form ml-5">
              <label className="label-1">Item Category Type</label>
              <label className="label-1">:</label>
              <select
                className="input-1 border border-[#00000036]"
                value={data.item_category_id} // Bind to data.item_category_id
                onChange={(e) => {
                  const selectedValue = e.target.value;
                  setData({
                    ...data,
                    item_category_id: selectedValue, // Update item_category_id in data
                  });
                }}
              >
                <option value="">Item Category Type</option>
                {itemCategoryData.map((items, index) => (
                  <option key={index} value={items.sub_category_id}>
                    {items.item_category_name} - {items.item_sub_category_name}
                  </option>
                ))}
              </select>
            </div>
          </div>
          <div className="CreateItem-info-div mr-5">
            <div className="CreateItem-form ml-5">
              <label className="label-1">Item Measure Unit</label>
              <label className="label-1">:</label>
              <select
                className="input-1 border border-[#00000036]"
                value={data.item_measure_unit}
                onChange={(e) => {
                  const Data = { ...data };
                  Data.item_measure_unit = e.target.value;
                  setData(Data);
                }}
              >
                <option value="">select measure unit</option>
                <option value="kg">kg</option>
                <option value="l">l</option>
                <option value="pcs">pcs</option>
              </select>
            </div>
            <div className="CreateItem-form ml-5">
              <label className="label-1">Item Description</label>
              <label className="label-1">:</label>
              <input
                type="text"
                className="input-1 border border-[#00000036]"
                value={data.item_description}
                onChange={(e) => {
                  const Data = { ...data };
                  Data.item_description = e.target.value;
                  setData(Data);
                }}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="flex items-center justify-end gap-x-6 mb-30 mt-10 px-[100px]">
        <button
          className="w-[118px] h-[45px] flex items-center justify-center bg-[#5B6D49] border rounded-[23px] px-4"
          onClick={SubmitHandler}
        >
          <p className="text-white">ADD</p>
        </button>
        <button
          className="w-[118px] h-[45px] flex items-center justify-center bg-[#ab2626] border rounded-[23px] px-4"
          onClick={ResetHandler}
        >
          <p className="text-white">CANCEL</p>
        </button>
      </div>
    </div>
  );
}
