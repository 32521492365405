import React, {useState} from "react";
import jsPDF from "jspdf";

const SalesInvoiceDetailModal = ({ isOpen, onClose, invoice }) => {
    const [pdfPreviewUrl, setPdfPreviewUrl] = useState(null);
    if (!isOpen || !invoice) return null;

    const generatePDF = () => {
        const doc = new jsPDF();

        // Adding sales invoice details to the PDF
        doc.setFontSize(20);
        doc.text("Sales Invoice Details", 20, 20);
        doc.setFontSize(12);
        doc.text(`Invoice No: ${invoice.invoice_no}`, 20, 40);
        doc.text(`Customer: ${invoice.customer}`, 20, 50);
        doc.text(`Invoice Date: ${new Date(invoice.invoice_date).toLocaleDateString()}`, 20, 60);
        doc.text(`Payment Due Date: ${new Date(invoice.payment_due_date).toLocaleDateString()}`, 20, 70);
        doc.text(`Grand Total: ${invoice.grand_total}`, 20, 80);
        doc.text(`Description: ${invoice.description}`, 20, 90);

        // Adding invoice items
        doc.text("Invoice Items:", 20, 110);
        invoice.sales_invoice_items.forEach((item, index) => {
            const yPosition = 120 + (index * 10);
            doc.text(`Item ID: ${item.item_id}, Item Name: ${item.item_name}, Quantity: ${item.quantity}`, 20, yPosition);
        });

        // Save PDF to a blob and create a URL for preview
        const pdfBlob = doc.output("blob");
        const url = URL.createObjectURL(pdfBlob);
        setPdfPreviewUrl(url);
    };

    const downloadPDF = () => {
        const doc = new jsPDF();

        // Populate the PDF with invoice details again for download
        doc.setFontSize(20);
        doc.text("Sales Invoice Details", 20, 20);
        doc.setFontSize(12);
        doc.text(`Invoice No: ${invoice.invoice_no}`, 20, 40);
        doc.text(`Customer: ${invoice.customer}`, 20, 50);
        doc.text(`Invoice Date: ${new Date(invoice.invoice_date).toLocaleDateString()}`, 20, 60);
        doc.text(`Payment Due Date: ${new Date(invoice.payment_due_date).toLocaleDateString()}`, 20, 70);
        doc.text(`Grand Total: ${invoice.grand_total}`, 20, 80);
        doc.text(`Description: ${invoice.description}`, 20, 90);

        // Adding invoice items
        doc.text("Invoice Items:", 20, 110);
        invoice.sales_invoice_items.forEach((item, index) => {
            const yPosition = 120 + (index * 10);
            doc.text(`Item ID: ${item.item_id}, Item Name: ${item.item_name}, Quantity: ${item.quantity}`, 20, yPosition);
        });

        // Download PDF
        doc.save(`Invoice-${invoice.invoice_no}.pdf`);
    };

    return (
        <div className="fixed inset-0 flex items-center justify-center bg-gray-900 bg-opacity-50 z-50 overflow-y-auto">
            <div className="bg-white rounded-lg w-3/4 max-w-6xl max-h-[95vh] p-6 relative overflow-hidden">
                <button
                    onClick={onClose}
                    className="absolute top-4 right-4 text-gray-500 hover:text-gray-700"
                >
                    Close
                </button>

                <div className="overflow-y-auto max-h-[100vh] pr-4">
                    <h2 className="text-2xl font-bold mb-4">Sales Invoice Details</h2>
                    <div className="mb-4">
                        <p><strong>Invoice ID:</strong> {invoice?.sales_invoice_id}</p>
                        <p><strong>Invoice No:</strong> {invoice?.invoice_no}</p>
                        <p>
                            <strong>Customer:</strong>{invoice.customer ? `${invoice?.customer} - ${invoice?.customer_name}` : ""}
                        </p>
                        <p><strong>Invoice
                            Date:</strong> {invoice.invoice_date ? new Date(invoice.invoice_date).toLocaleDateString() : "N/A"}
                        </p>
                        <p><strong>Payment Due
                            Date:</strong> {invoice.payment_due_date ? new Date(invoice.payment_due_date).toLocaleDateString() : "N/A"}
                        </p>
                        <p><strong>Grand Total:</strong> {invoice?.grand_total}</p>
                        <p><strong>Settled amount:</strong> {invoice?.settled_amount}</p>
                        <p><strong>Unsettled amount:</strong> {invoice?.unsettled_amount}</p>
                        <p><strong>Description:</strong> {invoice?.description}</p>
                        <p><strong>Debited
                            account:</strong> {`${invoice?.debit_account_code} - ${invoice.debit_account_name}`}</p>
                        <p><strong>Credited
                            account:</strong> {`${invoice?.credit_account_code} - ${invoice.credit_account_name}`}</p>
                    </div>

                    <h3 className="text-xl font-semibold mb-2">Invoice Items</h3>
                    <table className="min-w-full bg-white border border-gray-200">
                        <thead>
                        <tr className="bg-gray-200">
                            <th className="px-4 py-2 text-left">Item ID</th>
                            <th className="px-4 py-2 text-left">Item Name</th>
                            <th className="px-4 py-2 text-left">Quantity</th>
                        </tr>
                        </thead>
                        <tbody>
                        {invoice.sales_invoice_items.map((item) => (
                            <tr key={item.item_id} className="border-t">
                                <td className="px-4 py-2">{item.item_id}</td>
                                <td className="px-4 py-2">{item.item_name}</td>
                                <td className="px-4 py-2">{item.quantity}</td>
                            </tr>
                        ))}
                        </tbody>
                    </table>

                    <div className="mt-8 flex space-x-4">
                        <button
                            onClick={generatePDF}
                            className="px-6 py-3 bg-blue-600 text-white rounded-lg hover:bg-blue-700 text-lg"
                        >
                            Preview Invoice
                        </button>
                        <button
                            onClick={downloadPDF}
                            className="px-6 py-3 bg-green-600 text-white rounded-lg hover:bg-green-700 text-lg"
                        >
                            Download Invoice
                        </button>
                    </div>

                    {pdfPreviewUrl && (
                        <div className="mt-6">
                            <h3 className="text-2xl font-semibold mb-4">PDF Preview</h3>
                            <iframe
                                src={pdfPreviewUrl}
                                width="100%"
                                height="400"
                                title="Invoice Preview"
                                className="border border-gray-300"
                            />
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default SalesInvoiceDetailModal;
