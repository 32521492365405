import React, { useEffect, useState } from "react";
import { IoIosCloudUpload } from "react-icons/io";
import FileUpload from "./upload_files";
import { IoIosCloseCircleOutline } from "react-icons/io";
import axios from "axios";
import Notification from "../../../../components/notification/notification";

function AddDriverPopup({ togglePopup }) {
  const backendUrl = process.env.REACT_APP_VEHICLE_FLEET_BACKEND_URL;

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [driverDetails, setDriverDetails] = useState({
    employee_number: "",
    license_number: "",
    driver_id: "",
    employee_fullname: "",
    employee_contact_no: "",
    employee_email: "",
    files: [],
  });
  const [filteredEmployees, setFilteredEmployees] = useState([]);
  const [showSuggestions, setShowSuggestions] = useState(false);
  const [employeeNumbers, setEmployeeNumbers] = useState([]);
  const [isResetConfirmationOpen, setIsResetConfirmationOpen] = useState(false);
  const [isSaveConfirmationOpen, setIsSaveConfirmationOpen] = useState(false);
  const [notification, setNotification] = useState({ message: "", type: "" });

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  // get all employee number
  const getDriverID = async () => {
    try {
      const response = await axios.get(
        `${backendUrl}/driver/get-all-drivers-employee-number`
      );
      const data = response.data;
      console.log(data);

      setEmployeeNumbers(data);
    } catch (error) {
      console.error("Error fetching designations:", error);
    }
  };

  useEffect(() => {
    getDriverID();
  }, []);

  //Selected  Employee Number
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setDriverDetails((prevDetails) => ({
      ...prevDetails,
      [name]: value,
    }));

    if (name === "employee_number" && value.length > 0) {
      // Filter employee numbers that match the input format
      const filtered = employeeNumbers.filter((employeeNumber) =>
        employeeNumber.toLowerCase().includes(value.toLowerCase())
      );
      setFilteredEmployees(filtered);
      setShowSuggestions(true);
    } else {
      setShowSuggestions(false);
    }
  };

  // Employee Number Auto suggestion
  const handleSuggestionClick = (suggestion) => {
    console.log("Data:", suggestion);
    setDriverDetails((prevDetails) => ({
      ...prevDetails,
      employee_number: suggestion,
    }));
    setShowSuggestions(false);
    fetchDriverDetails(suggestion);
    fetchLastDriverID();
  };

  // File State Clear
  const handleRemoveFile = (index) => {
    setUploadedFiles((prevFiles) => prevFiles.filter((_, i) => i !== index));
  };

  // Auto Generating Driver ID
  const fetchLastDriverID = async () => {
    try {
      const response = await axios.get(
        `${backendUrl}/driver/get-last-driver-id`
      );
      const { lastDriverId } = response.data;
      const newDriverId = `D${(parseInt(lastDriverId.slice(1)) + 1)
        .toString()
        .padStart(3, "0")}`;
      setDriverDetails((prevDetails) => ({
        ...prevDetails,
        driver_id: newDriverId,
      }));
    } catch (error) {
      console.error("Error fetching last driver ID:", error);
      // setErrorMessage("Failed to fetch last driver ID");
    }
  };

  // get employeeNo for data
  const fetchDriverDetails = async (employeeNo) => {
    try {
      const response = await fetch(
        `${backendUrl}/driver/get-driver-data-for-Employee-Number?employee_no=${employeeNo}`
      );

      if (!response.ok) {

        setNotification({
          message: 'Error get deta',
          type: "error",
        });      }

      const data = await response.json();

      setDriverDetails((prevDetails) => ({
        ...prevDetails,
        employee_number: employeeNo,
        employee_fullname: data.employee_fullname,
        employee_contact_no: data.employee_contact_no,
        employee_email: data.employee_email,
      }));
    } catch (error) {
      console.log(error)
      // toast.error("failed to fetch driver details");
    }
  };

  const handleOpenResetConfirmation = () => {
    setIsResetConfirmationOpen(true);
  };

  const handleOpenSaveConfirmation = () => {
    setDriverDetails((prevDetails) => ({
      ...prevDetails,
      files: uploadedFiles,
    }));
    setIsSaveConfirmationOpen(true);
  };

  const handleCloseSaveConfirmation = () => {
    setIsSaveConfirmationOpen(false);
  };

  const handleCloseResetConfirmation = () => {
    setIsResetConfirmationOpen(false);
  };

  // Save Form
  const handleSaveForm = async () => {
    console.log(driverDetails);

    try {
      // Create a FormData object
      const formData = new FormData();

      // Append vehicle details (non-file fields)
      for (const key in driverDetails) {
        if (driverDetails.hasOwnProperty(key)) {
          formData.append(key, driverDetails[key]);
        }
      }

      // If you have files to upload (e.g., vehicle registration documents)
      if (driverDetails.registration_document) {
        formData.append(
          "registration_document",
          driverDetails.registration_document
        ); // Adjust the key to match your backend
      }

      // Send the POST request with multipart/form-data
      const response = await axios.post(
        `${backendUrl}/driver/upload-details`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      console.log(response.data);
      setNotification({
        message: response.data,
        type: "success",
      });

      // Handle success notification if needed
    } catch (error) {
      console.log(error);
      if (error.response && error.response.status === 400) {
        // Safely access error messages by checking if they exist
        const errorMessage =
          (error.response.data[0] && error.response.data[0].msg) ||
          error.response.data.error ||
          error.response.data.message ||
          "An unexpected error occurred";

        setNotification({
          message: errorMessage,
          type: "error",
        });
      }
    }

    setIsSaveConfirmationOpen(false);
  };

  // Handle Notification Close
  const handleCloseNotification = () => {
    setNotification({ message: "", type: "" });
  };

  // use State Reset
  const handleResetForm = () => {
    setDriverDetails({
      employee_number: "",
      license_number: "",
      driver_id: "",
      employee_fullname: "",
      employee_contact_no: "",
      employee_email: "",
      files: [],
    });
    setUploadedFiles([]);
    setIsResetConfirmationOpen(false);
  };

  return (
    <div className="flex items-center justify-center h-full bg-gray-100">
      <div className="relative w-full p-8 bg-white rounded-lg">
        <div className="flex items-center justify-between mb-6">
          <h2 className="text-2xl font-semibold">Add Driver</h2>
          <button
            type="button"
            onClick={togglePopup}
            className="p-4 text-4xl text-gray-500 transition duration-300 hover:text-gray-700"
          >
            <IoIosCloseCircleOutline />
          </button>
        </div>

        <form>
          <div className="items-center gap-8 mt-5">
            <div className="grid grid-cols-2 gap-y-[30px] gap-x-[60px] text-[20px]">
              {/* Employee Number */}
              <div className="relative">
                <label className="block text-gray-700">Employee Number</label>
                <input
                  type="text"
                  name="employee_number"
                  className="w-full p-2 mt-2 border border-gray-300 rounded"
                  value={driverDetails.employee_number}
                  onChange={handleInputChange}
                />
                {showSuggestions && filteredEmployees.length > 0 && (
                  <ul className="absolute z-10 w-full mt-1 overflow-y-auto bg-white border border-gray-300 rounded-lg shadow-lg max-h-40">
                    {filteredEmployees.map((suggestion, index) => (
                      <li
                        key={index}
                        className="p-2 cursor-pointer hover:bg-gray-200"
                        onClick={() => handleSuggestionClick(suggestion)}
                      >
                        {suggestion}
                      </li>
                    ))}
                  </ul>
                )}
              </div>

              {/* Notification Alert */}
              <Notification
                message={notification.message}
                type={notification.type}
                onClose={handleCloseNotification}
              />
              {/* Driver Name */}
              <div>
                <label className="block text-gray-700">Driver Name</label>
                <input
                  type="text"
                  name="employee_fullname"
                  className="w-full p-2 mt-2 border border-gray-300 rounded"
                  value={driverDetails.employee_fullname}
                  readOnly
                />
              </div>

              {/* Phone Number */}
              <div>
                <label className="block text-gray-700">Phone Number</label>
                <input
                  type="text"
                  name="employee_contact_no"
                  className="w-full p-2 mt-2 border border-gray-300 rounded"
                  value={driverDetails.employee_contact_no}
                  readOnly
                />
              </div>

              {/* Email Address */}
              <div>
                <label className="block text-gray-700">Email Address</label>
                <input
                  type="text"
                  name="employee_email"
                  className="w-full p-2 mt-2 border border-gray-300 rounded"
                  value={driverDetails.employee_email}
                  readOnly
                />
              </div>

              {/* License Number */}
              <div>
                <label className="block text-gray-700">License Number</label>
                <input
                  type="text"
                  name="license_number"
                  className="w-full p-2 mt-2 border border-gray-300 rounded"
                  value={driverDetails.license_number}
                  onChange={handleInputChange}
                />
              </div>

              {/* Driver ID */}
              <div>
                <label className="block text-gray-700">Driver ID</label>
                <input
                  type="text"
                  name="driver_id"
                  className="w-full p-2 mt-2 border border-gray-300 rounded"
                  value={driverDetails.driver_id}
                  onChange={handleInputChange}
                />
              </div>
            </div>
          </div>

          <h1 className="text-[30px] font-bold mb-8 mt-10">
            Driver Registration Document
          </h1>
          <button
            type="button"
            onClick={handleOpenModal}
            className="p-3 border border-[#069855] rounded-[25px] text-[#069855]"
          >
            <div className="flex items-center gap-2">
              <div className="text-[15px] font-bold">Click Here to Upload</div>
              <IoIosCloudUpload />
            </div>
          </button>

          {uploadedFiles.length > 0 && (
            <div className="mt-5">
              <h3 className="mb-2 text-xl font-bold">Selected Files</h3>
              {uploadedFiles.map((file, index) => (
                <div
                  key={index}
                  className="flex items-center justify-between p-2 mb-2 bg-yellow-100 border rounded-lg"
                >
                  <span>{file.name}</span>
                  <button
                    onClick={() => handleRemoveFile(index)}
                    className="px-3 py-1 text-white bg-red-500 rounded"
                  >
                    Remove
                  </button>
                </div>
              ))}
            </div>
          )}
        </form>

        <div className="mt-5">
          <div className="flex items-center justify-between px-4 py-2">
            <div></div>
            <div className="flex gap-4">
              <button
                type="button"
                className="px-4 py-2 text-green-600 bg-white border border-black rounded-xl"
                onClick={handleOpenResetConfirmation}
              >
                Reset
              </button>
              <button
                type="submit"
                className="px-4 py-2 text-white bg-green-600 border border-black rounded-xl"
                onClick={handleOpenSaveConfirmation}
              >
                Save
              </button>
            </div>
          </div>
        </div>

        {isModalOpen && (
          <div className="fixed top-0 left-0 z-50 flex items-center justify-center w-full h-full bg-gray-200 bg-opacity-80">
            <div className="p-8 bg-white rounded-lg">
              <FileUpload
                setUploadedFiles={setUploadedFiles}
                uploadedFiles={uploadedFiles}
                onClose={handleCloseModal}
              />
              <button
                className="p-3 py-2 mt-4 text-white bg-red-500 rounded-lg"
                onClick={handleCloseModal}
              >
                Close
              </button>
            </div>
          </div>
        )}

        {isResetConfirmationOpen && (
          <div className="fixed top-0 left-0 z-50 flex items-center justify-center w-full h-full bg-gray-200 bg-opacity-80">
            <div className="p-8 bg-white rounded-lg">
              <h2 className="mb-4 text-xl font-bold">Confirm Reset</h2>
              <p>
                Are you sure you want to reset the form? This action cannot be
                undone.
              </p>
              <div className="flex justify-end gap-4 mt-6">
                <button
                  className="px-4 py-2 text-white bg-red-500 rounded-xl"
                  onClick={handleResetForm}
                >
                  Yes, Reset
                </button>
                <button
                  className="px-4 py-2 text-gray-600 bg-white border border-gray-300 rounded-xl"
                  onClick={handleCloseResetConfirmation}
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        )}

        {isSaveConfirmationOpen && (
          <div className="fixed top-0 left-0 z-50 flex items-center justify-center w-full h-full bg-gray-200 bg-opacity-80">
            <div className="p-8 bg-white rounded-lg">
              <h2 className="mb-4 text-xl font-bold">Confirm Save</h2>
              <p>Are you sure you want to save the form data?</p>
              <div className="flex justify-end gap-4 mt-6">
                <button
                  className="px-4 py-2 text-white bg-green-500 rounded-xl"
                  onClick={handleSaveForm}
                >
                  Yes, Save
                </button>
                <button
                  className="px-4 py-2 text-gray-600 bg-white border border-gray-300 rounded-xl"
                  onClick={handleCloseSaveConfirmation}
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
      
    </div>
  );
}

export default AddDriverPopup;
