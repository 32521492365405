import React, { useRef } from "react";
import jsPDF from "jspdf";
import { useLocation } from "react-router-dom";
import html2canvas from "html2canvas";
import Drug_Logo from "../../../../Assets/icons/drug-logo.png";

const Purchase_Order_PDF = () => {
  const location = useLocation();
  console.log("Location Data:", location.state);
  const committeeData = location.state.PurchaseData;
  const tableData = location.state.purchaseItems;
  const { REACT_APP_API_ENDPOINT } = process.env;

  // const tableData = location.state.testing_samples;
  // const sampleData = location.state;
  // console.log("PDF data:", sampleData);
  const formRef = useRef(null);

  const downloadPdf = () => {
    const input = formRef.current;
    html2canvas(input, { useCORS: true })
      .then((canvas) => {
        const imgData = canvas.toDataURL("image/png");
        const pdf = new jsPDF("p", "mm", "a4");
        const pdfWidth = pdf.internal.pageSize.getWidth();
        const pdfHeight = (canvas.height * pdfWidth) / canvas.width;
        pdf.addImage(imgData, "PNG", 0, 0, pdfWidth, pdfHeight);
        pdf.save("OpeningPriceForm.pdf");
      })
      .catch((error) => {
        console.error("Error generating PDF:", error);
      });
  };

  return (
    <div className="flex justify-center mt-10">
      <button
        onClick={downloadPdf}
        className="bg-blue-500 text-white w-[100px] h-[100px] py-2 px-4 rounded"
      >
        Download Form as PDF
      </button>
      <div ref={formRef} id="form-to-print">
        <div className="py-[70px] px-[150px] font-sans">
          <div className="flex justify-center">
            <img
              className="w-[634px] h-[113px]"
              src={Drug_Logo}
              alt="drug-coop-logo"
            />
          </div>
          <div className="flex justify-center mt-8">
            <p className="font-semibold text-[#2D499B] text-[27px]">
              Sample Testing Committee Report
            </p>
          </div>
          <hr
            className="bg-[#2D499B] mx-auto mt-2 rounded-xl"
            style={{ height: "5px", width: "200px", border: "none" }}
          />
          <div className="mt-10 text-[18px] font-semibold">
            <div>
              <div className=" items-center mt-4">
                <div className="flex  box-border w-full p-4 border-2 rounded-[30px] border-black ">
                  <div className="grid grid-cols-2 gap-6">
                    <div>
                      <p>Supplier:</p>
                    </div>
                    <div>
                      <input
                        className="border border-black rounded-[30px] h-10"
                        value={committeeData.supplier_name}
                        disabled
                      ></input>
                    </div>
                    <div className=" row-span-3">
                      <p>Address:</p>
                    </div>
                    <div className=" row-span-3">
                      {" "}
                      <textarea
                        className="border border-black rounded-[20px] h-[140px] w-full text-justify"
                        value={committeeData.address}
                        disabled
                      ></textarea>
                    </div>
                  </div>
                  <div className="inline-block min-h-full w-[1.4px] self-stretch bg-black ml-2">
                    {" "}
                  </div>

                  <div className="grid grid-cols-2 gap-6 ml-4">
                    <div>
                      <p>Purchase Order No:</p>
                    </div>
                    <div>
                      <input
                        className="border border-black rounded-[30px] h-10 "
                        value={committeeData.po_id}
                        disabled
                      ></input>
                    </div>

                    <div>
                      <p>Issue Date:</p>
                    </div>
                    <div>
                      <input
                        className="border border-black rounded-[30px] h-10"
                        value={committeeData.po_issue_date}
                        disabled
                      ></input>
                    </div>
                    <div>Reference No:</div>
                    <div>
                      <input
                        className="border border-black rounded-[30px] h-10"
                        value={committeeData.reference_no}
                        disabled
                      ></input>
                    </div>
                    <div>Delivery Date:</div>
                    <div>
                      <input
                        className="border border-black rounded-[30px] h-10"
                        value={committeeData.delivery_date}
                        disabled
                      ></input>
                    </div>
                  </div>
                </div>
              </div>
              <div className=" gap-4 items-center mt-4">
                <div className="grid grid-cols-6 grid-flow-row gap-6 box-border w-full p-4 border-2 rounded-[30px] border-black">
                  <div className="text-[15px]">
                    <p>Number</p>
                    {/* <div className="border border-black rounded-[30px] h-10"></div> */}
                  </div>
                  <div className="text-[15px]">
                    <p>Item ID</p>
                    {/* <div className="border border-black rounded-[30px] h-10"></div> */}
                  </div>
                  <div className="text-[15px]">
                    <p>Unit</p>
                    {/* <div className="border border-black rounded-[30px] h-10"></div> */}
                  </div>

                  <div className="text-[15px]">
                    <p>Quantity</p>
                    {/* <div className="border border-black rounded-[30px] h-10"></div> */}
                  </div>

                  <div className="text-[15px]">
                    <p>Rate</p>
                    {/* <div className="border border-black rounded-[30px] h-10"></div> */}
                  </div>

                  <div className="text-[15px]">
                    <p>Amount</p>
                    {/* <div className="border border-black rounded-[30px] h-10"></div> */}
                  </div>
                  <div className="gird grid-cols-1">
                    {tableData.map((samples, index) => (
                      <div key={index}>
                        <div className="border border-black rounded-[30px] h-10 mt-2 flex">
                          <input
                            value={index + 1}
                            disabled
                            className="text-center w-full h-full"
                          />
                        </div>
                      </div>
                    ))}
                  </div>
                  <div className="gird grid-cols-1">
                    {tableData.map((samples, index) => (
                      <div key={index}>
                        <div className="border border-black rounded-[30px] h-10 mt-2 flex">
                          <input
                            value={samples.item_id}
                            disabled
                            className="text-center w-full h-full"
                          />
                        </div>
                      </div>
                    ))}
                  </div>
                  <div className="gird grid-cols-1">
                    {tableData.map((samples, index) => (
                      <div key={index}>
                        <div className="border border-black rounded-[30px] h-10 mt-2 flex">
                          <input
                            value={samples.item_measure_unit}
                            disabled
                            className="text-center w-full h-full"
                          />
                        </div>
                      </div>
                    ))}
                  </div>
                  <div className="gird grid-cols-1">
                    {tableData.map((samples, index) => (
                      <div key={index}>
                        <div className="border border-black rounded-[30px] h-10 mt-2 flex">
                          <input
                            value={samples.item_qty}
                            disabled
                            className="text-center w-full h-full"
                          />
                        </div>
                      </div>
                    ))}
                  </div>
                  <div className="gird grid-cols-1">
                    {tableData.map((samples, index) => (
                      <div key={index}>
                        <div className="border border-black rounded-[30px] h-10 mt-2 flex">
                          <input
                            value={samples.rate}
                            disabled
                            className="text-center w-full h-full"
                          />
                        </div>
                      </div>
                    ))}
                  </div>
                  <div className="gird grid-cols-1">
                    {tableData.map((samples, index) => (
                      <div key={index}>
                        <div className="border border-black rounded-[30px] h-10 mt-2 flex">
                          <input
                            value={samples.amount}
                            disabled
                            className="text-center w-full h-full"
                          />
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>

              <div className="box-border w-full p-4 border-2 rounded-[30px] border-black mt-4 flex">
                <dvi className="grid grid-cols-2 gap-4">
                  <div className=" col-span-2 text-[#0041FF] text-[40px]">
                    Approved By Chairman
                  </div>
                  <hr class=" h-[2px] border-t-0 bg-black col-span-2" />
                  <div>
                    <p className=" flex justify-center">Amount in</p>
                    <p className=" flex justify-center">Words</p>
                  </div>
                  <div>
                    <textarea
                      className="border border-black rounded-[20px] mr-4 w-[200px] overflow-hidden"
                      value={committeeData.amount_in_words}
                      disabled
                    ></textarea>
                  </div>
                </dvi>

                <div className="inline-block min-h-full w-[1.4px] self-stretch bg-black">
                  {" "}
                </div>

                <div className="grid grid-cols-2">
                  <div className=" flex justify-end">
                    <p>Total Order(Rs):</p>
                  </div>
                  <div>
                    <input
                      className="border border-black rounded-[30px] h-10 mr-4 ml-4"
                      value={committeeData.total_order}
                      disabled
                    ></input>
                  </div>

                  <div className="flex justify-end">
                    <p>Total Discount(Rs):</p>
                  </div>
                  <div>
                    <input
                      className="border border-black rounded-[30px] h-10 mr-4 ml-4"
                      value={committeeData.total_discount}
                      disabled
                    ></input>
                  </div>

                  <div className="flex justify-end">
                    <p>Total VAT(Rs):</p>
                  </div>
                  <div>
                    <input
                      className="border border-black rounded-[30px] h-10 mr-4 ml-4"
                      value={committeeData.total_vat}
                      disabled
                    ></input>
                  </div>

                  <div className=" flex justify-end">
                    <p>Total (Rs):</p>
                  </div>
                  <div>
                    <input
                      className="border border-black rounded-[30px] h-10 mr-4 ml-4"
                      value={committeeData.total}
                      disabled
                    ></input>
                  </div>
                </div>
              </div>

              <div className="mt-10">
                <div className="grid grid-cols-3 grid-flow-row gap-4">
                  <div className=" text-center ">
                    <p>Prepared By:</p>
                    <div className="border border-black rounded-[30px] h-[80px] flex  justify-center">
                      {" "}
                      <img
                        className="h-[80px]"
                        src={`${REACT_APP_API_ENDPOINT}/v1/supplyChain/sample_testing/getFileByFileName/${committeeData.prepared_img}`}
                        alt=""
                      />
                    </div>
                    <input
                      disabled
                      value={committeeData.prepared_by}
                      className="text-center"
                    />
                    <input
                      value={committeeData.prepared_date}
                      disabled
                      className="text-center"
                    />
                  </div>
                  <div className="text-center">
                    <p>Checked By:</p>
                    <div className="border border-black rounded-[30px] h-[80px] flex justify-center ">
                      {" "}
                      <img
                        className="h-[80px]"
                        src={`${REACT_APP_API_ENDPOINT}/v1/supplyChain/sample_testing/getFileByFileName/${committeeData.checked_img}`}
                        alt=""
                      />
                    </div>
                    <input
                      disabled
                      value={committeeData.checked_by}
                      className="text-center"
                    />
                    <input
                      value={committeeData.checked_date}
                      disabled
                      className="text-center"
                    />
                  </div>
                  <div className=" text-center">
                    <p className=" text-center">Approved By:</p>
                    <div className="border border-black rounded-[30px] h-[80px] flex justify-center">
                      {" "}
                      <img
                        className="h-[80px]"
                        src={`${REACT_APP_API_ENDPOINT}/v1/supplyChain/sample_testing/getFileByFileName/${committeeData.approved_img}`}
                        alt=""
                      />
                    </div>
                    <input
                      disabled
                      value={committeeData.approved_by}
                      className="text-center"
                    />
                    <input
                      value={committeeData.approved_date}
                      disabled
                      className="text-center"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Purchase_Order_PDF;
