/** @format */

import React, { useEffect } from "react";
import { useState } from "react";
// import Navbar from "../navbar/navbar";
import moment from "moment";
import axios from "axios";
import { IoIosCloseCircleOutline, IoIosCloudUpload } from "react-icons/io";
import "react-datepicker/dist/react-datepicker.css"; // Ensure you have this import for DatePicker styling
import GooglePlacesAutocomplete from "react-google-places-autocomplete";
import Notification from "../../../../components/notification/notification";
import { useParams } from "react-router-dom";

const { REACT_APP_GOOGLE_MAPS_KEY } = process.env;
const Add_Manager_Transportation_Schedules = ({ togglePopup }) => {
  const backendUrl = process.env.REACT_APP_VEHICLE_FLEET_BACKEND_URL;
  const [isResetConfirmationOpen, setIsResetConfirmationOpen] = useState(false);
  const [isSaveConfirmationOpen, setIsSaveConfirmationOpen] = useState(false);
  const [driverMap, setDriverMap] = useState({});
  const [vehicleMap, setVehicleMap] = useState({});
  const [driverDetails, setDriverDetails] = useState([]);
  const [allManagerDetails,setAllManagerDetails] = useState([0])
  const [vehicleDetails, setVehicleDetails] = useState([]);
  const [notification, setNotification] = useState({ message: "", type: "" });
  const [managerDetails, setManagerDetails] = useState({
    schedule_id: "",
    manager_id: "",
    manager_name: "",
    driver_id: "",
     destination_date:"",
    driver_name: "",
    department: "",
    departure_location: "",
    departure_point: "",
    destination_locations: [
      {
        destination_location: "",
        destination_points: "",
      },
    ],
    departure_date: "",
     destination_time:"",
    journey_status: "",
    departure_time: "",
    purpose_of_transport: "",
    vehicle_id: "",
    estimated_amount_of_fuel: "",
    estimated_fuel_cost: "",
    estimated_km: "",
    arrival_date:"",
    arrival_time:""
  });

  const handleOpenResetConfirmation = () => {
    setIsResetConfirmationOpen(true);
  };

  const handleOpenSaveConfirmation = () => {
    setIsSaveConfirmationOpen(true);
  };

  const handleCloseSaveConfirmation = () => {
    setIsSaveConfirmationOpen(false);
  };

  const handleCloseResetConfirmation = () => {
    setIsResetConfirmationOpen(false);
  };

  const fetchDriverDetails = async () => {
    const params = {
      departure_date: managerDetails.departure_date,
      arrival_date: managerDetails.destination_date,
      departure_time: managerDetails.departure_time,
      arrival_time: managerDetails.destination_time,
    };
  
    try {
      const response = await axios.get(`${backendUrl}/driver/getDriverByDate`, {
        params, // Pass params in the Axios config
      });
  
      console.log(params);
      console.log(response.data);
  
      // No need for response.ok, Axios returns an error for non-2xx status codes
      if (response.status !== 200) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
  
      const data = response.data; // Axios automatically parses JSON response
      console.log("driver details:", data);
      setDriverDetails(data);
  
      // Create a map of driver_id to driver details
      const map = data.reduce((acc, driver) => {
        acc[driver.driver_id] = driver;
        return acc;
      }, {});
      setDriverMap(map);
    } catch (error) {
      console.error("Error fetching driver details:", error);
    }
  };
  
    // Define a function to fetch Driver IDs
    const fetchManegerIDAndName = async () => {
      try {
        const response = await axios(
          `${backendUrl}/managerRoutesSchedule/getAllManager`
        );
        // console.log(response.data)
        setAllManagerDetails(response.data);
      } catch (error) {
        console.error("Error fetching maneger id :", error);
      }
    };

    const SchduleManegerID = async () => {
      try {
        const response = await fetch(
          `${backendUrl}/managerRoutesSchedule/getLastManagerRoutesID`
        );
  
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
  
        const data = await response.json();
        console.log("driver details:", data);
        setManagerDetails((prevDetails) => ({
          ...prevDetails,
          schedule_id: data.lastManagerRouteId,
        }));
  
       
      } catch (error) {
        console.error("Error fetching maneger id :", error);
      }
    }
  

  // Define a function to fetch vehicle IDs
  // const fetchVehicleDetails = async () => {
  //   try {

  //     const params = {
  //       departure_date: managerDetails.departure_date,
  //       arrival_date: managerDetails.destination_date,
  //       departure_time: managerDetails.departure_time,
  //       arrival_time: managerDetails.destination_time,
  //     };


  //     const response = await axios(
  //       `${backendUrl}/vehicle/allVehiclesbyDate?date=${encodeURIComponent(
  //         currentDate
  //       )}`
  //     );

  //     if (!response.ok) {
  //       throw new Error(`HTTP error! Status: ${response.status}`);
  //     }

  //     const data = await response.json();
  //     console.log("vehcile details:", data);
  //     setVehicleDetails(data);

  //     // Create a map of driver_id to driver details
  //     const map = data.reduce((acc, vehicle) => {
  //       acc[vehicle.vehicle_id] = vehicle;
  //       return acc;
  //     }, {});
  //     setVehicleMap(map);
  //   } catch (error) {
  //     console.error("Error fetching vehicle details:", error);
  //   }
  // };

  // Define a function to fetch vehicle IDs
const fetchVehicleDetails = async () => {
  try {
    const params = {
      departure_date: managerDetails.departure_date,
      arrival_date: managerDetails.destination_date, // Corrected this
      departure_time: managerDetails.departure_time,
      arrival_time: managerDetails.destination_time, // Corrected this
    };

    const response = await axios.get(
      `${backendUrl}/vehicle/allVehiclesbyDate`,
      {
        params
      }
    );

    console.log(params);

    // Axios handles JSON parsing automatically
    const data = response.data;
    console.log("vehicle details:", data);
    setVehicleDetails(data);

    // Create a map of vehicle_id to vehicle details
    const map = data.reduce((acc, vehicle) => {
      acc[vehicle.vehicle_id] = vehicle;
      return acc;
    }, {});
    setVehicleMap(map);
  } catch (error) {
    console.error("Error fetching vehicle details:", error);
  }
};


  // Save Form
  const handleSaveForm = async () => {
    console.log(managerDetails);
  
    try {
      const response = await axios.post(
        `${backendUrl}/managerRoutesSchedule`,
        managerDetails,
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
        }
      );
  
      // Show success notification
      setNotification({
        message: "Form submitted successfully!",
        type: "success",
      });
  
    } catch (error) {
      console.log(error);
  
      let errorMessage = "An unexpected error occurred";
  
      if (error.response) {
        if (error.response.status === 500) {
          errorMessage = error.response.data.error;
        } else if (error.response.status === 400) {
          // Access first error message if available
          errorMessage = error.response.data[0]?.msg || error.response.data.error || errorMessage;
        }
      }
  
      // Show error notification
      setNotification({
        message: errorMessage,
        type: "error",
      });
    }
    
    setIsSaveConfirmationOpen(false);
  };
  

  // use State Reset
  const handleResetForm = () => {
    setManagerDetails({
      schedule_id: "",
      manager_id: "",
      manager_name: "kasun perera",
      driver_id: "",
     destination_date:"",
      driver_name: "",
      department: "",
      departure_location: "",
      departure_point: "",
      destination_locations: [
        {
          destination_location: "",
          destination_points: "",
        },
      ],
      departure_date: "",
       destination_time:"",
      journey_status: "",
      departure_time: "",
      purpose_of_transport: "",
      vehicle_id: "",
      estimated_amount_of_fuel: "",
      estimated_fuel_cost: "",
      estimated_km: "",
    });
    setIsResetConfirmationOpen(false);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setManagerDetails((prevDetails) => ({
      ...prevDetails,
      [name]: value,
    }));
  };

  // const handleDepartureLocationChange = (fieldName, place) => {
  //   console.log(place);
  //   setManagerDetails((prevDetails) => ({
  //     ...prevDetails,
  //     departure_location: place.label, // or any other place details you need
  //   }));
  // };

  // const handleDepartureLocationChange = (fieldName, place) => {
  //   console.log(place);
  //   if (place && place.value && place.value.geometry) {
  //     const latitude = place.value.geometry.location.lat();
  //     const longitude = place.value.geometry.location.lng();

  //     console.log("Place:", place);
  //     console.log("Latitude:", latitude, "Longitude:", longitude);

  //     // Update state with place label, latitude, and longitude
  //     setManagerDetails((prevDetails) => ({
  //       ...prevDetails,
  //       departure_location: place.label, // store the place label
  //       departure_point: `${(latitude, longitude)}`, // store latitude
  //     }));
  //   }
  // };

  const handleDepartureLocationChange = (fieldName, place) => {
    console.log(place);
    if (place && place.value && place.value.place_id) {
      const placeId = place.value.place_id;
  
      const service = new window.google.maps.places.PlacesService(
        document.createElement("div")
      );
  
      service.getDetails(
        { placeId: placeId },
        (result, status) => {
          if (status === window.google.maps.places.PlacesServiceStatus.OK) {
            const latitude = result.geometry.location.lat();
            const longitude = result.geometry.location.lng();
  
            console.log("Latitude:", latitude, "Longitude:", longitude);
  
            setManagerDetails((prevDetails) => ({
              ...prevDetails,
              departure_location: place.label, // store the place label
              departure_point: `${latitude}, ${longitude}`, // store latitude and longitude
            }));
          }
        }
      );
    }
  };
  

  const handleDestinationLocationChange = (fieldName, place) => {
    console.log(place);
    if (place && place.value && place.value.place_id) {
      const placeId = place.value.place_id;
  
      const service = new window.google.maps.places.PlacesService(
        document.createElement("div")
      );
  
      service.getDetails(
        { placeId: placeId },
        (result, status) => {
          if (status === window.google.maps.places.PlacesServiceStatus.OK) {
            const latitude = result.geometry.location.lat();
            const longitude = result.geometry.location.lng();
  
            console.log("Latitude:", latitude, "Longitude:", longitude);
  
            setManagerDetails((prevDetails) => {
              const updatedLocations = [...prevDetails.destination_locations]; // Get the current destination locations
              updatedLocations[0] = {  // You can use any index you want to update
                destination_location: place.label,
                destination_points: `${latitude}, ${longitude}`,
              };
  
              return {
                ...prevDetails,
                destination_locations: updatedLocations, // Update the array in state
              };
            });
          }
        }
      );
    }
  };
  
  const handleVehicleIDChange = (selectedValue) => {
    const vehicle = vehicleMap[selectedValue];
    if (vehicle) {
      setManagerDetails((prevDetails) => ({
        ...prevDetails,
        vehicle_id: vehicle.vehicle_id,
      }));
    }
  };

    // Handle Notification Close
    const handleCloseNotification = () => {
      setNotification({ message: "", type: "" });
    };

  const handleDriverIDChange = (selectedValue) => {
    const driver = driverMap[selectedValue];
    if (driver) {
      setManagerDetails((prevDetails) => ({
        ...prevDetails,
        driver_id: driver.driver_id,
        driver_name: driver.employee_fullname,
      }));
    }
  };

  const handleManagerIDChange = (selectedValue) => {
    console.log(selectedValue); // Log selected value (assumed to be manager number or ID)
    
    // Lookup manager by managerNo or another identifier
    const manager = allManagerDetails.find(
      (manager) => manager.managerNo === selectedValue
    );
    
    
    if (manager) {
      // Update manager details state
      setManagerDetails((prevDetails) => ({
        ...prevDetails,
        manager_id: manager.managerNo,
        manager_name: manager.managerFullname,
      }));
    }
  };
  

  useEffect(() => {
    // fetchManegerID();
    SchduleManegerID()
    // fetchDriverDetails();
    // fetchVehicleDetails();
    fetchManegerIDAndName()
  }, []);

  useEffect(() => {
    fetchDriverDetails();
    fetchVehicleDetails();
  }, [managerDetails.departure_date, managerDetails.arrival_date, managerDetails.departure_time, managerDetails.arrival_time ])


  return (
    <div className="fixed top-0 left-0 z-50 flex items-center justify-center w-full h-full bg-gray-200 bg-opacity-80">
      <div className="w-[80%] h-[90%] overflow-y-auto p-8 bg-white rounded-lg">
        <div className="flex items-center justify-between mb-6">
          <h2 className="text-2xl font-semibold">
            Add Manager Transportation Schedules
          </h2>
          <button
            type="button"
            onClick={togglePopup}
            className="p-4 text-4xl text-gray-500 transition duration-300 hover:text-gray-700"
          >
            <IoIosCloseCircleOutline />
          </button>
        </div>

                {/* Notification Alert */}
        <Notification
          message={notification.message}
          type={notification.type}
          onClose={handleCloseNotification}
        />


        <form>
          <div className="items-center gap-8 mt-5 overflow-y-auto">
            <div className="grid grid-cols-2 gap-y-[30px] gap-x-[60px] text-[20px] ">

              {/* Schedule ID */}
              <div>
                <label className="block text-gray-700">Schedule ID</label>
                <input
                  type="text"
                  name="schedule_id"
                  className="w-full p-2 mt-2 border border-gray-300 rounded"
                  value={managerDetails.schedule_id}
                  onChange={handleInputChange}
                  readOnly
                />
              </div>

            {/* Manager ID */}
              <div>
                <label className="block text-gray-700">Manager ID*</label>

                <select
                  name="manager_id"
                  className="w-full p-2 mt-2 border border-gray-300 rounded"
                  value={managerDetails.manager_id}
                  onChange={(e) => handleManagerIDChange(e.target.value)} // Handle change event
                >
                  <option value="" disabled>
                    Select the manager
                  </option>
                  {allManagerDetails.map((items, index) => (
                    <option key={index} value={items.managerNo}>
                      {items.managerNo} - {items.managerFullname}
                    </option>
                  ))}
                </select>
              </div>

           
        
              {/* Department */}
              <div>
                <label className="block text-gray-700">Department*</label>
                <select
                  name="department"
                  className="w-full p-2 mt-2 border border-gray-300 rounded"
                  value={managerDetails.department}
                  onChange={handleInputChange} // Handle change event
                >
                  <option value="" disabled>
                    Select the department
                  </option>
                  <option value="HR">HR</option>
                  <option value="Finance">Finance</option>
                  <option value="Sales">Sales</option>
                  <option value="Packing Section">Packing Section</option>
                  <option value="Production">Production</option>
                </select>
              </div>

            {/* Departure Location :- include location for this */}
              <div>
                <label className="block text-gray-700 mb-2">
                  Departure Location
                </label>
                <GooglePlacesAutocomplete
                  apiKey={REACT_APP_GOOGLE_MAPS_KEY} // Replace with your Google Maps API key
                  selectProps={{
                    onChange: (place) =>
                      handleDepartureLocationChange(
                        "departure_location",
                        place
                      ),
                    styles: {
                      input: (provided) => ({
                        ...provided,
                      }),
                    },
                  }}
                />
              </div>

              {/* Destination Location :- include location for this */}
              <div>
                <label className="block text-gray-700 mb-2">
                  Destination Location
                </label>
                <GooglePlacesAutocomplete
                  apiKey={REACT_APP_GOOGLE_MAPS_KEY} // Replace with your Google Maps API key
                  selectProps={{
                    onChange: (place) =>
                      handleDestinationLocationChange(
                        "destination_location",
                        place
                      ),
                    styles: {
                      input: (provided) => ({
                        ...provided,
                      }),
                    },
                  }}
                />
              </div>

              {/*  Destination Date*  */}
                {/* <div>
                <label className="block text-gray-700">Destination Date</label>
                <input
                  type="date"
                  name="destination_date"
                  className="w-full p-2 mt-2 border border-gray-300 rounded"
                  value={managerDetails.destination_date}
                  onChange={handleInputChange}
                />
              </div> */}

              {/* Destination Time  */}
              {/* <div>
                <label className="block text-gray-700">Destination Time</label>
                <input
                  type="time"
                  name="destination_time"
                  className="w-full p-2 mt-2 border border-gray-300 rounded"
                  value={managerDetails.destination_time}
                  onChange={handleInputChange}
                />
              </div> */}
              
              {/* Departure Date  */}
              <div>
                <label className="block text-gray-700">Departure Date</label>
                <input
                  type="date"
                  name="departure_date"
                  className="w-full p-2 mt-2 border border-gray-300 rounded"
                  value={managerDetails.departure_date}
                  onChange={handleInputChange}
                />
              </div>

            {/* Arrival Date  */}
              <div>
                <label className="block text-gray-700">Arrival Date </label>
                <input
                  type="date"
                  name="destination_date"
                  className="w-full p-2 mt-2 border border-gray-300 rounded"
                  value={managerDetails.destination_date}
                  onChange={handleInputChange}
                />
              </div>


                   {/* journey_status */}
                   <div>
                <label className="block text-gray-700">Journey Status</label>
                <input
                  type="text"
                  name="journey_status"
                  className="w-full p-2 mt-2 border border-gray-300 rounded"
                  value={managerDetails.journey_status}
                  onChange={handleInputChange}
                />
              </div>

              {/* Departure Time  */}
              <div>
                <label className="block text-gray-700">Departure Time</label>
                <input
                  type="time"
                  name="departure_time"
                  className="w-full p-2 mt-2 border border-gray-300 rounded"
                  value={managerDetails.departure_time}
                  onChange={handleInputChange}
                />
              </div>

            {/* arrival time  */}
              <div>
                <label className="block text-gray-700">Arrival Time</label>
                <input
                  type="time"
                  name="destination_time"
                  className="w-full p-2 mt-2 border border-gray-300 rounded"
                  value={managerDetails.destination_time}
                  onChange={handleInputChange}
                />
              </div>

              {/* purpose of transport */}
              <div>
                <label className="block text-gray-700"> Purpose of Transport</label>
                <input
                  type="text"
                  name="purpose_of_transport"
                  className="w-full p-2 mt-2 border border-gray-300 rounded"
                  value={managerDetails.purpose_of_transport}
                  onChange={handleInputChange}
                />
              </div>
              
              {/*  Vehicle ID */}
              <div>
                <label className="block text-gray-700">Vehicle ID*</label>

                <select
                  name="vehicle_id"
                  className="w-full p-2 mt-2 border border-gray-300 rounded"
                  value={managerDetails.vehicle_id}
                  onChange={(e) => handleVehicleIDChange(e.target.value)} // Handle change event
                >
                  <option value="" disabled>
                    Select the vehicle
                  </option>
                  {vehicleDetails.map((items, index) => (
                    <option key={index} value={items.vehicle_id}>
                      {items.vehicle_id} - {items.vehicle_no_plate_no}
                    </option>
                  ))}
                </select>
              </div>


                 {/* Driver ID */}
                 <div>
                <label className="block text-gray-700">Driver ID*</label>

                <select
                  name="driver_id"
                  className="w-full p-2 mt-2 border border-gray-300 rounded"
                  value={managerDetails.driver_id}
                  onChange={(e) => handleDriverIDChange(e.target.value)} // Handle change event
                >
                  <option value="" disabled>
                    Select the driver
                  </option>
                  {driverDetails.map((items, index) => (
                    <option key={index} value={items.driver_id}>
                      {items.driver_id} - {items.employee_fullname}
                    </option>
                  ))}
                </select>
              </div>

            
              {/* Estimated Amount Of Fuel Litres  */}
              <div>
                <label className="block text-gray-700">
                  Estimated Amount Of Fuel Litres
                </label>
                <input
                  type="text"
                  name="estimated_amount_of_fuel"
                  className="w-full p-2 mt-2 border border-gray-300 rounded"
                  value={managerDetails.estimated_amount_of_fuel}
                  onChange={handleInputChange}
                />
              </div>
              {/* estimated_cost_for_Fuel */}
              <div>
                <label className="block text-gray-700">
                Estimated fuel cost
                </label>
                <input
                  type="text"
                  name="estimated_fuel_cost"
                  className="w-full p-2 mt-2 border border-gray-300 rounded"
                  value={managerDetails.estimated_fuel_cost}
                  onChange={handleInputChange}
                />
              </div>
              {/* estimated_km_for_the_journey  */}
              <div>
                <label className="block text-gray-700">
                  Estimated km for the journey
                </label>
                <input
                  type="text"
                  name="estimated_km"
                  className="w-full p-2 mt-2 border border-gray-300 rounded"
                  value={managerDetails.estimated_km}
                  onChange={handleInputChange}
                />
              </div>
         
     
            </div>
          </div>
        </form>

        <div className="mt-5">
          <div className="flex items-center justify-between px-4 py-2">
            <div></div>
            <div className="flex gap-4">
              <button
                type="button"
                className="px-4 py-2 text-green-600 bg-white border border-black rounded-xl"
                onClick={handleOpenResetConfirmation}
              >
                Reset
              </button>
              <button
                type="submit"
                className="px-4 py-2 text-white bg-green-600 border border-black rounded-xl"
                onClick={handleOpenSaveConfirmation}
              >
                Save
              </button>
            </div>
          </div>
        </div>

        {isResetConfirmationOpen && (
          <div className="fixed top-0 left-0 z-50 flex items-center justify-center w-full h-full bg-gray-200 bg-opacity-80">
            <div className="p-8 bg-white rounded-lg">
              <h2 className="mb-4 text-xl font-bold">Confirm Reset</h2>
              <p>
                Are you sure you want to reset the form? This action cannot be
                undone.
              </p>
              <div className="flex justify-end gap-4 mt-6">
                <button
                  className="px-4 py-2 text-white bg-red-500 rounded-xl"
                  onClick={handleResetForm}
                >
                  Yes, Reset
                </button>
                <button
                  className="px-4 py-2 text-gray-600 bg-white border border-gray-300 rounded-xl"
                  onClick={handleCloseResetConfirmation}
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        )}

        {isSaveConfirmationOpen && (
          <div className="fixed top-0 left-0 z-50 flex items-center justify-center w-full h-full bg-gray-200 bg-opacity-80">
            <div className="p-8 bg-white rounded-lg">
              <h2 className="mb-4 text-xl font-bold">Confirm Save</h2>
              <p>Are you sure you want to save the form data?</p>
              <div className="flex justify-end gap-4 mt-6">
                <button
                  className="px-4 py-2 text-white bg-green-500 rounded-xl"
                  onClick={handleSaveForm}
                >
                  Yes, Save
                </button>
                <button
                  className="px-4 py-2 text-gray-600 bg-white border border-gray-300 rounded-xl"
                  onClick={handleCloseSaveConfirmation}
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Add_Manager_Transportation_Schedules;
