import axios from "axios";
import React, { useEffect, useState } from "react";
import { IoIosCloseCircleOutline } from "react-icons/io";
import Notification from "../../../components/notification/notification";
import Autocomplete from "./autocomplete";

const AddManualDisposeRecord = ({ togglePopup, resetTable }) => {
  const backendUrl = process.env.REACT_APP_BACKEND_URL_QUALITY;
  const [sampleDetails, setSampleDetails] = useState({
    dispose_id: "",
    sample_id: "",
    disposed_date: "",
    tested_date: ""
  });

  const [notification, setNotification] = useState({ message: "", type: "" });
  const [isResetConfirmationOpen, setIsResetConfirmationOpen] = useState(false);
  const [isSaveConfirmationOpen, setIsSaveConfirmationOpen] = useState(false);
  const [sampleIds, setSampleIds] = useState([]);

    // Handle input change
    const handleInputChange = (e, index = null) => {
      const { name, value } = e.target;
  
      if (index !== null) {
        const newStages = [...sampleDetails.qualityStages];
        newStages[index][name] = value;
        setSampleDetails((prevDetails) => ({
          ...prevDetails,
          qualityStages: newStages,
        }));
      } else {
        setSampleDetails((prevDetails) => ({
          ...prevDetails,
          [name]: value,
        }));
      }
    };


  // Fetch fetchLastDispostID
  const fetchAllSampleID = async () => {
    try {
      const response = await axios.get(`${backendUrl}/qualitySamples/getAllSampleId`);
       setSampleIds(response.data);
    } catch (error) {
      console.error("Error fetching sample IDs:", error);
    }
  };


  // Handle Save Form
  const handleSaveForm = async () => {
    console.log(sampleDetails)
    try {
      const response = await axios.post(
        `${backendUrl}/qulaityDisposals/addManualDisposal`,
        sampleDetails,
      
      );

      setNotification({
        message: "Sample details saved successfully!",
        type: "success",
      });

      resetTable(true);
    } catch (error) {
      console.error(error);
      setNotification({
        message: "Error saving sample details. Please try again.",
        type: "error",
      });
    }
    setIsSaveConfirmationOpen(false);
  };

  // Handle Reset Form
  const handleResetForm = () => {
    setSampleDetails({
      dispose_id: "",
      sample_id: "",
      disposed_date: "",
      tested_date: ""
    });
    setIsResetConfirmationOpen(false);
  };

  const handleSampleIDSelect = async (selectedOption) => {
    const sampleID = selectedOption ? selectedOption.value : "";
    
    setSampleDetails((prevDetails) => ({
      ...prevDetails,
      sample_id: sampleID,
    }));
  
    if (sampleID) {
      try {
        // Make GET request to fetch details based on the selected Sample ID
        const response = await axios.get(`${backendUrl}/qualitySamples/getSamples`, {
          params: { sample_id: sampleID },
        });
  

     
        // Update the form fields with the fetched data
        setSampleDetails((prevDetails) => ({
          ...prevDetails,
          sample_type:response.data[0].sample_type,
        }));

        console.log(sampleDetails)
      } catch (error) {
        console.error("Error fetching sample details:", error);
      }
    }
  };
  
  // Open Reset Confirmation
  const handleOpenResetConfirmation = () => {
    setIsResetConfirmationOpen(true);
  };

  // Close Reset Confirmation
  const handleCloseResetConfirmation = () => {
    setIsResetConfirmationOpen(false);
  };

  // Open Save Confirmation
  const handleOpenSaveConfirmation = () => {
    setIsSaveConfirmationOpen(true);
  };

  // Close Save Confirmation
  const handleCloseSaveConfirmation = () => {
    setIsSaveConfirmationOpen(false);
  };

  const fetchLastDisposalID = async () => {
    try {
      const response = await axios.get(`${backendUrl}/qulaityDisposals/lastDisposalId`);
      setSampleDetails((prevDetails) => ({
        ...prevDetails,
        dispose_id: response.data.lastDisposalId,
      }));
    } catch (error) {
      console.error("Error fetching sample IDs:", error);
    }
  }


  // Handle Notification Close
  const handleCloseNotification = () => {
    setNotification({ message: "", type: "" });
  };

    // Effect to fetch data on mount
    useEffect(() => {
      fetchAllSampleID();
      fetchLastDisposalID()
    }, []);
  
  


  return (
    <div className="fixed top-0 left-0 z-50 flex items-center justify-center w-full h-full bg-gray-200 bg-opacity-80">
      <div className="w-[80%] h-[60%] overflow-y-auto p-8 bg-white rounded-lg">
        <div className="flex items-center justify-between mb-6">
          <h2 className="text-2xl font-semibold">Add Manual Dispose Record</h2>
          <button
            type="button"
            onClick={togglePopup}
            className="p-4 text-4xl text-gray-500 transition duration-300 hover:text-gray-700"
          >
            <IoIosCloseCircleOutline />
          </button>
        </div>

          {/* Notification Alert */}
          <Notification
          message={notification.message}
          type={notification.type}
          onClose={handleCloseNotification}
        />
        <form>
          <div className="items-center gap-8 mt-5">
            <div className="grid grid-cols-2 gap-y-[30px] gap-x-[60px] text-[20px]">
              
               {/* Dispose ID*/}
               <div>
                <label className="block text-gray-700">Dispose ID*</label>
                <input
                  type="text"
                  name="dispose_id"
                  className="w-full p-2 mt-2 border border-gray-300 rounded"
                  value={sampleDetails.dispose_id}
                  onChange={handleInputChange}
                />
              </div>

              
              {/* Sample ID */}
              <div>
                <label className="block text-gray-700">Sample ID*</label>
                <Autocomplete
                  suggestions={sampleIds} // Use the mock data for demonstration
                  onSelect={handleSampleIDSelect}
                />
              </div>

              {/* Tested date */}
              <div>
                <label className="block text-gray-700">Tested date*</label>
                <input
                  type="date"
                  name="tested_date"
                  className="w-full p-2 mt-2 border border-gray-300 rounded"
                  value={sampleDetails.tested_date}
                  onChange={handleInputChange}
                />
              </div>
                {/* Expiry date */}
              <div>
                <label className="block text-gray-700">Tested date*</label>
                <input
                  type="date"
                  name="disposed_date"
                  className="w-full p-2 mt-2 border border-gray-300 rounded"
                  value={sampleDetails.disposed_date}
                  onChange={handleInputChange}
                />
              </div>



            </div>
          </div>

        </form>

        {/* Buttons */}
        <div className="flex justify-end gap-4 mt-8">
          <button
            type="button"
            onClick={handleOpenSaveConfirmation}
            className="px-4 py-2 text-white bg-green-500 rounded hover:bg-green-600"
          >
            Save
          </button>

          <button
            type="button"
            onClick={handleOpenResetConfirmation}
            className="px-4 py-2 text-white bg-red-500 rounded hover:bg-red-600"
          >
            Reset
          </button>
        </div>
      </div>

      {/* Confirmation Modals */}
      {isResetConfirmationOpen && (
        <div className="fixed top-0 left-0 z-50 flex items-center justify-center w-full h-full bg-gray-200 bg-opacity-80">
          <div className="p-8 bg-white rounded-lg">
            <h3 className="mb-4 text-lg font-semibold">Are you sure you want to reset the form?</h3>
            <div className="flex justify-end gap-4">
              <button
                type="button"
                onClick={handleCloseResetConfirmation}
                className="px-4 py-2 text-white bg-gray-500 rounded hover:bg-gray-600"
              >
                Cancel
              </button>
              <button
                type="button"
                onClick={handleResetForm}
                className="px-4 py-2 text-white bg-red-500 rounded hover:bg-red-600"
              >
                Reset
              </button>
            </div>
          </div>
        </div>
      )}

      {isSaveConfirmationOpen && (
        <div className="fixed top-0 left-0 z-50 flex items-center justify-center w-full h-full bg-gray-200 bg-opacity-80">
          <div className="p-8 bg-white rounded-lg">
            <h3 className="mb-4 text-lg font-semibold">Are you sure you want to save the form?</h3>
            <div className="flex justify-end gap-4">
              <button
                type="button"
                onClick={handleCloseSaveConfirmation}
                className="px-4 py-2 text-white bg-gray-500 rounded hover:bg-gray-600"
              >
                Cancel
              </button>
              <button
                type="button"
                onClick={handleSaveForm}
                className="px-4 py-2 text-white bg-green-500 rounded hover:bg-green-600"
              >
                Save
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  )
};

export default AddManualDisposeRecord;
