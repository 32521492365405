import axios from "axios";
import React, { useEffect, useState } from "react";
import { IoIosCloseCircleOutline } from "react-icons/io";
import Notification from "../../../components/notification/notification";

const Edit_chat_account_popup = ({ editePopup, row }) => {
  const backendUrl = process.env.REACT_APP_BACKEND_URL_FINANCE;
  const [editChatOfAccount, setEditChatOfAccount] = useState({
    account_code: "",
    account_name: "",
    currency: "",
    description: "",
    action_by:"",
    account_id: "",

  });

  const [isResetConfirmationOpen, setIsResetConfirmationOpen] = useState(false);
  const [isSaveConfirmationOpen, setIsSaveConfirmationOpen] = useState(false);
  const [notification, setNotification] = useState({ message: "", type: "" });


  // Handle Input Change
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setEditChatOfAccount((prevDetails) => ({
      ...prevDetails,
      [name]: value,
    }));
  };

  const handleOpenResetConfirmation = () => {
    setIsResetConfirmationOpen(true);
  };

  const handleOpenSaveConfirmation = () => {

    const user = getCookieValue("employee_no");

    setEditChatOfAccount((prevDetails) => ({
      ...prevDetails,
      action_by: user,
    }));
    setIsSaveConfirmationOpen(true);

  };

  const handleCloseSaveConfirmation = () => {
    setIsSaveConfirmationOpen(false);
  };

  const handleCloseResetConfirmation = () => {
    setIsResetConfirmationOpen(false);
  };

  // Edit Form
  const handleEditForm = async () => {
    console.log(editChatOfAccount)
    try {
      const response = await axios.put(
        `${backendUrl}/account/update/${editChatOfAccount.account_id}`,
        editChatOfAccount
      );
      setNotification({
        message: response.data.message,
        type: "success",
      });

      handleResetForm()

    } catch (error) {
      console.log(error);

      setNotification({
        message: "Account details Edit Failed",
        type: "error",
      });
    }
    setIsSaveConfirmationOpen(false);
  };

  // Reset Form
  const handleResetForm = () => {
    setEditChatOfAccount({
      account_code: "",
      account_name: "",
      currency: "",
      description: "",
      action_by:"",

    });
    setIsResetConfirmationOpen(false);
  };

  // Handle Notification Close
  const handleCloseNotification = () => {
    setNotification({ message: "", type: "" });
  };

  useEffect(() => {
    setEditChatOfAccount(row);
  }, []);

  return (
    <div className="flex items-center justify-center h-full bg-gray-100">
      <div className="relative w-full p-8 bg-white rounded-lg">
        <div className="flex items-center justify-between mb-6">
          <h2 className="text-2xl font-semibold">Edit Accounts</h2>
          <button
            type="button"
            onClick={editePopup}
            className="p-4 text-4xl text-gray-500 transition duration-300 hover:text-gray-700"
          >
            <IoIosCloseCircleOutline />
          </button>
        </div>

        {/* Notification Alert */}
        <Notification
          message={notification.message}
          type={notification.type}
          onClose={handleCloseNotification}
        />

        <form>
          {/* Checkbox for "Is Enabled" */}

          <div className="items-center gap-8 mt-5">
            {/* Define a two-column grid */}
            <div className="grid grid-cols-2 gap-y-[30px] gap-x-[60px] text-[20px]">
              {/* Account Code */}
              <div>
                <label className="block text-gray-700">Account Code</label>
                <input
                  type="text"
                  name="account_code"
                  value={editChatOfAccount.account_code}
                  className="w-full p-2 mt-2 border border-gray-300 rounded"
                  onChange={handleInputChange}
                />
              </div>

              {/* Account Name */}
              <div>
                <label className="block text-gray-700">Account Name</label>
                <input
                  type="text"
                  name="account_name"
                  value={editChatOfAccount.account_name}
                  onChange={handleInputChange}
                  className="w-full p-2 mt-2 border border-gray-300 rounded"
                />
              </div>

              {/* currency */}
              <div>
                <label className="block text-gray-700">Currency</label>
                <select
                  name="currency"
                  value={editChatOfAccount.currency}
                  onChange={handleInputChange}
                  className="w-full p-2 mt-2 border border-gray-300 rounded"
                >
                  <option value="" disabled>
                    Select Currency
                  </option>
                  <option value="LKR">LKR - SRI Lanka LKR</option>
                </select>
              </div>

              {/* description */}
              <div>
                <label className="block text-gray-700">Description</label>
                <input
                  type="text"
                  name="description"
                  onChange={handleInputChange}
                  value={editChatOfAccount.description}
                  className="w-full p-2 mt-2 border border-gray-300 rounded"
                />
              </div>
            </div>
          </div>
        </form>

        <div className="mt-5">
          <div className="flex items-center justify-between px-4 py-2">
            <div></div>
            <div className="flex gap-4">
              <button
                type="button"
                className="flex items-center gap-2 px-4 py-2 text-white bg-black rounded hover:bg-gray-800 focus:outline-none focus:ring-2 focus:ring-black"
                onClick={handleOpenResetConfirmation}
              >
                Reset
              </button>
              <button
                type="submit"
                className="flex items-center gap-2 px-4 py-2 text-white bg-black rounded hover:bg-gray-800 focus:outline-none focus:ring-2 focus:ring-black"
                onClick={handleOpenSaveConfirmation}
              >
                Save
              </button>
            </div>
          </div>
        </div>
        {isResetConfirmationOpen && (
          <div className="fixed top-0 left-0 z-50 flex items-center justify-center w-full h-full bg-gray-200 bg-opacity-80">
            <div className="p-8 bg-white rounded-lg">
              <h2 className="mb-4 text-xl font-bold">Confirm Reset</h2>
              <p>
                Are you sure you want to reset the form? This action cannot be
                undone.
              </p>
              <div className="flex justify-end gap-4 mt-6">
                <button
                  className="px-4 py-2 text-white bg-red-500 rounded-xl"
                  onClick={handleResetForm}
                >
                  Yes, Reset
                </button>
                <button
                  className="px-4 py-2 text-gray-600 bg-white border border-gray-300 rounded-xl"
                  onClick={handleCloseResetConfirmation}
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        )}

        {isSaveConfirmationOpen && (
          <div className="fixed top-0 left-0 z-50 flex items-center justify-center w-full h-full bg-gray-200 bg-opacity-80">
            <div className="p-8 bg-white rounded-lg">
              <h2 className="mb-4 text-xl font-bold">Confirm Save</h2>
              <p>Are you sure you want to save the form data?</p>
              <div className="flex justify-end gap-4 mt-6">
                <button
                  className="px-4 py-2 text-white bg-green-500 rounded-xl"
                  onClick={handleEditForm}
                >
                  Yes, Save
                </button>
                <button
                  className="px-4 py-2 text-gray-600 bg-white border border-gray-300 rounded-xl"
                  onClick={handleCloseSaveConfirmation}
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Edit_chat_account_popup;


const getCookieValue = (cookieName) => {
  const name = cookieName + "=";
  const decodedCookie = decodeURIComponent(document.cookie);
  const cookieArray = decodedCookie.split(";");
  for (let i = 0; i < cookieArray.length; i++) {
    let cookie = cookieArray[i].trim();
    if (cookie.indexOf(name) === 0) {
      return cookie.substring(name.length, cookie.length);
    }
  }
  return "";
};