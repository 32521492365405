import React, { useEffect, useState } from "react";
import "./posDashboard.css";
import axios from "axios";
import Cookies from "js-cookie"; // Import js-cookie
import usePermissions from "../../../components/permissions/permission";

export default function PosDashboard() {
  const { hasPermission } = usePermissions();
  const [bills, setBills] = useState([]);
  const [selectedBillItems, setSelectedBillItems] = useState([]);
  const [selectedDate, setSelectedDate] = useState(
    new Date().toISOString().split("T")[0]
  );
  const [selectedBillId, setSelectedBillId] = useState("");
  const [totalRevenue, setTotalRevenue] = useState(0);
  const [totalSales, setTotalSales] = useState(0);
  const [totalSoldItems, setTotalSoldItems] = useState(0);
  const branchId = Cookies.get("branch"); // Get the branch ID from the cookies

  useEffect(() => {
    fetchTodaySalesData(); // Automatically trigger when the component is mounted
    fetchSales(selectedDate); // Automatically fetch sales for the selected date
  }, [selectedDate]);

  const fetchTodaySalesData = async () => {
    if (!branchId) {
      console.error("Branch ID not found in cookies");
      return;
    }

    try {
      const res = await axios.get(
        `https://back-ayurveda.erp-quortech.com/v1/inventory/pos/getTodaySalesRevenueByPosTypeAndBranchId`,
        {
          params: {
            branch_id: branchId, // Include branch_id in the query parameters
          },
        }
      );
      setTotalRevenue(res.data.total_revenue);
      setTotalSales(res.data.total_no_of_sales);
      setTotalSoldItems(res.data.total_no_of_sales); // Assuming this is the total sold items
    } catch (error) {
      console.error("Failed to fetch today's sales data:", error);
    }
  };

  const fetchSales = async (date) => {
    if (!branchId) {
      console.error("Branch ID not found in cookies");
      return;
    }

    try {
      const res = await axios.get(
        `https://back-ayurveda.erp-quortech.com/v1/inventory/pos/getAllSalesByDatePosTypeAndBranchId`,
        {
          params: {
            pos_date: date,
            branch_id: branchId,
          },
        }
      );
      setBills(res.data);
    } catch (error) {
      console.error("Failed to fetch sales:", error);
    }
  };

  const handleRowClick = (bill) => {
    setSelectedBillId(bill.pos_id);
    setSelectedBillItems(bill.pos_items);
  };

  // Format currency
  const formatCurrency = (amount) => {
    return new Intl.NumberFormat("en-LK", {
      style: "currency",
      currency: "LKR",
      minimumFractionDigits: 2,
    }).format(amount);
  };

  const Card = ({ title1, title2, subTitle, img, value }) => (
    <div className=" h-[2%] bg-[#F3F8FF] rounded-[22px] flex p-3">
      <div className="flex items-center justify-between">
        <div>
          <p className="font-bold text-[20px] w-[300px] text-[#797C80] ml-6">
            {title1} {title2}
          </p>
        </div>

        <div>
          <div className="p-5 rounded-[12px] bg-[#9CAC8B] flex items-center justify-center">
            <p className="font-bold text-2xl text-white">{formatCurrency(value)}</p>
          </div>
        </div>
      </div>
    </div>
  );

  return (
    <div className="p-4 bg-background h-screen">
      <p className="text-[20px] font-bold ml-10 ">Dashboard</p>
      <div className="w-full flex flex-wrap justify-between my-3 px-5">
        {hasPermission(1090) && (
          <Card
            title1="Sales"
            title2="Today"
            subTitle=""
            img="path/to/image1.png"
            value={totalRevenue} // Display total revenue in currency format
          />
        )}
        {hasPermission(1091) && (
          <Card
            title1="Total Sales"
            title2="Today"
            subTitle=""
            img="path/to/image2.png"
            value={totalSales} // Display total sales
          />
        )}
        {hasPermission(1092) && (
          <Card
            title1="Total Sold Items"
            title2="Today"
            subTitle=""
            img="path/to/image3.png"
            value={totalSoldItems} // Display total sold items
          />
        )}
      </div>
      <div className="w-full flex justify-end items-center gap-5">
        <p className="text-[20px] font-bold ml-10 ">Date </p>
        <p className="text-[20px] font-bold ml-10 ">:</p>
        <input
          className="w-[259px] h-[35px] rounded-[46px] border px-5"
          type="date"
          value={selectedDate}
          onChange={(e) => setSelectedDate(e.target.value)}
        />
      </div>

      {/* Table 1: Bills */}
      <div className="w-full h-[330px] bg-[#F3F8FF] rounded-[46px] flex flex-col justify-start my-5">
        <div className="w-full h-[50px] rounded-[46px] bg-[#BFD8AE] flex justify-around items-center px-5 pr-[35px]">
          <p className="text-[20px] font-bold text-[#656565] text-center w-3">
            #
          </p>
          <p className="text-[20px] font-bold  text-[#656565] text-center w-[350px]">
            Bill Id
          </p>
          <p className="text-[20px] font-bold  text-[#656565] text-center w-[200px]">
            Date
          </p>
          <p className="text-[20px] font-bold  text-[#656565] text-center w-[100px]">
            Time
          </p>
          <p className="text-[20px] font-bold  text-[#656565] text-center w-[100px]">
            Total
          </p>
        </div>
        <div className="w-full h-[270px] flex flex-col justify-around items-center px-5 overflow-y-auto">
          {bills.map((bill, index) => (
            <div
              key={index}
              className={`w-full flex justify-around items-center cursor-pointer ${selectedBillId === bill.pos_id ? "bg-gray-200" : ""
                }`}
              onClick={() => handleRowClick(bill)}
            >
              <p className="text-[20px] font-bold text-[#656565] text-center w-3">
                {index + 1}
              </p>
              <p className="text-[20px] font-bold text-[#656565] text-center w-[350px]">
                {bill.pos_id}
              </p>
              <p className="text-[20px] font-bold text-[#656565] text-center w-[200px]">
                {bill.pos_date.split("T")[0]}
              </p>
              <p className="text-[20px] font-bold text-[#656565] text-center w-[100px]">
                {bill.pos_date.split("T")[1].split(".")[0]} {/* Extract the time part */}
              </p>

              <p className="text-[20px] font-bold text-[#656565] text-center w-[100px]">
                {formatCurrency(bill.pos_net_total)}
              </p>
            </div>
          ))}
        </div>
      </div>

      {/* Table 2: Items for Selected Bill */}
      <div className="w-full h-[340px] bg-[#F3F8FF] rounded-[46px] flex flex-col justify-start my-10">
        <div className="w-full h-[50px] rounded-[46px] bg-[#BFD8AE] flex justify-around items-center px-5 pr-[35px]">
          <p className="text-[20px] font-bold text-[#656565] text-center w-3">
            #
          </p>
          <p className="text-[20px] font-bold text-[#656565] text-center w-[350px]">
            Item Name
          </p>
          <p className="text-[20px] font-bold text-[#656565] text-center w-[200px]">
            Sold Qty
          </p>
        </div>
        <div className="w-full flex flex-col justify-around items-center px-5 overflow-y-auto">
          {selectedBillItems.length > 0 ? (
            selectedBillItems.map((item, index) => (
              <div
                key={index}
                className="w-full flex justify-around items-center"
              >
                <p className="text-[20px] font-bold text-[#656565] text-center w-3">
                  {index + 1}
                </p>
                <p className="text-[20px] font-bold text-[#656565] text-center w-[350px]">
                  {item.inventory_batch_id}
                </p>
                <p className="text-[20px] font-bold text-[#656565] text-center w-[200px]">
                  {item.pos_item_qty}
                </p>
              </div>
            ))
          ) : (
            <p className="text-[20px] font-bold text-[#656565] text-center">
              Select a bill to view items
            </p>
          )}
        </div>
      </div>
    </div>
  );
}
