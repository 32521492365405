import React, { useEffect, useState } from "react";

const ReturnBills = () => {
  const [returnDetails, setReturnDetails] = useState([]); // Initialize as an empty array

  useEffect(() => {
    // Fetch data from API
    const fetchReturnDetails = async () => {
      try {
        const response = await fetch("https://back-ayurveda.erp-quortech.com/v1/inventory/pos/get-returns");
        const data = await response.json();

        // Ensure the data is properly structured before setting it
        if (data && data.returnDetails) {
          setReturnDetails(data.returnDetails);
        } else {
          console.error("Invalid response structure:", data);
        }
      } catch (error) {
        console.error("Error fetching return details:", error);
      }
    };

    fetchReturnDetails();
  }, []);

  // Format currency
  const formatCurrency = (amount) => {
    return new Intl.NumberFormat("en-LK", {
      style: "currency",
      currency: "LKR",
      minimumFractionDigits: 2,
    }).format(amount);
  };

  return (
    <div className="bg-[#E5EDF9] overflow-y-auto h-screen">
      <div className="mt-5 ml-6">
        <p className="font-bold text-[35px] mt-5">Returned Bills</p>
        <div className="flex items-center gap-5">
          <div>
            <label className="font-bold text-[20px]">Bill ID</label>
            <input
              className="border border-black rounded-xl p-2 w-[300px] ml-4"
              placeholder="Enter Bill ID"
            />
          </div>
        </div>

        <div className="w-full flex items-center justify-center px-12 mt-3">
          <div className="bg-PrimaryColor1 w-full h-[62px] rounded-[100px] mx-20 flex items-center">
            <p className="text-[15px] font-bold ml-[50px] text-white">
              Item name :
            </p>
            <div>
              <input
                id="itemSearchInput"
                type="text"
                className="h-10 p-2 rounded-xl mr-5 ml-5 w-[400px]"
                placeholder="Search Item..."
              />
            </div>
          </div>
        </div>

        <div className="w-full flex items-center justify-center my-3">
          <div className="bg-[#F3F8FF] w-full min-h-96 mx-16 rounded-t-[40px] rounded-b-[50px]">
            <div className="w-full h-[80px] bg-PrimaryColor2 rounded-[100px] flex items-center justify-around">
              <p className="w-10 text-[20px] font-bold text-[#656565] text-center">
                Return ID
              </p>
              <div className="w-[350px] flex flex-col items-center">
                <p className="w-full text-[20px] font-bold text-[#656565] text-center ">
                  New POS ID
                </p>
                <div className="border-[2px] border-[#656565] w-[67px] rounded-[100px] "></div>
              </div>
              <div className="w-[160px] flex flex-col items-center">
                <p className="w-full text-[20px] font-bold text-[#656565] text-center ">
                  Returned Date
                </p>
                <div className="border-[2px] border-[#656565] w-[67px] rounded-[100px] "></div>
              </div>
              <div className="w-[110px] flex flex-col items-center">
                <p className="w-full text-[20px] font-bold text-[#656565] text-center ">
                  Refund Amount
                </p>
                <div className="border-[2px] border-[#656565] w-[67px] rounded-[100px] "></div>
              </div>
            </div>
            <div className="overflow-y-auto max-h-[300px]">
              {/* Conditionally render return details */}
              {returnDetails.length > 0 ? (
                returnDetails.map((detail) => (
                  <div
                    key={detail.return_id}
                    className="w-full flex items-start justify-around my-3"
                  >
                    <p className="w-10 text-[15px] font-bold text-[#656565] text-center">
                      {detail.return_id}
                    </p>
                    <p className="w-[350px] text-[15px] font-bold text-[#656565] text-center">
                      {detail.new_pos_id ? detail.new_pos_id : "N/A"}
                    </p>
                    <p className="w-[160px] text-[15px] font-bold text-[#656565] text-center">
                      {new Date(detail.return_date).toLocaleDateString()}
                    </p>
                    <p className="w-[110px] text-[15px] font-bold text-[#656565] text-center">
                      {formatCurrency(detail.refund_amount)}
                    </p>
                  </div>
                ))
              ) : (
                <p className="text-center">No return details available</p>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ReturnBills;
