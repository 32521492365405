/** @format */

import React, { useState, useEffect, useCallback, useMemo } from "react";

const Customers_Permission = ({ onPermissionsChange, userPermissions }) => {
  const [isVisible, setIsVisible] = useState(false);
  const [checkedValues, setCheckedValues] = useState({});

  const toggleVisibility = () => {
    setIsVisible(!isVisible);
  };

  // Initialize checkedValues based on userPermissions
  useEffect(() => {
    if (userPermissions && userPermissions.length > 0) {
      const initialCheckedValues = userPermissions.reduce((acc, curr) => {
        acc[String(curr)] = true;
        return acc;
      }, {});
      setCheckedValues(initialCheckedValues);
      console.log("Initialized checked values:", initialCheckedValues);
    }
  }, [userPermissions]);

  const permissionHierarchy = useMemo(
    () => ({
      6: { children: [150, 151, 152] }, // 6 sidebar id sub menu items 150-152
      151: { children: [1550, 1560] },
      152: { children: [1570, 1580] },
      // Add the rest of your permissions here
    }),
    []
  );

  const handleCheckboxChange = (value, dependentValue) => {
    setCheckedValues((prev) => {
      const newValues = { ...prev, [value]: !prev[value] };

      if (dependentValue && !prev[dependentValue]) {
        newValues[dependentValue] = true;
      }

      // Automatically check parent checkboxes if child checkbox is checked
      if (newValues[value]) {
        Object.keys(permissionHierarchy).forEach((key) => {
          if (permissionHierarchy[key]?.children.includes(value)) {
            newValues[key] = true;
          }
        });
      }

      // Automatically check child checkboxes if parent checkbox is checked
      if (permissionHierarchy[value] && newValues[value]) {
        permissionHierarchy[value].children.forEach((child) => {
          newValues[child] = true;
        });
      }

      return newValues;
    });
  };

  const updatePermissions = useCallback(() => {
    const selectedPermissions = Object.keys(checkedValues)
      .filter((key) => checkedValues[key])
      .map(Number);

    // Ensure parent permissions are included if any of their children are selected
    const allPermissions = new Set(selectedPermissions);

    // Iterate through the hierarchy to ensure parents are included
    selectedPermissions.forEach((perm) => {
      Object.keys(permissionHierarchy).forEach((key) => {
        if (permissionHierarchy[key]?.children.includes(perm)) {
          allPermissions.add(Number(key));
        }
      });
    });

    // Add the root permission (6) if any of its children are selected
    if (
      selectedPermissions.some((perm) =>
        permissionHierarchy[6].children.includes(perm)
      ) &&
      !allPermissions.has(6)
    ) {
      allPermissions.add(6);
    }

    // Convert the set to an array and pass it to the callback
    onPermissionsChange(Array.from(allPermissions));
  }, [checkedValues, onPermissionsChange, permissionHierarchy]);

  useEffect(() => {
    updatePermissions();
  }, [checkedValues, updatePermissions]);

  return (
    <div>
      <div>
        <div>
          <p className="font-bold text-[20px] ml-[20%] mt-4 underline">
            Customers
          </p>
          <div className="ml-[20%]">
            <button
              className="bg-blue-500 p-3 mt-5 text-white font-semibold rounded-[30px]"
              onClick={toggleVisibility}
            >
              {isVisible ? "Hide" : "Show"} Details
            </button>
          </div>
        </div>
        <div>
          {isVisible && (
            <div className="ml-[20%] mt-5 grid grid-cols-3 gap-10">
              <div>
                <p className="bg-gray-200 rounded-[20px] p-5 font-bold text-[15px] text-black">
                  Add Customer
                </p>
                <label className="block mb-2 text-blue-500">
                  <div className="ml-5 mt-2">
                    <input
                      type="checkbox"
                      className="mr-2"
                      value="150"
                      checked={!!checkedValues[150]}
                      onChange={() => handleCheckboxChange(150)}
                    />
                    Add Customer Details
                  </div>
                </label>
              </div>
              <div>
                <p className="bg-gray-200 rounded-[20px] p-5 font-bold text-[15px] text-black">
                  Update/View Customer
                </p>
                <div className="ml-5 mt-2">
                  <label className="block mb-2 text-blue-500">
                    <input
                      type="checkbox"
                      className="mr-2"
                      value="1550"
                      checked={!!checkedValues[1550]}
                      onChange={() => handleCheckboxChange(1550)}
                    />
                    View Customer Details
                  </label>
                  <label className="block mb-2 text-blue-500">
                    <input
                      type="checkbox"
                      className="mr-2"
                      value="1560"
                      checked={!!checkedValues[1560]}
                      onChange={() => handleCheckboxChange(1560)}
                    />
                    Update Customer Details
                  </label>
                </div>
              </div>
              <div>
                <p className="bg-gray-200 rounded-[20px] p-5 font-bold text-[15px] text-black">
                  Credit Clearance
                </p>
                <div className="ml-5 mt-2">
                  <label className="block mb-2 text-blue-500">
                    <input
                      type="checkbox"
                      className="mr-2"
                      value="1570"
                      checked={!!checkedValues[1570]}
                      onChange={() => handleCheckboxChange(1570)}
                    />
                    View Clearance Details
                  </label>
                  <label className="block mb-2 text-blue-500">
                    <input
                      type="checkbox"
                      className="mr-2"
                      value="1580"
                      checked={!!checkedValues[1580]}
                      onChange={() => handleCheckboxChange(1580)}
                    />
                    Pay Credit Clearance Details
                  </label>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Customers_Permission;
