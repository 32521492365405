/** @format */

import React, { useState, useEffect } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import axios from "axios";
import Cookies from "js-cookie";
import cloudiccon from "../../../../../Assets/icons/cloud-add.png";

const Opening_Price_Quo_Form = () => {
  const [file, setFile] = useState(null);
  const [departments, setDepartments] = useState([]);
  const [selectDepartment, setSelectDepartment] = useState("");
  const [selectSubject, setSelectSubject] = useState("");
  const [reqTitle, setReqTitle] = useState("");
  const { REACT_APP_API_ENDPOINT } = process.env;
  const [selectedItem, setSelectedItem] = useState(null);
  const userId = Cookies.get("employee_no");
  const currentDate = new Date(); // Get the current date and time
  const formattedDate = currentDate.toISOString().split("T")[0];
  const [imagePreview, setImagePreview] = useState(null);
  const [image, setImage] = useState(null);
  const [itemCategoryData, setItemCategoryData] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [searchQuery1, setSearchQuery1] = useState("");
  const [prResults, setPrResults] = useState([]);
  const [supplierResults, setSupplierResults] = useState([]);
  const [selectSupplierID, setSelectSupplierID] = useState("");
  const [selectPRTitle, setPRTitle] = useState("");
  const [selectPRID, setPRID] = useState("");
  const [totalDiscountRate, setTotalDiscountRate] = useState(0);
  const [subTotal, setSubTotal] = useState(0);
  const [netTotal, setNetTotal] = useState(0);
  const [category, setCategory] = useState("");
  const [samOpenPCom, setOpenPCom] = useState("");
  const [tableData, setTableData] = useState([]);
  const [items, setItems] = useState([]);

  const [selectComStatus, setSelectComStatus] = useState("APPROVED");
  const [selectComType, setSelectComType] = useState("OPENING COMMITTEE");

  // Handle search query change
  const handleSearchChange = async (event) => {
    const query = event.target.value;
    setSearchQuery(query);

    // Fetch search results when the query is not empty
    if (query.length > 1) {
      try {
        const response = await axios.get(
          `${REACT_APP_API_ENDPOINT}/v1/supplyChain/supplier/search/${query}`
        );
        setSupplierResults(response.data); // Assuming the API returns a list of suppliers
      } catch (error) {
        console.error("Error fetching suppliers:", error);
      }
    } else {
      setSupplierResults([]); // Clear results if query is too short
    }
  };

  // Handle supplier selection
  const handleSelectSupplier = (supplier) => {
    setSelectSupplierID(supplier.supplier_id);
    setSearchQuery(supplier.supplier_name); // Set the input value to the selected supplier name
    setSupplierResults([]); // Hide the dropdown after selection
  };

  // Handle search query change
  const handleSearchChange1 = async (event) => {
    const query = event.target.value;
    setSearchQuery1(query);

    // Fetch search results when the query is not empty
    if (query.length > 1) {
      try {
        const response = await axios.get(
          `${REACT_APP_API_ENDPOINT}/v1/supplychain/purchaseRequest/get-quotation-sended-PR?pr_id=${encodeURIComponent(
            query
          )}`
        );
        setPrResults(response.data); // Assuming the API returns a list of suppliers
      } catch (error) {
        console.error("Error fetching Purchase Req:", error);
      }
    } else {
      setPrResults([]); // Clear results if query is too short
    }
  };

  useEffect(() => {
    if (selectComStatus !== null) {
      fetchItemData();
    }
  }, [selectComStatus, selectComType]);

  const fetchItemData = async () => {
    try {
      const response = await fetch(
        `${REACT_APP_API_ENDPOINT}/v1/supplyChain/committee/members/all/committees?status=${selectComStatus}&committee_title=${selectComType}`
      );
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const data = await response.json();
      setItems(data);
      console.log("Data", data);
    } catch (error) {
      console.error("Error fetching employee data:", error);
    }
  };

  // Handle purchase request selection
  const handleSelectPurchaseReq = (pr) => {
    // Update common properties
    setPRID(pr.pr_id); // Set the common PR ID
    setPRTitle(pr.title); // Set the common PR title
    setSearchQuery1(pr.pr_id); // Set the common search query
    setCategory(pr.category);

    // Clone the existing quotations to update the specific quotation's table data

    // Prepare the table data based on the selected purchase request
    const formattedTableData = pr.pr_items.map((item) => ({
      item_id: item.item_id,
      item_name: item.item_name,
      item_qty: item.item_qty,
      item_measure_unit: item.item_measure_unit,
    }));
    console.log("formattedTableData", formattedTableData);
    setTableData(formattedTableData);
    // Clear search results to close the dropdown
    setPrResults([]);
  };
  console.log("tableData", tableData);

  const handleSubmit = async () => {
    try {
      const formData = new FormData();

      // Add text data to the formData
      formData.append("pr_id", selectPRID);
      formData.append("supplier_id1", selectSupplierID);
      formData.append("uploaded_by1", userId);
      formData.append("uploaded_date1", formattedDate);

      // Extract item_id and item_qty from tableData and add it to the formData
      const items1 = tableData.map((item) => ({
        item_id: item.item_id,
        item_quantity: item.item_qty,
        unit_price: item.item_unit_price,
        discount_rate: item.item_discount_rate,
        vat: item.item_VAT,
        ses: item.item_SES,
        other_tax: item.item_other_TAX,
        line_amount: item.item_line_amount,
      }));
      formData.append("items1", JSON.stringify(items1));
      formData.append("net_total1", subTotal);
      formData.append("document1", file);

      // Inspect the FormData content
      for (let pair of formData.entries()) {
        console.log(`${pair[0]}: ${pair[1]}`);
      }

      // Make the POST request using Axios
      const response = await axios.post(
        `${REACT_APP_API_ENDPOINT}/v1/supplyChain/openingcommittee/add-quotations`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      // Handle the response
      console.log("Response:", response.data);
      alert("Quotation for Purchase Request successfully Uploaded");
      handleReset();

      // Refresh the page after reset
      window.location.reload();
    } catch (error) {
      // Handle errors
      console.error("Error:", error);
      alert("Failed to add Quotation for Purchase Request");
    }
  };

  // Reset function
  const handleReset = () => {
    setPRTitle("");
    setPRID("");
    setSearchQuery("");
    setSearchQuery1("");
    setCategory("");
    setSupplierResults([]);
    setTableData([]);
    setSelectSupplierID("");
    setSelectedItem("");
    setFile("");
  };

  // Handle file input change
  const handleFileInputChange = (e) => {
    const selectedFile = e.target.files[0];
    setFile(selectedFile); // Set the selected file
  };

  // Handle file drop
  const handleFileDrop = (e) => {
    e.preventDefault();
    const droppedFile = e.dataTransfer.files[0];
    setFile(droppedFile); // Set the dropped file
  };

  // Remove file
  const removeFile = () => {
    setFile(null); // Reset the file to null
  };

  const handleDrop = (e) => {
    e.preventDefault();
    const file = e.dataTransfer.files[0];
    setImage(file);
    const reader = new FileReader();

    reader.onload = () => {
      setImagePreview(reader.result);
    };

    reader.readAsDataURL(file);
  };

  const calculateSubTotal = () => {
    const total = tableData.reduce((accumulator, item) => {
      return accumulator + (item.item_line_amount || 0); // Ensure item_line_amount is a number
    }, 0);

    setSubTotal(total.toFixed(2)); // Set subTotal with 2 decimal points
  };
  // useEffect to update subTotal whenever tableData changes
  useEffect(() => {
    calculateSubTotal();
  }, [tableData]);

  const handleInputChange = (index, key, value) => {
    const temp = [...tableData];

    // Ensure value is not less than or equal to 0 for relevant fields
    if (
      key === "item_unit_price" ||
      key === "item_discount_rate" ||
      key === "item_VAT" ||
      key === "item_SES" ||
      key === "item_other_TAX"
    ) {
      if (value <= 0) {
        value = 0; // Set to 0 if less than or equal to 0
      }
    }

    // Update the corresponding value
    temp[index][key] = parseFloat(value) || 0;

    // Recalculate the line amount
    temp[index].item_line_amount = calculateLineAmount(temp[index]);

    setTableData(temp);
  };

  const calculateLineAmount = (item) => {
    // Calculate the line amount based on quantity, unit price, and various rates
    const {
      item_qty,
      item_unit_price,
      item_discount_rate,
      item_VAT,
      item_SES,
      item_other_TAX,
    } = item;

    const totalAmount = item_qty * item_unit_price;
    console.log("totalAmount", totalAmount);
    // Calculate the discount amount
    const discountAmount = (item_discount_rate / 100) * totalAmount;
    console.log("discountAmount", discountAmount);

    // Calculate the total price after discount
    const totalAfterDiscount = item_qty * item_unit_price - discountAmount;
    console.log("totalAfterDiscount", totalAfterDiscount);

    // Calculate the VAT amount based on the total after discount
    const vatAmount = (item_VAT / 100) * totalAfterDiscount;
    console.log("vatAmount", vatAmount);

    // Calculate the SES amount based on the total after discount
    const sesAmount = (item_SES / 100) * totalAfterDiscount;
    console.log("sesAmount", sesAmount);

    // Calculate the other tax amount based on the total after discount
    const otherTaxAmount = (item_other_TAX / 100) * totalAfterDiscount;
    console.log("otherTaxAmount", otherTaxAmount);

    // Final line amount calculation
    const lineAmount =
      totalAfterDiscount + vatAmount + sesAmount + otherTaxAmount;
    console.log("lineAmount", lineAmount);

    return parseFloat(lineAmount.toFixed(2));
  };

  return (
    <div className="bg-background overflow-y-auto h-screen relative">
      <div>
        <p className="text-[40px] font-sans ml-6 mt-[1%] font-bold text-[#071C50] ">
          Opening Price Quotation Form
        </p>
      </div>

      <div className="bg-[#F3F8FF] w-[95%] mt-6 ml-6 rounded-[46px] relative p-6">
        <div className="grid grid-cols-2 gap-6">
          {/* Purchase Request No */}
          <div className="flex items-center">
            <p className="text-[22px] text-[black] font-bold mt-2 mb-2">
              Purchase Request No:
            </p>
            <input
              className="rounded-lg border border-gray-300 w-[60%] ml-4 mt-2 mb-2"
              type="text"
              value={searchQuery1}
              onChange={handleSearchChange1}
              placeholder="Search by Purchase Request No"
            />
            {/* Dropdown for search results */}
            {prResults.length > 0 && (
              <div className="absolute top-full w-[30%] left-[30%] bg-white border border-gray-300 z-10 max-h-40 overflow-y-auto shadow-lg">
                {prResults.map((pr) => (
                  <div
                    key={pr.pr_id}
                    onClick={() => handleSelectPurchaseReq(pr)}
                    className="px-4 py-2 hover:bg-gray-200 cursor-pointer"
                  >
                    {pr.pr_id} - {pr.title}
                  </div>
                ))}
              </div>
            )}
          </div>

          {/* Purchase Request Title */}
          <div className="flex items-center">
            <p className="text-[22px] text-[black] font-bold mt-2 mb-2">
              Purchase Request Title:
            </p>
            <input
              className="rounded-lg border border-gray-300 w-[60%] ml-4 mt-2 mb-2"
              value={selectPRTitle}
              readOnly
            />
          </div>

          {/* Sample Testing Committee */}
          <div className="flex items-center col-span-2">
            <p className="text-[22px] text-[black] font-bold mt-2 mb-2">
              Opening Price From Committee:
            </p>
            <select
              value={samOpenPCom}
              onChange={(e) => setOpenPCom(e.target.value)}
              className="rounded-lg border border-[#00000036] ml-4 w-[30%]"
            >
              <option>Select the Committee</option>
              {items.map((item, index) => (
                <option key={index} value={item.committee_id}>
                  {item.committee_id} - {item.committee_title}
                </option>
              ))}
            </select>
          </div>
        </div>
      </div>
      <div className="w-full flex flex-col items-center justify-center">
        <div className="bg-[#F3F8FF] w-[90%] rounded-[46px] border border-[#00000036] px-[200px] py-11 my-10 flex flex-col items-center justify-center">
          <p className="header-1 text-center">Quotation</p>
          {/* Search input for supplier name */}
          <div className="w-[600px] flex items-center justify-between my-10 relative">
            <label className="label-1">Supplier Name</label>
            <label className="label-1">:</label>
            <input
              className="input-1 border border-[#00000036]"
              type="text"
              value={searchQuery}
              onChange={(e) => handleSearchChange(e)}
              placeholder="Search by supplier name"
            />

            {/* Dropdown for search results */}
            {supplierResults && supplierResults.length > 0 && (
              <div className="absolute top-full w-[60%] ml-[50%] left-0 right-0 bg-white border border-gray-300 z-10 max-h-40 overflow-y-auto shadow-lg">
                {supplierResults.map((supplier) => (
                  <div
                    key={supplier.supplier_id}
                    onClick={() => handleSelectSupplier(supplier)}
                    className="px-4 py-2 hover:bg-gray-200 cursor-pointer"
                  >
                    {supplier.supplier_name}
                  </div>
                ))}
              </div>
            )}
          </div>

          {/* Supplier ID input (auto-populated) */}
          <div className="w-[600px] flex items-center justify-between my-10">
            <label className="label-1">Supplier ID</label>
            <label className="label-1">:</label>
            <input
              className="input-1 border border-[#00000036]"
              type="text"
              value={selectSupplierID || ""}
              readOnly // Make the input read-only as it will be auto-populated
            />
          </div>

          <div className="w-[600px] flex items-center justify-between my-10">
            <label className="label-1">Subject</label>
            <label className="label-1">:</label>
            <input
              className="input-1 border border-[#00000036]"
              type="text"
              value={category}
            />
          </div>

          <p className="header-1 mb-10">Purchase Request Item Details</p>

          <div className="flex flex-col justify-start w-[130%]  bg-[#F3F8FF] rounded-[46px] border border-[#00000036] mt-10">
            <div className="w-full h-[86px] flex items-center justify-around bg-[#BFD8AE] rounded-[46px] mr-5">
              <p className="w-10 p1 text-[#656565] text-center">#</p>
              <div className="w-[200px] flex flex-col items-center">
                <p className="w-full p1 text-[#656565] text-center ">Item ID</p>
                <div className="border-[3px] border-[#656565] w-[67px] rounded-[100px] mt-1"></div>
              </div>
              <div className="w-[200px] flex flex-col items-center">
                <p className="w-full p1 text-[#656565] text-center ">
                  Item Name
                </p>
                <div className="border-[3px] border-[#656565] w-[67px] rounded-[100px] mt-1"></div>
              </div>
              <div className="w-[200px] flex flex-col items-center">
                <p className="w-full p1 text-[#656565] text-center ">
                  Item Qty
                </p>
                <div className="border-[3px] border-[#656565] w-[67px] rounded-[100px] mt-1"></div>
              </div>
              <div className="w-[200px] flex flex-col items-center">
                <p className="w-full p1 text-[#656565] text-center ">
                  Item Measure Unit
                </p>
                <div className="border-[3px] border-[#656565] w-[67px] rounded-[100px] mt-1"></div>
              </div>
              <div className="w-[200px] flex flex-col items-center">
                <p className="w-full p1 text-[#656565] text-center ">
                  Unit Price
                </p>
                <div className="border-[3px] border-[#656565] w-[67px] rounded-[100px] mt-1"></div>
              </div>
              <div className="w-[200px] flex flex-col items-center">
                <p className="w-full p1 text-[#656565] text-center ">
                  Discount Rate
                </p>
                <div className="border-[3px] border-[#656565] w-[67px] rounded-[100px] mt-1"></div>
              </div>
              <div className="w-[200px] flex flex-col items-center">
                <p className="w-full p1 text-[#656565] text-center ">VAT</p>
                <div className="border-[3px] border-[#656565] w-[67px] rounded-[100px] mt-1"></div>
              </div>
              <div className="w-[200px] flex flex-col items-center">
                <p className="w-full p1 text-[#656565] text-center ">SES</p>
                <div className="border-[3px] border-[#656565] w-[67px] rounded-[100px] mt-1"></div>
              </div>
              <div className="w-[200px] flex flex-col items-center">
                <p className="w-full p1 text-[#656565] text-center ">
                  Other TAX
                </p>
                <div className="border-[3px] border-[#656565] w-[67px] rounded-[100px] mt-1"></div>
              </div>
              <div className="w-[200px] flex flex-col items-center">
                <p className="w-full p1 text-[#656565] text-center ">
                  Line Amount
                </p>
                <div className="border-[3px] border-[#656565] w-[67px] rounded-[100px] mt-1"></div>
              </div>
            </div>
            <div className="flex flex-col gap-y-5 my-3 h-[250px] overflow-y-scroll">
              {tableData.length > 0
                ? tableData.map((item, index) => (
                    <div key={index} className="w-full  flex justify-around ">
                      <div className="w-10 flex text-center">
                        <p className="w-full p1 text-[#656565] text-center ">
                          {index + 1}
                        </p>
                      </div>
                      <div className="w-[200px] flex  text-center">
                        <p className="w-full p1 text-[#656565] text-center ">
                          {item.item_id}
                        </p>
                      </div>
                      <div className="w-[200px] flex  text-center">
                        <p className="w-full p1 text-[#656565] text-center ">
                          {item.item_name}
                        </p>
                      </div>
                      <div className="w-[200px] flex text-center">
                        <p className="w-full p1 text-[#656565] text-center ">
                          {item.item_qty}
                        </p>
                      </div>
                      <div className="w-[200px] flex  text-center">
                        <p className="w-full p1 ml-5 text-[#656565] text-center ">
                          {item.item_measure_unit}
                        </p>
                      </div>

                      <div className="w-[200px] flex  text-center">
                        <p className="w-full p1 text-[#656565] text-center ">
                          <input
                            type="number"
                            className="w-[80%] border border-[#797979] rounded-[40px] px-[10px]"
                            value={item.item_unit_price}
                            min="0"
                            onChange={(e) =>
                              handleInputChange(
                                index,
                                "item_unit_price",
                                e.target.value
                              )
                            }
                          />
                        </p>
                      </div>
                      <div className="w-[200px] flex  text-center">
                        <p className="w-full p1 text-[#656565] text-center ">
                          <input
                            type="number"
                            className="w-[80%] border border-[#797979] rounded-[40px] px-[10px]"
                            value={item.item_discount_rate}
                            min="0"
                            onChange={(e) =>
                              handleInputChange(
                                index,
                                "item_discount_rate",
                                e.target.value
                              )
                            }
                          />
                        </p>
                      </div>
                      <div className="w-[200px] flex  text-center">
                        <p className="w-full p1 text-[#656565] text-center ">
                          <input
                            type="number"
                            className="w-[80%] border border-[#797979] rounded-[40px] px-[10px]"
                            value={item.item_VAT}
                            min="0"
                            onChange={(e) =>
                              handleInputChange(
                                index,
                                "item_VAT",
                                e.target.value
                              )
                            }
                          />
                        </p>
                      </div>
                      <div className="w-[200px] flex  text-center">
                        <p className="w-full p1 text-[#656565] text-center ">
                          <input
                            type="number"
                            className="w-[80%] border border-[#797979] rounded-[40px] px-[10px]"
                            value={item.item_SES}
                            min="0"
                            onChange={(e) =>
                              handleInputChange(
                                index,
                                "item_SES",
                                e.target.value
                              )
                            }
                          />
                        </p>
                      </div>
                      <div className="w-[200px] flex  text-center">
                        <p className="w-full p1 text-[#656565] text-center ">
                          <input
                            type="number"
                            className="w-[80%] border border-[#797979] rounded-[40px] px-[10px]"
                            value={item.item_other_TAX}
                            min="0"
                            onChange={(e) =>
                              handleInputChange(
                                index,
                                "item_other_TAX",
                                e.target.value
                              )
                            }
                          />
                        </p>
                      </div>
                      <div className="w-[200px] flex  text-center">
                        <p className="w-full p1 ml-5 text-[#656565] text-center ">
                          {item.item_line_amount}
                          {/* Display with two decimal points */}
                        </p>
                      </div>
                    </div>
                  ))
                : null}
            </div>
          </div>

          <div className="w-full flex justify-end items-start px-16 pt-3 ml-[15%]">
            <div className="w-[350px] h-full  flex flex-col gap-y-2">
              <div className="w-[350px] h-full rounded-[23px] bg-white flex flex-col px-4 py-3">
                <div className="flex items-center justify-between">
                  <p className="p1 text-[#656565]"> Net Total</p>
                  <p className="p1 text-[#656565]">{subTotal}</p>
                </div>
              </div>
            </div>
          </div>

          <div className=" flex items-center justify-center mt-5">
            <div>
              <h2 className="text-dialogHeader font-poppins font-semibold text-[26px] text-center">
                Upload Files
              </h2>
              <hr className=" ml-[20%] w-[60%] border-solid border-b-2 border-black"></hr>
            </div>
          </div>
          <div className="bg-white justify-center items-center rounded-[26px] min-h-[400px] w-[80%] h-[90%] flex-shrink-0 mt-5 ml-[10%]">
            <main className="container mx-auto max-w-screen-lg h-full">
              {/* File upload modal */}
              <article className="relative h-full flex flex-col rounded-md">
                {/* Scroll area */}
                <section className="h-full overflow-auto p-8 w-full flex flex-col">
                  <div className="flex items-center">
                    <img
                      src={cloudiccon}
                      alt="Cloud Icon"
                      className="h-10 w-10 rounded-full border border-gray-600"
                    />
                    <div>
                      <p className="text-gray-800 font-inter font-medium text-lg ml-2">
                        Upload files
                      </p>
                      <p className="text-dialogSubmenu-500 text-gray-400 font-inter font-medium text-sm ml-2">
                        Select and upload a file of your choice
                      </p>
                    </div>
                  </div>
                  <hr className="w-[100%] border-solid border-b-2 border-grayColor mt-2" />

                  <header
                    className="border-dashed border-2 bg-dialogboxFileup border-gray-400 py-12 flex flex-col justify-center items-center mt-2 rounded-[26px]"
                    onDrop={handleFileDrop}
                    onDragOver={(e) => e.preventDefault()}
                  >
                    <p className="mb-3 font-semibold text-gray-900 flex flex-wrap justify-center items-center flex-col">
                      <div>
                        <img
                          src={cloudiccon}
                          alt="Cloud Icon"
                          className="h-10 w-10 rounded-full"
                        />
                      </div>
                      <span>Drag & drop PDF file here</span>
                      &nbsp;
                      <span className="text-gray-400">or</span>
                    </p>
                    <input
                      id="hidden-input"
                      type="file"
                      className="hidden"
                      onChange={handleFileInputChange}
                    />
                    <button
                      id="button"
                      className="mt-2 rounded-[16px] px-3 py-1 bg-white hover:bg-gray-300 focus:shadow-outline focus:outline-none"
                      onClick={() =>
                        document.getElementById("hidden-input").click()
                      }
                    >
                      Browse Files
                    </button>
                  </header>

                  {/* Uploaded file display */}
                  {file && (
                    <div className="pt-8 pb-3">
                      <h1 className="font-semibold sm:text-lg text-gray-900">
                        Uploaded File
                      </h1>
                      <div className="flex justify-between items-center mt-2">
                        <span className="text-gray-900">{file.name}</span>
                        <span className="text-gray-700 text-sm">
                          {file.size > 1024
                            ? file.size > 1048576
                              ? Math.round(file.size / 1048576) + "mb"
                              : Math.round(file.size / 1024) + "kb"
                            : file.size + "b"}
                        </span>
                        {/* Remove file button */}
                        <button
                          onClick={removeFile}
                          className="text-red-500 text-sm ml-4"
                        >
                          Remove
                        </button>
                      </div>
                    </div>
                  )}
                </section>
              </article>
            </main>
          </div>
        </div>
      </div>

      <div className="w-full flex items-center justify-end gap-x-6 my-10 px-16">
        <button
          className="text-white font-bold text-2xl py-2 px-4 rounded-lg bg-[#AA4343]"
          onClick={handleReset}
        >
          Cancel
        </button>
        <button
          onClick={handleSubmit}
          className="bg-[#5B6D49] hover:bg-[#495e33] text-white font-bold text-2xl py-2 px-4 rounded-lg"
        >
          Submit a Quotation
        </button>
      </div>
    </div>
  );
};

export default Opening_Price_Quo_Form;
