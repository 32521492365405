/** @format */

import React, { useState, useEffect } from "react";
import "./addItemcategory.css";
import { IoMdCloseCircle } from "react-icons/io";
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";

export default function AddItemCategory() {
  const currentDate = new Date(); // Get the current date and time
  const formattedDate = currentDate.toISOString(); // Format the date to ISO string
  const userId = Cookies.get("employee_no");
  const [subItem, setSubItem] = useState("");
  const [selectedSubItems, setSelectedSubItems] = useState([]);
  const [subItemList, setSubItemList] = useState([]);
  const [mainCategories, setMainCategories] = useState([]);
  const navigate = useNavigate();

  const [alert, setAlert] = useState({
    status: "",
    alert: "",
    show: false,
  });

  const [data, setData] = useState({
    item_category_name: "",
    item_sub_category_names: [], // Adjusted key for sub-items
    item_category_update_user_id: userId,
    item_category_description: "",
    item_category_status: "ACTIVE",
  });

  useEffect(() => {
    // Fetch main categories on component mount
    fetchMainCategories();
  }, []);

  useEffect(() => {
    // Fetch sub-items when item_type changes
    if (data.item_category_name) {
      fetchSubItems(data.item_category_name);
    }
  }, [data.item_category_name]);

  const SubmitHandler = async () => {
    try {
      const res = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/itemCategory/add`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            item_category_name: data.item_category_name,
            item_sub_category_names: selectedSubItems, // Use selected sub-items
            item_category_update_user_id: data.item_category_update_user_id,
            item_category_description: data.item_category_description,
            item_category_status: data.item_category_status,
          }),
        }
      );
      const result = await res.json();
      console.log(result);
      if (res.status === 200 || res.status === 201) {
        // alert('Bill Added Successfully');
        if (res.status === 200 || res.status === 201) {
          console.log("hello:");
          window.alert("Item Category Added Sucssfully");
          ResetHandler();
        }
        // setTableData([])
        // setPoId(IdGenerate("ADDITEM"));
        // setBranch("BRANCH-0001");
      }
    } catch (error) {
      setAlert({
        status: "error",
        alert: "Something Went Wrong",
        show: true,
      });
    }
  };

  const fetchMainCategories = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/itemCategory/get-category`
      );
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const result = await response.json();
      if (
        result.item_category_names &&
        Array.isArray(result.item_category_names)
      ) {
        setMainCategories(result.item_category_names);
      } else {
        console.error("Unexpected response format:", result);
      }
    } catch (error) {
      console.error("Error fetching main categories:", error);
    }
  };

  const fetchSubItems = async (storeMainCategory) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/itemCategory/get-sub-category?item_category_name=${storeMainCategory}`
      );
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const data = await response.json();
      if (
        data.item_sub_category_names &&
        Array.isArray(data.item_sub_category_names)
      ) {
        setSubItemList(
          data.item_sub_category_names.map((subItem) => ({
            store_sub_category: subItem,
          }))
        );
      } else {
        console.error("Unexpected response format:", data);
      }
    } catch (error) {
      console.error("Error fetching sub-items:", error);
    }
  };

  const handleAddSubItem = () => {
    if (subItem) {
      const selectedSubItem = subItemList.find(
        (item) => item.store_sub_category === subItem
      );
      if (selectedSubItem && !selectedSubItems.includes(subItem)) {
        setSelectedSubItems((prevSelectedSubItems) => [
          ...prevSelectedSubItems,
          subItem,
        ]);
        setSubItem(""); // Reset selected sub-item after adding
      }
    }
  };

  const handleSubItemChange = (event) => {
    setSubItem(event.target.value);
  };

  const handleDeleteSubItem = (index) => {
    setSelectedSubItems((prevSelectedSubItems) =>
      prevSelectedSubItems.filter((_, i) => i !== index)
    );
  };

  const ChangeHandler = (e) => {
    const Data = { ...data };
    Data[e.target.id] = e.target.value;
    setData(Data);
  };

  const ResetHandler = () => {
    setData({
      item_category_name: "",
      item_sub_category_names: [],
      item_category_update_user_id: userId,
      item_category_description: "",
      item_category_status: "ACTIVE",
    });
    setSelectedSubItems([]); // Reset the selected sub-items
  };

  const CancelHandler = () => {
    navigate(-1);
  };

  return (
    <div className="p-10 bg-background">
      <p className="header-1"> Add Item Category</p>
      <div className="w-full flex items-center justify-center">
        <div className="bg-[#F3F8FF] rounded-[46px] border border-[#00000036] px-[200px] py-11 my-10 flex flex-col items-center justify-center">
          <p className="header-1 text-center">Enter Item Category Details</p>
          <div className="w-[600px] flex items-center justify-between my-10">
            <p className="label-1">Item Main Type</p>
            <p className="label-1">:</p>
            <select
              className="input-1 border border-[#00000036]"
              value={data.item_category_name}
              onChange={(e) => {
                const Data = { ...data };
                Data.item_category_name = e.target.value;
                setData(Data);
              }}
            >
              <option value="" disabled>
                Select Item Type
              </option>
              {mainCategories.length > 0 ? (
                mainCategories.map((category, index) => (
                  <option key={index} value={category}>
                    {category}
                  </option>
                ))
              ) : (
                <option disabled>No categories available</option>
              )}
            </select>
          </div>
          <div className="w-[780px] flex items-center justify-between my-10">
            <p className="label-1">Add Sub Item Category</p>
            <p className="label-1">:</p>
            <div className="flex items-center">
              <select
                className="input-1 border border-[#00000036]"
                value={subItem}
                onChange={handleSubItemChange}
              >
                <option value="">Select Sub Item Category</option>
                {subItemList && subItemList.length > 0 ? (
                  subItemList.map((subItem, index) => (
                    <option key={index} value={subItem.store_sub_category}>
                      {subItem.store_sub_category}
                    </option>
                  ))
                ) : (
                  <option disabled>No Sub Item Categories</option>
                )}
              </select>
              <button
                onClick={handleAddSubItem}
                className="bg-[#99BC85] text-white h-[30px] w-[70px] rounded-md ml-5"
              >
                Add
              </button>
            </div>
          </div>
          <div className="w-[600px] flex flex-wrap gap-2 ml-40">
            {selectedSubItems.map((item, index) => (
              <div
                key={index}
                className="flex items-center ml-40 text-[12px] bg-[#E1F0DA] justify-between rounded-lg p-1"
              >
                <div>{item}</div>
                <IoMdCloseCircle
                  onClick={() => handleDeleteSubItem(index)}
                  className="text-black cursor-pointer ml-2"
                  size={20}
                />
              </div>
            ))}
          </div>

          <div className="w-[650px] flex items-center justify-between my-10 mr-14">
            <p className="label-1">Item Category Description</p>
            <p className="label-1">:</p>
            <input
              className="input-1 border border-[#00000036]"
              id="item_category_description"
              onChange={(e) => ChangeHandler(e)}
              value={data.item_category_description} // Use the correct key from the state
            />
          </div>
        </div>
      </div>
      <div className=" flex items-center justify-end gap-x-6 mb-10 px-[360px]">
        <button
          className="w-[118px] h-[45px] flex items-center justify-center bg-[#ab2626] border rounded-[23px] px-4"
          onClick={() => CancelHandler()}
        >
          <p className="p2 text-white">Cancel</p>
        </button>
        <button
          className="w-[118px] h-[45px] flex items-center justify-center bg-[#5B6D49] border rounded-[23px] px-4"
          onClick={() => SubmitHandler()}
        >
          <p className="p2 text-white">Add</p>
        </button>
      </div>
    </div>
  );
}
