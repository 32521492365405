import React from "react";
import DriverP from "../../../Assets/vehicleFleetImg/Performance_with_colour.svg";
import AnimatedCard from "../../../components/vehicleFleetCard/card";
import usePermissions from "../../../components/permissions/permission";

const Home = () => {
  const { hasPermission } = usePermissions();

  return (
    <div className="flex flex-wrap justify-center items-center gap-8 p-4 bg-gray-100 min-h-screen">
      {/* Card 1 */}
      {/* {hasPermission(5180) && ( */}
        <AnimatedCard
          imageSrc={DriverP}
          title="Monthly Driver Performance"
          link="/performance/driver-performance-monitoring"
        />
      {/* )} */}
    </div>
  );
};

export default Home;
