import React, { useEffect, useRef, useState } from "react";
import moment from "moment";
import Navbar from "../../../components/navbar/navbar";
import { BsFuelPumpDiesel } from "react-icons/bs";
import { FaMoneyCheckDollar } from "react-icons/fa6";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from "recharts";

import axios from "axios";
import Notification from "../../../components/notification/notification";

const GenerateMonthlyFuelReport = () => {
  const backendUrl = process.env.REACT_APP_VEHICLE_FLEET_BACKEND_URL;
  const [count, setCount] = useState(0);
  const [currentTime, setCurrentTime] = useState(moment().format("h:mm:ss a"));
  const [isOpen, setIsOpen] = useState(false);

  const [data, setData] = useState([]); // State to hold fetched data

  const currentDate = moment().format("MMMM Do YYYY");

  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  const [searchQuery, setSearchQuery] = useState("");
  const [seletedValue, setSeletedValue] = useState({
    search_term: "",
    vehicle_type:""
  });
  const [notification, setNotification] = useState({ message: "", type: "" });
  const [isOpenEdit, setIsOpenEdit] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(20); // Default rows per page
  const [tableData, setTableData] = useState([]);
  const [selectedRow, setSelectedRow] = useState(null);
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const [pagination, setPagination] = useState({
    current_page: 1,
    page_size: 20,
    total: 0,
    total_pages: 0,
  });

  const handleRowSelect = (id) => {
    console.log(id);
    if (selectedRow === id) {
      setSelectedRow(null);
      setDropdownVisible(false);
    } else {
      setSelectedRow(id);
      setDropdownVisible(true);
    }
  };

  const togglePopup = () => {
    setIsOpen(!isOpen);
    getTableData();
  };

  const editePopup = () => {
    setIsOpenEdit(!isOpenEdit);
    getTableData();
  };

  const handleEdit = () => {
    getTableData();
    setIsOpenEdit(true);
  };

  const handleDelete = async () => {
    try {
      const response = await axios.put(
        `${backendUrl}/driver/delete-driver-details`,
        {}, // Empty body as PUT requires some data
        {
          headers: {
            "Content-Type": "application/json",
          },
          params: {
            driver_id: selectedRow.driver_id, // Passing the vehicle_id as a query param
          },
        }
      );

      // Success response
      setNotification({
        message: response.data.message || "Vehicle deactivated successfully!",
        type: "success",
      });

      // Optionally refresh the data
      getTableData();
    } catch (error) {
      console.error("Error deleting vehicle:", error);

      setNotification({
        message:
          error.response?.data?.error || "Delete failed, vehicle not found",
        type: "error",
      });
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setSeletedValue((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  // Function to handle fetching table data
  const getTableData = async () => {
    try {
      const params = {
       page_size: rowsPerPage,
        page: pagination.current_page,
        vehicle_id: seletedValue.search_term,
        vehicle_type:seletedValue.vehicle_type,
        start_date: "2024-08-10",
        end_date: "2024-08-25",
      };

      console.log(params);

      const response = await axios.get(
        `${backendUrl}/fuelsManagements/fuelConsumption`,
        {
          params,
        }
      );

      console.log("----Driver------");
      console.log(response.data);
      console.log("----Driver------");

      // Ensure you set both data and pagination state
      if (response.data) {
        setTableData(response.data.data); // Set the data array
        setPagination(response.data.pagination); // Set the pagination info
        setCount(response.data);
        setData(response.data.fuelType);
      } else {
        console.error("Unexpected response format:", response.data);
        setTableData([]);
      }
    } catch (error) {
      console.error("Error fetching sample details:", error);
      setTableData([]);
    }
  };

  const handleRowsPerPageChange = (newRowsPerPage) => {
    setRowsPerPage(newRowsPerPage);
    setPagination((prevPagination) => ({
      ...prevPagination,
      current_page: 1, // Reset to first page whenever rows per page changes
    }));
  };

  const downloadPDF = async (row) => {
    console.log(row);
    // Check if upload_paths exists and has at least one element
    if (!row.upload_paths || row.upload_paths.length === 0) {
      setNotification({
        message: "The document is in the process of being verified",
        type: "error",
      });
      return; // Exit the function if no paths are provided
    }

    const temp = row.upload_paths[0]; // Get the first upload path

    try {
      const response = await axios.get(`${backendUrl}/vehicle/downloadFiles`, {
        params: {
          registration_upload_path: temp, // Pass registration_upload_path as a query param
        },
        responseType: "blob", // Ensure we expect a blob response
      });

      const pdfBlob = new Blob([response.data], { type: "application/pdf" });
      const link = document.createElement("a");
      link.href = URL.createObjectURL(pdfBlob);
      link.download = "VehicleDetails.pdf"; // Add .pdf extension
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      console.log("Error downloading the PDF:", error);
    }
  };

  const PdfExport = () => {};

  const ExcelExport = () => {};

  // Handler for closing dropdown and deselecting row on outside click
  const handleClickOutside = (event) => {
    if (actionsRef.current && !actionsRef.current.contains(event.target)) {
      setSelectedRow(null);
      setDropdownVisible(false);
    }
  };

  const actionsRef = useRef(null); // Ref for the "actions" element

  // Close dropdown and deselect row if clicking outside the table
  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  // Handle Notification Close
  const handleCloseNotification = () => {
    setNotification({ message: "", type: "" });
  };

  // Call the API whenever rowsPerPage or currentPage changes
  useEffect(() => {
    getTableData();
  }, [
    rowsPerPage,
    pagination.current_page,
    seletedValue,
    seletedValue.search_term,
  ]);

  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentTime(moment().format("h:mm:ss a"));
    }, 1000);
    return () => clearInterval(timer);
  }, []);

  useEffect(() => {
    getTableData();
  }, []);

  return (
    <div className="mx-10 mt-5">
      <Navbar />
      <div className="overflow-hidden">
        <div className="flex items-center justify-between mt-6">
          <div>
            <p className="text-[30px] font-semibold">Fuel Reports</p>
            <div className="flex align-left items-center gap-6">
              <p className="text-[15px] font-semibold text-black">
                Vehicle fleet management system
              </p>
              <p className="text-[15px] font-semibold text-black">
                fuel management
              </p>
              <p className="text-[15px] font-semibold text-[#ABDCB0]">
                fuel Reports
              </p>
            </div>
          </div>
          <div className="flex items-center gap-6">
            <div>
              <div className="text-[#069855] text-[20px] font-bold">
                {currentDate}
              </div>
            </div>
            <div className="text-[20px] font-bold">{currentTime}</div>
          </div>
        </div>
        <div className="flex flex-col md:flex-row md:items-center md:gap-4 mt-6">
          {/* Search Box for Account Name */}
          <div className="w-full md:w-[350px]">
            <input
              type="text"
              placeholder="search vehicle Id"
              name="search_term"
              value={seletedValue.search_term}
              onChange={handleChange}
              className="w-full p-2 border border-black rounded focus:ring-2"
            />
          </div>

          {/* Root Type */}
          <div className="w-full md:w-[350px]">
            <select
              id="vehicle_type"
              name="vehicle_type"
              className="w-full p-2 border border-black rounded focus:ring-2 focus:ring-black"
              value={seletedValue.vehicle_type}
              onChange={handleChange}
            >
              {/* Other options */}
              <option value="">Select Vehicle Type</option>
              <option value="CAR">CAR</option>
              <option value="VAN">VAN</option>
              <option value="SUV">SUV</option>
            </select>
          </div>

          {/* Notification Alert */}
          <Notification
            message={notification.message}
            type={notification.type}
            onClose={handleCloseNotification}
          />

          {/* Button Container */}
          <div
            className="relative flex items-center gap-4 ml-auto"
            id="actions"
            ref={actionsRef}
          >
            {/* <button
              className="flex items-center gap-2 px-4 py-2 text-white bg-gradient-to-r from-red-400 to-red-600 rounded hover:from-red-500 hover:to-red-700 focus:outline-none focus:ring-2 focus:ring-red-500"
              onClick={PdfExport}
            >
              <IoAdd className="text-xl" />
              PDF Export
            </button>
            <button
              className="flex items-center gap-2 px-4 py-2 text-white bg-gradient-to-r from-green-400 to-green-600 rounded hover:from-green-500 hover:to-green-700 focus:outline-none focus:ring-2 focus:ring-green-500"
              onClick={ExcelExport}
            >
              <IoAdd className="text-xl" />
              Excel Export
            </button> */}
            {/* {selectedRow ? (
              // Action Dropdown
              <div className="relative">
                <button
                  onClick={() => setDropdownVisible(!dropdownVisible)}
                  className="flex items-center gap-2 px-4 py-2 text-white bg-indigo-600 rounded hover:bg-indigo-800 focus:outline-none focus:ring-2 focus:ring-indigo-500"
                >
                  Actions
                </button>
                {dropdownVisible && (
                  <div className="absolute right-0 z-20 mt-2 bg-white border border-black rounded shadow-lg">
                    <button
                      onClick={handleEdit}
                      className="flex items-center w-full px-4 py-2 text-xl text-black hover:bg-gray-100"
                    >
                      <FaEdit className="mr-2" /> Edit
                    </button>
                    <button
                      onClick={handleDelete}
                      className="flex items-center w-full px-4 py-2 text-xl text-black hover:bg-gray-100"
                    >
                      <FaTrash className="mr-2" /> Delete
                    </button>
                  </div>
                )}
              </div>
            ) : (
              <button
                className="flex items-center gap-2 px-4 py-2 text-white bg-indigo-600 rounded hover:bg-indigo-800 focus:outline-none focus:ring-2 focus:ring-indigo-500"
                onClick={togglePopup}
              >
                <IoAdd className="text-xl" />
                Add 
              </button>
            )} */}
          </div>
        </div>
        <div className="mt-5">
          {/* <div>
            <button
              className="bg-[#069855] text-white p-3 rounded-[12px] font-semibold flex items-center gap-2"
              onClick={togglePopup}
            >
              <IoAdd /> Add fuel consumption data
            </button>
          </div> */}

          {/* card and Bar chart section */}
          <div>
            <div className="mt-10 flex justify-between items-center">
              <div className="grid grid-cols-2 grid-flow-rows gap-8">
                {/* card 1 */}
                <div className="border border-black w-[350px] h-[200px] p-4 rounded-xl">
                  <div className="flex justify-between items-center mt-1">
                    <div>
                      <BsFuelPumpDiesel className="w-8 h-8" />
                    </div>

                    <div className="">
                      <p className="font-semibold text-green-400">
                        {currentTime}
                      </p>
                    </div>
                  </div>
                  <p className="mt-5 font-semibold">Total fuel consumed</p>
                  {/* include liters amount */}
                  <p className="text-[30px] font-semibold">
                    {count.total_fuel_consumption || 0}
                  </p>
                </div>
                {/* card 2 */}
                <div className="border border-black p-4 w-[350px] rounded-xl">
                  <div className="flex justify-between items-center mt-2">
                    <div>
                      <FaMoneyCheckDollar className="w-8 h-8" />
                    </div>

                    <div>
                      <p className="font-semibold text-green-400">
                        {currentTime}
                      </p>
                    </div>
                  </div>
                  <p className="mt-5 font-semibold"> Total fuel cost</p>
                  {/* include liters amount */}
                  <p className="text-[30px] font-semibold">
                    {count.total_fuel_cost || 0}
                  </p>
                </div>
              </div>

              {/* right content */}
              <div>
                <div className="border-2 border-black p-4 w-[500px] rounded-lg">
                  <h2 className="text-2xl font-bold mb-4">Fuel Consumption</h2>

                  <ResponsiveContainer width="100%" height={400}>
                    <BarChart
                      layout="vertical"
                      data={data}
                      margin={{
                        top: 5,
                        right: 30,
                        left: 20,
                        bottom: 5,
                      }}
                    >
                      <CartesianGrid strokeDasharray="3 3" />
                      <XAxis type="number" />
                      <YAxis type="category" dataKey="fuel_type" />
                      <Tooltip />
                      <Bar dataKey="total_fuel_consumption" />
                    </BarChart>
                  </ResponsiveContainer>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="my-4 overflow-x-auto mt-10 z-auto">
        {/* Scrollable Table Container with Increased Height */}
        <div className="max-h-[600px] overflow-y-auto">
          {/* TailwindCSS Table */}
          <table className="min-w-full bg-white border border-black ">
            <thead className="bg-gray-200">
              <tr>
                <th className="px-6 py-3 font-bold text-left text-gray-500 uppercase border-b border-gray sticky top-0 bg-gray-200 ">
                  Vehicle ID
                </th>
                <th className="px-6 py-3 font-bold text-left text-gray-500 uppercase border-b border-gray sticky top-0 bg-gray-200 ">
                  Fuel Type
                </th>
                <th className="px-6 py-3 font-bold text-left text-gray-500 uppercase border-b border-gray sticky top-0 bg-gray-200 ">
                  Fuel Efficiency
                </th>
                <th className="px-6 py-3 font-bold text-left text-gray-500 uppercase border-b border-gray sticky top-0 bg-gray-200 ">
                  Total Distance Traveled
                </th>
                <th className="px-6 py-3 font-bold text-left text-gray-500 uppercase border-b border-gray sticky top-0 bg-gray-200 ">
                  Total Fuel Consumption
                </th>
                <th className="px-6 py-3 font-bold text-left text-gray-500 uppercase border-b border-gray sticky top-0 bg-gray-200 ">
                  Fuel Cost
                </th>
              </tr>
            </thead>
            <tbody>
              {tableData.map((row, index) => (
                <tr
                  key={index}
                  className={`${
                    selectedRow === row
                      ? "bg-blue-600 text-white"
                      : "hover:bg-gray-100"
                  }`}
                  onClick={() => handleRowSelect(row)}
                >
                  <td className="px-6 py-4 border-b border-black">
                    {row.vehicle_id}
                  </td>
                  <td className="px-6 py-4 border-b border-black">
                    {row.fuel_type}
                  </td>
                  {/* <td className="px-6 py-4 border-b border-black">
                  {row.is_enabled === 1 ? (
                    <span className="text-green-500">Enable</span>
                  ) : (
                    <span className="text-red-500">Disable</span>
                  )}
                </td> */}
                  <td className="px-6 py-4 border-b border-black">
                    {row.fuel_efficiency}
                  </td>
                  <td className="px-6 py-4 border-b border-black">
                    {row.total_distance_traveled}
                  </td>
                  <td className="px-6 py-4 border-b border-black">
                    {row.total_fuel_consumption}
                  </td>
                  <td className="px-6 py-4 border-b border-black">
                    {row.fuel_cost}
                  </td>
                  {/* <td className="px-6 py-4 border-b border-black">
                  {row.driver_details_status}
                </td>
                <td className="px-6 py-4 text-sm text-gray-900 whitespace-nowrap ">
                  {!row.upload_paths ||
                  row.upload_paths.length === 0 ||
                  row.upload_paths.includes("PENDING") ? (
                    <button
                      className="px-4 py-2 bg-red-500 text-white font-semibold rounded-lg hover:bg-red-600 focus:outline-none focus:ring-2 focus:ring-red-400 focus:ring-opacity-75 transition duration-300 cursor-not-allowed"
                      onClick={() => downloadPDF(row)}
                    >
                      Pending
                    </button>
                  ) : (
                    <button
                      className="px-4 py-2 bg-green-500 text-white font-semibold rounded-lg hover:bg-green-600 focus:outline-none focus:ring-2 focus:ring-green-400 focus:ring-opacity-75 transition duration-300"
                      onClick={() => downloadPDF(row)}
                    >
                      Download
                    </button>
                  )}
                </td> */}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>

      {/* Pagination Controls */}
      <div className="flex justify-center mt-4 gap-2">
        {[20, 40, 60].map((size) => (
          <button
            key={size}
            onClick={() => handleRowsPerPageChange(size)}
            className={`px-4 py-2 ${
              rowsPerPage === size
                ? "bg-green-600 text-white" // Active button with green background and white text
                : "bg-green-300 text-black" // Inactive button with lighter green background and black text
            } rounded hover:bg-green-500`} // Hover effect for both states
          >
            {size}
          </button>
        ))}
        <button
          onClick={() =>
            setPagination((prevPagination) => ({
              ...prevPagination,
              currentPage: Math.min(
                prevPagination.currentPage + 1,
                prevPagination.totalPages
              ),
            }))
          }
          disabled={pagination.currentPage >= pagination.totalPages}
          className="px-4 py-2 bg-green-600 text-white rounded hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-green-600"
        >
          Load More
        </button>
      </div>

      {/* Add Fuel Consumption Process Popup */}
      {isOpen && (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-gray-800 bg-opacity-50">
          {/* <div className="w-3/4 p-6 bg-white rounded-lg shadow-lg">
            <FuelConsumptionPopup togglePopup={togglePopup} />
          </div> */}
        </div>
      )}
    </div>
  );
};

export default GenerateMonthlyFuelReport;
