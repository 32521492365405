/** @format */

import React, { useEffect, useState, useRef } from "react";
import { useReactToPrint } from "react-to-print";
import { Link, useNavigate, useLocation } from "react-router-dom";
import AsyncSelect from "react-select/async";
import Printer from "../../Assets/icons/print 1.png";
import IdGenerate from "../../utils/id_generate";
import axios from "axios";
import Cookies from "js-cookie";
import jsPDF from "jspdf";
import "jspdf-autotable";

export default function ReleasedItemReturnReqFromBranchView() {
  const location = useLocation();
  const navigate = useNavigate();
  const selectedRecord = location.state?.selectedRecord || {};
  const [tableData, setTableData] = useState(selectedRecord.items || []);
  const userId = Cookies.get("employee_no");
  const [netTotal, setNetTotal] = useState(0);

  console.log(selectedRecord);
  const [approvedQuantity, setApprovedQuantity] = useState(
    selectedRecord.approved_quantity || ""
  );

  const currentDate = new Date(); // Get the current date and time
  const formattedDate = currentDate.toISOString().split("T")[0]; // Format the date to ISO string

  const contentToPrint = useRef(null);
  const handlePrint = useReactToPrint({
    content: () => contentToPrint.current,
    documentTitle: "Print This Document",
    onBeforePrint: () => console.log("before printing..."),
    onAfterPrint: () => console.log("after printing..."),
    removeAfterPrint: true,
  });

  const Data = [...tableData]; // Create a copy of tableData
  Data.push({
    item_name: selectedRecord.item_name,
    batch_id: selectedRecord.batch_id,
    return_qty: selectedRecord.return_qty,
    return_description: selectedRecord.return_description,
    item_release_qty: 0,
    inventory_release_item_description: " ",
  });

  useEffect(() => {
    // Calculate line_amount for each item and set the net total
    const updatedTableData = tableData.map((item) => ({
      ...item,
      line_amount: item.item_price * item.accept_qty, // Calculate line_amount
    }));

    // Calculate net total from the line_amount values
    const total = updatedTableData.reduce(
      (acc, item) => acc + (item.line_amount || 0),
      0
    );

    setTableData(updatedTableData); // Update tableData with new line_amount values
    setNetTotal(total); // Set net total
  }, [tableData]);

  const BackHandler = () => {
    navigate(-1); // Navigate back to the previous page
  };

  const PdfExport = () => {
    const doc = new jsPDF();

    // Define the table columns and rows
    const columns = [
     
      "Item Name",
      "Return Requested Qty",
      "Received Qty",
      "Price(LKR)",
      "Store",
      "Location",
      "Note",
      "Line Amount",
    ];

    const rows = tableData.map((item) => [
      item.item_name,
      item.return_qty,
      item.accept_qty,
      item.item_price,
      item.store_id,
      item.location,
      item.return_description,
      item.item_price * item.accept_qty,
      
    ]);

    // Add title
    doc.text(" Details", 14, 10);

    // Generate the PDF table
    doc.autoTable({
      head: [columns],
      body: rows,
      startY: 20,
    });

    // Save the PDF
    doc.save("Item Return Received Request From Branch.pdf");
  };


  return (
    <div className="p-10 bg-background">
      {/* <TopNaw moduleName ='Inventory Items Purchase' userName={userName}/> */}
      <p className="header-1">Item Return Recevied Request From Branch</p>

      <div className="w-full flex items-center justify-center ">
        <div className="bg-[#F3F8FF] rounded-[46px] border border-[#00000036] px-[200px] py-11 my-10 flex flex-col items-center justify-center">
          <p className="header-1">Return Recevied Request Details</p>
          <div className="flex flex-col flex-wrap justify-between items-center">
            <div className="w-[600px] flex items-center justify-between my-10">
              <p className="label-1">Return Request Id :</p>
              <p className="font-[22px] w-[355px] h-[46px] rounded-[23px] bg-[#FFFFFF] border border-[#00000036] px-7 flex items-center ">
                {selectedRecord.return_id}
              </p>
            </div>
            <div className="w-[600px] flex items-center justify-between my-10">
              <p className="label-1">RR Date :</p>
              <p className="font-[22px] w-[355px] h-[46px] rounded-[23px] bg-[#FFFFFF] border border-[#00000036] px-7 flex items-center ">
                {new Date(selectedRecord.return_at).toISOString().split("T")[0]}
              </p>
            </div>
          </div>
        </div>
      </div>

      <p className="header-1">Return Request Items</p>

      {/* table */}
      <div className="w-full flex items-center justify-center my-10">
        <div className="bg-[#F3F8FF] w-full min-h-96 mx-16 rounded-t-[40px] rounded-b-[50px]">
          <div className="w-full h-20 bg-PrimaryColor2 rounded-[100px] flex items-center justify-around">
            <p className="w-10 p1 text-[#656565] text-center">#</p>
            <div className="w-[350px] flex flex-col items-center">
              <p className="w-full p1 text-[#656565] text-center ">Item Name</p>
              <div className="border-[3px] border-[#656565] w-[67px] rounded-[100px] mt-1"></div>
            </div>
            <div className="w-[170px] flex flex-col items-center">
              <p className="w-full p1 text-[#656565] text-center ">
                Return Requested Qty
              </p>
              <div className="border-[3px] border-[#656565] w-[67px] rounded-[100px] mt-1"></div>
            </div>
            <div className="w-[160px] flex flex-col items-center">
              <p className="w-full p1 text-[#656565] text-center ">
                Recevied Qty
              </p>
              <div className="border-[3px] border-[#656565] w-[67px] rounded-[100px] mt-1"></div>
            </div>
            <div className="w-[160px] flex flex-col items-center">
              <p className="w-full p1 text-[#656565] text-center ">
                Price(LKR)
              </p>
              <div className="border-[3px] border-[#656565] w-[67px] rounded-[100px] mt-1"></div>
            </div>
            <div className="w-[160px] flex flex-col items-center">
              <p className="w-full p1 text-[#656565] text-center ">Store</p>
              <div className="border-[3px] border-[#656565] w-[67px] rounded-[100px] mt-1"></div>
            </div>
            <div className="w-[160px] flex flex-col items-center">
              <p className="w-full p1 text-[#656565] text-center ">Location</p>
              <div className="border-[3px] border-[#656565] w-[67px] rounded-[100px] mt-1"></div>
            </div>
            <div className="w-[200px] flex flex-col items-center">
              <p className="w-full p1 text-[#656565] text-center ">Note</p>
              <div className="border-[3px] border-[#656565] w-[67px] rounded-[100px] mt-1"></div>
            </div>
            <div className="w-[200px] flex flex-col items-center">
              <p className="w-full p1 text-[#656565] text-center ">
                Line Amount
              </p>
              <div className="border-[3px] border-[#656565] w-[67px] rounded-[100px] mt-1"></div>
            </div>
          </div>
          <div className="">
            {tableData.length > 0
              ? tableData.map((item, index) => (
                  <div
                    key={index}
                    className="w-full flex items-start justify-around my-3  "
                  >
                    <p className="w-10 p1 text-[#656565] text-center">
                      {index + 1}
                    </p>
                    <p className="w-[350px] p1 text-[#656565] text-center">
                      {item.item_name}
                    </p>
                    <p className="w-[160px] p1 text-[#656565] text-center">
                      {item.return_qty}
                    </p>
                    <p className="w-[160px] p1 text-[#656565] text-center">
                      {item.accept_qty}
                    </p>
                    <p className="w-[160px] p1 text-[#656565] text-center">
                      {item.item_price}
                    </p>
                    <p className="w-[160px] p1 text-[#656565] text-center">
                      {item.store_id}
                    </p>
                    <p className="w-[160px] p1 text-[#656565] text-center">
                      {item.location}
                    </p>
                    <p className="w-[160px] p1 text-[#656565] text-center">
                      {item.return_description}
                    </p>
                    <p className="w-[160px] p1 text-[#656565] text-center">
                      {item.item_price * item.accept_qty}
                    </p>
                  </div>
                ))
              : null}
          </div>
        </div>
      </div>

      <div className="w-full flex justify-between items-start px-16 pt-3 ">
        <div className="w-[620px]  rounded-[23px] bg-[#F3F8FF] px-10 py-7 flex flex-col justify-between gap-y-7">
          <div className="w-full flex items-center gap-2">
            <p className="p1 text-[#656565] w-[500px]">Branch ID:</p>
            <div className="relative w-[300px]">
              <p className="font-[22px] w-[310px] h-[46px] rounded-[23px] bg-[#FFFFFF] border border-[#00000036] px-7 flex items-center ">
                {selectedRecord.branch_id}
              </p>
            </div>
          </div>
        </div>
        <div className="w-[350px] h-full  flex flex-col gap-y-2">
          <div className="w-[350px] h-full rounded-[23px] bg-[#F3F8FF] flex flex-col px-4 py-3">
            <div className="flex items-center justify-between">
              <p className="p1 text-[#656565]"> Net Total</p>
              <p className="p1 text-[#656565]">{netTotal.toFixed(2)}</p>
            </div>
          </div>
        </div>
      </div>

      <div className="flex w-full items-center justify-end gap-x-10 my-10">
        <button className="w-[138px] h-[41px] flex items-center justify-between bg-white border rounded-[23px] px-5">
          <img src={Printer} alt="" />
          <p
            className="p2 text-[#656565]"
            // onClick={() => {
            //   handlePrint(null, () => contentToPrint.current);
            // }}

            onClick={PdfExport}
          >
            Print
          </p>
        </button>
        <button
          className="w-[118px] h-[45px] flex items-center justify-center bg-[#AA4343] border rounded-[23px] px-4 text-white font-bold text-xl"
          onClick={BackHandler}
        >
          Back
        </button>
      </div>

      {/* Print layout */}

      {/* <div className="p-10 hidden">
        <div className="p-10" ref={contentToPrint}>
          <p className="header-1">Inventory Purchase (GRN)</p>

          <div id="printable" className="formal-document">
            <h1 className="document-title">Goods Received Note</h1>
            <div className="document-section">
              <h2 className="section-title">Purchase Order Details</h2>
              <div className="section-content">
                <p>
                  <strong>Purchase Order Id:</strong> {PoData[0].po_id}
                </p>
                <p>
                  <strong>Supplier:</strong> {PoData[0].supplier_name}
                </p>
                <p>
                  <strong>PO Date:</strong> {PoData[0].po_issue_date}
                </p>
              </div>
            </div>

            <div className="document-section">
              <h2 className="section-title">Purchasing Items</h2>
              <table className="items-table">
                <thead>
                  <tr>
                    <th>#</th>
                    <th>Item Name</th>
                    <th>Requested Qty</th>
                    <th>Supplied Qty</th>
                    <th>Unit</th>
                    <th>Remark</th>
                  </tr>
                </thead>
                <tbody>
                  {poItemsData.length > 0 &&
                    poItemsData.map((item, index) => (
                      <tr key={index}>
                        <td>{index + 1}</td>
                        <td>{item.item_name}</td>
                        <td>{item.requested_qty}</td>
                        <td>{item.supplied_qty}</td>
                        <td>{item.unit}</td>
                        <td>{item.remark}</td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div> */}
    </div>
  );
}
