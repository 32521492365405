import React, { useEffect, useState } from 'react';
import FileUpload from './upload_files';
import Notification from '../../../components/notification/notification';
import axios from 'axios';

const BankReconciliation = () => {
    const backendUrl = process.env.REACT_APP_BACKEND_URL_FINANCE;
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [allAccount, setAllAccount] = useState([]);
    const [uploadedFiles, setUploadedFiles] = useState([]);
    const [isSaveConfirmationOpen, setIsSaveConfirmationOpen] = useState(false);
    // const [isResetConfirmationOpen, setIsResetConfirmationOpen] = useState(false);
    const handleCloseSaveConfirmation = () => setIsSaveConfirmationOpen(false);
    // const handleCloseResetConfirmation = () => setIsResetConfirmationOpen(false);
    const [notification, setNotification] = useState({ message: "", type: "" });
    const [pageData, setPageData] = useState({})
    const [bankReconciliation, setBankReconciliation] = useState({
        bank_account: "",
        from_date: "",
        to_date: "",
        closing_balance: "",
        account_name: ""
    })

        // useState for storing selected bank statement and GL transaction IDs
        const [selectedBankStatements, setSelectedBankStatements] = useState([]);
        const [selectedGLTransactions, setSelectedGLTransactions] = useState([]);
    
        // Function to handle checkbox change for bank statements
        const handleBankStatementSelect = (bankStatementID, isSelected) => {
            setSelectedBankStatements(prevSelected =>
                isSelected
                    ? [...prevSelected, bankStatementID]
                    : prevSelected.filter(id => id !== bankStatementID)
            );
        };
    
        // Function to handle checkbox change for GL transactions
        const handleGLTransactionSelect = (GLTransactionID, isSelected) => {
            setSelectedGLTransactions(prevSelected =>
                isSelected
                    ? [...prevSelected, GLTransactionID]
                    : prevSelected.filter(id => id !== GLTransactionID)
            );
        };

    const getAccountData = async () => {
        try {
            const response = await axios.get(
                `${backendUrl}/account/getAllAccountsByFilters`
            );

            const formattedData = response.data.data.map((item) => ({
                account_id: item.account_id,
                name: item.account_name,
            }));


            setAllAccount(formattedData);
        } catch (error) {
            console.error("Error fetching sample details:", error);
            // setTableData([]);
        }
    };


    const handleSaveForm = async () => {
        console.log("Selected Bank Statements:", selectedBankStatements);
        console.log("Selected GL Transactions:", selectedGLTransactions);
    
        // // Check if selections are made
        // if (selectedBankStatements.length === 0 || selectedGLTransactions.length === 0) {
        //     console.warn("Please select at least one bank statement and one GL transaction.");
        //     return;
        // }
    
        try {
            const response = await axios.post(
                `${backendUrl}/banking/manualReconcile`,
                {
                    bank_statement_ids: selectedBankStatements,
                    transaction_ids: selectedGLTransactions,
                }
            );

            setNotification({
                message: response.data.message,
                type: "success",
              });
    
            // Optionally handle the response, e.g., updating state or displaying a success message
            console.log("Response from server:", response.data);
            // Optionally reset the selections after successful submission
            setSelectedBankStatements([]);
            setSelectedGLTransactions([]);

            getData()


        } catch (error) {

            setNotification({
                message: error.response.data.error,
                type: "error",
              });
            console.error("Error while reconciling:", error);
            // Optionally reset selections or handle error display
            setSelectedBankStatements([]);
            setSelectedGLTransactions([]);
        }

        setIsSaveConfirmationOpen(false)

    };
    

    // Handle Notification Close
    const handleCloseNotification = () => {
        setNotification({ message: "", type: "" });
    };

    // const handleResetForm = () => {
    //     // Implement your reset logic here
    //     setUploadedFiles([]); // Reset uploaded files
    //     console.log("Form reset!");
    //     handleCloseResetConfirmation();
    // };
    const handleOpenModal = () => {
        setIsModalOpen(true);
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setBankReconciliation((prevState) => ({
            ...prevState,
            [name]: value,
        }));
    };

    const handleChangeAccount = (e) => {
        const { value } = e.target;

        const selectedAccount = allAccount.find((type) => type.name === value);

        console.log(selectedAccount)
        setBankReconciliation((prevState) => ({
            ...prevState,
            bank_account: selectedAccount.account_id,
            account_name: selectedAccount.name
        }));
    };

    const getData = async () => {
        try {
            const params = {
                ...bankReconciliation,
            };

            const response = await axios.get(
                `${backendUrl}/banking/getReconcileStatement`,
                { params }
            );


            setPageData(response.data)


        } catch (error) {
            console.error("Error fetching sample details:", error);
            // setTableData([]);
        }
    }

    const handleReconcile = () => {
        setIsSaveConfirmationOpen(true)
    
    };



    const handleCloseModal = () => {
        setIsModalOpen(false);
    };

    useEffect(() => {
        getAccountData()
    }, [])

    useEffect(() => {
        getData()
    }, [bankReconciliation])

    return (
        <div className="bg-gray-100 min-h-screen">
            <div className="max-w-8xl mx-auto bg-white p-6 rounded-lg shadow-lg">
                <h2 className="text-2xl font-semibold mb-4">Bank Reconciliation Tool</h2>

                {/* Notification Alert */}
                <Notification
                    message={notification.message}
                    type={notification.type}
                    onClose={handleCloseNotification}
                />
                {/* Actions and Dropdown/Text Box Section */}
                <div className="flex justify-between items-center mb-4">
                    <div className="flex items-center space-x-4 w-2/3 mx-auto">
                        {/* Dropdown Box */}
                        <div className="w-1/2">
                            <label className="block text-gray-500 mb-2">Select Account</label>
                            <select
                                name="account_name"
                                value={bankReconciliation.account_name}
                                onChange={handleChangeAccount}
                                className="w-full p-2 border rounded-lg"
                            >
                                <option value="">-- Select Account --</option>
                                {allAccount.map((type) => (
                                    <option key={type.id} value={type.name}>
                                        {type.name}
                                    </option>
                                ))}
                            </select>
                        </div>

                        {/* From Date */}
                        <div className="w-1/2">
                            <label className="block text-gray-500 mb-2">Enter From Date</label>
                            <input
                                type="date"
                                name="from_date"
                                value={bankReconciliation.from_date}
                                onChange={handleChange}
                                className="w-full p-2 border rounded-lg"
                            />
                        </div>

                        {/* To Date */}
                        <div className="w-1/2">
                            <label className="block text-gray-500 mb-2">Enter To Date</label>
                            <input
                                type="date"
                                name="to_date"
                                value={bankReconciliation.to_date}
                                onChange={handleChange}
                                className="w-full p-2 border rounded-lg"
                            />
                        </div>

                        {/* Account Balance */}
                        <div className="w-1/2">
                            <label className="block text-gray-500 mb-2">Enter Account Balance</label>
                            <input
                                type="text"
                                name="closing_balance"
                                value={bankReconciliation.closing_balance}
                                onChange={handleChange}
                                placeholder="Close Balance"
                                className="w-full p-2 border rounded-lg"
                            />
                        </div>
                    </div>

                    {/* Upload Button */}
                    <button
                        className="px-4 py-2 bg-black text-white rounded-md"
                        onClick={handleOpenModal}
                    >
                        Upload Bank Statement
                    </button>
                </div>

                {/* Balance Summary Section */}
                <h3 className="text-2xl font-semibold mb-4">Reconciliation</h3>

                <div className="grid grid-cols-3 gap-4 mb-6 bg-gray-50 p-4 rounded-lg">
                    <div className="text-center">
                        <span className="text-gray-500">Closing Balance as per Bank</span>
                        <h3 className="text-xl font-bold text-green-600">{pageData.closingBalancePerBankStatement} </h3>
                    </div>
                    <div className="text-center">
                        <span className="text-gray-500">Closing Balance as per ERP</span>
                        <h3 className="text-xl font-bold text-green-600">{pageData.closingBalanceAsPerERP} </h3>
                    </div>
                    <div className="text-center">
                        <span className="text-gray-500">Difference</span>
                        <h3 className="text-xl font-bold text-red-600">{pageData.difference} </h3>
                    </div>
                </div>

                {/* Right-aligned Reconcile Button */}
               

                <div className="flex justify-end mb-4">
                <button
                    className="px-4 py-2 bg-black text-white rounded-md"
                    onClick={handleReconcile}
                >
                    Reconcile
                </button>
                </div>

                {/* Table for Unreconciled Bank Statement and General Ledger */}
                <div>
            <div className="flex h-screen">
                {/* Spacer */}
                <div className="mx-4"></div>

                {/* Scrollable Bank Statement Table */}
                <div className="w-1/2 h-full overflow-y-scroll">
                    <table className="min-w-full">
                        <thead className="sticky top-0 bg-gray-50 ">
                            <tr className="border-b border-gray-200">
                                <th className="py-5 px-3 text-left w-1/6">Bank Statement ID</th>
                                <th className="py-5 px-3 text-left w-1/6">Date</th>
                                <th className="py-5 px-3 text-left w-1/6">Description</th>
                                <th className="py-5 px-3 text-right w-1/6">Deposit</th>
                                <th className="py-5 px-3 text-right w-1/6">Withdrawal</th>
                                <th className="py-5 px-3 text-right w-1/6">Cheque No</th>
                                <th className="py-5 px-3 text-center w-1/6">Select</th>
                            </tr>
                        </thead>
                        <tbody className="text-gray-600 text-sm font-light">
                            {Array.isArray(pageData.unreconcileBankStatement) && pageData.unreconcileBankStatement.map((item) => (
                                <tr key={item.bankStatementID} className="border-b border-gray-200 hover:bg-gray-100">
                                    <td className="py-5 px-3 text-left w-1/6">{item.bankStatementID}</td>
                                    <td className="py-5 px-3 text-left w-1/6">{item.date}</td>
                                    <td className="py-5 px-3 text-left w-1/6">{item.description}</td>
                                    <td className="py-5 px-3 text-right w-1/6 text-green-500">{item.deposit}</td>
                                    <td className="py-5 px-3 text-right w-1/6 text-red-500">{item.withdrawal}</td>
                                    <td className="py-5 px-3 text-right w-1/6">{item.cheque_no}</td>
                                    <td className="py-5 px-3 text-center w-1/6">
                                        <input
                                            type="checkbox"
                                            className="form-checkbox h-4 w-4"
                                            checked={selectedBankStatements.includes(item.bankStatementID)} // Set checked based on state
                                            onChange={(e) => handleBankStatementSelect(item.bankStatementID, e.target.checked)}
                                        />
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>

                <div className="mx-0.5"></div>

                {/* Scrollable GL Transaction Table */}
                <div className="w-1/2 h-full overflow-y-scroll">
                    <table className="min-w-full">
                        <thead className="sticky top-0 bg-gray-50 ">
                            <tr className="border-b border-gray-200">
                                <th className="py-5 px-3 text-right w-1/6">GL Transaction ID</th>
                                <th className="py-5 px-3 text-right w-1/6">Date</th>
                                <th className="py-5 px-3 text-center w-1/6">Description</th>
                                <th className="py-5 px-3 text-center w-1/6">Debit</th>
                                <th className="py-5 px-3 text-center w-1/6">Credit</th>
                                <th className="py-5 px-3 text-center w-1/6">Cheque No</th>
                                <th className="py-5 px-3 text-center w-1/6">Select</th>
                            </tr>
                        </thead>
                        <tbody className="text-gray-600 text-sm font-light">
                            {Array.isArray(pageData.unreconcileGL) && pageData.unreconcileGL.map((item) => (
                                <tr key={item.GLTransactionID} className="border-b border-gray-200 hover:bg-gray-100">
                                    <td className="py-5 px-3 text-right w-1/6">{item.GLTransactionID}</td>
                                    <td className="py-5 px-3 text-right w-1/6">{item.date}</td>
                                    <td className="py-5 px-3 text-center w-1/6">{item.description}</td>
                                    <td className="py-5 px-3 text-center w-1/6 text-blue-500">{item.debit}</td>
                                    <td className="py-5 px-3 text-center w-1/6 text-blue-500">{item.credit}</td>
                                    <td className="py-5 px-3 text-center w-1/6 text-blue-500">{item.cheque_no}</td>
                                    
                                    <td className="py-5 px-3 text-center w-1/6">
                                        <input
                                            type="checkbox"
                                            className="form-checkbox h-4 w-4"
                                            checked={selectedGLTransactions.includes(item.GLTransactionID)} // Set checked based on state
                                            onChange={(e) => handleGLTransactionSelect(item.GLTransactionID, e.target.checked)}
                                        />
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>


            </div>

            {isModalOpen && (
                <div className="fixed top-0 left-0 z-50 flex items-center justify-center w-full h-full bg-gray-200 bg-opacity-80">
                    <div className="p-8 bg-white rounded-lg">
                        <FileUpload
                            setUploadedFiles={setUploadedFiles}
                            uploadedFiles={uploadedFiles}
                            onClose={handleCloseModal}
                        />

                        <button
                            className="p-3 py-2 mt-4 text-white bg-red-500 rounded-lg"
                            onClick={handleCloseModal}
                        >
                            Close
                        </button>
                    </div>
                </div>
            )}


            {isSaveConfirmationOpen && (
                <div className="fixed top-0 left-0 z-50 flex items-center justify-center w-full h-full bg-gray-200 bg-opacity-80">
                    <div className="p-8 bg-white rounded-lg">
                        <h2 className="mb-4 text-xl font-bold">Confirm Save</h2>
                        <p>Are you sure you want to save the form data?</p>
                        <div className="flex justify-end gap-4 mt-6">
                            <button
                                className="px-4 py-2 text-white bg-green-500 rounded-xl"
                                onClick={handleSaveForm}
                            >
                                Yes, Save
                            </button>
                            <button
                                className="px-4 py-2 text-gray-600 bg-white border border-gray-300 rounded-xl"
                                onClick={handleCloseSaveConfirmation}
                            >
                                Cancel
                            </button>
                        </div>
                    </div>
                </div>
            )}

            {/* {isResetConfirmationOpen && (
                <div className="fixed top-0 left-0 z-50 flex items-center justify-center w-full h-full bg-gray-200 bg-opacity-80">
                    <div className="p-8 bg-white rounded-lg">
                        <h2 className="mb-4 text-xl font-bold">Confirm Reset</h2>
                        <p>
                            Are you sure you want to reset the form? This action cannot be
                            undone.
                        </p>
                        <div className="flex justify-end gap-4 mt-6">
                            <button
                                className="px-4 py-2 text-white bg-red-500 rounded-xl"
                                onClick={handleResetForm}
                            >
                                Yes, Reset
                            </button>
                            <button
                                className="px-4 py-2 text-gray-600 bg-white border border-gray-300 rounded-xl"
                                onClick={handleCloseResetConfirmation}
                            >
                                Cancel
                            </button>
                        </div>
                    </div>
                </div>
            )} */}
        </div>
    );
};

export default BankReconciliation;
