import axios from "axios";
import React, {useEffect, useState} from "react";
import {IoIosCloseCircleOutline, IoIosCloudUpload} from "react-icons/io";
// import Autocomplete from "./autocomplete"; // Make sure to adjust the import path if needed
import Notification from "../../../components/notification/notification";
import {add} from "date-fns";
import FileUpload from "./upload_files";
import Cookies from "js-cookie";

const Sales_invoice_form = ({formMode, initialFormData, togglePopup, getTableData}) => {
    const backendUrl = process.env.REACT_APP_BACKEND_URL_FINANCE;

    const [formData, SetFormData] = useState({
        customer: "",
        invoice_no: "",
        invoice_date: "",
        payment_due_date: "",
        grand_total: 13,
        debit_account_id: null,
        credit_account_id: null,
        prepared_by: "",
        sales_invoice_items: [
            {item_id: "", qty: null,},
        ],
        description: "",
    });

    const [allCustomers, setAllCustomers] = useState([]);
    const [notification, setNotification] = useState({message: "", type: ""});
    const [isSaveConfirmationOpen, setIsSaveConfirmationOpen] = useState(false);
    const [allAccount, setAllAccount] = useState([]);
    const [uploadedFiles, setUploadedFiles] = useState([]);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [allItems, setAllItems] = useState([])

    const handleCloseModal = () => {
        setIsModalOpen(false);
    };

    const handleOpenModal = () => {
        setIsModalOpen(true);
    };

    const handleChange = async (e, index = null) => {
        const {name, value} = e.target;
        if (index !== null) {
            const newItems = [...formData.sales_invoice_items];
            newItems[index][name] = value;

            // Update the total price for individual item based on item_id and qty
            if (newItems[index].item_id && newItems[index].qty) {
                const itemData = { item_id: newItems[index].item_id, qty: newItems[index].qty };
                try {
                    const itemResponse = await axios.post(
                        `${backendUrl}/receivable/salesInvoice/getTotPriceOfItemQty`,
                        itemData
                    );
                    newItems[index].totPriceOfItemQty = itemResponse.data.totPriceOfItemQty;
                } catch (error) {
                    console.error("Error fetching item total price:", error);
                }
            }

            SetFormData((prevDetails) => ({
                ...prevDetails,
                sales_invoice_items: newItems,
            }));

            // Update grand total after each item quantity change
            updateGrandTotal(newItems);

            console.log("formdata", formData)
        } else {
            SetFormData((prevDetails) => ({
                ...prevDetails,
                [name]: value,
            }));
            console.log("formdataWihnull", formData)
        }
    };

    const updateGrandTotal = async (salesItems) => {
        try {
            const response = await axios.post(
                `${backendUrl}/receivable/salesInvoice/getTotAmountOfSalesInvoice`,
                { sales_invoice_items: salesItems.map(({ item_id, qty }) => ({ item_id, qty: Number(qty) })) }
            );
            SetFormData((prevDetails) => ({
                ...prevDetails,
                grand_total: response.data.grandTotal,
            }));
        } catch (error) {
            console.error("Error calculating grand total:", error);
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const requestData = {
                ...formData,
                sales_invoice_items: formData.sales_invoice_items.map((item) => ({
                    item_id: item.item_id,
                    qty: Number(item.qty)
                })),
            };

            let response;
            if (formMode === "edit") {
                requestData.prepared_by = Cookies.get("employee_no")
                const { sales_invoice_id, ...cleaned_data } = requestData
                response = await axios.put(`${backendUrl}/receivable/salesInvoice/udateSalesInvoice/${requestData.sales_invoice_id}`, cleaned_data);
                setNotification({ message: "Sales invoice updated successfully!", type: "success" });
            } else {
                requestData.prepared_by = Cookies.get("employee_no")
                response = await axios.post(`${backendUrl}/receivable/salesInvoice/addSalesInvoice`, requestData);
                setNotification({ message: "Sales invoice created successfully!", type: "success" });
            }

            const salesInvoiceID = response.data.salesInvoiceID;
            console.log("response.data",response.data)
            console.log("salesInvoiceID",salesInvoiceID)

            // Check if there are uploaded files to send
            if (uploadedFiles.length > 0) {
                const formData = new FormData();
                formData.append("salesInvoiceID", salesInvoiceID); // Pass sales invoice ID
                uploadedFiles.forEach((file) => {
                    formData.append("salesInvoiceFile", file); // Append files
                });

                // Send multipart/form-data request to upload files
                await axios.post(
                    `${backendUrl}/receivable/salesInvoice/uploadSalesInvoiceImage`,
                    formData,
                    {
                        headers: {
                            "Content-Type": "multipart/form-data",
                        },
                    }
                );

                setNotification({
                    message: "Sales invoice and files uploaded successfully!",
                    type: "success",
                });
            }

            // Reset form and close popup
            SetFormData({
                customer: "",
                invoice_no: "",
                invoice_date: "",
                payment_due_date: "",
                grand_total: "",
                debit_account_id: "",
                credit_account_id: "",
                prepared_by: "",
                sales_invoice_items: [{ item_id: "", qty: "" }],
                description: "",
                salesInvoiceFile: "",
            });

            handleCloseSaveConfirmation()
            togglePopup();
            getTableData(); // Refresh table after submission

        } catch (error) {
            handleCloseSaveConfirmation()
            console.error("Error submitting sales invoice:", error);
            setNotification({ message: "An error occurred. Please try again.", type: "error" });
        }
    };

    // File State Clear
    const handleRemoveFile = (index) => {
        setUploadedFiles((prevFiles) => prevFiles.filter((_, i) => i !== index));
    };

    // Open Save Confirmation
    const handleOpenSaveConfirmation = () => {
        setIsSaveConfirmationOpen(true);
    };

    // Close Save Confirmation
    const handleCloseSaveConfirmation = () => {
        setIsSaveConfirmationOpen(false);
    };

    // Add a new testing stage
    const addStage = () => {
        SetFormData((prevDetails) => ({
            ...prevDetails,
            sales_invoice_items: [
                ...prevDetails.sales_invoice_items,
                {item_id: "", qty: ""},
            ],
        }));
    };


    // Handle Notification Close
    const handleCloseNotification = () => {
        setNotification({message: "", type: ""});
    };

    // Remove a testing stage
    const removeStage = (index) => {
        const temp = formData.sales_invoice_items;
        console.log(temp);

        const newStages = temp.filter((_, i) => i !== index);

        SetFormData((prevDetails) => ({
            ...prevDetails,
            sales_invoice_items: [
                newStages,
            ],
        }));
    };

    const getAccountData = async () => {
        try {
            const response = await axios.get(
                `${backendUrl}/account/getAllAccountsByFilters`
            );

            const formattedData = response.data.data.map((item) => ({
                id: item.account_id,
                code: item.account_code,
                name: item.account_name,
            }));

            setAllAccount(formattedData);
        } catch (error) {
            console.error("Error fetching sample details:", error);
        }
    };

    const getAllCustomers = async () => {
        try {
            const response = await axios.get(
                `${backendUrl}/paymentEntry/getPartyByPartyType`,
                {
                    params: {
                        party_type: "Customer",
                    },
                }
            );
            console.log(response.data);
            setAllCustomers(response.data.data);
        } catch (error) {
            console.log(error);
        }
    };

    const getAllItems = async () => {
        try {
            const response = await axios.get(
                `${backendUrl}/receivable/salesInvoice/getSaleableItems`
            );
            const formattedData = response.data.map((item) => ({
                item_id: item.item_id,
                item_name: item.item_name
            }));

            setAllItems(formattedData);
        } catch (error) {
            console.error("Error fetching Items details:", error);
        }
    }

    useEffect(() => {
        console.log("init",initialFormData)
        console.log("init",formMode)

        const fetchRowTotals = async () => {
            const updatedItems = await Promise.all(
                initialFormData.sales_invoice_items.map(async (item) => {
                    // Fetch total price if not present
                    if (!item.totPriceOfItemQty && item.item_id && item.qty) {
                        try {
                            const itemData = { item_id: item.item_id, qty: item.qty };
                            const response = await axios.post(
                                `${backendUrl}/receivable/salesInvoice/getTotPriceOfItemQty`,
                                itemData
                            );
                            return { ...item, totPriceOfItemQty: response.data.totPriceOfItemQty };
                        } catch (error) {
                            console.error("Error fetching item total price:", error);
                            return item;
                        }
                    }
                    return item; // return item as is if totPriceOfItemQty exists
                })
            );

            // Set the updated items and other initial data
            SetFormData({
                sales_invoice_id: initialFormData.sales_invoice_id || "",
                customer: initialFormData.customer || "",
                invoice_no: initialFormData.invoice_no || "",
                invoice_date: initialFormData.invoice_date
                    ? new Date(initialFormData.invoice_date).toISOString().split('T')[0]
                    : "",
                payment_due_date: initialFormData.payment_due_date
                    ? new Date(initialFormData.payment_due_date).toISOString().split('T')[0]
                    : "",
                grand_total: initialFormData.grand_total || "",
                debit_account_id: initialFormData.debit_account_id || "",
                credit_account_id: initialFormData.credit_account_id || "",
                prepared_by: initialFormData.prepared_by || "",
                sales_invoice_items: updatedItems,
                description: initialFormData.description || "",
            });

            // Update grand total if necessary
            updateGrandTotal(updatedItems);
        };

        if (formMode === "edit" && initialFormData) {
            fetchRowTotals();
        }
    }, [formMode, initialFormData]);


    useEffect(() => {
        getAllCustomers();
        getAccountData();
        getAllItems();
    }, []);

    return (
        <div
            className="fixed top-0 left-0 z-50 flex items-center justify-center w-full h-full bg-gray-200 bg-opacity-80">
            <div className="w-[80%] h-[90%] overflow-y-auto p-8 bg-white rounded-lg">
                <div className="flex items-center justify-between mb-6">
                    <h2 className="text-2xl font-semibold">Add Sales Invoice</h2>
                    <button
                        type="button"
                        onClick={togglePopup}
                        className="p-4 text-4xl text-gray-500 transition duration-300 hover:text-gray-700"
                    >
                        <IoIosCloseCircleOutline/>
                    </button>
                </div>
                <Notification
                    message={notification.message}
                    type={notification.type}
                    onClose={handleCloseNotification}
                />
                <form onSubmit={handleSubmit}>
                    <div className="items-center gap-8 mt-5">
                        <div className="grid grid-cols-2 gap-y-[30px] gap-x-[60px] text-[20px]">
                            {/* Customer */}
                            <div>
                                <label className="block text-gray-700 font-semibold mb-1">
                                    Customer
                                </label>
                                <select
                                    name="customer"
                                    className="w-full p-2 mt-2 border border-gray-300 rounded"
                                    value={formData.customer}
                                    onChange={handleChange}
                                    required
                                >
                                    <option value=""></option>
                                    {allCustomers && allCustomers.length > 0 ? (
                                        allCustomers.map((cus) => (
                                            <option key={cus._id} value={cus.customer_id}>
                                                {cus.customer_name}
                                            </option>
                                        ))
                                    ) : (
                                        <option disabled>No Customer available</option>
                                    )}
                                </select>
                            </div>

                            {/* Invoice NO */}
                            <div>
                                <label className="block text-gray-700 font-semibold mb-1">
                                    Invoice No
                                </label>
                                <input
                                    type="text"
                                    name="invoice_no"
                                    className="w-full p-2 mt-2 border border-gray-300 rounded"
                                    value={formData.invoice_no}
                                    onChange={handleChange}
                                />
                            </div>

                            {/* Invoice Date */}
                            <div>
                                <label className="block text-gray-700 font-semibold mb-1">
                                    Invoice Date
                                </label>
                                <input
                                    type="date"
                                    name="invoice_date"
                                    className="w-full p-2 mt-2 border border-gray-300 rounded"
                                    value={formData.invoice_date}
                                    onChange={handleChange}
                                    required
                                />
                            </div>

                            {/* Payment Due Date */}
                            <div>
                                <label className="block text-gray-700 font-semibold mb-1">
                                    Payment Due Date
                                </label>
                                <input
                                    type="date"
                                    name="payment_due_date"
                                    className="w-full p-2 mt-2 border border-gray-300 rounded"
                                    value={formData.payment_due_date}
                                    onChange={handleChange}
                                />
                            </div>
                        </div>
                    </div>
                    <hr className="mt-10"/>

                    {/* Dynamically render testing stages */}
                    {formData.sales_invoice_items.map((item, index) => (
                        <div key={index} className="relative mb-4">
                            <div className="flex items-center">
                                <label className="block mb-2 mr-2 text-gray-700">
                                    Items {index + 1}
                                </label>
                                <button
                                    type="button"
                                    className="absolute top-0 right-0 mt-2 mr-2 font-semibold text-red-600 hover:text-red-800"
                                    onClick={() => removeStage(index)}
                                >
                                    <IoIosCloseCircleOutline/>
                                </button>
                            </div>

                            <div className="flex space-x-4">
                                <div className="w-1/3">
                                    <label className="block text-gray-700">Item</label>
                                    <select
                                        name="item_id"
                                        className="w-full p-2 mt-2 border border-gray-300 rounded"
                                        onChange={(e) => handleChange(e, index)}
                                        value={item.item_id}
                                        required
                                    >
                                        <option value=""></option>
                                        {allItems.map((item) => (
                                            <option key={item.item_id} value={item.item_id}>
                                                {item.item_name}
                                            </option>
                                        ))}
                                    </select>
                                </div>

                                <div className="w-1/3">
                                    <label className="block text-gray-700">Quantity</label>
                                    <input
                                        type="number"
                                        name="qty"
                                        className="w-full p-2 mt-2 border border-gray-300 rounded"
                                        value={item.qty}
                                        onChange={(e) => handleChange(e, index)}
                                        required
                                    />
                                </div>

                                <div className="w-1/3">
                                    <label className="block text-gray-700">Total Price</label>
                                    <input
                                        type="text"
                                        className="w-full p-2 mt-2 border border-gray-300 rounded"
                                        value={item.totPriceOfItemQty || 0}
                                        readOnly
                                        disabled
                                    />
                                </div>
                            </div>
                        </div>
                    ))}

                    <button
                        type="button"
                        onClick={addStage}
                        className="p-2 mt-4 text-white bg-blue-500 rounded hover:bg-blue-600"
                    >
                        Add Items
                    </button>

                    <div className="mt-4 text-right">
                        <span className="text-lg font-semibold text-gray-700">Grand Total: </span>
                        <span className="text-lg font-bold text-gray-900">{formData.grand_total || 0}</span>
                    </div>

                    <hr className="mt-10"/>

                    <div className="flex justify-between">
                        {/* Debit Account */}
                        <div>
                            <label className="block text-gray-700 font-semibold mb-1">
                                Debit Account
                            </label>
                            <select
                                name="debit_account_id"
                                className="w-full p-3 mt-2 border border-gray-300 rounded-md focus:ring-2 "
                                onChange={handleChange}
                                value={formData.debit_account_id}
                            >
                                <option value=""></option>
                                {allAccount.map((acc) => (
                                    <option key={acc.id} value={acc.id}>
                                        {`${acc.code} - ${acc.name}`}
                                    </option>
                                ))}
                            </select>
                        </div>

                        {/* Credit Account */}
                        <div>
                            <label className="block text-gray-700 font-semibold mb-1">
                                Credit Account
                            </label>
                            <select
                                name="credit_account_id"
                                className="w-full p-3 mt-2 border border-gray-300 rounded-md focus:ring-2 "
                                onChange={handleChange}
                                value={formData.credit_account_id}
                            >
                                <option value=""></option>
                                {allAccount.map((acc) => (
                                    <option key={acc.id} value={acc.id}>
                                        {`${acc.code} - ${acc.name}`}
                                    </option>
                                ))}
                            </select>
                        </div>
                    </div>

                    <hr className="mt-10"/>

                    <div className="flex justify-between mt-4">
                        <div className="w-1/2 ml-2">
                            <label className="block text-gray-700 font-semibold mb-1">
                                Description
                            </label>
                            <textarea
                                name="description"
                                className="w-full p-2 mt-2 border border-gray-300 rounded"
                                value={formData.description}
                                onChange={handleChange}
                            />
                        </div>
                    </div>

                    <hr className="mt-10"/>

                    {uploadedFiles.length === 0 && (
                        <>
                            {/* Section for Document Upload */}
                            <h1 className="text-2xl font-bold mt-10 mb-4">
                                Sales Invoice Document
                            </h1>
                            <button
                                type="button"
                                onClick={handleOpenModal}
                                className="p-3 border border-green-600 rounded-full text-green-600 hover:bg-green-600 hover:text-white transition-all duration-300"
                            >
                                <div className="flex items-center gap-2">
                                    <span className="text-sm font-bold">
                                        Click Here to Upload
                                    </span>
                                    <IoIosCloudUpload className="text-xl"/>
                                </div>
                            </button>
                        </>
                    )}

                    {/* Display Uploaded Files */}
                    {uploadedFiles.length > 0 && (
                        <div className="mt-5">
                            <h3 className="mb-2 text-xl font-bold">Selected Files</h3>
                            {uploadedFiles.map((file, index) => (
                                <div
                                    key={index}
                                    className="flex items-center justify-between p-2 mb-2 bg-yellow-100 border rounded-lg"
                                >
                                    <span>{file.name}</span>
                                    <button
                                        onClick={() => handleRemoveFile(index)}
                                        className="px-3 py-1 text-white bg-red-500 rounded hover:bg-red-600 transition-colors duration-200"
                                    >
                                        Remove
                                    </button>
                                </div>
                            ))}
                        </div>
                    )}
                    {/* Buttons */}
                    <div className="flex justify-end gap-4 mt-8">
                        <button
                            type="button"
                            onClick={togglePopup}
                            className="px-4 py-2 text-white bg-red-500 rounded hover:bg-red-600"
                        >
                            Cancel
                        </button>
                        <button
                            type="button"
                            onClick={handleOpenSaveConfirmation}
                            className="px-4 py-2 text-white bg-green-500 rounded hover:bg-green-600"
                        >
                            Save
                        </button>
                    </div>

                    {isSaveConfirmationOpen && (
                        <div
                            className="fixed top-0 left-0 z-50 flex items-center justify-center w-full h-full bg-gray-200 bg-opacity-80">
                            <div className="p-8 bg-white rounded-lg">
                                <h3 className="mb-4 text-lg font-semibold">
                                    Are you sure you want to save the form?
                                </h3>
                                <div className="flex justify-end gap-4">
                                    <button
                                        type="button"
                                        onClick={handleCloseSaveConfirmation}
                                        className="px-4 py-2 text-white bg-gray-500 rounded hover:bg-gray-600"
                                    >
                                        Cancel
                                    </button>
                                    <button
                                        type="submit"
                                        className="px-4 py-2 text-white bg-green-500 rounded hover:bg-green-600"
                                    >
                                        Save
                                    </button>
                                </div>
                            </div>
                        </div>
                    )}
                </form>


                {isModalOpen && (
                    <div
                        className="fixed top-0 left-0 z-50 flex items-center justify-center w-full h-full bg-gray-200 bg-opacity-80">
                        <div className="p-8 bg-white rounded-lg">
                            <FileUpload
                                setUploadedFiles={setUploadedFiles}
                                uploadedFiles={uploadedFiles}
                                onClose={handleCloseModal}
                            />
                            <button
                                className="p-3 py-2 mt-4 text-white bg-red-500 rounded-lg"
                                onClick={handleCloseModal}
                            >
                                Close
                            </button>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};

export default Sales_invoice_form;

const getCookieValue = (cookieName) => {
    const name = cookieName + "=";
    const decodedCookie = decodeURIComponent(document.cookie);
    const cookieArray = decodedCookie.split(";");
    for (let i = 0; i < cookieArray.length; i++) {
        let cookie = cookieArray[i].trim();
        if (cookie.indexOf(name) === 0) {
            return cookie.substring(name.length, cookie.length);
        }
    }
    return "";
};

// const user = getCookieValue("employee_fullname");
