import React, { useEffect } from "react";
import { IoIosCloseCircle } from "react-icons/io"; // Import the close icon
import { ImSpinner2 } from "react-icons/im"; // Import the spinner icon

const Notification = ({ message, type, onClose }) => {
  // Automatically close the notification after 3 seconds
  useEffect(() => {
    if (message) {
      const timer = setTimeout(() => {
        onClose();
      }, 3000);

      return () => clearTimeout(timer); // Cleanup the timer
    }
  }, [message, onClose]);

  if (!message) return null; // Don't render if there's no message

  return (
    <div
      className={`fixed top-1/8 left-1/2 transform -translate-x-1/2 -translate-y-1/2 z-50 p-4 flex items-center justify-between rounded shadow-md transition-transform ${
        type === "success"
          ? "bg-green-100 text-green-800"
          : "bg-red-100 text-red-800"
      }`}
    >
      {/* Loading Spinner */}
      <div className="flex items-center">
        <ImSpinner2 className="mr-2 animate-spin" />
        <span>{message}</span>
      </div>

      {/* Cancel Button */}
      <button onClick={onClose} className="ml-4 text-2xl">
        <IoIosCloseCircle className="text-gray-700 hover:text-gray-900" />
      </button>
    </div>
  );
};

export default Notification;
