/** @format */

import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { GrPrevious, GrNext } from "react-icons/gr";
import CSV from "../../../Assets/icons/csv.png";
import usePermissions from "../../../components/permissions/permission";
import ViewMoreItems from "./viewmoreitems";

const ViewCreatedJobList = () => {
  const [jobList, setJobList] = useState([]);
  const { hasPermission } = usePermissions();
  const navigate = useNavigate();
  const [filterOption, setFilterOption] = useState("");
  const [searchQuery, setSearchQuery] = useState("");
  const [searchMonth, setSearchMonth] = useState("");
  const [searchYear, setSearchYear] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(50);
  const { REACT_APP_API_ENDPOINT } = process.env;

  const [viewMoreData, setViewMoreData] = useState(null);
  const [viewMoreOpen, setViewMoreOpen] = useState(false);

  useEffect(() => {
    fetchItemData();
  }, []);

  const fetchItemData = async () => {
    try {
      const response = await fetch(
        `${REACT_APP_API_ENDPOINT}/v1/production/productionlevel/allproduction`
      );
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const data = await response.json();
      setJobList(data);
      console.log("get Created Job List:", data);
    } catch (error) {
      console.error("Error fetching Created Job List:", error);
    }
  };

  const handleFilterChange = (e) => {
    setFilterOption(e.target.value);
  };

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };

  const handleMonthChange = (e) => {
    setSearchMonth(e.target.value);
  };

  const handleYearChange = (e) => {
    setSearchYear(e.target.value);
  };

  const HandleOnClick = (itemReq) => {
    // Navigate to the new page and pass the object
    navigate("/created-job-add-item", {
      state: { selectedRecord: itemReq },
    });
  };

  // Pagination Logic
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const filteredEmployees = jobList.filter((item) => {
    const searchMatch =
      !searchQuery ||
      item.job_name.toLowerCase().includes(searchQuery.toLowerCase());

    return searchMatch;
  });

  const currentItems = filteredEmployees.slice(
    indexOfFirstItem,
    indexOfLastItem
  );

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const handleExportCSV = () => {
    const csvContent =
      "data:text/csv;charset=utf-8," +
      jobList.map((row) => Object.values(row).join(",")).join("\n");

    const encodedUri = encodeURI(csvContent);
    const link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    link.setAttribute("download", "employee_data.csv");
    document.body.appendChild(link);
    link.click();

    setTimeout(() => {
      window.alert("Successfully Downloaded");
    }, 100);
  };

  const handleViewMore = (itemReq) => {
    setViewMoreData(itemReq);
    setViewMoreOpen(true);
  };
  return (
    <div className="bg-background h-screen overflow-y-auto">
      <p className="text-[25px] font-sans ml-6 mt-6 font-bold text-[#797C80]">
        View Created Job List
      </p>
      <div className="flex justify-between overflow-hidden pb-6">
        <div>
          <div className="flex flex-col font-poppins font-bold ml-6 mt-4">
            <div className="relative font-sans group hidden sm:block">
              <input
                type="text"
                placeholder="Search by Job Name"
                value={searchQuery}
                onChange={handleSearchChange}
                className="w-[200px] sm:w-[300px] h-10 duration-300 rounded-full border border-white px-2 py-1"
              />
            </div>
          </div>
        </div>
      </div>

      <div className="flex justify-end mt-[-3%] mb-4 mr-4">
        {hasPermission(2520) && (
          <button className="bg-primary text-white font-sans p-3 rounded-[20px]">
            <div className="flex items-center" onClick={handleExportCSV}>
              <div onClick={handleExportCSV} className="font-semibold">
                Export CSV
              </div>
              <div>
                <img className="h-8 w-8" src={CSV} alt="logo-csv" />
              </div>
            </div>
          </button>
        )}
      </div>

      <div className="overflow-y-auto max-h-[calc(100vh-200px)] ml-5 mb-10 mr-5 rounded-[22px] ">
        <table className="table-auto border-none overflow-x-auto w-full">
          <thead>
            <tr className="bg-white font-sans text-[16px]">
              <th className="text-primary px-6 py-4">Production ID</th>
              <th className="text-primary px-6 py-4">Job ID</th>
              <th className="text-primary px-6 py-4">Job Name</th>
              <th className="text-primary px-6 py-4">Production Manager</th>
              <th className="text-primary px-6 py-4">Total Stages</th>
              <th className="text-primary px-6 py-4">Completed Stages</th>
              <th className="text-primary px-6 py-4">Action</th>
            </tr>
          </thead>
          <tbody className="font-semibold font-sans">
            {currentItems.map((itemReq) => (
              <tr key={itemReq.production_id}>
                <td className="bg-white px-6 py-4 text-center">
                  {itemReq.production_id}
                </td>
                <td className="bg-white px-6 py-4 text-center">
                  {itemReq.job_id}
                </td>
                <td className="bg-white px-6 py-4 text-center">
                  {itemReq.job_name}
                </td>
                <td className="bg-white px-6 py-4 text-center">
                  {itemReq.production_manager}
                </td>
                <td className="bg-white px-6 py-4 text-center">
                  {itemReq.total_stages}
                </td>
                <td className="bg-white px-6 py-4 text-center">
                  {itemReq.completed_stages}
                </td>
                <td className="bg-white px-6 py-4 text-center">
                  {hasPermission(2510) && (
                    <button
                      className="bg-gray-300 p-2 items-center rounded-md w-[200px]"
                      onClick={() => HandleOnClick(itemReq)}
                      // setselectedItemReq={itemReq}
                    >
                      View
                    </button>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <div className="flex justify-center mt-4 mb-4">
        <button
          onClick={() => paginate(currentPage - 1)}
          disabled={currentPage === 1}
          className="bg-primary text-white px-4 py-2 mx-1 rounded-md"
        >
          <GrPrevious className="w-3 h-3" />
        </button>
        {[...Array(Math.ceil(filteredEmployees.length / itemsPerPage))].map(
          (page, index) => (
            <button
              key={index}
              onClick={() => paginate(index + 1)}
              className={`${
                currentPage === index + 1 ? "bg-gray-300" : "bg-white"
              } text-primary px-4 py-2 mx-1 rounded-md`}
            >
              {index + 1}
            </button>
          )
        )}
        <button
          onClick={() => paginate(currentPage + 1)}
          disabled={
            currentPage === Math.ceil(filteredEmployees.length / itemsPerPage)
          }
          className="bg-primary text-white px-4 py-2 mx-1 rounded-md"
        >
          <GrNext className="w-3 h-3" />
        </button>
      </div>

      {viewMoreOpen && (
        <ViewMoreItems
          onClose={() => setViewMoreOpen(false)}
          units={viewMoreData.job_create_unit_quantity}
        />
      )}
    </div>
  );
};

export default ViewCreatedJobList;
