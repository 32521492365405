import React, { useState, useEffect } from "react";
// import Navbar from "../navbar/navbar";
import moment from "moment";
import FileUpload1 from "./1upload_files";
import FileUpload2 from "./2upload_files";
import axios from "axios";
import { IoIosCloseCircleOutline, IoIosCloudUpload } from "react-icons/io";
// import FileUpload from "../driver_details/upload_files";
import Notification from "../../../../components/notification/notification";

// import Time_Management_Table from "./time_management_table";
const Add_upload_request_letter_form = ({ togglePopup }) => {
  const currentDate = moment().format("YYYY-MM-DD");
  const backendUrl = process.env.REACT_APP_VEHICLE_FLEET_BACKEND_URL;
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModalOpen2, setIsModalOpen2] = useState(false);

  const [vehicleMap, setVehicleMap] = useState({});
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [uploadedFiles2, setUploadedFiles2] = useState([]);
  const [isResetConfirmationOpen, setIsResetConfirmationOpen] = useState(false);
  const [isSaveConfirmationOpen, setIsSaveConfirmationOpen] = useState(false);
  const [notification, setNotification] = useState({ message: "", type: "" });
  const [vehicleDetails, setVehicleDetails] = useState([]);
  const [upReqLetterDetails, setupReqLetterDetails] = useState({
    maintenance_id: "",
    date_of_request: "",
    maintenance_type: "",
    maintenance_priority: "",
    desc_of_issue: "",
    date_of_approval: "",
    vehicle_service_dates: [],
    vehicle_id: "",
    upload_req_letter: [],
    approval_doc: [],
  });

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleOpenModal2 = () => {
    setIsModalOpen2(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const handleCloseModal2 = () => {
    setIsModalOpen2(false);
  };

  // Define a function to fetch vehicle IDs
  const fetchVehicleDetails = async () => {
    try {
      const response = await axios(
        `${backendUrl}/vehicle/allVehicles`
      );

      const formattedData = response.data.data.map((item) => ({
        vehicle_id: item.vehicle_id,
        vehicle_no_plate_no: item.vehicle_no_plate_no,
        
      }));
       setVehicleDetails(formattedData);

    } catch (error) {
      console.error("Error fetching vehicle details:", error);
    }
  };

  // File State Clear
  const handleRemoveFile = (index) => {
    setUploadedFiles((prevFiles) => prevFiles.filter((_, i) => i !== index));
  };

  // File State Clear
  const handleRemoveFile2 = (index) => {
    setUploadedFiles2((prevFiles) => prevFiles.filter((_, i) => i !== index));
  };



  const handleOpenResetConfirmation = () => {
    setIsResetConfirmationOpen(true);
  };

  const handleOpenSaveConfirmation = () => {
    setupReqLetterDetails((prevDetails) => ({
      ...prevDetails,
      upload_req_letter: uploadedFiles,
      approval_doc: uploadedFiles2,
    }));

    setIsSaveConfirmationOpen(true);
  };

  const handleCloseSaveConfirmation = () => {
    setIsSaveConfirmationOpen(false);
  };

  const handleCloseResetConfirmation = () => {
    setIsResetConfirmationOpen(false);
  };


//   const handleSaveForm = async () => {
//     try {
//         const formData = new FormData();

//         // Append non-file fields
//         for (const key in upReqLetterDetails) {
//             if (upReqLetterDetails.hasOwnProperty(key)) {
//                 // Convert vehicle_service_dates array to JSON format
//                 if (key === "vehicle_service_dates") {
//                     formData.append(key, JSON.stringify(upReqLetterDetails[key])); // Convert to JSON string
//                 } else {
//                     formData.append(key, upReqLetterDetails[key]);
//                 }
//             }
//         }

//         // Append file fields
//         if (upReqLetterDetails.upload_req_letter) {
//             formData.append('upload_req_letter', upReqLetterDetails.upload_req_letter[0]);
//         }
//         if (upReqLetterDetails.approval_doc) {
//             formData.append('approval_doc', upReqLetterDetails.approval_doc[0]);
//         }

//         console.log(upReqLetterDetails);

//         // Send POST request
//         const response = await axios.post(`${backendUrl}/maintenance/maintenance`, formData, {
//             headers: {
//                 'Content-Type': 'multipart/form-data',
//             },
//         });

//         console.log(response.data);
//         setNotification({
//             message: response.data.message,
//             type: 'success',
//         });
//     } catch (error) {
//         const errorMessage = error.response?.data?.error || 'An unexpected error occurred';
//         setNotification({
//             message: errorMessage,
//             type: 'error',
//         });
//     }
//     setIsSaveConfirmationOpen(false);
// };


const handleSaveForm = async () => {
  try {
    // Create a FormData object
    const formData = new FormData();

    // Append maintenance form details (non-file fields)
    for (const key in upReqLetterDetails) {
      if (upReqLetterDetails.hasOwnProperty(key) && key !== 'upload_req_letter' && key !== 'approval_doc') {
        formData.append(key, upReqLetterDetails[key]);
      }
    }

    // Append files if they are available
    if (upReqLetterDetails.upload_req_letter) {
      formData.append('upload_req_letter', upReqLetterDetails.upload_req_letter);
    }

    if (upReqLetterDetails.approval_doc) {
      formData.append('approval_doc', upReqLetterDetails.approval_doc);
    }

    // Append vehicle service dates as a JSON string if needed
    if (upReqLetterDetails.vehicle_service_dates) {
      formData.append(
        'vehicle_service_dates',
        JSON.stringify(upReqLetterDetails.vehicle_service_dates)
      );
    }

    // Send the PUT request to update maintenance details
    const response = await axios.put(
      `${backendUrl}/maintenance/maintenanc`,
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
       
      }
    );

    console.log(response.data);
    setNotification({
      message: response.data.message || 'Maintenance form saved successfully',
      type: 'success',
    });
  } catch (error) {
    console.error('Error saving maintenance form:', error);

    const errorMessage = error.response?.data?.error || 'An unexpected error occurred';
    setNotification({
      message: errorMessage,
      type: 'error',
    });
  }
};

  
  // Handle Notification Close
  const handleCloseNotification = () => {
    setNotification({ message: "", type: "" });
  };

  // use State Reset
  const handleResetForm = () => {
    setupReqLetterDetails({
      vehicle_id: "",
      part_name: "",
      part_number: "",
      part_life_span: "",
      installation_date: "",
      manufactured_date: "",
      expiry_date: "",
      maintenance_date: "",

      files: [],
    });
    setUploadedFiles([]);
    setIsResetConfirmationOpen(false);
  };

  const handleVehicleIDChange = (selectedValue) => {
      setupReqLetterDetails((prevDetails) => ({
        ...prevDetails,
        vehicle_id: selectedValue ,
      }));
    
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setupReqLetterDetails((prevDetails) => ({
      ...prevDetails,
      [name]: value,
    }));
  };

  // Function to handle date input change
  const handleDateChange = (index, event) => {

    const newDates = [...upReqLetterDetails.vehicle_service_dates];
    newDates[index] = event.target.value;
    setupReqLetterDetails({
      ...upReqLetterDetails,
      vehicle_service_dates: newDates,
    });
  };

  // Function to add a new date field
  const addDateField = () => {
    setupReqLetterDetails({
      ...upReqLetterDetails,
      vehicle_service_dates: [...upReqLetterDetails.vehicle_service_dates, ""],
    });
  };

  const getLastMaintenanceID = async () => {
    try {
      const response = await axios.get(
        `${backendUrl}/maintenance/getLastMaintenanceID`
      );
      setupReqLetterDetails({
        ...upReqLetterDetails,
        maintenance_id: response.data.lastMaintenanceId,
      });
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchVehicleDetails();
    getLastMaintenanceID();
  }, []);

  return (
    <div className="fixed top-0 left-0 z-50 flex items-center justify-center w-full h-full bg-gray-200 bg-opacity-80">
      <div className="w-[80%] h-[90%] overflow-y-auto p-8 bg-white rounded-lg">
        <div className="flex items-center justify-between mb-6">
          <h2 className="text-2xl font-semibold">
            Add Upload Request Letter Form
          </h2>
          <button
            type="button"
            onClick={togglePopup}
            className="p-4 text-4xl text-gray-500 transition duration-300 hover:text-gray-700"
          >
            <IoIosCloseCircleOutline />
          </button>
        </div>

        {/* Notification Alert */}
        <Notification
          message={notification.message}
          type={notification.type}
          onClose={handleCloseNotification}
        />

        <form>
          <div className="items-center gap-8 mt-5 ">
            <div className="grid grid-cols-2 gap-y-[30px] gap-x-[60px] text-[20px] ">
              {/* maintenance id */}
              <div>
                <label className="block text-gray-700">Maintenance Id</label>
                <input
                  type="text"
                  name="maintenance_id"
                  className="w-full p-2 mt-2 border border-gray-300 rounded"
                  value={upReqLetterDetails.maintenance_id}
                  // onChange={handleInputChange}
                  readOnly
                />
              </div>

              {/*  Vehicle ID */}
              <div>
                <label className="block text-gray-700">Vehicle ID*</label>

                <select
                  name="vehicle_id"
                  className="w-full p-2 mt-2 border border-gray-300 rounded"
                  value={upReqLetterDetails.vehicle_id}
                  onChange={(e) => handleVehicleIDChange(e.target.value)} // Handle change event
                >
                  <option value="" disabled>
                    Select the vehicle
                  </option>
                  {vehicleDetails.map((items, index) => (
                    <option key={index} value={items.vehicle_id}>
                      {items.vehicle_id} - {items.vehicle_no_plate_no}
                    </option>
                  ))}
                </select>
              </div>

              {/* maintenance_priority */}
              {/* Maintenance Type */}
              <div>
                <label className="block text-gray-700">Maintenance Type</label>
                <select
                  name="maintenance_type"
                  className="w-full p-2 mt-2 border border-gray-300 rounded"
                  value={vehicleDetails.maintenance_type}
                  onChange={handleInputChange}
                >
                  <option value="">Select Maintenance Type</option>
                  <option value="OIL CHANGE">OIL CHANGE</option>
                  {/* <option value="LOW">LOW</option>
                  <option value="MEDIUM">MEDIUM</option> */}
                </select>
              </div>

              {/* Date of Request */}
              <div>
                <label className="block text-gray-700">
                  Vehicle Service date
                </label>
                {upReqLetterDetails.vehicle_service_dates.map((date, index) => (
                  <div key={index} className="flex items-center mt-2">
                    <input
                      type="date"
                      name={`vehicle_service_dates${index}`}
                      className="w-full p-2 border border-gray-300 rounded"
                      value={date || ""}
                      onChange={(e) => handleDateChange(index, e)} // Pass index and event
                    />
                  </div>
                ))}
                <button
                  type="button"
                  className="mt-2 px-4 py-2 bg-blue-500 text-white rounded"
                  onClick={addDateField} // Call addDateField to add a new date input
                >
                  Add Date
                </button>
              </div>

              {/* vehicle service date */}
              <div>
                <label className="block text-gray-700">Date of Request</label>
                <input
                  type="date"
                  name="date_of_request"
                  className="w-full p-2 mt-2 border border-gray-300 rounded"
                  value={upReqLetterDetails.date_of_request}
                  onChange={handleInputChange}
                />
              </div>

              {/* Date of Approval  */}
              <div>
                <label className="block text-gray-700"> Date of Approval</label>
                <input
                  type="date"
                  name="date_of_approval"
                  className="w-full p-2 mt-2 border border-gray-300 rounded"
                  value={upReqLetterDetails.date_of_approval}
                  onChange={handleInputChange}
                />
              </div>

              {/* Description of Issue) */}
              <div>
                <label className="block text-gray-700">
                  Description of Issue
                </label>
                <input
                  type="text"
                  name="desc_of_issue"
                  className="w-full p-2 mt-2 border border-gray-300 rounded"
                  value={upReqLetterDetails.desc_of_issue}
                  onChange={handleInputChange}
                />
              </div>

              {/*  Risk Level*/}
              <div>
                <label className="block text-gray-700"> Risk Level</label>
                <select
                  name="maintenance_priority"
                  className="p-2 border border-black rounded-[12px]"
                  value={upReqLetterDetails.maintenance_priority}
                  onChange={handleInputChange}
                >
                  <option value="">Select Risk Level Type</option>
                  <option value="HIGH">HIGH</option>
                  <option value="LOW">LOW</option>
                  <option value="MEDIUM">MEDIUM</option>
                </select>
              </div>
            </div>
          </div>

          <div className="flex justify-between w-full">
            {/* Request Letter  */}
            <div className=" flex-col  ">
              <h1 className="text-[30px] font-bold mb-8 mt-10">
                Request Letter
              </h1>

              <button
                type="button"
                onClick={handleOpenModal}
                className="p-3 border border-[#069855] rounded-[25px] text-[#069855] mb-4"
              >
                <div className="flex items-center gap-2">
                  <div className="text-[15px] font-bold">
                    Click Here to Upload
                  </div>
                  <IoIosCloudUpload />
                </div>
              </button>

              {uploadedFiles.length > 0 && (
                <div className="mt-5 w-full">
                  <h3 className="mb-2 text-xl font-bold">Selected Files</h3>
                  {uploadedFiles.map((file, index) => (
                    <div
                      key={index}
                      className="flex items-center justify-between p-2 mb-2 bg-yellow-100 border rounded-lg"
                    >
                      <span>{file.name}</span>
                      <button
                        onClick={() => handleRemoveFile(index)}
                        className="px-3 py-1 text-white bg-red-500 rounded"
                      >
                        Remove
                      </button>
                    </div>
                  ))}
                </div>
              )}
            </div>

            {/* Upload Letter */}
            <div className=" flex-col  ">
              <h1 className="text-[30px] font-bold mb-8 mt-10">
                Upload Letter
              </h1>
              <button
                type="button"
                onClick={handleOpenModal2}
                className="p-3 border border-[#069855] rounded-[25px] text-[#069855]"
              >
                <div className="flex items-center gap-2">
                  <div className="text-[15px] font-bold">
                    Click Here to Upload
                  </div>
                  <IoIosCloudUpload />
                </div>
              </button>

              {uploadedFiles2.length > 0 && (
                <div className="mt-5">
                  <h3 className="mb-2 text-xl font-bold">Selected Files</h3>
                  {uploadedFiles2.map((file, index) => (
                    <div
                      key={index}
                      className="flex items-center justify-between p-2 mb-2 bg-yellow-100 border rounded-lg"
                    >
                      <span>{file.name}</span>
                      <button
                        onClick={() => handleRemoveFile2(index)}
                        className="px-3 py-1 text-white bg-red-500 rounded"
                      >
                        Remove
                      </button>
                    </div>
                  ))}
                </div>
              )}
            </div>
          </div>
        </form>

        <div className="mt-5">
          <div className="flex items-center justify-between px-4 py-2">
            <div></div>
            <div className="flex gap-4">
              <button
                type="button"
                className="px-4 py-2 text-green-600 bg-white border border-black rounded-xl"
                onClick={handleOpenResetConfirmation}
              >
                Reset
              </button>
              <button
                type="submit"
                className="px-4 py-2 text-white bg-green-600 border border-black rounded-xl"
                onClick={handleOpenSaveConfirmation}
              >
                Save
              </button>
            </div>
          </div>
        </div>

        {isModalOpen && (
          <div className="fixed top-0 left-0 z-50 flex items-center justify-center w-full h-full bg-gray-200 bg-opacity-80">
            <div className="p-8 bg-white rounded-lg">
              <FileUpload1
                setUploadedFiles={setUploadedFiles}
                uploadedFiles={uploadedFiles}
                onClose={handleCloseModal}
              />
              <button
                className="p-3 py-2 mt-4 text-white bg-red-500 rounded-lg"
                onClick={handleCloseModal}
              >
                Close
              </button>
            </div>
          </div>
        )}

        {isModalOpen2 && (
          <div className="fixed top-0 left-0 z-50 flex items-center justify-center w-full h-full bg-gray-200 bg-opacity-80">
            <div className="p-8 bg-white rounded-lg">
              <FileUpload2
                setUploadedFiles={setUploadedFiles2}
                uploadedFiles={uploadedFiles2}
                onClose={handleCloseModal2}
              />
              <button
                className="p-3 py-2 mt-4 text-white bg-red-500 rounded-lg"
                onClick={handleCloseModal2}
              >
                Close
              </button>
            </div>
          </div>
        )}

        {isResetConfirmationOpen && (
          <div className="fixed top-0 left-0 z-50 flex items-center justify-center w-full h-full bg-gray-200 bg-opacity-80">
            <div className="p-8 bg-white rounded-lg">
              <h2 className="mb-4 text-xl font-bold">Confirm Reset</h2>
              <p>
                Are you sure you want to reset the form? This action cannot be
                undone.
              </p>
              <div className="flex justify-end gap-4 mt-6">
                <button
                  className="px-4 py-2 text-white bg-red-500 rounded-xl"
                  onClick={handleResetForm}
                >
                  Yes, Reset
                </button>
                <button
                  className="px-4 py-2 text-gray-600 bg-white border border-gray-300 rounded-xl"
                  onClick={handleCloseResetConfirmation}
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        )}

        {isSaveConfirmationOpen && (
          <div className="fixed top-0 left-0 z-50 flex items-center justify-center w-full h-full bg-gray-200 bg-opacity-80">
            <div className="p-8 bg-white rounded-lg">
              <h2 className="mb-4 text-xl font-bold">Confirm Save</h2>
              <p>Are you sure you want to save the form data?</p>
              <div className="flex justify-end gap-4 mt-6">
                <button
                  className="px-4 py-2 text-white bg-green-500 rounded-xl"
                  onClick={handleSaveForm}
                >
                  Yes, Save
                </button>
                <button
                  className="px-4 py-2 text-gray-600 bg-white border border-gray-300 rounded-xl"
                  onClick={handleCloseSaveConfirmation}
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Add_upload_request_letter_form;
