import React from "react";
import VCD from '../../../Assets/vehicleFleetImg/Inventory_management.svg'
import FCD from "../../../Assets/vehicleFleetImg/fuel_consuption.svg"
import LocationImg from "../../../Assets/vehicleFleetImg/location_dashboard.svg";
import AnimatedCard from "../../../components/vehicleFleetCard/card";
import usePermissions from "../../../components/permissions/permission";

const Home = () => {
  
const { hasPermission } = usePermissions();
  return (
    <div className="flex flex-wrap justify-center items-center gap-8 p-4 bg-gray-100 min-h-screen">
      {/* Card 1 */}
      {/* {hasPermission (5165) && ( */}
      <AnimatedCard
        imageSrc= {VCD}
        title="Vehicle Count Dashboard"
        link='/inventory-management/vehicle-count-dashboard'
       
      />
    {/* )} */}
      {/* Card 2 */}
      {/* {hasPermission (5170) && ( */}
      <AnimatedCard
        imageSrc={FCD}
        title="Fuel Consumption Dashboard"
        link='/inventory-management/fuel-consumption-dashboard'
      />

      {/* )} */}
      {/* Card 3 */}
      {/* {hasPermission (5175) && ( */}
      <AnimatedCard
        imageSrc={LocationImg}
        title="Location Data Dashboard"
        link='/inventory-management/location-data-dashboard'  
      />
      {/* )} */}
    </div>
  );
};

export default Home;
