import React, { useState, useEffect } from "react";
import axios from "axios";
import Cookies from "js-cookie";
import Items from "../../../Assets/icons/items.png";
import Cube from "../../../Assets/icons/Cube-2.png";
import Toroid from "../../../Assets/icons/SuperToroid-2.png";
import Sales from "../../../Assets/icons/sales.png";
import Card1 from "../../../components/card 1/card1";
import usePermissions from "../../../components/permissions/permission";
import Tot from "../../../Assets/icons/tot.png";
export default function PosItems() {
  const { hasPermission } = usePermissions();
  const currentDate = new Date();
  const formattedDate = currentDate.toISOString().split("T")[0];

  const [billDate, setBillDate] = useState(formattedDate);
  const [totalAvailableItems, setTotalAvailableItems] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");

  // Fetch branch ID from cookie
  const branchId = Cookies.get("branch");

  useEffect(() => {
    const fetchItems = async () => {
      try {
        const res = await axios.get(
          `https://back-ayurveda.erp-quortech.com/v1/inventory/pos/getBranchStockBatchByBatchId/${branchId}/B`
        );
        console.log("Fetched Items: ", res.data);
        setTotalAvailableItems(res.data);
      } catch (error) {
        console.error("Error fetching items:", error);
      }
    };

    if (branchId) {
      fetchItems();
    }
  }, [branchId]);

  // Filter items based on the search query
  useEffect(() => {
    if (searchQuery !== "") {
      const filteredItems = totalAvailableItems.filter((item) =>
        item.item_name.toLowerCase().includes(searchQuery.toLowerCase())
      );
      setTotalAvailableItems(filteredItems);
    }
  }, [searchQuery, totalAvailableItems]);

  return (
    <div className="p-4 bg-background">
      <p className="text-[25px] font-bold ml-6">Item View</p>
      <div className="w-full flex flex-wrap justify-between my-3 px-5">
        {hasPermission(1100) && (
          <Card1
            title1="Total"
            title2="Items"
            subTitle=""
            value={totalAvailableItems.length}
            img={Tot}
          />
        )}
        {hasPermission(1101) && (
          <Card1
            title1="Total Sales"
            title2=""
            subTitle="(Today)"
            info2={1111}
            value={totalAvailableItems.length} // Adjust as needed
            img={Sales}
          />
        )}
        {hasPermission(1102) && (
          <Card1
            title1="Total Sold"
            title2="Items"
            subTitle="(Today)"
            info2={1111}
            value={totalAvailableItems.length} // Adjust as needed
            img={Items}
          />
        )}
      </div>
      <div className="flex justify-between items-center">
        <div className=" flex  items-center gap-5">
          <p className="text-[20px] font-bold">Date </p>
          <p className="label-1">:</p>
          <input
            className="w-[259px] h-[40px] rounded-[46px] border px-5"
            type="date"
            value={billDate}
            onChange={(e) => setBillDate(e.target.value)}
          />
        </div>
        <div className=" flex items-center ">
          <input
            className="w-[259px] h-[40px] rounded-[46px] border px-5"
            type="text"
            placeholder="Search item"
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
          />
        </div>
      </div>

      <div className="w-full h-[640px] bg-[#F3F8FF] rounded-[46px] flex flex-col justify-start my-4">
        <div className="w-full h-[72px] rounded-[46px] bg-[#BFD8AE] flex justify-around items-center px-5 pr-[35px]">
          <p className="font-bold text-[24px] text-[#656565] text-center w-3">
            #
          </p>
          <p className="font-bold text-[24px] text-[#656565] text-center w-[650px]">
            Item Name
          </p>
          <p className="font-bold text-[24px] text-[#656565] text-center w-[200px]">
            Available Qty.
          </p>
        </div>

        <div
          className="w-full flex flex-col justify-around items-center px-5 overflow-y-auto"
          style={{ maxHeight: "480px" }}
        >
          {totalAvailableItems.length > 0
            ? totalAvailableItems.map((item, index) => (
                <div
                  key={index}
                  className="w-full flex justify-around items-center"
                >
                  <p className="font-bold text-[18px] text-[#656565] text-center w-3">
                    {index + 1}
                  </p>
                  <p className="font-bold text-[18px] text-[#656565] text-left w-[650px]">
                    {item.item_name}
                  </p>
                  <p className="font-bold text-[18px] text-[#656565] text-center w-[200px]">
                    {item.current_qty}
                  </p>
                </div>
              ))
            : null}
        </div>
      </div>
    </div>
  );
}
