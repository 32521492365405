/** @format */

import React, { useState, useEffect } from "react";
import { GrPrevious, GrNext } from "react-icons/gr";
import usePermissions from "../../../components/permissions/permission";
import { Link, useNavigate } from "react-router-dom";

const View_Item = () => {
  const { hasPermission } = usePermissions();
  const navigate = useNavigate();
  const [itemData, setItemData] = useState([]);
  const [fetchKey, setFetchKey] = useState(0);
  const [item_name, setItem_name] = useState("");
  const [page, setPage] = useState("");
  const [page1, setPage1] = useState(1); // Default to page 1
  const [limit, setLimit] = useState(40); // Default to limit of 10
  const { REACT_APP_API_ENDPOINT } = process.env;
  const [searchQuery, setSearchQuery] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(15);

  const [selectedStoreItemType, setSelectedStoreItemType] =
    useState("Store Item Type");
  const [subItemType, setSubItemType] = useState("");

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const filteredItems = itemData.filter((item) => {
    const searchMatch =
      !searchQuery ||
      item.item_name.toLowerCase().includes(searchQuery.toLowerCase());

    return searchMatch;
  });

  const currentItems = filteredItems.slice(indexOfFirstItem, indexOfLastItem);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  // Define a function to fetch data
  const fetchData = async () => {
    const endpoint = `${REACT_APP_API_ENDPOINT}/v1/inventory/item/get-all-item?page=${page1}&limit=${limit}`;
    try {
      const response = await fetch(endpoint);
      if (response.ok) {
        const data = await response.json();
        setItemData(data.items);
        setPage(data.totalPages);
        console.log("item data:", data.items);
      } else {
        const errorData = await response.json();
        console.error("Failed to fetch items data:", errorData);
      }
    } catch (error) {
      console.error("Error occurred while fetching items data:", error);
    }
  };

  const handleFetchData = () => {
    if (page1 && limit) {
      fetchData();
    } else {
      alert("Please select both page and limit.");
    }
  };

  // Define a function to fetch data
  const fetchDefaultData = async () => {
    const endpoint = `${REACT_APP_API_ENDPOINT}/v1/inventory/item/get-all-item?page=${page}&limit=${limit}`;
    try {
      const response = await fetch(endpoint);
      if (response.ok) {
        const data = await response.json();
        setItemData(data.items);
        setPage(data.totalPages);
        // console.log("item data:", data.items);
      } else {
        const errorData = await response.json();
        console.error("Failed to fetch items data:", errorData);
      }
    } catch (error) {
      console.error("Error occurred while fetching items data:", error);
    }
  };

  useEffect(() => {
    fetchDefaultData();
  }, [fetchKey]);

  const fetchSearchData = async () => {
    const endpoint = `${REACT_APP_API_ENDPOINT}/v1/inventory/item/search?search=${encodeURIComponent(
      item_name
    )}&page=${page1}`;
    try {
      const response = await fetch(endpoint);
      if (response.ok) {
        const data = await response.json();
        setItemData(data.items);
        setPage(data.totalPages);
        // console.log("item data:", data.items);
      } else {
        const errorData = await response.json();
        console.error("Failed to fetch items data:", errorData);
      }
    } catch (error) {
      console.error("Error occurred while fetching items data:", error);
    }
  };

  useEffect(() => {
    fetchSearchData();
  }, [item_name]);

  const handleDownload = (filePath) => {
    if (!filePath) {
      alert("No file available for download.");
      return;
    }

    const downloadUrl = `${REACT_APP_API_ENDPOINT}/v1/hris/employees/downloadfile?file_path=${encodeURIComponent(
      filePath
    )}`;
    window.location.href = downloadUrl;
  };
  const HandleOnClick = (item) => {
    // Navigate to the new page and pass the object
    navigate("/items/update", {
      state: { selectedRecord: item },
    });
  };

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };

  return (
    <div className="bg-background h-screen overflow-y-auto">
      <div>
        <p className="font-sans text-[#797C80] text-[32px] ml-6 mt-6 font-bold">
          View Items
        </p>
      </div>

      <div className="mt-6">
        <div>
          <div className="ml-6 mt-4">
            <div className="flex flex-col font-poppins font-bold">
              <form className="form relative mb-4">
                <input
                  type="text"
                  required=""
                  placeholder="Search Item Name"
                  className="input rounded-full w-[300px] h-10 px-8 py-3 border-2 border-transparent focus:outline-none focus:border-blue-500 placeholder-gray-400"
                  value={searchQuery}
                  onChange={handleSearchChange}
                />
              </form>
              <div className="flex mb-4">
                <select
                  className="input rounded-full w-[200px] h-[5%] px-8 py-3 border-2 border-transparent focus:outline-none focus:border-blue-500 placeholder-gray-400 mr-4"
                  value={page1}
                  onChange={(e) => setPage1(parseInt(e.target.value))}
                >
                  <option value="">Select Page</option>
                  {Array.from({ length: page }, (_, i) => i + 1).map(
                    (pageNum) => (
                      <option key={pageNum} value={pageNum}>
                        Page {pageNum}
                      </option>
                    )
                  )}
                </select>

                <select
                  className="input rounded-full w-[200px] h-[5%] px-8 py-3 border-2 border-transparent focus:outline-none focus:border-blue-500 placeholder-gray-400"
                  value={limit}
                  onChange={(e) => setLimit(parseInt(e.target.value))}
                >
                  <option value="">Select Limit</option>
                  <option value="10">10</option>
                  <option value="20">20</option>
                  <option value="30">30</option>
                  <option value="40">40</option>
                  <option value="50">50</option>
                </select>
                <button
                  className="bg-primary text-white px-4 py-2 rounded-full ml-4"
                  onClick={handleFetchData}
                >
                  Get Item Data
                </button>
              </div>
            </div>
          </div>

          <div className="overflow-y-auto max-h-[calc(100vh-200px)] ml-5 mr-5 rounded-[22px] ">
            <table className="table-auto border-none overflow-x-auto w-full">
              <thead>
                <tr className="bg-white">
                  <th className="text-primary justify-center text-[15px] font-bold py-4">
                    Item ID
                  </th>
                  <th className="text-primary text-[15px] font-bold py-4">
                    Item Name
                  </th>
                  <th className="text-primary text-[15px] font-bold py-4">
                    Item Measure Unit
                  </th>
                  <th className="text-primary text-[15px] font-bold py-4">
                    Item Description
                  </th>
                  <th className="text-primary text-[15px] font-bold py-4">
                    Item Category ID
                  </th>
                  <th className="text-primary text-[15px] font-bold py-4">
                    Item Status
                  </th>
                  <th className="text-primary text-[15px] font-bold py-4">
                    Action
                  </th>
                </tr>
              </thead>
              <tbody>
                {currentItems.map((items) => (
                  <tr key={items._id} className="bg-white text-center">
                    <td className="py-4">{items.item_id}</td>
                    <td className="py-4">{items.item_name}</td>
                    <td className="py-4">{items.item_measure_unit}</td>
                    <td className="py-4">{items.item_description}</td>
                    <td className="py-4">{items.item_category_id}</td>
                    <td className="py-4">{items.item_status}</td>
                    <td className="py-4">
                      {hasPermission(1670) && (
                        <button
                          onClick={() => HandleOnClick(items)}
                          className="bg-primary rounded-[10px] p-2 text-white"
                        >
                          Edit
                        </button>
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <div className="flex justify-center mt-4">
            <button
              onClick={() => paginate(currentPage - 1)}
              disabled={currentPage === 1}
              className="bg-primary text-white px-4 py-2 mx-1 rounded-md"
            >
              <GrPrevious className="w-3 h-3" />
            </button>
            {[...Array(Math.ceil(filteredItems.length / itemsPerPage))].map(
              (page, index) => (
                <button
                  key={index}
                  onClick={() => paginate(index + 1)}
                  className={`${
                    currentPage === index + 1 ? "bg-gray-300" : "bg-white"
                  } text-primary px-4 py-2 mx-1 rounded-md`}
                >
                  {index + 1}
                </button>
              )
            )}
            <button
              onClick={() => paginate(currentPage + 1)}
              disabled={
                currentPage === Math.ceil(filteredItems.length / itemsPerPage)
              }
              className="bg-primary text-white px-4 py-2 mx-1 rounded-md"
            >
              <GrNext className="w-3 h-3" />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default View_Item;
