/** @format */

import React, { useState, useEffect, useCallback, useMemo } from "react";

const Item_Permission = ({ onPermissionsChange }) => {
  const [isVisible, setIsVisible] = useState(false);
  const [checkedValues, setCheckedValues] = useState({});

  const toggleVisibility = () => {
    setIsVisible(!isVisible);
  };
  //that shoulde change

  const permissionHierarchy = useMemo(
    () => ({
      10: { children: [190, 191] },
      191: { children: [1670, 1680, 1690] },
      // Add the rest of your permissions here
    }),
    []
  );
  const handleCheckboxChange = (value, dependentValue) => {
    setCheckedValues((prev) => {
      const newValues = { ...prev, [value]: !prev[value] };

      if (dependentValue && !prev[dependentValue]) {
        newValues[dependentValue] = true;
      }

      // Automatically check parent checkboxes if child checkbox is checked
      if (newValues[value]) {
        Object.keys(permissionHierarchy).forEach((key) => {
          if (permissionHierarchy[key]?.children.includes(value)) {
            newValues[key] = true;
          }
        });
      }

      // Automatically check child checkboxes if parent checkbox is checked
      if (permissionHierarchy[value] && newValues[value]) {
        permissionHierarchy[value].children.forEach((child) => {
          newValues[child] = true;
        });
      }

      return newValues;
    });
  };

  const updatePermissions = useCallback(() => {
    const selectedPermissions = Object.keys(checkedValues)
      .filter((key) => checkedValues[key])
      .map(Number);

    if (
      selectedPermissions.some((perm) =>
        permissionHierarchy[10].children.includes(perm)
      ) &&
      !selectedPermissions.includes(10)
    ) {
      selectedPermissions.push(10);
    }

    onPermissionsChange(selectedPermissions);
  }, [checkedValues, onPermissionsChange, permissionHierarchy]);

  useEffect(() => {
    updatePermissions();
  }, [checkedValues, updatePermissions]);

  return (
    <div>
      <div>
        <div>
          <p
            className="font-bold text-[20px] ml-[20%] mt-4 underline"
            Value="1"
          >
            Item
          </p>
          <div className="ml-[20%]">
            <button
              className="bg-blue-500 p-3 mt-5 text-white font-semibold rounded-[30px]"
              onClick={toggleVisibility}
            >
              {isVisible ? "Hide" : "Show"} Details
            </button>
          </div>
        </div>
        <div>
          {isVisible && (
            <div className="ml-[20%] mt-5 grid grid-cols-3 gap-10">
              <div>
                <p
                  className="bg-gray-200 rounded-[20px] p-5 font-bold text-[15px] text-black"
                  value="2"
                >
                  Add Item
                </p>
                <label className="block mb-2 text-blue-500">
                  <input
                    type="checkbox"
                    className="mr-2"
                    value="190"
                    checked={!!checkedValues[190]}
                    onChange={() => handleCheckboxChange(190)}
                  />
                  Add Item
                </label>
              </div>
              <div>
                <p className="bg-gray-200 rounded-[20px] p-5 font-bold text-[15px] text-black">
                  View Item
                </p>
                <div className="ml-5 mt-2">
                  <label className="block mb-2 text-blue-500">
                    <input
                      type="checkbox"
                      className="mr-2"
                      value="1670"
                      checked={!!checkedValues[1670]}
                      onChange={() => handleCheckboxChange(1670)}
                    />
                    Edit
                  </label>
                  <label className="block mb-2 text-blue-500">
                    <input
                      type="checkbox"
                      className="mr-2"
                      value="1680"
                      checked={!!checkedValues[1680]}
                      onChange={() => handleCheckboxChange(1680)}
                    />
                    Update Item Details
                  </label>
                  <label className="block mb-2 text-blue-500">
                    <input
                      type="checkbox"
                      className="mr-2"
                      value="1690"
                      checked={!!checkedValues[1690]}
                      onChange={() => handleCheckboxChange(1690)}
                    />
                    Delete Item Details
                  </label>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Item_Permission;
