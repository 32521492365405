import axios from "axios";
import React, { useEffect, useState } from "react";
import Notification from "../../../components/notification/notification";

const EndProductData = () => {
  const backendUrl = process.env.REACT_APP_BACKEND_URL_FINANCE;

  const [data, setDeta] = useState([]);
  const [allaccount, setAllAccount] = useState([]);
  const [selectedAccount, setSelectedAccount] = useState({});
  const [notification, setNotification] = useState({ message: "", type: "" });

  


  const handleAccountChange = (entryId, accountId) => {
    setSelectedAccount((prev) => ({ ...prev, [entryId]: accountId }));
  };

  // test

  const handleSubmit = async () => {
    const user = getCookieValue("employee_no");
    const submissionData = {
      updates: Object.entries(selectedAccount).map(([id, account_id]) => ({
        id: parseInt(id),
        account_id: account_id.toString(),
      })),
      action_by: user,
    };
  
    try {
      const response = await axios.put(
        `${backendUrl}/account/accountAuthorization/updateAccountAuthorization`,
        submissionData
      );
  
      setNotification({
        message: response.data.message,
        type: "success",
      });
  
    } catch (error) {
      console.error("Error", error);
  
      // Check for a possible null error response
      const errorMessage =
        error.response && error.response.data && error.response.data.error
          ? error.response.data.error
          : "An unexpected error occurred";
  
      setNotification({
        message: errorMessage,
        type: "error",
      });
    }
  };
  


  const handleCloseNotification = () => {
    setNotification({ message: "", type: "" });
  };

  

  async function getAccountAuthorization() {
    try {
      const response = await axios.get(
        `${backendUrl}/account/accountAuthorization/getAccountsAuthorization`,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      setDeta(response.data);

      // Handle the response data
      console.log("Account Authorization Details:", response.data);
      return response.data;
    } catch (error) {
      console.error("Error fetching account authorization:", error);
    }
  }

  const getAccountData = async () => {
    try {
      const response = await axios.get(
        `${backendUrl}/account/getAllAccountsByFilters`
      );

      const formattedData = response.data.data.map((item) => ({
        id: item.account_id,
        code: item.account_code,
        name: item.account_name,
      }));

      setAllAccount(formattedData);
    } catch (error) {
      console.error("Error fetching sample details:", error);
    }
  };

  const getCustomScenarioName = (scenarioName) => {
    switch (scenarioName) {
      case "POS create":
        return "Please select financial accounts for scenario entries in POS sale create";
      case "POS return":
        return "Please select financial accounts for scenario entries in POS sale return";
      case "Inventory dispose":
        return "Please select financial accounts for scenario entries in inventory dispose";
      case "End product create":
        return "Please select financial accounts for scenario entries in product creation";
      case "GRN create":
        return "Please select financial accounts for scenario entries in GRN creation";
      default:
        return scenarioName;
    }
  };

  const getCustomEntryName = (scenarioName, entryName) => {
    if (scenarioName === "POS create") {
      switch (entryName) {
        case "POS add sale debit cash":
          return "Asset cash account to debit in POS sale by cash";
        case "POS add sale debit card":
          return "Asset bank account to debit in POS sale by bank card";
        case "POS add sale debit credit":
          return "Asset receivable account to debit in POS sale by credit";
        case "POS add sale credit revenue":
          return "Revenue account to credit in POS sale";
        case "POS add cost debit":
          return "Cost account to debit in POS sale";
        case "POS add asset credit":
          return "Asset account to credit in POS sale";
        default:
          return entryName;
      }
    } else if (scenarioName === "POS return") {
      switch (entryName) {
        case "POS return asset debit":
          return "Asset account to debit in POS sale return";
        case "POS return cost credit":
          return "Cost account to credit in POS sale return";
        default:
          return entryName;
      }
    } else if (scenarioName === "Inventory dispose") {
      switch (entryName) {
        case "Inventory dispose expense debit":
          return "Expense account to debit in inventory item dispose";
        case "Inventory dispose assets credit":
          return "Assets account to credit in inventory item dispose";
        default:
          return entryName;
      }
    } else if (scenarioName === "End product create") {
      switch (entryName) {
        case "Product create asset debit":
          return "Asset account to debit in product creation";
        case "Product create asset credit":
          return "Asset account to credit in product creation";
        default:
          return entryName;
      }
    } else if (scenarioName === "GRN create") {
      switch (entryName) {
        case "GRN create asset debit":
          return "Asset account to debit in GRN issued";
        case "GRN create accrued liability credit":
          return "Accrued liability account to credit in GRN issued";
        default:
          return entryName;
      }
    }
    // Fallback if no match is found
    return entryName;
  };

  useEffect(() => {
    getAccountAuthorization();
    getAccountData();
  }, []);

  return (
    <div className="container mx-auto p-6 bg-gray-50">
      <h1 className="text-3xl font-bold text-center mb-6">
        Account Authorization
      </h1>

          {/* Notification Alert */}
          <Notification
          message={notification.message}
          type={notification.type}
          onClose={handleCloseNotification}
        />
      {data.map((scenario, index) => (
        <div
          key={index}
          className="mb-6 p-5 bg-white rounded-lg shadow-lg hover:shadow-xl transition-shadow duration-200"
        >
          <h2 className="text-2xl font-semibold text-gray-800 mb-4">
            {getCustomScenarioName(scenario.scenario_name)}
          </h2>
          <ul className="space-y-3 ">
            {scenario.entries.map((entry) => (
              <li
                key={entry.id}
                className="flex items-center justify-between p-3 bg-gray-100 rounded-lg "
              >
                <span className="font-medium text-gray-700">
                  {getCustomEntryName(scenario.scenario_name, entry.scenario_entry_name)}
                </span>
                <select
                  value={selectedAccount[entry.id] || ""} // Default to empty to leave it unselected initially
                  onChange={(e) =>
                    handleAccountChange(entry.id, e.target.value)
                  }
                  className="border border-gray-300 rounded-lg p-2 bg-white focus:outline-none focus:ring-2 focus:ring-blue-500 w-80"
                >
                  <option value="" disabled>
                    {entry.account_name}
                  </option>
                  {/* Placeholder option */}
                  {allaccount.map((option) => (
                    <option key={option.id} value={option.id}>
                      {`${option.code} - ${option.name}`}
                    </option>
                  ))}
                </select>
              </li>
            ))}
          </ul>
          <div className="flex justify-end mt-4">
            <button
              onClick={() => handleSubmit(scenario.scenario_name)}
              className="bg-blue-600 text-white px-4 py-2 rounded-lg shadow transition-transform transform hover:scale-105"
            >
              Submit
            </button>
          </div>
        </div>
      ))}
    </div>
  );
};

export default EndProductData;

const getCookieValue = (cookieName) => {
  const name = cookieName + "=";
  const decodedCookie = decodeURIComponent(document.cookie);
  const cookieArray = decodedCookie.split(";");
  for (let i = 0; i < cookieArray.length; i++) {
    let cookie = cookieArray[i].trim();
    if (cookie.indexOf(name) === 0) {
      return cookie.substring(name.length, cookie.length);
    }
  }
  return "";
};
