import React from "react";
import { PieChart, Pie, Cell, ResponsiveContainer, Label } from "recharts";

const COLORS = ["#FF00FF", "#FFFF99", "#99FF99", "#FF9933", "#CC99FF"];

const RADIAN = Math.PI / 180;

// Function to render the customized labels for each segment
const renderCustomizedLabel = ({
  cx,
  cy,
  midAngle,
  innerRadius,
  outerRadius,
  percent,
  name,
}) => {
  const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
  const x = cx + radius * Math.cos(-midAngle * RADIAN);
  const y = cy + radius * Math.sin(-midAngle * RADIAN);

  return (
    <text
      x={x}
      y={y}
      fill="black"
      textAnchor={x > cx ? "start" : "end"}
      dominantBaseline="central"
    >
      {`${name}: ${(percent * 100).toFixed(0)}%`}
    </text>
  );
};

const Pie_chart = ({ data }) => {
  // console.log(data);
  // const chartData = [
  //   { name: "CAR MALFUNCTION", value: 5.00, fill: "#6ab04c" },
  //   { name: "OPERATIONAL", value: 30.00, fill: "#FF00FF" },
  //   { name: "UNDER MAINTENANCE", value: 70.00, fill: "#FFFF99" },
  // ];

  return (
    <ResponsiveContainer width="100%" height={500}>
      <PieChart>
        <Pie
          data={data}
          cx="50%"
          cy="50%"
          labelLine={false}
          label={renderCustomizedLabel} // Use the customized label function
          outerRadius={150} // Increase the outer radius for a larger pie chart
          fill="#8884d8"
          dataKey="value"
        >
          {data.map((entry, index) => (
            <Cell key={`cell-${index}`} fill={entry.fill} />
          ))}
        </Pie>
      </PieChart>
    </ResponsiveContainer>
  );
};

export default Pie_chart;
