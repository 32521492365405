/** @format */

import React, { useEffect, useState } from "react";
import AsyncSelect from "react-select/async";
import "./updateItem.css";
import axios from "axios";
import { Link, useNavigate, useLocation } from "react-router-dom";
import usePermissions from "../../../components/permissions/permission";
import Cookies from "js-cookie";
import Arrow from "../../../Assets/icons/down-arrow.png";

export default function UpdateItem() {
  const location = useLocation();
  const navigate = useNavigate();
  const { REACT_APP_API_ENDPOINT } = process.env;
  const [fetchKey, setFetchKey] = useState(0);
  const selectedRecord = location.state?.selectedRecord || {};
  const { hasPermission } = usePermissions();
  const currentDate = new Date(); // Get the current date and time
  const formattedDate = currentDate.toISOString(); // Format the date to ISO string
  const userId = Cookies.get("employee_no");
  const [itemCategoryData, setItemCategoryData] = useState([]);
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);

  const [data, setData] = useState({
    item_id: selectedRecord.item_id || "",
    item_name: selectedRecord.item_name || "",
    item_measure_unit: selectedRecord.item_measure_unit || "",
    item_description: selectedRecord.item_description || "",
    item_update_date: selectedRecord.item_update_date || formattedDate,
    item_update_user_id: selectedRecord.item_update_user_id || userId,
    item_status: selectedRecord.item_status || "ACTIVE",
    item_category_id: selectedRecord.item_category_id || "",
  });

  const fetchData = async () => {
    try {
      const response = await fetch(
        `${REACT_APP_API_ENDPOINT}/v1/inventory/itemCategory/get-couple-category`
      );
      const data = await response.json();
      setItemCategoryData(data);
      // console.log("Items:", itemCategoryData);
    } catch (error) {
      console.error("Error fetching Item Category data:", error);
    }
  };

  useEffect(() => {
    fetchData();
  }, [fetchKey]);

  const UpdateItemHandler = async () => {
    if (data.item_id !== "") {
      try {
        const updateData = {
          item_name: data.item_name,
          item_category_id: data.item_category_id,
          item_description: data.item_description,
          item_measure_unit: data.item_measure_unit,
          item_update_date: formattedDate,
          item_update_user_id: userId,
          item_status: data.item_status,
        };

        const res = await fetch(
          `${process.env.REACT_APP_BACKEND_URL}/item/update/${data.item_id}`,
          {
            method: "PUT",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(updateData),
          }
        );

        const contentType = res.headers.get("content-type");
        if (res.ok) {
          if (contentType && contentType.includes("application/json")) {
            const responseData = await res.json();
            console.log(responseData);
            alert("Item Updated Successfully");
            navigate(-1);
          } else {
            const textData = await res.text();
            console.log("Non-JSON response:", textData);
            alert("Item Updated Successfully");
            navigate(-1);
          }
        } else {
          if (res.status === 400) {
            if (contentType && contentType.includes("application/json")) {
              const errorResponse = await res.json();
              alert(`Error: ${errorResponse.error}`);
            } else {
              const errorText = await res.text();
              alert(`Error: ${errorText}`);
            }
          } else if (res.status === 409) {
            alert("Item Already Exists");
          } else if (res.status === 500) {
            alert("Internal Server Error");
          } else {
            const errorText = await res.text();
            console.error("Error response:", errorText);
            alert("Something Went Wrong: " + errorText);
          }
        }
      } catch (error) {
        console.error("Error occurred while updating item:", error);
        alert("Something Went Wrong");
      }
    }
  };

  const DeleteItemHandler = async () => {
    if (data.item_id !== "") {
      try {
        const res = await fetch(
          `${process.env.REACT_APP_BACKEND_URL}/item/delete/${data.item_id}`,
          {
            method: "DELETE",
          }
        );

        if (res.ok) {
          const responseData = await res.json();
          console.log(responseData);
          alert("Item Deleted Successfully");
          navigate(-1); // Navigate back to the previous page
        } else {
          if (res.status === 400) {
            alert("Fill all fields");
          } else if (res.status === 409) {
            alert("Item Already Exists");
          } else if (res.status === 500) {
            alert("Internal Server Error");
          } else {
            alert("Something Went Wrong");
          }
        }
      } catch (error) {
        console.error("Error occurred while deleting item:", error);
        alert("Something Went Wrong");
      }
    }
  };

  const CancelItemHandler = () => {
    navigate(-1); // Navigate back to the previous page
  };

  return (
    <div className="bg-background h-screen overflow-y-auto">
      <div>
        <p className="font-sans text-[#797C80] text-[32px] ml-6 mt-6 font-bold">
          Update Item
        </p>
      </div>
      <div className="w-full flex items-center justify-center">
        <div className="bg-[#F3F8FF] rounded-[46px] border border-[#00000036] px-[200px] py-11 my-10 flex flex-col items-center justify-center">
          <p className="header-1 text-center">Update Item</p>
          <div className="w-[600px] flex items-center justify-between my-10">
            <label className="label-1">Item Id</label>
            <label className="label-1">:</label>
            <input
              className="input-1 border border-[#00000036]"
              disabled
              value={data.item_id}
              // onChange={(e) => setData({ ...data, item_id: e.target.value })}
            />
          </div>
          <div className="w-[600px] flex items-center justify-between my-10">
            <label className="label-1">Item Name</label>
            <label className="label-1">:</label>
            <input
              className="input-1 border border-[#00000036]"
              value={data.item_name}
              onChange={(e) => setData({ ...data, item_name: e.target.value })}
            />
          </div>
          <div className="w-[600px] flex items-center justify-between my-10">
            <label className="label-1">Item Category ID</label>
            <label className="label-1">:</label>
            <select
              className="input-1 border border-[#00000036] no-arrow"
              value={data.item_category_id}
              disabled
              onChange={(e) =>
                setData({ ...data, item_category_id: e.target.value })
              }
            >
              <option value="">{data.item_category_id}</option>
              {itemCategoryData.map((items, index) => (
                <option key={index} value={items.sub_category_id}>
                  {items.item_category_name} - {items.item_sub_category_name}
                </option>
              ))}
            </select>
          </div>
          <div className="w-[600px] flex items-center justify-between my-10">
            <label className="label-1">Item Measure Unit</label>
            <label className="label-1">:</label>
            <select
              className="input-1 border border-[#00000036] no-arrow"
              value={data.item_measure_unit}
              onChange={(e) =>
                setData({ ...data, item_measure_unit: e.target.value })
              }
            >
              <option value="">select measure unit</option>
              <option value="kg">kg</option>
              <option value="l">l</option>
              <option value="pcs">pcs</option>
            </select>
          </div>
          <div className="w-[600px] flex items-center justify-between my-10">
            <label className="label-1">Item Description</label>
            <label className="label-1">:</label>

            <textarea
              className="form-input border border-[#00000036]"
              value={data.item_description}
              onChange={(e) =>
                setData({ ...data, item_description: e.target.value })
              }
            />
          </div>
        </div>
      </div>
      <div className=" flex items-center justify-end gap-x-6 mb-10 px-[150px]">
        <button
          className="w-[118px] h-[45px] flex items-center justify-center bg-transparent border border-black rounded-[23px] px-4"
          onClick={CancelItemHandler}
        >
          <p className="p2 text-black">Cancel</p>
        </button>
        {hasPermission(1690) && (
          <button
            className="w-[118px] h-[45px] flex items-center justify-center bg-[#ab2626] border rounded-[23px] px-4"
            onClick={() => setShowDeleteConfirmation(true)}
          >
            <p className="p2 text-white">Delete</p>
          </button>
        )}
        {hasPermission(1680) && (
          <button
            className="w-[118px] h-[45px] flex items-center justify-center bg-[#5B6D49] border rounded-[23px] px-4"
            onClick={UpdateItemHandler}
          >
            <p className="p2 text-white">Update</p>
          </button>
        )}
      </div>

      {showDeleteConfirmation && (
        <div className="fixed inset-0 bg-gray-600 bg-opacity-50 flex items-center justify-center">
          <div className="bg-white p-6 rounded-[20px]">
            <p className="mb-4 font-semibold text-[18px]">
              Are you sure you want to Delete?
            </p>
            <div className="flex justify-center">
              <button
                className="bg-gray-200 px-4 py-2 rounded-md mr-2 font-semibold"
                onClick={() => setShowDeleteConfirmation(false)}
              >
                Cancel
              </button>
              <button
                className="bg-[#ab2626] text-white px-4 py-2 font-semibold rounded-md"
                onClick={DeleteItemHandler}
              >
                Yes, Delete
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
